import store from '@/store'
import i18n from '@/i18n'
import router from '@/router'
import { ROUTER } from '@/router'

const $t = (val, params = {}) => i18n.global.t(val, params)

const joinUrlPath = (...props) =>
  props.reduce((acc, str) => acc + (str[0] === '/' ? str : `/${str}`))

const authHeader = () => {
  let token = localStorage.getItem('token')
  if (typeof token === 'string') {
    return { Authorization: 'Bearer ' + token }
  } else {
    return {}
  }
}

export default class Rest {
  constructor(axios) {
    this.axios = axios.create()
  }
  get(url, params = {}, { isThirdParty = false, isAuth = false } = {}) {
    const _params = Object.assign({}, params, {
      nocache: new Date().getTime(), // Safari fix
      lang: i18n.global.locale,
      platform: 'desktop',
    })
    const headers = isAuth ? authHeader() : {}

    if (isThirdParty) {
      return new Promise((resolve, reject) => {
        this.axios
          .get(url, {
            params: _params,
          })
          .then((res) => {
            resolve({
              data: res.data,
            })
          })
          .catch((error) => {
            reject(error)
          })
      })
    }

    return new Promise((resolve, reject) => {
      this.axios
        .get(joinUrlPath(process.env.VUE_APP__API_URL, url), {
          params: _params,
          headers,
        })
        .then((res) => {
          resolve({
            data: res.data.data,
            status: res.data.status || 'OK',
            success: res.data.success,
          })
        })
        .catch((error) => {
          const statusCodeGroups = {
            1: 'information',
            3: 'redirect',
            4: 'client',
            5: 'server',
            6: 'nonClientServerError',
          }
          const status = error.response?.status || 600
          const statusCodeGroup = statusCodeGroups[Math.floor(status / 100)]

          switch (statusCodeGroup) {
            case statusCodeGroups[4]:
              if (status === 401) {
                store.dispatch('user/reset')
                router.push({ name: ROUTER.NAME.AUTH.LOGIN })
                reject({
                  status: status,
                  errors: error.response.data.errors,
                  data: error.response.data.data,
                  title: $t('error.e400.new.title'),
                })
              }
              if (error.response.data.errors) {
                reject({
                  status: status,
                  errors: error.response.data.errors,
                  data: error.response.data.data,
                  title: $t('error.e400.new.title'),
                })
              } else {
                reject({
                  status: status,
                  errors: {
                    data: [$t('error.eUnknown.new.text')],
                  },
                  title: $t('error.eUnknown.new.title'),
                })
              }
              break
            case statusCodeGroups[5]:
              reject({
                status: status,
                errors: {
                  data: [$t('error.e500.new.text')],
                },
                title: $t('error.e500.new.title'),
              })
              throw new Error(
                JSON.stringify({
                  code: status,
                  errorID: '500 ejf8e9fyw41',
                  url,
                  errorContent: error.message,
                })
              )
            default:
              reject({
                status: status,
                errors: {
                  data: [$t('error.eUnknown.new.text')],
                },
                title: $t('error.eUnknown.new.title'),
              })
              throw new Error(
                JSON.stringify({
                  errorID: '600 ejf8fffyw32',
                  url,
                  errorContent: error.message || error,
                })
              )
          }
        })
    })
  }
  post(url, params, { isAuth = false, headers = {} } = {}) {
    const _params = Object.assign({}, params, {
      lang: i18n.global.locale,
      platform: 'desktop',
    })
    const _headers = isAuth ? authHeader() : {}
    return new Promise((resolve, reject) => {
      this.axios
        .post(joinUrlPath(process.env.VUE_APP__API_URL, url), _params, {
          headers: {
            ..._headers,
            ...headers,
          },
        })
        .then((res) => {
          resolve({
            data: res.data.data,
            status: res.data.status || 'OK',
            success: res.data.success,
          })
        })
        .catch((error) => {
          const statusCodeGroups = {
            1: 'information',
            3: 'redirect',
            4: 'client',
            5: 'server',
            6: 'nonClientServerError',
          }
          const status = error.response?.status || 600
          const statusCodeGroup = statusCodeGroups[Math.floor(status / 100)]

          switch (statusCodeGroup) {
            case statusCodeGroups[4]:
              if (status === 401) {
                store.dispatch('user/reset')
                router.push({ name: ROUTER.NAME.AUTH.LOGIN })
                reject({
                  status: status,
                  errors: error.response.data.errors,
                  data: error.response.data.data,
                  title: $t('error.e400.new.title'),
                })
              }
              if (error.response.data.errors) {
                reject({
                  status: status,
                  errors: error.response.data.errors,
                  data: error.response.data.data,
                  title: $t('error.e400.new.title'),
                })
              } else {
                reject({
                  status: status,
                  errors: {
                    data: [$t('error.eUnknown.new.text')],
                  },
                  title: $t('error.eUnknown.new.title'),
                })
              }
              break
            case statusCodeGroups[5]:
              reject({
                status: status,
                errors: {
                  data: [$t('error.e500.new.text')],
                },
                title: $t('error.e500.new.title'),
              })
              throw new Error(
                JSON.stringify({
                  code: status,
                  errorID: '500 ejf8e9fyw31',
                  url,
                  errorContent: error.message,
                })
              )
            default:
              reject({
                status: status,
                errors: {
                  data: [$t('error.eUnknown.new.text')],
                },
                title: $t('error.eUnknown.new.title'),
              })
              throw new Error(
                JSON.stringify({
                  errorID: '600 ejf8e9fyw32',
                  url,
                  errorContent: error.message || error,
                })
              )
          }
        })
    })
  }
}
