<template>
  <div class="loader">
    <div class="loader__progress-wrapper">
      <icon
        class="loader__logo"
        :name="$t('web.icon_logo')"
        width="120"
        height="120"
        padding="6"
      >
        <logo />
      </icon>
      <div class="loader__loading loading">
        <div class="loading__dot loading__dot--first"></div>
        <div class="loading__dot loading__dot--second"></div>
        <div class="loading__dot loading__dot--third"></div>
        <div class="loading__dot loading__dot--fourth"></div>
        <div class="loading__dot loading__dot--fifth"></div>
      </div>
    </div>
  </div>
</template>

<script>
import Icon from '@/components/Icon'
import { defineAsyncComponent } from 'vue'
export default {
  name: 'Loader',
  components: {
    Icon,
    Logo: defineAsyncComponent(() =>
      import(
        `@/whitelabel/${process.env.VUE_APP__APP_NAME}/image/icons/svg/logo-second.svg`
      )
    ),
  },
}
</script>

<style scoped lang="scss">
.loader {
  background-color: white;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  z-index: 100000;

  &__progress-wrapper {
    position: relative;
    padding-bottom: 48px;
  }

  &__logo {
    filter: drop-shadow(1px 1px 0px var(--main-dark-extra))
      drop-shadow(-1px -1px 0px var(--main-light-extra));
    animation: logotype 2s infinite ease-in-out;
  }

  &__loading {
    position: absolute;
    z-index: 100001;
    width: calc(100% + 80px);
    margin: 0 -40px;
    height: 24px;
    bottom: 0;
    &.loading {
      display: flex;
      align-items: center;
      justify-content: space-around;
    }
    .loading {
      &__dot {
        transform: scale(0, 0);
        border-radius: 50%;
        background-color: var(--main);
        animation: loading-dot 1s infinite ease-in-out;
        &--first {
          animation-delay: 0s;
          height: 12px;
          width: 12px;
        }
        &--second {
          animation-delay: 0.15s;
          height: 18px;
          width: 18px;
        }
        &--third {
          animation-delay: 0.3s;
          height: 24px;
          width: 24px;
        }
        &--fourth {
          animation-delay: 0.45s;
          height: 18px;
          width: 18px;
        }
        &--fifth {
          animation-delay: 0.6s;
          height: 12px;
          width: 12px;
        }
      }
    }
  }

  @keyframes logotype {
    0% {
      transform: scale(1, 1);
      timing-function: ease-in-out;
    }
    23% {
      transform: scale(1, 1);
      timing-function: ease-in-out;
    }
    25% {
      transform: scale(1.04, 1.04);
      timing-function: ease-in-out;
    }
    75% {
      transform: scale(1, 1);
      timing-function: ease-in-out;
    }
    100% {
      transform: scale(1, 1);
      timing-function: ease-in-out;
    }
  }
  @keyframes loading-dot {
    0% {
      transform: scale(0, 0);
      timing-function: ease-in-out;
    }
    35% {
      transform: scale(1, 1);
      timing-function: ease-in-out;
    }
    70% {
      transform: scale(0, 0);
      timing-function: ease-in-out;
    }
    100% {
      transform: scale(0, 0);
      timing-function: ease-in-out;
    }
  }
}
</style>
