import { createApp } from 'vue'
require(`./whitelabel/${process.env.VUE_APP__APP_NAME}/theme.css`)
import App from './App.vue'
import * as Sentry from '@sentry/browser'
import { Integrations } from '@sentry/tracing'
import router from './router'
import store from './store'
import i18n from './i18n'
import whitelabelI18NPlugin from './plugins/whitelabel-i18n'
import apiPlugin from './plugins/api'
import momentPlugin from './plugins/moment'
import routerPlugin from './plugins/router'
import constantsPlugin from './plugins/constants'
import axios from 'axios'
import VueAxios from 'vue-axios'
import 'bootstrap/dist/css/bootstrap.min.css'
import VueFinalModal from 'vue-final-modal'
import Notifications from '@kyvg/vue3-notification'
import VueSmoothScroll from 'vue3-smooth-scroll'
import Markdown from 'vue3-markdown-it'
import 'bootstrap'

window.getVersion = () => console.log(process.env.VERSION)

if (process.env.VUE_APP__MODE === 'production') {
  Sentry.init({
    dsn: process.env.VUE_APP__SENTRY_URL,
    release: `${process.env.VUE_APP__APP_NAME}@${process.env.VERSION}`,
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 1.0,
  })
}

const app = createApp(App)
app.config.errorHandler = (err) => {
  Sentry.captureException(err)
}
app
  .use(store)
  .use(router)
  .use(i18n)
  .use(whitelabelI18NPlugin, i18n)
  .use(apiPlugin, axios)
  .use(VueAxios, axios)
  .use(momentPlugin)
  .use(routerPlugin)
  .use(constantsPlugin)
  .use(VueFinalModal())
  .use(Notifications)
  .use(VueSmoothScroll)
  .use(Markdown)
  .mount('#app')

window.addEventListener('error', (event) => {
  if (event?.message && /Loading chunk .* failed/i.test(event.message)) {
    Sentry.captureMessage('Chunk Error Resolved in error listener')
    window.location.reload()
    return
  }
  Sentry.captureException(event)
})
window.addEventListener('unhandledrejection', (event) => {
  if (event?.message && /Loading chunk .* failed/i.test(event.message)) {
    Sentry.captureMessage('Chunk Error Resolved in unhandledrejection listener')
    window.location.reload()
    return
  }
  if (event instanceof Error) {
    Sentry.captureException(event)
  } else if (typeof event === 'object' && event !== null) {
    Sentry.captureException(
      new Error('Error 75k67ky: ' + JSON.stringify({ ...event }))
    )
  } else if (typeof event === 'string') {
    Sentry.captureException(new Error('Error bh4twefsd: ' + event))
  } else {
    Sentry.captureException(event)
  }
})

export default app.config.globalProperties
