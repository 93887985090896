import Api from './Api'

let api = new Api()

export const createApi = (axios) => {
  api.create(axios)
  return api
}

export default api
