const state = () => ({
  pwdSecret: '#qwe%eWc8*/',
})

const getters = {
  pwdSecret(state) {
    return state.pwdSecret + 'Las9f3$&KP083@*JC8124'
  },
  pwdVector() {
    return 'If8ncV8YzeBv1Voo'
  },
}

const actions = {}

const mutations = {}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
