{
    "web": {
        "why_use": "为什么使用{productName}",
        "download_VPN": "下载{productName}并立即开始使用！",
        "getVPN": "获取{productName}",
        "access": "从世界任意地方安全访问内容",
        "jurisdiction": "欧盟的管辖权确保机密性",
        "anonymity": "防止基于IP的广告服务跟踪",
        "simple_conf": "易于安装和使用",
        "secure": "安全支付并保护免受黑客攻击",
        "protection": "防止侵入性广告",
        "get_VPN_app_phone": "为您的设备获取{productName}",
        "keep_safe": "保持网上安全和隐私。",
        "different_device": "为不同的设备获取{productName}",
        "authorize": "登录",
        "dont_have_an_account": "没有账户？",
        "register": "注册",
        "pass": "密码",
        "sign_in": "登录",
        "forgot_pass": "忘记密码？",
        "email": "电子邮件",
        "instructions_for_macos": "MacOS的说明",
        "instructions_for_windows": "Windows的说明",
        "current-plan": "当前套餐",
        "date": "日期",
        "price": "价格",
        "price_from": "价格自",
        "next_due": "下次到期",
        "payment": "付款",
        "cancel": "取消",
        "go_to_settings": "转到“设置”",
        "ok": "确定",
        "dialog_cancel-subscription_title-AppStore": "您可以通过购买订阅的Apple Store账户取消订阅",
        "dialog_cancel-subscription_title-PlayMarket": "您可以通过购买订阅的Play Market账户取消订阅",
        "dialog_cancel-subscription_title-sure": "您确定要取消订阅吗？",
        "yes": "是",
        "no": "否",
        "subscription_canceled_successfully": "订阅已成功取消",
        "dashboard_dialog_thanks-for-registration_title": "谢谢您的注册！",
        "dashboard_dialog_thanks-for-registration_description_1": "请记住您的登录密码。",
        "Password": "密码",
        "dashboard_dialog_thanks-for-registration_description_2-1": "或",
        "dashboard_dialog_thanks-for-registration_description_2-2": "点击这里",
        "dashboard_dialog_thanks-for-registration_description_2-3": "设置您的密码。",
        "prices_date-of-next-payment": "下次款项借记日期",
        "change_password": "更改密码",
        "current_password": "当前密码",
        "new_password": "新密码",
        "confirm-password": "确认密码",
        "reset": "重置",
        "email_address_associated": "输入与您的账户相关联的电子邮件地址。我们将通过电子邮件向您发送一个链接，以重置您的密码。",
        "get-link": "发送电子邮件",
        "sign-in": "登录",
        "forget-password_dialog_email-link_title": "我们已向您发送了一封电子邮件，其中包含重置密码的链接。",
        "i-have-promocode": "我有优惠码",
        "promo": "优惠码",
        "have_an_account": "我有一个账户",
        "reset-password": "重置密码",
        "state": {
            "register": "注册"
        },
        "action": {
            "register": "注册",
            "cancel": "取消"
        },
        "notify_password-changed": "密码已更改",
        "prices_discount": "折扣",
        "prices_email": "电子邮件",
        "prices_country": "您的国家",
        "prices_pay": "支付",
        "prices_privacy_p-0": "提交此表格即表示您同意我们的",
        "prices_privacy_p-1": "服务条款",
        "prices_privacy_p-2": "和",
        "prices_privacy_p-3": "隐私政策",
        "price_card": "卡",
        "price_internet-wallet": "网络钱包",
        "price_cryptocurrency": "数字加密货币",
        "prices_order-total": "订单总额",
        "i-dont-have-promocode": "我没有优惠码",
        "dashboard_dialog_thanks-for-registration_description_1_promo": "您输入的优惠码无效",
        "prices_user-has-account_how-to-change-plan_1": "若要更改收费套餐，请取消当前订阅从",
        "prices_user-has-account_how-to-change-plan_2": "账单",
        "prices_user-has-account_how-to-change-plan_3": "页，然后购买一个新的",
        "instructions_for_linux": "Linux操作说明",
        "prices_Step": "步骤",
        "tariff-name_Year": "年",
        "tariff-name_Month": "月",
        "month": "月",
        "prices_save": "保存",
        "prices_billedEvery": "计费每",
        "prices_the-most-popular": "最受欢迎的",
        "prices_Billed": "计费",
        "prices_every": "每",
        "icon_check-circle-empty": "空圈",
        "icon_check-circle": "复选框",
        "icon_like": "喜欢",
        "icon_info": "信息",
        "icon_down": "向下",
        "icon_check": "复选框",
        "prices_the-1-year-is-better": "1年套餐对您来说意味着最大的节省",
        "prices_choose-plan": "选择您的套餐",
        "prices_Chose-Payment-Method": "选择付款方式",
        "prices_Why-choose-VPN": "为什么选择{productName}？",
        "prices_VPN-includes": "{productName}包括",
        "prices_includes-support": "全天候客户支持",
        "prices_includes-money-back": "{numberOfDays}天退款保证",
        "prices_includes-security": "银行级安全与加密",
        "prices_includes-no-logs": "没有活动和连接日志",
        "instruction": "说明",
        "apps": "应用程序",
        "my-ip": "我的IP",
        "terms": "条款与条件",
        "return-refund-policy": "退货及退款政策",
        "policy_and_procedure": "DMCA通知和撤下政策及程序",
        "privacy_policy": "隐私政策",
        "footer_support": "支持",
        "footer_sales-department": "销售部",
        "footer_company": "公司",
        "footer_contacts": "联系人",
        "data-retention-policy": "数据保留策略",
        "pricing": "定价",
        "servers": "服务器",
        "knowledge-base": "知识库",
        "dashboard": "仪表板",
        "to_home_page": "到主页",
        "icon_logo": "标志",
        "icon_login": "登录",
        "icon_menu": "菜单",
        "icon_key": "密匙",
        "icon_logout": "登出",
        "log_out": "登出",
        "icon_lifebuoy": "救生圈",
        "knowledge_base": "知识库",
        "icon_support": "支持",
        "downloads": "下载",
        "billings": "账单",
        "icon_eyeOpen": "显示密码",
        "inactive": "未激活。付款后即开始使用",
        "icon_flag": "标记",
        "active_until": "有效期至",
        "servers_cta_title": "从今天开始安全浏览",
        "servers_cta_description": "保持您的上网安全和隐私",
        "downloads_instruction": "说明",
        "downloads_router": "路由",
        "downloads_openVPN_profiles": "OpenVPN配置文件",
        "tariff-name-long_Year": "一年",
        "tariff-name-long_Month": "一个月",
        "dialog_cancel-subscription_header": "取消订阅",
        "web_payment_history": "付款历史记录",
        "tariff": "费率",
        "payment_method": "付款方式",
        "status": "状态",
        "sum": "总和",
        "tbody_is_empty_1": "在这里您将看到有关您的付款信息",
        "tbody_is_empty_2": "下单",
        "status_paid": "已付",
        "status_not-paid": "未付",
        "my_services": "我的服务",
        "private_and_secure": "专用和安全的互联网接入(VPN)",
        "active": "活跃的",
        "downloads_apps_and_files": "应用程序和文件",
        "downloads_for_mobile": "移动设备",
        "downloads_for_desktop": "桌面设备",
        "downloads_for_browsers": "浏览器",
        "downloads_other_devices": "其他设备",
        "forget-password_dialog_email-link_header": "查看您的电子邮件",
        "pageNotFoundTitle": "找不到页面",
        "pageNotFoundDescription": "看起来不存在这样的页面。请回到我们这里来。",
        "pageNotFoundAction": "返回{productName}网站",
        "home": {
            "mainTitle": {
                "highlited": "您的\n数码",
                "rest": "保护"
            },
            "mainDescription": {
                "first": "您的个人资料和电脑",
                "second": "以防网络诈骗"
            },
            "mainButton": "从每月$2.50起*",
            "mainButtonDescription": "*退款保证",
            "secureTitle": {
                "first": "真正的安全",
                "second": "网络"
            },
            "secureDescription": "VPN99删除了您在互联网上独特的及可识别的内容。它隐藏您的IP地址并加密数据，以保持浏览历史记录的私密性。",
            "secureCard": {
                "noRestrictionsTitle": "没有禁地！",
                "noRestrictionsDescription": "您可以访问所有的网站，即使是被屏蔽的网站。此外，您的所有数据都得到了可靠的保护，免受诈骗者的侵害。",
                "anonimTitle": "您现在是匿名的！",
                "anonimDescription": "每个连接都是匿名的！我们不存储日志，所以即使是我们也不知道您在互联网上干什么;)"
            },
            "devicesTitle": {
                "first": "一个订阅",
                "second": "即可享受所有服务！"
            },
            "devicesDescription": "MacOS、Windows、Android、iOS、Chrome和Firefox的扩展等",
            "serversTitle": {
                "first": "快速和稳定的",
                "second": "连接任何地方！"
            },
            "serversDescription": "从17个服务器中选择一个服务器，或使用推荐的服务器 - 它是最近的，因此也是最快的。您将能够免费观看高清质量的电影和电视节目。",
            "faqTitle": "常见问题",
            "faq": {
                "whatIsVPNTitle": "VPN是什么意思？",
                "whatIsVPNDescription": {
                    "p1": "VPN是虚拟专用网络(Virtual Private Network)的缩写。",
                    "p2": "我们服务的主要目的是确保您的安全和隐私。为此，{productName}对通过网络传输的数据流进行加密。"
                },
                "rollUp": "上滚",
                "more": "阅读",
                "howWorksTitle": "VPN的工作原理？",
                "howWorksDescription": {
                    "p1": "VPN为系统添加了一个新的网络接口，其功能就像将设备直接连接到专有网络一样。它还会更改您的IP地址并加密所有传输的数据。VPN允许通过公共网络（例如，通过互联网）接收和传输信息，就像您的设备直接连接到专有网络一样，使用相同的安全策略，并提供对专有网络上所有资源的访问。"
                },
                "whatDiferenceWithProxyTitle": "代理服务器和VPN服务之间有什么区别？",
                "whatDiferenceWithProxyDescription": {
                    "p1": "VPN连接比代理服务器更稳定、更多加密。并非所有程序都能与代理服务器一起工作，其中许多程序需要进一步的配置。如果您使用的是VPN，则不需要单独配置每个应用程序。"
                },
                "whatCountriesTitle": "有哪些国家可用？",
                "whatCountries": {
                    "first": "目前各个国家的地点列表",
                    "link": "在这里",
                    "second": "因此，当连接时，您会得到一个基于这些国家地理位置的IP地址。"
                },
                "logsTitle": "您保存了日志文件吗？",
                "logs": {
                    "p1": "{productName}具有严格的无日志记录策略。"
                }
            },
            "footerTitle": {
                "first": "开始",
                "second": "使用VPN99"
            },
            "footerDescription": "在您的设备上下载应用程序"
        },
        "my_ip_address": "我的IP地址",
        "your_ip": "这是您的IP地址",
        "fail-payment_title": "哎呀！有点不对劲。",
        "fail-payment_description": "您还未支付。也许您在付款过程中输入数据时出错了。别担心，您可以再试一次。",
        "fail-payment_go-to-prices": "转到费率套餐，然后重试",
        "success_congratulations-title": "恭喜您购买成功！",
        "success_congratulations-description": "请等一下。您将被重定向到您的个人账户，在那里您可以管理您的购买。",
        "success-payment_go-to-dashboard": "现在登录",
        "servers_title": "一键访问10多个国家",
        "servers_title_description": "在{continents_number}大洲{servers_number}个国家中选择一个VPN服务器",
        "servers_locations_list_title": "VPN服务器位置列表",
        "servers_continent_all": "所有国家",
        "allservers": "国家",
        "continent_asia": "亚洲",
        "continent_europe": "欧洲",
        "continent_america": "美洲",
        "our_vpn_server": "我们的服务器",
        "subscription_canceled_failure": "哎呀！取消时出错。请联系支持部门。",
        "prices_trial-days": "+ {count}天试用",
        "days": "{count}天",
        "prices_trial": "试用",
        "pageForbidden": "禁止访问",
        "pageForbiddenTitle": "禁止访问",
        "pageForbiddenDescription": "似乎您尝试访问的位置是受限的。",
        "pageForbiddenAction": "返回到{productName}网站",
        "private_finally": "私人网上冲浪",
        "no_one_can_see": "没有人能看到您在做什么",
        "no_restrictions_on_content_and_censorship": "没有网络限制或审查",
        "one_click_and_you_defend": "只需点击一下，您就能得到保护",
        "removes_everything": "{productName}通过隐藏您的IP地址和加密您的数据来保持您的浏览历史的私密性，并删除网上可显露您浏览痕迹的任何内容。",
        "enjoy_fast_and_stable_connection": "随时随地享受快速稳定的连接",
        "choose_your_server": "在{count}国家中选择您的服务器，享受最快的VPN服务。更多的服务器意味着更少的负载，而广泛的地点意味着您更有可能在附近找到一台服务器。",
        "easy_to_use_apps": "适用于所有设备的用户友好型应用程序",
        "subscription_comes": "一个{productName}订阅可用于您的所有设备：{appsList}以及更多",
        "money_back_guarantee": "退款保证",
        "faq_footer_description": "您可以在我们的网站上找到您感兴趣的其他信息",
        "faq_footer_knowledge-base": "知识库",
        "tryFree": "免费试用",
        "year": "年",
        "icon_up": "上",
        "status_failed": "失败",
        "status_refund": "退款",
        "status_trial": "试用",
        "onHoldMessage": "出现问题，我们无法自动向您收取{platform}设备上的{productName}订阅费用。"
    },
    "css": "",
    "validator": {
        "is_empty": "字段必须为空",
        "is_not_empty": "必填字段",
        "is_email": "检查输入是否正确",
        "is_phone": "输入正确的电话号码",
        "is_password": "密码不能包含{extraSymbols}",
        "is_password_has_enough_symbols": "密码必须至少包含6个字符",
        "is_password_less_max_symbols": "密码必须少于32个字符",
        "is_password_has_space": "密码不能包含空格标记",
        "is_not_empty_with_field_name": "{fieldName}字段是必填的",
        "is_password_has_not_english_letters": "不允许使用非拉丁字母"
    },
    "passwords-not-equal": "密码不匹配",
    "error": {
        "e500": {
            "new": {
                "text": "我们已经知道这个问题，很快就会解决！请稍后再试。",
                "title": "500 — 服务器错误"
            }
        },
        "eUnknown": {
            "new": {
                "text": "我们已经知道这个问题，很快就会解决！请稍后再试。",
                "title": "500 — 服务器错误"
            },
            "oops": {
                "text": "哎呀！加载用户数据时出错。请尝试重新加载页面。"
            }
        },
        "e400": {
            "new": {
                "title": "错误"
            }
        }
    },
    "whitelabel": {
        "vpn99": {
            "web_be_safe": "以最优惠的价格提供在线安全服务",
            "web_online_security": "每月仅${pricePerMonth}起"
        },
        "trustvpn": {
            "web_be_safe": "现在就保护您的在线隐私！",
            "web_online_security": "超快速度，保护您的隐私，数字自由"
        }
    },
    "meta": {
        "descriptionDataRetentionPolicy": "您的数据将被保留的情况和期限。您的所有数据都按照GDPR指南安全地存储。",
        "descriptionMyIp": "在线查看您的IP地址、计算机地址。查看您的IP地址显示的样子",
        "descriptionPrivacyPolicy": "我们的隐私政策包括我们收集哪些数据、如何使用这些数据，以及您可以如何控制您的信息。我们根据威尔士法律使用cookie，但您可以调整您的偏好。",
        "descriptionTerms": "接受{productName}的使用条款，即表示您同意协议中列出的条款和条件以及双方的责任。当VPN服务连续12小时不可用或出现故障时，在某些情况下可获得补偿。",
        "descriptionAccountBilling": "账户 - 付款历史记录",
        "descriptionAccountPanel": "账户 - 仪表板",
        "descriptionAccountPassword": "账户 - 更改密码",
        "descriptionDownload": "下载",
        "descriptionForgot": "恢复密码",
        "descriptionReset": "重置密码",
        "descriptionHome": "下载并安装{productName}，您将在全球范围内获得全面保护。{productName}，高速并完全匿名。",
        "descriptionPayFail": "付款被拒",
        "descriptionPaySuccess": "付款成功",
        "descriptionPrice": "购买并安装{productName}，您将在全球范围内获得全面保护。获得昂贵VPN的所有好处。{productName}，高速并完全匿名",
        "descriptionServers": "服务器",
        "titleDataRetentionPolicy": "数据保留策略",
        "titleMyIp": "在线查看您的IP地址",
        "titlePrivacyPolicy": "隐私策略：{productName}为保护您的私人信息而采取的步骤",
        "titleTerms": "使用条款：使用{productName}提供的服务时适用的具体条款。",
        "titleAccountBilling": "账户 - 付款历史记录",
        "titleAccountPanel": "账户 - 仪表板",
        "titleAccountPassword": "账户 - 更改密码",
        "titleDownload": "下载",
        "titleForgot": "恢复密码",
        "titleReset": "重置密码",
        "titleHome": "立即下载{productName}，您将以合理的价格获得可靠的保障。",
        "titleLogin": "登录",
        "titlePayFail": "付款被拒",
        "titlePaySuccess": "付款成功",
        "titlePrice": "立即获取{productName}，您将以合理的价格获得可靠的保障",
        "titleRegister": "注册",
        "titleServers": "服务器",
        "titleApps": "应用程序",
        "descriptionApps": "应用程序",
        "descriptionLogin": "登录",
        "descriptionRegister": "注册",
        "titleReturnRefundPolicy": "退货退款政策",
        "descriptionReturnRefundPolicy": "退货退款政策",
        "titlePolicyAndProcedure": "DMCA通知和撤下政策及程序",
        "descriptionPolicyAndProcedure": "DMCA通知和撤下政策及程序"
    },
    "getVPN": "获取{productName}",
    "signIn": "登录",
    "billings-page": {
        "waiting-processing": "正在等待付款处理的响应。"
    },
    "servers": {
        "USDal": "美国达拉斯"
    }
}
