const localesArrayEnv = process.env.VUE_APP__LOCALES.split(',')

function loadCountries() {
  const locales = require.context('./list', true, /[A-Za-z0-9-_,\s]+\.json$/i)
  const countries = {}
  locales.keys().forEach((key) => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i)
    if (matched && matched.length > 1) {
      const locale = matched[1]
      if (localesArrayEnv.includes(locale)) {
        countries[locale] = locales(key)
      }
    }
  })
  return countries
}

export default loadCountries()
