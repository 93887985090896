{
    "web": {
        "why_use": "Por que usar a {productName}",
        "download_VPN": "Baixe a {productName} e comece a usar hoje mesmo!",
        "getVPN": "Obter {productName}",
        "access": "Acesso seguro ao conteúdo de qualquer lugar do mundo",
        "jurisdiction": "A jurisdição da UE garante confidencialidade",
        "anonymity": "Evite o rastreamento baseado em IP para serviços de anúncios",
        "simple_conf": "Fácil configuração e uso",
        "secure": "Pagamentos seguros e proteção contra hackers",
        "protection": "Proteção contra publicidade intrusiva",
        "get_VPN_app_phone": "Obtenha a {productName} para seu dispositivo",
        "keep_safe": "Permaneça seguro e privado online.",
        "different_device": "Obtenha a {productName} para um dispositivo diferente",
        "authorize": "Entrar",
        "dont_have_an_account": "Não possui uma conta?",
        "register": "Inscreva-se",
        "pass": "Senha",
        "sign_in": "Entrar",
        "forgot_pass": "Esqueceu sua senha?",
        "email": "E-mail",
        "instructions_for_macos": "Instruções para macOS",
        "instructions_for_windows": "Instruções para Windows",
        "current-plan": "Plano atual",
        "date": "Data",
        "price": "Preço",
        "price_from": "Preço a partir de",
        "next_due": "Próximo vencimento",
        "payment": "Pagamento",
        "cancel": "Cancelar",
        "go_to_settings": "Ir para Configurações",
        "ok": "Ok",
        "dialog_cancel-subscription_title-AppStore": "Você pode cancelar a assinatura através da conta da Apple Store com a qual a assinatura foi feita",
        "dialog_cancel-subscription_title-PlayMarket": "Você pode cancelar a assinatura através da conta do Google Play com a qual a assinatura foi feita",
        "dialog_cancel-subscription_title-sure": "Tem certeza de que deseja cancelar a assinatura?",
        "yes": "Sim",
        "no": "Não",
        "subscription_canceled_successfully": "Assinatura cancelada com sucesso",
        "dashboard_dialog_thanks-for-registration_title": "Agradecemos sua inscrição!",
        "dashboard_dialog_thanks-for-registration_description_1": "Lembre-se da sua senha para fazer login.",
        "Password": "Senha",
        "dashboard_dialog_thanks-for-registration_description_2-1": "ou",
        "dashboard_dialog_thanks-for-registration_description_2-2": "clique aqui",
        "dashboard_dialog_thanks-for-registration_description_2-3": "para definir sua própria senha.",
        "prices_date-of-next-payment": "Data do próximo débito",
        "change_password": "Alterar senha",
        "current_password": "Senha atual",
        "new_password": "Nova senha",
        "confirm-password": "Confirmar senha",
        "reset": "Redefinir",
        "email_address_associated": "Insira o endereço de e-mail associado à sua conta. Enviaremos um link para redefinir sua senha.",
        "get-link": "Enviar e-mail",
        "sign-in": "Entrar",
        "forget-password_dialog_email-link_title": "Enviamos um e-mail com um link para redefinir sua senha.",
        "i-have-promocode": "Tenho código promocional",
        "promo": "Código promocional",
        "have_an_account": "Tenho uma conta",
        "reset-password": "Redefinir senha",
        "state": {
            "register": "Inscreva-se"
        },
        "action": {
            "register": "Inscreva-se",
            "cancel": "Cancelar"
        },
        "notify_password-changed": "A senha foi alterada",
        "prices_discount": "Desconto",
        "prices_email": "E-mail",
        "prices_country": "Seu país",
        "prices_pay": "Pagar",
        "prices_privacy_p-0": "Ao enviar este formulário, você concorda com os nossos",
        "prices_privacy_p-1": "Termos de Serviço",
        "prices_privacy_p-2": "e",
        "prices_privacy_p-3": "Política de Privacidade",
        "price_card": "Cartão",
        "price_internet-wallet": "Carteira eletrônica",
        "price_cryptocurrency": "Criptomoeda",
        "prices_order-total": "Total do pedido",
        "i-dont-have-promocode": "Não tenho código promocional",
        "dashboard_dialog_thanks-for-registration_description_1_promo": "O código promocional inserido é inválido",
        "prices_user-has-account_how-to-change-plan_1": "Para alterar o plano tarifário, cancele a assinatura atual na página de",
        "prices_user-has-account_how-to-change-plan_2": "Cobranças",
        "prices_user-has-account_how-to-change-plan_3": "e, em seguida, adquira uma nova assinatura",
        "instructions_for_linux": "Instruções para Linux",
        "prices_Step": "Etapa",
        "tariff-name_Year": "Ano",
        "tariff-name_Month": "Mês",
        "month": "mês",
        "prices_save": "Salvar",
        "prices_billedEvery": "cobrado a cada",
        "prices_the-most-popular": "Mais popular",
        "prices_Billed": "Cobrado",
        "prices_every": "a cada",
        "icon_check-circle-empty": "Empty Circle",
        "icon_check-circle": "Checkbox",
        "icon_like": "Like",
        "icon_info": "Info",
        "icon_down": "Down",
        "icon_check": "Checkbox",
        "prices_the-1-year-is-better": "O plano de 1 ano garante a maior economia para você",
        "prices_choose-plan": "Escolha seu plano",
        "prices_Chose-Payment-Method": "Escolha o método de pagamento",
        "prices_Why-choose-VPN": "Por que escolher a {productName}?",
        "prices_VPN-includes": "A {productName} inclui",
        "prices_includes-support": "Suporte ao cliente 24 horas",
        "prices_includes-money-back": "Garantia de {numberOfDays} dias ou seu dinheiro de volta",
        "prices_includes-security": "Segurança e criptografia de classe bancária",
        "prices_includes-no-logs": "Sem registros de atividade e conexão",
        "instruction": "Instruções",
        "apps": "Aplicativos",
        "my-ip": "Meu IP",
        "terms": "Termos e Condições",
        "return-refund-policy": "Política de devolução e reembolso",
        "policy_and_procedure": "Política e procedimentos do DMCA",
        "privacy_policy": "Política de privacidade",
        "footer_support": "Suporte",
        "footer_sales-department": "Departamento de vendas",
        "footer_company": "Empresa",
        "footer_contacts": "Contatos",
        "data-retention-policy": "Política de retenção de dados",
        "pricing": "Preços",
        "servers": "Servidores",
        "knowledge-base": "Base de conhecimento",
        "dashboard": "Painel",
        "to_home_page": "Para página inicial",
        "icon_logo": "Logo",
        "icon_login": "Login",
        "icon_menu": "Menu",
        "icon_key": "Key",
        "icon_logout": "Logout",
        "log_out": "Sair",
        "icon_lifebuoy": "Lifebuoy",
        "knowledge_base": "Base de conhecimento",
        "icon_support": "Suporte",
        "downloads": "Downloads",
        "billings": "Cobranças",
        "icon_eyeOpen": "Mostrar senha",
        "inactive": "Inativo. Faça seu pagamento para começar a usar",
        "icon_flag": "Flag",
        "active_until": "Ativo até",
        "servers_cta_title": "Comece a navegação segura hoje mesmo",
        "servers_cta_description": "Mantenha sua internet segura e privada",
        "downloads_instruction": "Instruções",
        "downloads_router": "Roteador",
        "downloads_openVPN_profiles": "Perfis OpenVPN",
        "tariff-name-long_Year": "Um ano",
        "tariff-name-long_Month": "Um mês",
        "dialog_cancel-subscription_header": "Cancelar assinatura",
        "web_payment_history": "Histórico de pagamentos",
        "tariff": "Tarifa",
        "payment_method": "Método de pagamento",
        "status": "Status",
        "sum": "Valor",
        "tbody_is_empty_1": "Aqui você verá informações sobre seus pagamentos",
        "tbody_is_empty_2": "Fazer um pedido",
        "status_paid": "Pago",
        "status_not-paid": "Não pago",
        "my_services": "Meus serviços",
        "private_and_secure": "Acesso privado e seguro à Internet (VPN)",
        "active": "Ativo",
        "downloads_apps_and_files": "Aplicativos e arquivos",
        "downloads_for_mobile": "Dispositivos móveis",
        "downloads_for_desktop": "Desktops",
        "downloads_for_browsers": "Navegadores",
        "downloads_other_devices": "Outros dispositivos",
        "forget-password_dialog_email-link_header": "Verifique seu e-mail",
        "pageNotFoundTitle": "Página não encontrada",
        "pageNotFoundDescription": "Parece que essa página não existe. Por favor, volte para nós.",
        "pageNotFoundAction": "Retornar ao site da {productName}",
        "home": {
            "mainTitle": {
                "highlited": "Sua\nproteção",
                "rest": "digital"
            },
            "mainDescription": {
                "first": "de todos os seus dados pessoais e computadores",
                "second": "contra fraudadores cibernéticos"
            },
            "mainButton": "A partir de US$ 2,50 por mês*",
            "mainButtonDescription": "*reembolso de dinheiro garantido",
            "secureTitle": {
                "first": "Verdadeiramente seguro",
                "second": "Internet"
            },
            "secureDescription": "A VPN99 remove tudo o que o torna único e reconhecível na Internet. Ela oculta seu endereço IP e criptografa dados para manter seu histórico de navegação privado.",
            "secureCard": {
                "noRestrictionsTitle": "Sem proibições!",
                "noRestrictionsDescription": "Todos os sites da Internet estão disponíveis para você, até mesmo os bloqueados. Além disso, todos os seus dados são protegidos de forma confiável contra fraudadores.",
                "anonimTitle": "Agora fica anônimo!",
                "anonimDescription": "Cada conexão é anônima! Não armazenamos logs, de forma que nem nós teremos ideia do que você está fazendo na Internet ;)"
            },
            "devicesTitle": {
                "first": "Uma assinatura",
                "second": "para todos os dispositivos!"
            },
            "devicesDescription": "MacOS, Windows, Android, iOS, extensões para Chrome e Firefox e muito mais",
            "serversTitle": {
                "first": "Conexão rápida e estável",
                "second": "em qualquer lugar!"
            },
            "serversDescription": "Escolha um dos 17 servidores ou confie no servidor recomendado - ele é o mais próximo e, portanto, o mais rápido. Você poderá assistir filmes e programas de TV livremente com qualidade HD.",
            "faqTitle": "Perguntas frequentes",
            "faq": {
                "whatIsVPNTitle": "O que significa uma VPN?",
                "whatIsVPNDescription": {
                    "p1": "A sigla VPN significa Virtual Private Network (ou Rede Privada Virtual, em português).",
                    "p2": "O principal objetivo do nosso serviço é garantir sua segurança e privacidade. Para fazer isso, a {productName} criptografa o tráfego que você transmite pela rede."
                },
                "rollUp": "Voltar",
                "more": "Leia",
                "howWorksTitle": "Como funciona uma VPN?",
                "howWorksDescription": {
                    "p1": "A VPN adiciona uma nova interface de rede ao sistema, que funciona como se você conectasse seu dispositivo diretamente à rede privada. Ela também altera seu endereço IP e criptografa todos os dados transmitidos. Uma VPN permite que as informações sejam recebidas e transmitidas através de redes públicas (via Internet, por exemplo), como se seu dispositivo estivesse conectado a uma rede privada, diretamente, aplicando as mesmas políticas de segurança e fornecendo acesso a todos os recursos da rede privada."
                },
                "whatDiferenceWithProxyTitle": "Qual é a diferença entre servidores proxy e serviço VPN?",
                "whatDiferenceWithProxyDescription": {
                    "p1": "As conexões VPN são muito mais estáveis e criptografadas, ao contrário do proxy. Nem todos os programas são capazes de trabalhar com servidores proxy e muitos deles exigem configuração adicional. Se você estiver usando uma VPN, não precisará configurar cada aplicativo individualmente."
                },
                "whatCountriesTitle": "Quais países estão disponíveis?",
                "whatCountries": {
                    "first": "A lista atual de locais em vários países pode ser encontrada",
                    "link": "aqui",
                    "second": "Você obtém um endereço IP com a geolocalização desses países quando você se conecta."
                },
                "logsTitle": "Vocês guardam registos?",
                "logs": {
                    "p1": "{productName} tem uma política bastante restrita de não guardar registos"
                }
            },
            "footerTitle": {
                "first": "Começar",
                "second": "a usar a VPN99"
            },
            "footerDescription": "Baixe o app em seu dispositivo"
        },
        "my_ip_address": "Meu endereço IP",
        "your_ip": "Este é o seu endereço IP",
        "fail-payment_title": "Opa! Algo deu errado.",
        "fail-payment_description": "Sua tarifa não foi paga. Talvez você tenha cometido um erro ao inserir seus dados durante o pagamento. Não se preocupe, você pode tentar novamente.",
        "fail-payment_go-to-prices": "Acesse os planos tarifários e tente novamente",
        "success_congratulations-title": "Parabéns pela compra!",
        "success_congratulations-description": "Por favor, aguarde. Você será redirecionado para sua conta pessoal, onde poderá gerenciar suas compras.",
        "success-payment_go-to-dashboard": "Entrar agora",
        "servers_title": "Acesso com um clique a mais de 10 países",
        "servers_title_description": "Escolha um servidor VPN em {continents_number} continentes em um dos {servers_number} países",
        "servers_locations_list_title": "Lista de localizações de servidores VPN",
        "servers_continent_all": "Todos os países",
        "allservers": "País",
        "continent_asia": "Ásia",
        "continent_europe": "Europa",
        "continent_america": "América",
        "our_vpn_server": "Nossos servidores",
        "subscription_canceled_failure": "Oops! Existiu um erro ao cancelar. Por favor contacte o suporte",
        "prices_trial-days": "+ {count} dias de periodo de experimentação",
        "days": "{count} dias",
        "prices_trial": "Periodo de Experimentação",
        "pageForbidden": "Acesso restrito",
        "pageForbiddenTitle": "Acesso restrito",
        "pageForbiddenDescription": "Parece que tentou aceder a uma área limitada.",
        "pageForbiddenAction": "Voltar ao site do {productName}",
        "private_finally": "Navegação online privada",
        "no_one_can_see": "Ninguém pode ver o que está a fazer",
        "no_restrictions_on_content_and_censorship": "Sem restrições online ou censura",
        "one_click_and_you_defend": "Apenas um clique e estará protegido",
        "removes_everything": "{productName} remove tudo o que te torna único e reconhecível online, através da ocultação do teu IP e também da encriptação dos teus dados para manter o teu histórico de navegação privado.",
        "enjoy_fast_and_stable_connection": "Desfrute de uma ligação rápida e estável em qualquer lugar",
        "choose_your_server": "Escolha o seu servidor em {count} países e aproveite o serviço VPN mais rápido. Mais servidores significa menos carga e uma ampla variedade de locais significa que é mais provável que encontre um servidor próximo de si",
        "easy_to_use_apps": "Aplicações fáceis de usar para todos os seus dispositivos",
        "subscription_comes": "Uma única subscrição de {productName} para todos os teus dispositivos: {appsList} e mais",
        "money_back_guarantee": "Garantia de reembolso",
        "faq_footer_description": "Podes encontrar mais informações na nossa",
        "faq_footer_knowledge-base": "Base de Conhecimento",
        "tryFree": "Experimenta gratuitamente",
        "year": "ano",
        "icon_up": "Cima",
        "status_failed": "Falhou",
        "status_refund": "Reembolso",
        "status_trial": "Periodo de Experimentação",
        "onHoldMessage": "Algo não correu bem e não foi possível cobrar a tua subscrição do {productName} no teu dispositivo {platform}."
    },
    "css": "",
    "validator": {
        "is_empty": "O campo deve estar vazio",
        "is_not_empty": "Campo obrigatório",
        "is_email": "Verifique se a entrada está correta",
        "is_phone": "Insira o número correto",
        "is_password": "A senha pode não conter {ExtraSymbols}",
        "is_password_has_enough_symbols": "A senha deve conter pelo menos 6 caracteres",
        "is_password_less_max_symbols": "A senha deve conter menos de 32 caracteres",
        "is_password_has_space": "A senha pode não conter espaços",
        "is_not_empty_with_field_name": "O campo {fieldName} é obrigatório",
        "is_password_has_not_english_letters": "Letras não latinas não são permitidas"
    },
    "passwords-not-equal": "As senhas não coincidem",
    "error": {
        "e500": {
            "new": {
                "text": "Já temos conhecimento desse problema e o resolveremos em breve! Por favor, tente novamente mais tarde.",
                "title": "500 — Erro no servidor"
            }
        },
        "eUnknown": {
            "new": {
                "text": "Já temos conhecimento desse problema e o resolveremos em breve! Por favor, tente novamente mais tarde.",
                "title": "500 — Erro no servidor"
            },
            "oops": {
                "text": "Oops! Existiu um erro ao carregar a tua informação. Por favor atualiza a página."
            }
        },
        "e400": {
            "new": {
                "title": "Erro"
            }
        }
    },
    "whitelabel": {
        "vpn99": {
            "web_be_safe": "Segurança online ao melhor preço",
            "web_online_security": "Apenas $:price_per_month por mês"
        },
        "trustvpn": {
            "web_be_safe": "Protege a tua privacidade online agora!",
            "web_online_security": "Ultra-rápido, protege a tua privacidade, Liberdade Digital"
        }
    },
    "meta": {
        "descriptionDataRetentionPolicy": "As situações e períodos em que seus dados serão retidos. Todos os seus dados são armazenados com segurança de acordo com as diretrizes do RGPD.",
        "descriptionMyIp": "Encontre seu endereço IP, o endereço do seu computador na internet. Veja como seu endereço IP aparece",
        "descriptionPrivacyPolicy": "Nossa Política de Privacidade inclui informações sobre quais dados coletamos e como os usamos, além de como você pode controlar suas informações. Usamos cookies de acordo com a lei galesa, mas você pode ajustar suas preferências.",
        "descriptionTerms": "Ao aceitar os Termos de Uso da {ProductName}, você concorda com os termos e condições, bem como com as responsabilidades de ambas as partes descritas no contrato. A compensação está disponível em determinadas circunstâncias quando os serviços de VPN estão idisponíveis ou funcionando mal por mais de 12 horas consecutivas.",
        "descriptionAccountBilling": "Conta - Histórico de pagamentos",
        "descriptionAccountPanel": "Conta - Painel",
        "descriptionAccountPassword": "Conta - Alterar senha",
        "descriptionDownload": "Baixar",
        "descriptionForgot": "Restaurar senha",
        "descriptionReset": "Redefinir senha",
        "descriptionHome": "Baixe e instale a {productName} e você terá proteção total em todo o mundo. {productName}, altas velocidades e anonimato completo.",
        "descriptionPayFail": "Pagamento recusado",
        "descriptionPaySuccess": "O pagamento foi bem-sucedido",
        "descriptionPrice": "Compre e instale a {productName} e tenha proteção total em todo o mundo. Obtenha todos os benefícios de uma VPN cara. {productName}, altas velocidades e anonimato completo",
        "descriptionServers": "Servidores",
        "titleDataRetentionPolicy": "Política de retenção de dados",
        "titleMyIp": "Descubra qual é o seu endereço IP na internet",
        "titlePrivacyPolicy": "A Política de Privacidade: as medidas que a {productName} toma para proteger suas informações privadas",
        "titleTerms": "Termos de Uso: os termos específicos que se aplicam ao tirar proveito dos serviços fornecidos pel {productName}.",
        "titleAccountBilling": "Conta - Histórico de pagamentos",
        "titleAccountPanel": "Conta - Painel",
        "titleAccountPassword": "Conta - Alterar senha",
        "titleDownload": "Baixar",
        "titleForgot": "Restaurar senha",
        "titleReset": "Redefinir senha",
        "titleHome": "Baixe a {productName} hoje e tenha cobertura confiável por um preço acessível.",
        "titleLogin": "Entrar",
        "titlePayFail": "Pagamento recusado",
        "titlePaySuccess": "O pagamento foi bem-sucedido",
        "titlePrice": "Obtenha a {productName} hoje e tenha cobertura confiável pelo preço acessível",
        "titleRegister": "Inscreva-se",
        "titleServers": "Servidores",
        "titleApps": "Aplicativos",
        "descriptionApps": "Aplicativos",
        "descriptionLogin": "Entrar",
        "descriptionRegister": "Inscreva-se",
        "titleReturnRefundPolicy": "Política de devolução e reembolso",
        "descriptionReturnRefundPolicy": "Política de devolução e reembolso",
        "titlePolicyAndProcedure": "Política e procedimentos do DMCA",
        "descriptionPolicyAndProcedure": "Política e procedimentos do DMCA"
    },
    "getVPN": "Obter {productName}",
    "signIn": "Entrar",
    "billings-page": {
        "waiting-processing": "A aguardar uma resposta\ndo processamento de pagamento."
    },
    "servers": {
        "USDal": "EUA Dallas"
    }
}
