<template>
  <div>
    <vue-final-modal
      :name="name"
      :lock-scroll="true"
      :click-to-close="true"
      :esc-to-close="true"
      :max-width="400"
      @closed="onModalClosed"
      v-model="showModal"
      classes="signup-modal-container"
      content-class="signup-modal-content"
    >
      <button class="signup-modal__close" @click="showModal = false">
        <div>+</div>
      </button>
      <div class="signup-modal__title title title-md text-bold">
        {{ $t('web.dashboard_dialog_thanks-for-registration_title') }}
      </div>
      <div class="signup-modal__content">
        <p v-if="isPromoFailed">
          {{
            $t(
              'web.dashboard_dialog_thanks-for-registration_description_1_promo'
            )
          }}
        </p>
        <p>
          {{ $t('web.dashboard_dialog_thanks-for-registration_description_1') }}
        </p>
        <p>
          {{ $t('web.Password') }}: <strong>{{ password }}</strong>
        </p>
        <p>
          {{
            $t('web.dashboard_dialog_thanks-for-registration_description_2-1')
          }}
          <c-link
            :to="{
              name: $ROUTER.NAME.ACCOUNT.CHANGE_PASSWORD,
              query: {
                p: encryptedPassword,
              },
            }"
            @click="showModal = false"
            nowrap
            :label="
              $t('web.dashboard_dialog_thanks-for-registration_description_2-2')
            "
          />
          {{
            $t('web.dashboard_dialog_thanks-for-registration_description_2-3')
          }}
        </p>
      </div>
      <div class="signup-modal__action">
        <c-button
          @submit="buttonSudmited"
          :value="$t('web.ok')"
          compressed
          size="md"
          transparent
          noBorder
        ></c-button>
      </div>
    </vue-final-modal>
  </div>
</template>

<script>
import { API } from '@/CONSTANTS'
import CLink from '@/components/Link'
import CButton from '@/components/Button'
const CryptoJS = require('crypto-js')

export default {
  name: 'Sign Up Modal',

  components: {
    CLink,
    CButton,
  },

  data() {
    return {
      password: null,
      encryptedPassword: null,
      isPromoFailed: false,
      showModal: false,
    }
  },

  props: {
    name: {
      type: String,
      default: 'remember-password',
    },
  },

  computed: {
    showAfterRegistrationDialog: {
      get() {
        return this.$store.state.app.showAfterRegistrationDialog
      },
      set(value) {
        return this.$store.dispatch('app/setShowAfterRegistrationDialog', value)
      },
    },
  },

  watch: {
    showAfterRegistrationDialog(value) {
      if (value) {
        this.init()
      }
    },
  },

  methods: {
    async init() {
      const registerSuccessStatus = await this.$store.dispatch(
        'clientServer/getSuccessStatus',
        API.STATUS.KEY.REGISTER
      )
      if (registerSuccessStatus === API.STATUS.PROMO_VALIDATION_FAILED) {
        this.isPromoFailed = true
        this.$store.dispatch('clientServer/setSuccessStatus', {
          key: API.STATUS.KEY.REGISTER,
          value: undefined,
        })
      } else {
        this.isPromoFailed = false
      }

      this.encryptedPassword = localStorage.getItem('password')
      if (this.encryptedPassword && this.$store.getters['user/token']) {
        const bytes = CryptoJS.AES.decrypt(
          this.encryptedPassword,
          this.$store.getters['config/pwdSecret']
        )
        this.password = bytes.toString(CryptoJS.enc.Utf8)
        if (this.password) {
          this.showModal = true
        } else {
          this.showAfterRegistrationDialog = false
        }
      } else if (this.encryptedPassword) {
        localStorage.removeItem('password')
        this.showAfterRegistrationDialog = false
        this.encryptedPassword = ''
        this.password = ''
      } else {
        this.showAfterRegistrationDialog = false
      }
    },
    onModalClosed() {
      this.$emit('onClosed')
      localStorage.removeItem('password')
      this.showAfterRegistrationDialog = false
      this.encryptedPassword = ''
      this.password = ''
    },
    buttonSudmited() {
      this.showModal = false
    },
  },
}
</script>

<style lang="scss">
@import '@/assets/scss/global/variables';
.signup-modal-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.signup-modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  max-height: 90%;
  max-width: 500px;
  min-width: 400px;
  width: fit-content;
  margin: 0 1rem;
  border: none;
  border-radius: 0.25rem;
  background: #fff;
  overflow: hidden;
  p:not(:last-child) {
    margin-bottom: 0.5rem;
  }
  body[dir='rtl'] & {
    padding: 2rem 2rem 0 3rem;
  }
  body[dir='ltr'] & {
    padding: 2rem 3rem 0 2rem;
  }
  @media (max-width: 767.98px) {
    width: 90%;
    min-width: 0;
  }
}
.signup-modal__title {
  body[dir='rtl'] & {
    margin: 0 0 1rem 2rem;
  }
  body[dir='ltr'] & {
    margin: 0 2rem 1rem 0;
  }
}
.signup-modal__content {
  flex-grow: 1;
  overflow-y: auto;
}
.signup-modal__action {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-top: 1px solid #d1d1d1;
  body[dir='rtl'] & {
    margin: 1rem -2rem 0 -3rem;
  }
  body[dir='ltr'] & {
    margin: 1rem -3rem 0 -2rem;
  }
  > * {
    flex-grow: 1;
  }
}
.signup-modal__close {
  position: absolute;
  top: 0;
  padding: 0.5rem;
  line-height: 1.4rem;
  font-size: 2rem;
  transition: color $transTimeFast $easeDefault;
  body[dir='rtl'] & {
    left: 0;
  }
  body[dir='ltr'] & {
    right: 0;
  }
  & > div {
    transform: rotate(45deg);
    width: 1.4rem;
    height: 1.4rem;
  }
  &:hover {
    color: var(--main);
  }
}
</style>
