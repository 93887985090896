{
    "web": {
        "why_use": "なぜ {productName}を使うか。",
        "download_VPN": "{productName}をダウンロードして使い始めよう！",
        "getVPN": "{productName}を入手する",
        "access": "世界中どこからでも安全にコンテンツにアクセス可能",
        "jurisdiction": "EUの司法は機密生を保証",
        "anonymity": "広告サービスのIPベースのトラッキングを防止",
        "simple_conf": "セットアップ、使用簡単",
        "secure": "安全な支払い方法とハッカーから保護",
        "protection": "執ような広告からの保護",
        "get_VPN_app_phone": "{productName}をあなたのデバイスで！",
        "keep_safe": "オンラインではセキュリティーとプライバシーを！",
        "different_device": "別機種は {productName}にしよう！",
        "authorize": "ログイン",
        "dont_have_an_account": "アカウントはお持ちですか？",
        "register": "サインアップ",
        "pass": "パスワード",
        "sign_in": "ログイン",
        "forgot_pass": "パスワードを忘れましたか？",
        "email": "Eメール",
        "instructions_for_macos": "MacOS用 説明",
        "instructions_for_windows": "Windows用 説明",
        "current-plan": "現プラン",
        "date": "日付",
        "price": "価格",
        "price_from": "Xからの価格",
        "next_due": "次の期限",
        "payment": "支払い",
        "cancel": "キャンセル",
        "go_to_settings": "設定に進む",
        "ok": "OK",
        "dialog_cancel-subscription_title-AppStore": "サブスクリプションがなされたAPPLE Store アカウントからサブスクリプションをキャンセル",
        "dialog_cancel-subscription_title-PlayMarket": "サブスクリプションがなされた Play Market アカウントからサブスクリプションをキャンセル",
        "dialog_cancel-subscription_title-sure": "サブスクリプションを本当にキャンセルしますか？",
        "yes": "はい",
        "no": "いいえ",
        "subscription_canceled_successfully": "サブスクリプションが正常にキャンセルされました",
        "dashboard_dialog_thanks-for-registration_title": "ご登録ありがとうございます！",
        "dashboard_dialog_thanks-for-registration_description_1": "ログインのためにパスワードを覚えてください。",
        "Password": "パスワード",
        "dashboard_dialog_thanks-for-registration_description_2-1": "または",
        "dashboard_dialog_thanks-for-registration_description_2-2": "ここをクリックして",
        "dashboard_dialog_thanks-for-registration_description_2-3": "自分のパスワードを設定する。",
        "prices_date-of-next-payment": "次の引き落とし日付",
        "change_password": "パスワードの変更",
        "current_password": "今のパスワード",
        "new_password": "新しいパスワード",
        "confirm-password": "パスワードの確認",
        "reset": "リセット",
        "email_address_associated": "アカウントに登録されているメールアドレスを入力してください。パスワードリセット用ののリンクをお送りします。",
        "get-link": "Eメールを送る",
        "sign-in": "サインイン",
        "forget-password_dialog_email-link_title": "パスワードリセットのためのリンクをお送りしました。",
        "i-have-promocode": "プロモコードを持っています。",
        "promo": "プロモコード",
        "have_an_account": "アカウントがあります",
        "reset-password": "パスワードのリセット",
        "state": {
            "register": "サインアップ"
        },
        "action": {
            "register": "サインアップ",
            "cancel": "キャンセル"
        },
        "notify_password-changed": "パスワードが変更されました",
        "prices_discount": "ディスカウント",
        "prices_email": "Eメール",
        "prices_country": "国",
        "prices_pay": "支払う",
        "prices_privacy_p-0": "このフォームを送信すると、私達のXに同意したことになります",
        "prices_privacy_p-1": "規約",
        "prices_privacy_p-2": "そして",
        "prices_privacy_p-3": "プライバシーポリシー",
        "price_card": "カード",
        "price_internet-wallet": "インターネットウォレット",
        "price_cryptocurrency": "仮想通貨",
        "prices_order-total": "注文合計",
        "i-dont-have-promocode": "プロモコードは持っていません",
        "dashboard_dialog_thanks-for-registration_description_1_promo": "あなたの入力したプロモコードは有効ではありません",
        "prices_user-has-account_how-to-change-plan_1": "料金プランを変更するには、Xに現在のサブスクリプションをキャンセルしてください。",
        "prices_user-has-account_how-to-change-plan_2": "請求",
        "prices_user-has-account_how-to-change-plan_3": "ページ、それから新しいものを購入してください",
        "instructions_for_linux": "Linuxの説明",
        "prices_Step": "ステップ",
        "tariff-name_Year": "年",
        "tariff-name_Month": "月",
        "month": "月",
        "prices_save": "保存",
        "prices_billedEvery": "毎回の請求",
        "prices_the-most-popular": "再人気",
        "prices_Billed": "請求済み",
        "prices_every": "毎度",
        "icon_check-circle-empty": "空のサークル",
        "icon_check-circle": "チェックボックス",
        "icon_like": "いいね",
        "icon_info": "インフォ",
        "icon_down": "ダウン",
        "icon_check": "チェックボックス",
        "prices_the-1-year-is-better": "１年プランで最大限節約できます",
        "prices_choose-plan": "プランを選んで下さい",
        "prices_Chose-Payment-Method": "支払い方法を選んで下さい",
        "prices_Why-choose-VPN": "{productName}が選ばれる理由",
        "prices_VPN-includes": "{productName} には",
        "prices_includes-support": "年中無休のカスタマーサポート",
        "prices_includes-money-back": "{numberOfDays}日間以内の返金保障",
        "prices_includes-security": "銀行レベルのセキュリティーと暗号化",
        "prices_includes-no-logs": "アクティビティと接続ログはありません",
        "instruction": "説明",
        "apps": "アプリ",
        "my-ip": "私のIPアドレス",
        "terms": "利用規約",
        "return-refund-policy": "返品および返金ポリシー",
        "policy_and_procedure": "DMCAの通知と削除のポリシーと手順",
        "privacy_policy": "プライバシーポリシー",
        "footer_support": "サポート",
        "footer_sales-department": "営業部門",
        "footer_company": "会社",
        "footer_contacts": "コンタクト",
        "data-retention-policy": "データ保持ポリシー",
        "pricing": "価格",
        "servers": "サーバー",
        "knowledge-base": "知識ベース",
        "dashboard": "ダッシュボード",
        "to_home_page": "ホームへ戻る",
        "icon_logo": "ロゴ",
        "icon_login": "ログイン",
        "icon_menu": "メニュー",
        "icon_key": "キー",
        "icon_logout": "ログアウト",
        "log_out": "ログアウト",
        "icon_lifebuoy": "救命浮輪",
        "knowledge_base": "知識ベース",
        "icon_support": "サポート",
        "downloads": "ダウンロード",
        "billings": "請求",
        "icon_eyeOpen": "パスワード表示",
        "inactive": "非アクティブ、使い始めるためには支払いをしてください",
        "icon_flag": "フラグ",
        "active_until": "〜までアクティブ",
        "servers_cta_title": "今日から安全なブラウジングを始める",
        "servers_cta_description": "インターネットをプライベートで安全にキープ",
        "downloads_instruction": "指示",
        "downloads_router": "ルーター",
        "downloads_openVPN_profiles": "VPNプロファイルを開く",
        "tariff-name-long_Year": "１年",
        "tariff-name-long_Month": "１ヶ月",
        "dialog_cancel-subscription_header": "サブスクリプションをやめる",
        "web_payment_history": "支払い履歴",
        "tariff": "関税",
        "payment_method": "支払い方法",
        "status": "ステータス",
        "sum": "合計",
        "tbody_is_empty_1": "ここでお支払い情報を確認できます",
        "tbody_is_empty_2": "オーダーをする",
        "status_paid": "支払い済",
        "status_not-paid": "未払い",
        "my_services": "マイサービス",
        "private_and_secure": "プライベートかつ安全なインターネットアクセス（VPN）",
        "active": "アクティブ",
        "downloads_apps_and_files": "アプリとファイル",
        "downloads_for_mobile": "モバイル",
        "downloads_for_desktop": "デスクトップ",
        "downloads_for_browsers": "ブラウザ",
        "downloads_other_devices": "他デバイス",
        "forget-password_dialog_email-link_header": "Eメールをチェック",
        "pageNotFoundTitle": "ページが見つかりません",
        "pageNotFoundDescription": "そのページは見つかりません、戻ってきてください。",
        "pageNotFoundAction": "{productName} のウェブサイトに戻る",
        "home": {
            "mainTitle": {
                "highlited": "あなたの\nデジタル",
                "rest": "プロテクション"
            },
            "mainDescription": {
                "first": "あなたのパーソナルデータとパソコンの",
                "second": "サイバー詐欺に対して"
            },
            "mainButton": "月$2.50 から*",
            "mainButtonDescription": "*返金保証",
            "secureTitle": {
                "first": "安全保障",
                "second": "インターネット"
            },
            "secureDescription": "VPN99は、インターネット上であなたのことを認識できる全ての物を削除して非表示にます。あなたのIPアドレスを隠し、データの暗号化で関越履歴を非公開にします。",
            "secureCard": {
                "noRestrictionsTitle": "禁止なし！",
                "noRestrictionsDescription": "ブロックされているサイトも含め、すべてのインターネットサイトを利用できます。さらに、すべてのデータはネット詐欺から確実に保護されます。",
                "anonimTitle": "これからあなたは匿名です！",
                "anonimDescription": "ネット接続はすべて匿名です！ログの保存はしないので、こちらでもあなたがインターネットで何をしているかわかりません。"
            },
            "devicesTitle": {
                "first": "1つのサブスクリプション",
                "second": "で、デバイス全て使用可！"
            },
            "devicesDescription": "MacOS、Windows、Android、iOS、ChromeとFirefoxの拡張機能付き",
            "serversTitle": {
                "first": "高速で安定した",
                "second": "どこでもX ネット接続！"
            },
            "serversDescription": "17台のサーバーから1つを選ぶか、私たちの提供している推奨サーバーを信頼してください。一番近いサーバーなので、最速です。 映画やテレビ番組をHD品質で自由に視聴できるようになります。",
            "faqTitle": "よくある質問",
            "faq": {
                "whatIsVPNTitle": "VPNとは？",
                "whatIsVPNDescription": {
                    "p1": "VPNはVirtual Private Networkの略です。",
                    "p2": "私たちのサービスの主な目的は、あなたの安全とプライバシーを守ることです。これを行うために、{productName}はネットのトラフィックを全て暗号化します。"
                },
                "rollUp": "ロールアップ",
                "more": "読む",
                "howWorksTitle": "VPNの機能",
                "howWorksDescription": {
                    "p1": "VPNは、デバイスをプライベートネットワークに直接接続したかのように機能する新しいネットワークインターフェイスをシステムに追加します。 また、IPアドレスを変更し、送信されるすべてのデータを暗号化します。 VPNを使用すると、デバイスがプライベートネットワークに直接接続されているかのように、パブリックネットワークを通して（たとえばインターネット経由で）情報を送受信できます。同じセキュリティポリシーを適用し、プライベートネットワーク上のすべてのリソースへのアクセスを提供します。"
                },
                "whatDiferenceWithProxyTitle": "プロキシサーバーとVPNサービスの違いは何ですか？",
                "whatDiferenceWithProxyDescription": {
                    "p1": "VPN接続は、プロキシとは異なり、はるかに安定して暗号化されています。 すべてのプログラムがプロキシサーバーと連携できるわけではなく、それらの多くはさらに設定する必要があります。 VPNを使用している場合は、各アプリケーションを個別に設定する必要はありません。"
                },
                "whatCountriesTitle": "どの国で使えますか？",
                "whatCountries": {
                    "first": "現在地のリストを様々な国で見ることができます。",
                    "link": "ここ",
                    "second": "すると、これらの国の場所で"
                },
                "logsTitle": "ログファイルを保存しますか？",
                "logs": {
                    "p1": "{productName} には、厳密なログ記録なしのポリシーがあります。"
                }
            },
            "footerTitle": {
                "first": "始める",
                "second": "VPN99"
            },
            "footerDescription": "デバイスにアプリをダウンロード"
        },
        "my_ip_address": "マイIPアドレス",
        "your_ip": "これがあなたのIPアドレスです。",
        "fail-payment_title": "おっと、なにか間違っています。",
        "fail-payment_description": "関税が支払われておりません。お支払い時に入力ミスがあった様です。でも大丈夫です、もう一度お試しください。",
        "fail-payment_go-to-prices": "関税プランに入って再入力しえてください",
        "success_congratulations-title": "購入おめでとうございます！",
        "success_congratulations-description": "少々お待ちください、パーソナルアカウントに移動します。そちらで購入の管理ができます。",
        "success-payment_go-to-dashboard": "今すぐログイン",
        "servers_title": "ワンクリックで１０カ国にアクセス可能",
        "servers_title_description": "{continents_number}大陸の{servers_number}か国の1つにあるVPNサーバーを選択します。",
        "servers_locations_list_title": "VPNサーバーの場所リスト",
        "servers_continent_all": "全ての国",
        "allservers": "国",
        "continent_asia": "アジア",
        "continent_europe": "ヨーロッパ",
        "continent_america": "アメリカ",
        "our_vpn_server": "サーバー",
        "subscription_canceled_failure": "おっと！ キャンセル時にエラーが発生しました。 サポートにお問い合わせください。",
        "prices_trial-days": "お試し期間 + {count} 日間",
        "days": "{count} 日間",
        "prices_trial": "お試し",
        "pageForbidden": "アクセス禁止",
        "pageForbiddenTitle": "アクセス禁止",
        "pageForbiddenDescription": "アクセスしたリンクには制限がかかっているようです。",
        "pageForbiddenAction": "{productName} のウェブサイトに戻る",
        "private_finally": "プライベートネットサーフィン",
        "no_one_can_see": "何をしているのか誰にも分かりません",
        "no_restrictions_on_content_and_censorship": "オンラインで制限や検閲はありません",
        "one_click_and_you_defend": "ワンクリックであなたのプライバシーは守られます。",
        "removes_everything": "{productName} は、IPアドレスを非表示にし、データを暗号化して閲覧履歴を非公開にすることで、オンラインでの一意性と認識性を高めるすべてのものを削除します。",
        "enjoy_fast_and_stable_connection": "どこでも速くて安定したネット接続をお楽しみください",
        "choose_your_server": "{count} か国でサーバーを選択し、最速のVPNサービスをお楽しみください。 サーバーが多いほど負荷が少なくなり、カバーエリアが広いということは、近くにサーバーが見つかる可能性が高いことを意味します。",
        "easy_to_use_apps": "すべてのデバイス対応のユーザーフレンドリーなアプリ",
        "subscription_comes": "あなたの持つデバイス全てに1つの {productName} サブスクリプション: {appsList} など",
        "money_back_guarantee": "返金保障",
        "faq_footer_description": "あなたは私たちのウェブサイトであなたが興味を持っている他の情報を見つけることができます",
        "faq_footer_knowledge-base": "知識ベース",
        "tryFree": "無料でお試しください",
        "year": "年",
        "icon_up": "アップ",
        "status_failed": "失敗しました",
        "status_refund": "返金",
        "status_trial": "お試し",
        "onHoldMessage": "問題が発生し、 {productName} デバイスでの{platform} サブスクリプションの料金を自動的に請求できませんでした。"
    },
    "css": "",
    "validator": {
        "is_empty": "ここは空白でなければいけません。",
        "is_not_empty": "必須",
        "is_email": "入力が正しいかチェックしてください",
        "is_phone": "正しい数字を入力してください",
        "is_password": "パスワードには {extraSymbols}を含めることができません",
        "is_password_has_enough_symbols": "パスワードには６文字以上である必要があります。",
        "is_password_less_max_symbols": "パスワードには３２文字以下である必要があります。",
        "is_password_has_space": "パスワードに空欄を含めることはできません",
        "is_not_empty_with_field_name": "{fieldName}欄は必須です",
        "is_password_has_not_english_letters": "ラテン文字以外の文字は使用できません"
    },
    "passwords-not-equal": "パスワードが一致しません",
    "error": {
        "e500": {
            "new": {
                "text": "私たちはすでにこの問題について知っており、もうすぐ解決します！ あとでもう一度やり直してください。",
                "title": "500 — サーバーエラー"
            }
        },
        "eUnknown": {
            "new": {
                "text": "私たちはすでにこの問題について知っており、もうすぐ解決します！ あとでもう一度やり直してください。",
                "title": "500 — サーバーエラー"
            },
            "oops": {
                "text": "おっと！ ユーザーデータの読み込み中にエラーが発生しました。 ページを再読み込みしてみてください。"
            }
        },
        "e400": {
            "new": {
                "title": "エラー"
            }
        }
    },
    "whitelabel": {
        "vpn99": {
            "web_be_safe": "最安値でのオンラインセキュリティー",
            "web_online_security": "月額わずか${pricePerMonth} から"
        },
        "trustvpn": {
            "web_be_safe": "今すぐあなたのオンラインプライバシーを守ってください！",
            "web_online_security": "超高速、プライバシーの保護、デジタルフリーダム"
        }
    },
    "meta": {
        "descriptionDataRetentionPolicy": "データが保持される状況と期間。 GDPRガイドラインに従って、すべてのデータが安全に保存されます。",
        "descriptionMyIp": "あなたのIPアドレス、あなたのコンピュータのアドレスをオンラインで見つけてください。 IPアドレスがどのように表示されるかを確認する",
        "descriptionPrivacyPolicy": "当社のプライバシーポリシーには、当社が収集するデータとその使用方法、およびお客様が情報を管理する方法に関する情報が含まれています。 ウェールズ法に従ってCookieを使用していますが、好みを調整することができます。",
        "descriptionTerms": "{productName}の利用規約に同意することにより、利用規約と、契約に概説されている両当事者の責任に同意したことになります。 補償は、VPNサービスが利用できないか、12時間以上連続して誤動作している特定の状況で利用できます。",
        "descriptionAccountBilling": "アカウントー支払い履歴",
        "descriptionAccountPanel": "アカウントーダッシュボード",
        "descriptionAccountPassword": "アカウントーパスワード変更",
        "descriptionDownload": "ダウンロード",
        "descriptionForgot": "パスワードの復元",
        "descriptionReset": "パスワードリセット",
        "descriptionHome": "{productName}をダウンロードしてインストールすると、世界中で完璧に保護されます。 {productName}、高速で完全な匿名性。",
        "descriptionPayFail": "支払いが拒否されました",
        "descriptionPaySuccess": "支払い完了",
        "descriptionPrice": "{productName}を購入してインストールすると、世界中で完璧に保護されます。 高価なVPNのすべてのメリットを享受できます。 {productName}、高速で完全な匿名性",
        "descriptionServers": "サーバー",
        "titleDataRetentionPolicy": "データ保護のポリシー",
        "titleMyIp": "あなたのIPアドレスがオンラインであるか調べてください",
        "titlePrivacyPolicy": "プライバシーポリシー：{productName}があなたの個人情報を保護するために取るステップ",
        "titleTerms": "利用規約：{productName}が提供するサービスを利用する際に適用される特定の規約",
        "titleAccountBilling": "アカウントー支払い履歴",
        "titleAccountPanel": "アカウントーダッシュボード",
        "titleAccountPassword": "アカウントーパスワード変更",
        "titleDownload": "ダウンロード",
        "titleForgot": "パスワードの復元",
        "titleReset": "パスワードリセット",
        "titleHome": "{productName}をダウンロードすると、お手頃価格で信頼できるカバレッジを手に入れることができます。",
        "titleLogin": "サインイン",
        "titlePayFail": "支払いが拒否されました",
        "titlePaySuccess": "支払い完了",
        "titlePrice": "{productName}を入手すれば、手頃な価格で信頼できるカバレッジを手に入れることができます",
        "titleRegister": "サインアップ",
        "titleServers": "サーバー",
        "titleApps": "アプリ",
        "descriptionApps": "アプリ",
        "descriptionLogin": "サインイン",
        "descriptionRegister": "サインアップ",
        "titleReturnRefundPolicy": "返品返金ポリシー",
        "descriptionReturnRefundPolicy": "返品返金ポリシー",
        "titlePolicyAndProcedure": "DMCA通知と削除のポリシーと手順",
        "descriptionPolicyAndProcedure": "DMCA通知と削除のポリシーと手順"
    },
    "getVPN": "{productName}を手に入れる",
    "signIn": "サインイン",
    "billings-page": {
        "waiting-processing": "支払い処理からの応答を待っています。"
    },
    "servers": {
        "USDal": "米ドル"
    }
}
