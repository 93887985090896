{
    "web": {
        "why_use": "Why use {productName}",
        "download_VPN": "Download {productName} and start using today!",
        "getVPN": "Get {productName}",
        "access": "Safe access to content from anywhere in the world",
        "jurisdiction": "The jurisdiction of the EU guarantees confidentiality",
        "anonymity": "Prevent IP-based tracking for ad services",
        "simple_conf": "Easy setup and use",
        "secure": " Secure payments  and protection from hackers",
        "protection": "Protection against intrusive advertising",
        "get_VPN_app_phone": "Get the {productName} for your device",
        "keep_safe": "Stay secure and private online.",
        "different_device": "Get {productName} for a different device",
        "authorize": "Sign In",
        "dont_have_an_account": "Don’t have an account?",
        "register": "Sign Up",
        "pass": "Password",
        "sign_in": "Sign In",
        "forgot_pass": "Forgot your password?",
        "email": "Email",
        "instructions_for_macos": "Instructions for MacOS",
        "instructions_for_windows": "Instructions for Windows",
        "current-plan": "Current Plan",
        "date": "Date",
        "price": "Price",
        "price_from": "Price from",
        "next_due": "Next due",
        "payment": "Payment",
        "cancel": "Cancel",
        "go_to_settings": "Go to Settings",
        "ok": "Ok",
        "dialog_cancel-subscription_title-AppStore": "You can cancel the subscription through the Apple Store account where the subscription was made",
        "dialog_cancel-subscription_title-PlayMarket": "You can cancel the subscription through the Play Market account where the subscription was made",
        "dialog_cancel-subscription_title-sure": "Are you sure you want to cancel the subscription?",
        "yes": "Yes",
        "no": "No",
        "subscription_canceled_successfully": "Subscription canceled successfully",
        "dashboard_dialog_thanks-for-registration_title": "Thank you for registration!",
        "dashboard_dialog_thanks-for-registration_description_1": "Please remember your password to login.",
        "Password": "Password",
        "dashboard_dialog_thanks-for-registration_description_2-1": "Or",
        "dashboard_dialog_thanks-for-registration_description_2-2": "click here",
        "dashboard_dialog_thanks-for-registration_description_2-3": "to set your own password.",
        "prices_date-of-next-payment": "Date of next money debiting",
        "change_password": "Change Password",
        "current_password": "Current Password",
        "new_password": "New Password",
        "confirm-password": "Confirm Password",
        "reset": "Reset",
        "email_address_associated": "Enter the email address associated with your account. We will email you a link to reset your password.",
        "get-link": "Send Email",
        "sign-in": "Sign In",
        "forget-password_dialog_email-link_title": "We have sent you an email with a link to reset your password.",
        "i-have-promocode": "I have promo code",
        "promo": "Promo Code",
        "promo-redeem_success": "Promo Code Redeemed Successfully",
        "promo-redeem_error": "Failed to Redeem Promo Code",
        "activate_promo": "Activate Promo Code",
        "redeem_promo": "Redeem",
        "have_an_account": "I have an account",
        "reset-password": "Reset Password",
        "state": {
            "register": "Sign up"
        },
        "action": {
            "register": "Sign Up",
            "cancel": "Cancel"
        },
        "notify_password-changed": "Password has been changed",
        "prices_discount": "Discount",
        "prices_email": "Email",
        "prices_country": "Your country",
        "prices_pay": "Pay",
        "prices_privacy_p-0": "By submitting this form, you agree to our",
        "prices_privacy_p-1": "Terms of Service",
        "prices_privacy_p-2": "and",
        "prices_privacy_p-3": "Privacy Policy",
        "price_card": "Card",
        "price_internet-wallet": "Internet Wallet",
        "price_cryptocurrency": "Cryptocurrency",
        "prices_order-total": "Order total",
        "i-dont-have-promocode": "I do not have promo code",
        "dashboard_dialog_thanks-for-registration_description_1_promo": "The promo code you entered is not valid",
        "prices_user-has-account_how-to-change-plan_1": "To change the tariff plan, cancel the current subscription on the",
        "prices_user-has-account_how-to-change-plan_2": "Billings",
        "prices_user-has-account_how-to-change-plan_3": "page, then purchase a new one",
        "instructions_for_linux": "Instructions for Linux",
        "prices_Step": "Step",
        "tariff-name_Year": "Year",
        "tariff-name_Month": "Month",
        "tariff-name_6-months": "6 months",
        "tariff-name_half-year": "Half a year",
        "period-for-free": "{period} for free",
        "then": "then",
        "month": "month",
        "prices_save": "Save",
        "prices_billedEvery": "billed every",
        "prices_the-most-popular": "The most popular",
        "prices_Billed": "Billed",
        "prices_every": "every",
        "icon_check-circle-empty": "Empty Circle",
        "icon_check-circle": "Checkbox",
        "icon_like": "Like",
        "icon_info": "Info",
        "icon_down": "Down",
        "icon_check": "Checkbox",
        "prices_the-1-year-is-better": "The 1 Year Plan means the biggest saving for you",
        "prices_choose-plan": "Choose your plan",
        "prices_Chose-Payment-Method": "Choose Payment Method",
        "prices_Why-choose-VPN": "Why choose {productName}?",
        "prices_VPN-includes": "{productName} includes",
        "prices_includes-support": "24/7 customer support",
        "prices_includes-money-back": "{numberOfDays}-day money-back guarantee",
        "prices_includes-security": "Bank-class security & encryption",
        "prices_includes-no-logs": "No activity & connection logs",
        "instruction": "Instructions",
        "apps": "Apps",
        "my-ip": "My IP",
        "terms": "Terms & Conditions",
        "return-refund-policy": "Return & Refund Policy",
        "policy_and_procedure": "DMCA Notice and Takedown Policy and Procedures",
        "privacy_policy": "Privacy policy",
        "footer_support": "Support",
        "footer_sales-department": "Sales department",
        "footer_company": "Company",
        "footer_contacts": "Contacts",
        "data-retention-policy": "Data Retention Policy",
        "pricing": "Pricing",
        "servers": "Servers",
        "knowledge-base": "Knowledge Base",
        "dashboard": "Dashboard",
        "to_home_page": "To home page",
        "icon_logo": "Logo",
        "icon_login": "Login",
        "icon_menu": "Menu",
        "icon_key": "Key",
        "icon_logout": "Logout",
        "log_out": "Log Out",
        "icon_lifebuoy": "Lifebuoy",
        "knowledge_base": "Knowledge Base",
        "icon_support": "Support",
        "downloads": "Downloads",
        "billings": "Billings",
        "icon_eyeOpen": "Show Password",
        "inactive": "Inactive. Make your payment to start using it",
        "icon_flag": "Flag",
        "active_until": "Active until",
        "servers_cta_title": "Start Safe Browsing Today",
        "servers_cta_description": "Keep your internet safe and private",
        "downloads_instruction": "Instruction",
        "downloads_router": "Router",
        "downloads_openVPN_profiles": "OpenVPN profiles",
        "tariff-name-long_Year": "One Year",
        "tariff-name-long_Month": "One Month",
        "dialog_cancel-subscription_header": "Cancel Subscription",
        "web_payment_history": "Payment history",
        "tariff": "Tariff",
        "payment_method": "Payment method",
        "status": "Status",
        "sum": "Sum",
        "tbody_is_empty_1": "Here you will see information about your payments",
        "tbody_is_empty_2": "Place an order",
        "status_paid": "Paid",
        "status_not-paid": "Not Paid",
        "my_services": "My Services",
        "private_and_secure": "Private and secure internet access (VPN)",
        "active": "Active",
        "downloads_apps_and_files": "Apps and Files",
        "downloads_for_mobile": "Mobiles",
        "downloads_for_desktop": "Desktops",
        "downloads_for_browsers": "Browsers",
        "downloads_other_devices": "Other Devices",
        "forget-password_dialog_email-link_header": "Check Your Email",
        "pageNotFoundTitle": "Page not Found",
        "pageNotFoundDescription": "It looks like no such page exists. Please come back to us.",
        "pageNotFoundAction": "Return to {productName} website",
        "home": {
            "mainTitle": {
                "highlited": "Your\ndigital",
                "rest": "protection"
            },
            "mainDescription": {
                "first": "of all your personal data and computers",
                "second": "against cyber fraudsters"
            },
            "mainButton": "From $2.50 per month*",
            "mainButtonDescription": "*money refund guaranteed",
            "secureTitle": {
                "first": "Truly secure",
                "second": "Internet"
            },
            "secureDescription": "VPN99 removes everything that makes you unique and recognizable on the Internet. It hides your IP address and encrypts data to keep your browsing history private.",
            "secureCard": {
                "noRestrictionsTitle": "No bans!",
                "noRestrictionsDescription": "All Internet sites are available to you, even blocked ones. Moreover, all your data are reliably protected from fraudsters.",
                "anonimTitle": "You are now anonymous!",
                "anonimDescription": "Every single connection is anonymous! We do not store logs, so even we have no idea what are you up to on the Internet ;)"
            },
            "devicesTitle": {
                "first": "One subscription",
                "second": "for all devices!"
            },
            "devicesDescription": "MacOS, Windows, Android, iOS, extensions for Chrome and Firefox, and much more",
            "serversTitle": {
                "first": "Fast and stable",
                "second": "connection anywhere!"
            },
            "serversDescription": "Choose one of 17 servers or trust the recommended server - it is the closest and therefore the fastest. You will be able to freely watch movies and TV shows in HD quality.",
            "faqTitle": "FAQ",
            "faq": {
                "whatIsVPNTitle": "What does VPN mean?",
                "whatIsVPNDescription": {
                    "p1": "VPN stands for Virtual Private Network.",
                    "p2": "The main purpose of our service is to ensure your security and privacy. To do this, {productName} encrypts the traffic you transmit over the network."
                },
                "rollUp": "Roll Up",
                "more": "Read",
                "howWorksTitle": "How does a VPN work?",
                "howWorksDescription": {
                    "p1": "VPN adds a new network interface to the system that functions as if you connected your device directly to the private network. It also changes your IP address and encrypts all transmitted data. A VPN allows information to be received and transmitted over public networks (via the Internet, for example) just as if your device were connected to a private network, directly, applying the same security policies and providing access to all resources on the private network."
                },
                "whatDiferenceWithProxyTitle": "What is the difference between proxy servers and VPN service?",
                "whatDiferenceWithProxyDescription": {
                    "p1": "VPN-connections are much more stable and encrypted, unlike proxy. Not all programs are able to work with proxy servers and many of them require further configuration. If you are using a VPN, then you do not need to configure each application individually."
                },
                "whatCountriesTitle": "What countries are available?",
                "whatCountries": {
                    "first": "The current list of locations in various countries can be found",
                    "link": "here",
                    "second": "So, you get an IP address with the geolocation of these countries when you connect."
                },
                "logsTitle": "Do you save log files?",
                "logs": {
                    "p1": "{productName} has a strict no-logging policy."
                }
            },
            "footerTitle": {
                "first": "Start",
                "second": "using VPN99"
            },
            "footerDescription": "Download the app on your device"
        },
        "my_ip_address": "My IP address",
        "your_ip": "This is your IP address",
        "fail-payment_title": "Oops! Something wrong.",
        "fail-payment_description": "Your tariff has not been paid. Perhaps you made a mistake when entering data during payment. Don't worry, you can try again.",
        "fail-payment_go-to-prices": "Go to tariff plans and try again",
        "success_congratulations-title": "Congratulations on your purchase!",
        "success_congratulations-description": "Please, stand by. You will be redirected to your personal account, where you can manage your purchases.",
        "success-payment_go-to-dashboard": "Sign in now",
        "servers_title": "One click access to 10+ countries",
        "servers_title_description": "Choose a VPN server on {continents_number} continents in one of {servers_number} countries",
        "servers_locations_list_title": "List of VPN server locations",
        "servers_continent_all": "All countries",
        "allservers": "Country",
        "continent_asia": "Asia",
        "continent_europe": "Europe",
        "continent_america": "America",
        "our_vpn_server": "Our Servers",
        "subscription_canceled_failure": "Oops! There was an error when cancelling. Please contact support.",
        "prices_trial-days": "+ {count} days trial",
        "days": "{count} days",
        "prices_trial": "Trial",
        "pageForbidden": "Forbidden Access",
        "pageForbiddenTitle": "Forbidden Access",
        "pageForbiddenDescription": "It seems that where you tried to get access is limited.",
        "pageForbiddenAction": "Return to {productName} website",
        "private_finally": "Private online surfing",
        "no_one_can_see": "Nobody can see what you are doing",
        "no_restrictions_on_content_and_censorship": "No online restrictions or censorship",
        "one_click_and_you_defend": "One click, and you are protected",
        "removes_everything": "{productName} removes everything that makes you unique and recognizable online, by hiding your IP address and encrypting your data to keep your browsing history private.",
        "enjoy_fast_and_stable_connection": "Enjoy fast and stable connection anywhere",
        "choose_your_server": "Choose your server in {count} countries and enjoy the fastest VPN service. More servers means less load, and a wide range of locations means that you are more likely to find one nearby",
        "easy_to_use_apps": "User-friendly apps for all your devices",
        "subscription_comes": "One {productName} subscription for every device you own: {appsList} and more",
        "money_back_guarantee": "Money back guarantee",
        "faq_footer_description": "You can find other information that interests you in our",
        "faq_footer_knowledge-base": "Knowledge Base",
        "tryFree": "Try for Free",
        "year": "year",
        "icon_up": "Up",
        "status_failed": "Failed",
        "status_refund": "Refund",
        "status_trial": "Trial",
        "onHoldMessage": "Something has gone wrong and we have been unable to automatically charge you for {productName} subscription on your {platform} device.",
        "period_mini_month": "mo",
        "period_mini_year": "ye"
    },
    "css": "",
    "validator": {
        "is_empty": "The field must be empty",
        "is_not_empty": "Required field",
        "is_email": "Check the input is correct",
        "is_phone": "Enter the correct number",
        "is_password": "Password may not contain {extraSymbols}",
        "is_password_has_enough_symbols": "Password must contain at least 6 characters",
        "is_password_less_max_symbols": "Password must contain less than 32 characters",
        "is_password_has_space": "Password may not contain space mark",
        "is_not_empty_with_field_name": "The {fieldName} field is required",
        "is_password_has_not_english_letters": "Non-latin letters are not allowed"
    },
    "passwords-not-equal": "Password mismatch",
    "error": {
        "e500": {
            "new": {
                "text": "We already know about this problem and will solve it soon! Please try again later.",
                "title": "500 — Server Error"
            }
        },
        "eUnknown": {
            "new": {
                "text": "We already know about this problem and will solve it soon! Please try again later.",
                "title": "500 — Server Error"
            },
            "oops": {
                "text": "Oops! There was an error when loading user data. Please try reload the page."
            }
        },
        "e400": {
            "new": {
                "title": "Error"
            }
        }
    },
    "whitelabel": {
        "vpn99": {
            "web_be_safe": "Online security at the best price",
            "web_online_security": "From just ${pricePerMonth} per month"
        },
        "trustvpn": {
            "web_be_safe": "Protect your online privacy now!",
            "web_online_security": "Ultra-fast speed, Safeguard your privacy, Digital Freedom"
        }
    },
    "meta": {
        "descriptionDataRetentionPolicy": "The situations and periods that your data will be retained. All of your data is securely stored in accordance with GDPR guidelines.",
        "descriptionMyIp": "Find your IP Address, your computer's address online. See what your IP Address appears as",
        "descriptionPrivacyPolicy": "Our Privacy Policy includes information on what data we collect and how we use it, as well as how you can control your information. We use cookies in accordance with Welsh law, but you can adjust your preferences.",
        "descriptionTerms": "By accepting {productName}'s Terms of Use, you're agreeing to the terms and conditions, as well as the responsibilities of both parties outlined in the agreement. Compensation is available in certain circumstances when VPN services are unavailable or malfunctioning for over 12 consecutive hours.",
        "descriptionAccountBilling": "Account - Payment history",
        "descriptionAccountPanel": "Account - Dashboard",
        "descriptionAccountPassword": "Account - Change password",
        "descriptionDownload": "Download",
        "descriptionForgot": "Restore password",
        "descriptionReset": "Reset password",
        "descriptionHome": "Download and install {productName} and you'll get full protection across the globe. {productName}, high speeds and complete anonymity.",
        "descriptionPayFail": "Payment rejected",
        "descriptionPaySuccess": "Payment was successful",
        "descriptionPrice": "Buy and install {productName} and you'll get full protection across the globe. Get all the benefits of an expensive VPN. {productName}, high speeds and complete anonymity",
        "descriptionServers": "Servers",
        "titleDataRetentionPolicy": "Data Retention Policy",
        "titleMyIp": "Find out what your IP Address is online",
        "titlePrivacyPolicy": "The Privacy Policy: the steps {productName} takes to protect your private information",
        "titleTerms": "Terms of Use: the specific terms that apply when taking advantage of services provided by {productName}.",
        "titleAccountBilling": "Account - Payment history",
        "titleAccountPanel": "Account - Dashboard",
        "titleAccountPassword": "Account - Change password",
        "titleDownload": "Download",
        "titleForgot": "Restore password",
        "titleReset": "Reset password",
        "titleHome": "Download {productName} today and you'll get reliable coverage for an affordable price.",
        "titleLogin": "Sign in",
        "titlePayFail": "Payment rejected",
        "titlePaySuccess": "Payment was successful",
        "titlePrice": "Get {productName} today and you'll get reliable coverage for the affordable price",
        "titleRegister": "Sign up",
        "titleServers": "Servers",
        "titleApps": "Apps",
        "descriptionApps": "Apps",
        "descriptionLogin": "Sign In",
        "descriptionRegister": "Sign Up",
        "titleReturnRefundPolicy": "Return Refund Policy",
        "descriptionReturnRefundPolicy": "Return Refund Policy",
        "titlePolicyAndProcedure": "DMCA Notice and Takedown Policy and Procedures",
        "descriptionPolicyAndProcedure": "DMCA Notice and Takedown Policy and Procedures",
        "magicLink": "Magic",
        "descriptionMagicLink": "Magic suround you"
    },
    "getVPN": "Get {productName}",
    "signIn": "Sign In",
    "billings-page": {
        "waiting-processing": "Waiting for a response\nfrom payment processing."
    },
    "servers": {
        "USDal": "USA Dallas"
    }
}
