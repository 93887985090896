export default Object.freeze({
  TYPE: {
    TEXT: 'text',
    PASSWORD: 'password',
    EMAIL: 'email',
  },
  VIEW: {
    STANDARD: 'standard',
    BOX: 'box',
    INLINE: 'inline',
  },
})
