import store from '@/store'

export const clog = (...args) => {
  if (store.state.app.isDeveloperMode) {
    console.log(...args)
  }
}

function TS() {
  return new Date().toLocaleString('sv', { timeZone: 'UTC' }) + 'Z'
}

function hookLogType(logType) {
  const original = console[logType].bind(console)
  return function () {
    console.everything.push({
      type: logType,
      timeStamp: TS(),
      value: Array.from(arguments),
    })
    original.apply(console, arguments)
  }
}

export const toSaveLogs = () => {
  if (console.everything === undefined) {
    console.everything = []
    window.onerror = function (error, url, line) {
      console.everything.push({
        type: 'exception',
        timeStamp: TS(),
        value: { error, url, line },
      })
      return false
    }
    window.onunhandledrejection = function (e) {
      console.everything.push({
        type: 'promiseRejection',
        timeStamp: TS(),
        value: e.reason,
      })
    }
    ;['log', 'error', 'warn', 'debug'].forEach((logType) => {
      console[logType] = hookLogType(logType)
    })
  }
}
