{
    "web": {
        "why_use": "مميزات استخدام {productName}",
        "download_VPN": "قم بتحميل {productName} وابدأ باستخدامه اليوم!",
        "getVPN": "احصل على {productName} ",
        "access": "الوصول الآمن إلى أي محتوى من أي مكان في العالم",
        "jurisdiction": "ضمانات الاتحاد الأوروبي القانونية  للحفاظ على السرية",
        "anonymity": "إخفاء وتشفير الهوية  والسجلات بالكامل",
        "simple_conf": "ضبط واستخدام سهل",
        "secure": "دفع آمن وحماية من الاختراق",
        "protection": "الحماية من الإعلانات المزعجة",
        "get_VPN_app_phone": "احصل على {productName} لجهازك",
        "keep_safe": "حافظ على أمنك وخصوصيتك عبر الإنترنت.",
        "different_device": "احصل على {productName} لجهاز مختلف",
        "authorize": "تصريح",
        "dont_have_an_account": "ليس لديك حساب؟\r",
        "register": "تسجيل",
        "pass": "كلمة السر",
        "sign_in": "تسجيل الدخول\r",
        "forgot_pass": "هل نسيت كلمة السر؟",
        "email": "البريد الإلكتروني\r",
        "instructions_for_macos": "تعليمات لنظام تشغيل MacOS",
        "instructions_for_windows": "تعليمات لنظام تشغيل Windows",
        "current-plan": "خطة الاشتراك الحالية",
        "date": "التاريخ",
        "price": "السعر",
        "price_from": "السعر يبدأ من",
        "next_due": "تاريخ الدفعة التالية",
        "payment": "الدفع",
        "cancel": "إلغاء",
        "go_to_settings": "الذهاب إلى الإعدادات",
        "ok": "موافق",
        "dialog_cancel-subscription_title-AppStore": "يمكنك إلغاء الاشتراك من خلال حساب Apple Store حيث تم الاشتراك",
        "dialog_cancel-subscription_title-PlayMarket": "يمكنك إلغاء الاشتراك من خلال حساب Play Market حيث تم الاشتراك",
        "dialog_cancel-subscription_title-sure": "هل أنت متأكد أنك تريد إلغاء الاشتراك؟",
        "yes": "نعم",
        "no": "لا",
        "subscription_canceled_successfully": "تم إلغاء الاشتراك بنجاح",
        "dashboard_dialog_thanks-for-registration_title": "شكرا لك على التسجيل!",
        "dashboard_dialog_thanks-for-registration_description_1": "يرجى تذكر كلمة السر الخاصة بك لتسجيل الدخول.",
        "Password": "كلمة السر",
        "dashboard_dialog_thanks-for-registration_description_2-1": "أو",
        "dashboard_dialog_thanks-for-registration_description_2-2": "اضغط هنا",
        "dashboard_dialog_thanks-for-registration_description_2-3": "لتعيين كلمة السر الخاصة بك.",
        "prices_date-of-next-payment": "تاريخ خصم المبلغ التالي",
        "change_password": "تغيير كلمة السر",
        "current_password": "كلمة السر الحالية",
        "new_password": "كلمة سر جديدة\r",
        "confirm-password": "تأكيد كلمة السر",
        "reset": "إعادة تعيين",
        "email_address_associated": "أدخل عنوان البريد الإلكتروني المرتبط بحسابك. سنرسل لك رابطًا عبر البريد الإلكتروني لإعادة تعيين كلمة السر.",
        "get-link": "إرسال البريد الإلكتروني",
        "sign-in": "تسجيل الدخول\r",
        "forget-password_dialog_email-link_title": "لقد أرسلنا لك بريدًا إلكترونيًا يحتوي على رابط لإعادة تعيين كلمة السر.\n",
        "i-have-promocode": "لدي رمز ترويجي",
        "promo": "الرمز الترويجي",
        "have_an_account": "لدي حساب",
        "reset-password": "إعادة تعيين كلمة السر",
        "state": {
            "register": "تسجيل"
        },
        "action": {
            "register": "تسجيل",
            "cancel": "إلغاء"
        },
        "notify_password-changed": "تم تغيير كلمة السر",
        "prices_discount": "خصم",
        "prices_email": "البريد الإلكتروني\r",
        "prices_country": "بلدك\r",
        "prices_pay": "ادفع",
        "prices_privacy_p-0": "بإرسال هذا النموذج، فإنك توافق على",
        "prices_privacy_p-1": "شروط الخدمة",
        "prices_privacy_p-2": "و",
        "prices_privacy_p-3": "سياسة الخصوصية",
        "price_card": "بطاقة",
        "price_internet-wallet": "محفظة إلكترونية",
        "price_cryptocurrency": "عملة رقمية",
        "prices_order-total": "مجموع الطلب",
        "i-dont-have-promocode": "ليس لدي رمز ترويجي",
        "dashboard_dialog_thanks-for-registration_description_1_promo": "الرمز الترويجي الذي أدخلته غير صحيح",
        "prices_user-has-account_how-to-change-plan_1": "لتغيير خطة التعرفة، قم بإلغاء اشتراكك الحالي على",
        "prices_user-has-account_how-to-change-plan_2": "صفحة",
        "prices_user-has-account_how-to-change-plan_3": "الفواتير، ثم قم بإتمام عملية شراء جديدة",
        "instructions_for_linux": "تعليمات لنظام تشغيل Linux",
        "prices_Step": "الخطوة",
        "tariff-name_Year": "سنة",
        "tariff-name_Month": "الشهر",
        "month": "أشهر",
        "prices_save": "حفظ",
        "prices_billedEvery": "يتم الدفع كل",
        "prices_the-most-popular": "الأكثر شعبية",
        "prices_Billed": "يتم الدفع",
        "prices_every": "كل",
        "icon_check-circle-empty": "دائرة فارغة",
        "icon_check-circle": "خانة اختيار",
        "icon_like": "إعجاب",
        "icon_info": "معلومات",
        "icon_down": "أسفل",
        "icon_check": "خانة اختيار",
        "prices_the-1-year-is-better": "خطة الـ 1 سنوات هي الأكثر توفيرًا لك",
        "prices_choose-plan": "اختر خطة الاشتراك",
        "prices_Chose-Payment-Method": "اختر طريقة الدفع",
        "prices_Why-choose-VPN": "لمَ يتعين عليك اختيار {productName}؟",
        "prices_VPN-includes": "يتضمن {productName}",
        "prices_includes-support": "خدمة العملاء 24/7",
        "prices_includes-money-back": "ضمان استرداد الأموال خلال {numberOfDays} أيام",
        "prices_includes-security": "أمان وتشفير على مستوى البنوك",
        "prices_includes-no-logs": "لا يوجد سجلات نشاط أو اتصال",
        "instruction": "تعليمات",
        "apps": "التطبيقات",
        "my-ip": "عنوان بروتوكول الإنترنت الخاص بي",
        "terms": "شروط والأحكام",
        "return-refund-policy": "سياسة الاسترجاع والاسترداد\r",
        "policy_and_procedure": "سياسات وإجراءات الإشعار والإزالة لقانون الألفية للملكية الرقمية",
        "privacy_policy": "سياسة الخصوصية",
        "footer_support": "الدعم",
        "footer_sales-department": "قسم المبيعات",
        "footer_company": "الشركة",
        "footer_contacts": "جهات الاتصال",
        "data-retention-policy": "سياسة الاحتفاظ بالبيانات",
        "pricing": "الأسعار",
        "servers": "الخوادم",
        "knowledge-base": "قاعدة المعرفة\r",
        "dashboard": "لوحة التحكم",
        "to_home_page": "الذهاب إلى إلى الصفحة الرئيسية",
        "icon_logo": "شعار",
        "icon_login": "تسجيل الدخول",
        "icon_menu": "القائمة",
        "icon_key": "مفتاح",
        "icon_logout": "تسجيل الخروج",
        "log_out": "تسجيل الخروج",
        "icon_lifebuoy": "طوق نجاة",
        "knowledge_base": "قاعدة المعرفة\r",
        "icon_support": "الدعم",
        "downloads": "تنزيلات",
        "billings": "الفواتير",
        "icon_eyeOpen": "إظهار كلمة السر",
        "inactive": "غير فعّال. قم باتمام الدفع للبدء باستخدامه.",
        "icon_flag": "علم",
        "active_until": "فعّال لغاية",
        "servers_cta_title": "تصفح بأمان الآن",
        "servers_cta_description": "حافظ على الأمان والخصوصية في تصفح الإنترنت",
        "downloads_instruction": "التعليمات",
        "downloads_router": "الموجّه",
        "downloads_openVPN_profiles": "ملفات تعريف OpenVPN\r",
        "tariff-name-long_Year": "سنة واحدة",
        "tariff-name-long_Month": "شهر واحد",
        "dialog_cancel-subscription_header": "إلغاء الاشتراك",
        "web_payment_history": "سجل الدفع",
        "tariff": "التعرفة",
        "payment_method": "طريقة الدفع",
        "status": "الحالة",
        "sum": "المجموع",
        "tbody_is_empty_1": "هنا سترى معلومات حول مدفوعاتك",
        "tbody_is_empty_2": "أرسل طلبية",
        "status_paid": "مدفوع",
        "status_not-paid": "غير مدفوع",
        "my_services": "خدماتي\r",
        "private_and_secure": "وصول آمن وخاص إلى الإنترنت (VPN)",
        "active": "فعّال",
        "downloads_apps_and_files": "التطبيقات والملفات\r",
        "downloads_for_mobile": "الهواتف",
        "downloads_for_desktop": "أجهزة الكمبيوتر المكتبية\r",
        "downloads_for_browsers": "المتصفحات\r",
        "downloads_other_devices": "أجهزة أخرى\r",
        "forget-password_dialog_email-link_header": "تحقق من بريدك الإلكتروني",
        "pageNotFoundTitle": "الصفحة غير موجودة",
        "pageNotFoundDescription": "يبدو أنه لا توجد مثل هذه الصفحة. يرجى العودة إلينا.",
        "pageNotFoundAction": "العودة إلى الموقع الإلكتروني {productName}",
        "home": {
            "mainTitle": {
                "highlited": "حماية",
                "rest": "كاملة"
            },
            "mainDescription": {
                "first": "لجميع بياناتك الشخصية وأجهزتك",
                "second": "من المحتالين عبر شبكة الإنترنت"
            },
            "mainButton": "بدءًا من 2.50 دولارًا أمريكيًا في الشهر*",
            "mainButtonDescription": "*ضمان استرداد الأموال",
            "secureTitle": {
                "first": "التصفح الآمن",
                "second": "للإنترنت"
            },
            "secureDescription": "VPN99 يزيل كل ما يتعلق بك أو كل ما قد يسهل التعرف عليك، عن طريق إخفاء عنوان برتوكول الإنترنت الخاص بك وتشفير بياناتك للحفاظ على خصوصية سجل التصفح.",
            "secureCard": {
                "noRestrictionsTitle": "إمكانية تصفّح المواقع المحظورة!",
                "noRestrictionsDescription": "ستتمكّن من تصفّح جميع المواقع الإلكترونية، حتى المحظورة منها. كما سيتم حماية جميع بياناتك بالكامل من المحتالين.",
                "anonimTitle": "أنت الآن تتصفّح الإنترنت بشكل سري!",
                "anonimDescription": "لن يتمكّن أي شخص من معرفة أي موقع إلكتروني تزوره! إذ لن يتم الاحتفاظ بسجلاتك، فحتى نحن لن نتمكّن من معرفة تحركاتك عبر شبكة الإنترنت ;)"
            },
            "devicesTitle": {
                "first": "اشتراك واحد",
                "second": "لجميع الأجهزة التي تمتلكها!"
            },
            "devicesDescription": "تتوفر ملحقات MacOS و Windows و Android و iOS و Chrome و Firefox وغيرها.",
            "serversTitle": {
                "first": "اتصال سريع و",
                "second": "ثابت بشبكة الإنترنت من أي مكان!"
            },
            "serversDescription": "اختر واحدًا من الخوادم الـ17 أو اختر الخادم الموصى به - فهو الأقرب لك وبالتالي الأسرع. ستتمكن من مشاهدة الأفلام والبرامج التلفزيونية بحُرية وبجودة عالية.",
            "faqTitle": "أسئلة شائعة",
            "faq": {
                "whatIsVPNTitle": "ما هو VPN؟",
                "whatIsVPNDescription": {
                    "p1": "VPN يعني الشبكة الخاصة الافتراضية.",
                    "p2": "الهدف الرئيسي من خدمتنا هو ضمان تصفحك الآمن وخصوصيتك. للقيام بذلك، يقوم VPN99 بتشفير تحركاتك عبر شبكة الإنترنت."
                },
                "rollUp": "تراكمي",
                "more": "ليقرأ",
                "howWorksTitle": "ما هو مبدأ عمل VPN؟",
                "howWorksDescription": {
                    "p1": "تتم ترجمة VPN (Virtual Private Network) على أنها 'الشبكة الخاصة الافتراضية'. توجه الشبكة الافتراضية الخاصة (VPN) تحركاتك على الإنترنت عبر نفق هذه الشبكة، وهو عبارة عن اتصال مشفر بين جهازك ووجهتك على الويب. لا يقوم نفق الشبكة الافتراضية الخاصة فقط بتشفير بياناتك، ولكنه يقوم أيضًا بإخفاء عنوان برتوكول الإنترنت وموقعك، مما يزيد من مستوى أمن معلوماتك الشخصية على الإنترنت."
                },
                "whatDiferenceWithProxyTitle": "ما هو الفرق بين خوادم البروكسي وخدمة VPN؟",
                "whatDiferenceWithProxyDescription": {
                    "p1": "تعد اتصالات VPN أكثر استقرارًا وتشفيرًا، على عكس البروكسي. ليست كل البرامج قادرة على العمل مع خوادم الـ proxy والعديد منها يتطلب مزيدًا من الاعدادات. إذا كنت تستخدم VPN، فلن تحتاج إلى تعديل الإعدادات لكل تطبيق على حدة."
                },
                "whatCountriesTitle": "ما هي البلدان التي تتوفر فيها هذه الخدمة؟",
                "whatCountries": {
                    "first": "يمكنك تصفح قائمة البلدان التي تتوفر فيها هذه الخدمة",
                    "link": "هنا",
                    "second": "ستحصل على عنوان بروتوكول الإنترنت الخاص بالموقع الجغرافي لهذه البلدان عند الاتصال."
                },
                "logsTitle": "هل يتم الاحتفاظ بسجلات البيانات؟",
                "logs": {
                    "p1": "لدى {productName} سياسة صارمة بعدم الاحتفاظ بالسجلات."
                }
            },
            "footerTitle": {
                "first": "ابدأ",
                "second": "باستخدام VPN99"
            },
            "footerDescription": "حمّل التطبيق على جهازك"
        },
        "my_ip_address": "عنوان بروتوكول الإنترنت الخاص بي",
        "your_ip": "هذا هو عنوان بروتوكول الإنترنت الخاص بك",
        "fail-payment_title": "لقد حدث خطأ ما.",
        "fail-payment_description": "لم يتم دفع التعرفة. قد تكون قد ارتكبت خطأ عند إدخال البيانات أثناء الدفع. لا تقلق، يمكنك المحاولة مرة أخرى.",
        "fail-payment_go-to-prices": "اذهب إلى خطط التعرفة وحاول مرة أخرى",
        "success_congratulations-title": "تهانينا على إتمام عملية الشراء",
        "success_congratulations-description": "يرجى الانتظار، سيتم إعادة توجيهك إلى حسابك الشخصي حيث يمكنك إدارة مشترياتك.",
        "success-payment_go-to-dashboard": "سجّل الدخول الآن",
        "servers_title": "الوصول بكبسة واحدة إلى 10+ بلدان",
        "servers_title_description": "اختر خادم VPN في {continents_number} قارات في إحدى البلدان الـ {servers_number}",
        "servers_locations_list_title": "قائمة مواقع خادم VPN\r",
        "servers_continent_all": "جميع البلدان",
        "allservers": "البلد",
        "continent_asia": "آسيا",
        "continent_europe": "أوروبا",
        "continent_america": "أمريكا",
        "our_vpn_server": "الخوادم الخاصة بنا",
        "subscription_canceled_failure": "نعتذر، لقد حدث خطأ ما أثناء الإلغاء. يرجى التواصل مع قسم الدعم.",
        "prices_trial-days": "⁺ {count} أيام للنسخة التجريبية",
        "days": "{count} أيام",
        "prices_trial": "النسخة التجريبية",
        "pageForbidden": "تعذر الوصول",
        "pageForbiddenTitle": "تعذر الوصول",
        "pageForbiddenDescription": "يبدو أن الموقع الذي تحاول الوصول إليه محدود.",
        "pageForbiddenAction": "العودة إلى موقع {productName}",
        "private_finally": "الخصوصية في تصفح الإنترنت",
        "no_one_can_see": "لا أحد يستطيع رؤية ما تفعله",
        "no_restrictions_on_content_and_censorship": "لا قيود أو رقابة على الإنترنت",
        "one_click_and_you_defend": "تصفحك الآمن للإنترنت يبدأ بكسبة واحدة",
        "removes_everything": "{productName} يزيل كل ما يتعلق بك أو كل ما قد يسهل التعرف عليك، عن طريق إخفاء عنوان برتوكول الإنترنت الخاص بك وتشفير بياناتك للحفاظ على خصوصية سجل التصفح.",
        "enjoy_fast_and_stable_connection": "تمتع باتصال سريع وثابت في أي مكان",
        "choose_your_server": "اختر خادمك من بين {count} بلد، وتمتع بأسرع خدمة VPN. وجود عدد أكبر من الخوادم يعني حملًا أقل، ووجود مجموعة كبيرة من المواقع يعني أنه من المرجح أن تعثر على واحد قريب منك.",
        "easy_to_use_apps": "تطبيق متوافق مع جميع أجهزتك",
        "subscription_comes": "اشتراك {productName} واحد لكل جهاز تملكه: {appsList} والمزيد",
        "money_back_guarantee": "ضمان استرداد الاموال",
        "faq_footer_description": "يمكنك العثور على معلومات أخرى تهمك في",
        "faq_footer_knowledge-base": "قاعدة البيانات",
        "tryFree": "جرّب مجانًا",
        "year": "سنة",
        "icon_up": "للأعلى",
        "status_failed": "فشلت العملية",
        "status_refund": "استرداد الأموال",
        "status_trial": "النسخة التجريبية",
        "onHoldMessage": "لقد حدث خطأ ما ولم نتمكن من تحصيل رسوم الاشتراك في {productName} تلقائيًّا على جهاز {platform} الخاص بك."
    },
    "css": "",
    "validator": {
        "is_empty": "يجب أن يكون الحقل فارغًا",
        "is_not_empty": "هذا الحقل إجباري",
        "is_email": "تحقق من صحة المدخلات",
        "is_phone": "أدخل الرقم الصحيح",
        "is_password": "يجب ألا تحتوي كلمة السر على {extraSymbols}",
        "is_password_has_enough_symbols": "يجب أن تحتوي كلمة السر على 6 رموز على الأقل",
        "is_password_less_max_symbols": "يجب أن تحتوي كلمة السر على أقل من 32 رمز",
        "is_password_has_space": "يجب ألا تحتوي كلمة السر على علامة الفصل",
        "is_not_empty_with_field_name": "حقل {fieldName} إجباري",
        "is_password_has_not_english_letters": "لا يُسمح باستخدام الأحرف غير اللاتينية"
    },
    "passwords-not-equal": "كلمة السر غير متطابقة",
    "error": {
        "e500": {
            "new": {
                "text": "نحن نعلم عن هذه المشكلة وسنحلها قريبًا! يرجى المحاولة مجددًا في وقت لاحق.",
                "title": "500 - خطأ في الخادم"
            }
        },
        "eUnknown": {
            "new": {
                "text": "نحن نعلم عن هذه المشكلة وسنحلها قريبًا! يرجى المحاولة مجددًا في وقت لاحق.",
                "title": "500 - خطأ في الخادم"
            },
            "oops": {
                "text": "نعتذر، لقد حدث خطأ ما أثناء تحميل بيانات المستخدم. يرجى إعادة تحميل الصفحة."
            }
        },
        "e400": {
            "new": {
                "title": "خطأ"
            }
        }
    },
    "whitelabel": {
        "vpn99": {
            "web_be_safe": "التصفح الآمن للإنترنت بأفضل سعر",
            "web_online_security": "بدءًا من {pricePerMonth} دولار فقط في الشهر"
        },
        "trustvpn": {
            "web_be_safe": "حافظ على خصوصيتك أثناء تصفح الإنترنت!",
            "web_online_security": "سرعة فائقة، حماية الخصوصية، حرية كاملة على الإنترنت."
        }
    },
    "meta": {
        "descriptionDataRetentionPolicy": "الحالات والفترات التي سيتم فيها الاحتفاظ ببياناتك. يتم تخزين جميع بياناتك بشكل آمن وفقًا لإرشادات اللائحة العامة لحماية البيانات.",
        "descriptionMyIp": "ابحث عن عنوان بروتوكول الإنترنت وعنوان جهاز الكمبيوتر الخاص بك عبر الإنترنت، وسترى كيف يظهر عنوان بروتوكول الإنترنت الخاص بك.",
        "descriptionPrivacyPolicy": "تتضمن سياسة الخصوصية الخاصة بنا معلومات حول البيانات التي نجمعها وكيف نستخدمها، بالإضافة إلى كيف يمكنك التحكم في معلوماتك. نحن نستخدم ملفات تعريف الارتباط وفقًا لقانون ويلز، إلا أنه يمكنك تعديل تفضيلاتك.",
        "descriptionTerms": "بقبول شروط استخدام {productName}، فإنك توافق على شروط وأحكام ومسؤوليات كلا الطرفين الموضحة في الاتفاقية. التعويض متاح في حالات معينة عندما تكون خدمات VPN غير متوفرة أو معطلة لأكثر من 12 ساعة متتالية.",
        "descriptionAccountBilling": "الحساب - سجل الدفع",
        "descriptionAccountPanel": "الحساب - لوحة التحكم",
        "descriptionAccountPassword": "الحساب - تغيير كلمة السر",
        "descriptionDownload": "تحميل",
        "descriptionForgot": "استعادة كلمة السر",
        "descriptionReset": "إعادة تعيين كلمة السر",
        "descriptionHome": "قم بتحميل وتثبيت {productName} وستحصل على حماية كاملة في جميع أنحاء العالم. {productName}، سرعة عالية وسرية كاملة.",
        "descriptionPayFail": "تم رفض الدفع",
        "descriptionPaySuccess": "تم الدفع بنجاح",
        "descriptionPrice": "قم بشراء وتثبيت {productName} وستحصل على حماية كاملة في جميع أنحاء العالم. احصل على جميع مزايا شبكة خاصة افتراضية باهظة الثمن. {productName}، سرعة عالية وسرية كاملة.",
        "descriptionServers": "الخوادم",
        "titleDataRetentionPolicy": "سياسة الاحتفاظ بالبيانات الخاصة بـ{productName}",
        "titleMyIp": "تعرّف على عنوان بروتوكول الإنترنت الخاص بك ",
        "titlePrivacyPolicy": "سياسة الخصوصية: الخطوات التي يتخذها {productName} لحماية معلوماتك الخاصة",
        "titleTerms": "شروط الاستخدام: الشروط المحددة التي تنطبق عند الاستفادة من الخدمات التي يقدمها {productName}.",
        "titleAccountBilling": "الحساب - سجل الدفع",
        "titleAccountPanel": "الحساب - لوحة التحكم",
        "titleAccountPassword": "الحساب - تغيير كلمة السر",
        "titleDownload": "تحميل",
        "titleForgot": "استعادة كلمة السر",
        "titleReset": "إعادة تعيين كلمة السر",
        "titleHome": "قم بتحميل {productName} اليوم وتمتع بتغطية موثوقة بسعر مناسب.",
        "titleLogin": "تسجيل الدخول",
        "titlePayFail": "تم رفض الدفع",
        "titlePaySuccess": "تم الدفع بنجاح",
        "titlePrice": "احصل على {productName} اليوم وتمتع بتغطية موثوقة بسعر مناسب.",
        "titleRegister": "الاشتراك",
        "titleServers": "الخوادم",
        "titleApps": "التطبيقات",
        "descriptionApps": "التطبيقات",
        "descriptionLogin": "تسجيل الدخول",
        "descriptionRegister": "الاشتراك",
        "titleReturnRefundPolicy": "سياسة الاسترداد",
        "descriptionReturnRefundPolicy": "سياسة الاسترداد",
        "titlePolicyAndProcedure": "سياسات وإجراءات الإشعار والإزالة لقانون الألفية للملكية الرقمية",
        "descriptionPolicyAndProcedure": "سياسات وإجراءات الإشعار والإزالة لقانون الألفية للملكية الرقمية"
    },
    "getVPN": "اطلب {productName}",
    "signIn": "دخول",
    "billings-page": {
        "waiting-processing": "في انتظار معالجة عملية الدفع."
    },
    "servers": {
        "USDal": "الولايات المتحدة الأمريكية دالاس"
    }
}
