export default Object.freeze({
  ORANGEPAY: 'orangepay',
  COINPAYMENT: 'coinpayment',
  CENTROBILL: 'centrobill',
  COMMERCE: 'commerce-gate',
  FREE_KASSA: 'freekassa',
  ACCESSPAY: 'accesspay',
  STRIPE: 'stripe',
  APPLE: 'apple',
  GOOGLE: 'google',
  PROMO: 'promo',
})
