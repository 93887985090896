import { PAYMENT } from '@/CONSTANTS'

export default class Merchant {
  constructor(rest) {
    this.rest = rest
  }

  [PAYMENT.PAY_API_NAME[PAYMENT.MERCHANT.COMMERCE]]({ email, tariff_id, secret_key }) {
    return this.rest.post(
      '/payment/commercegate/params',
      {
        email,
        tariff_id,
      },
      {
        isAuth: true,
        headers: {
          'Purchase-Secret-Key': secret_key
        }
      }
    )
  }

  [PAYMENT.PAY_API_NAME[PAYMENT.MERCHANT.FREE_KASSA]]({ email, tariff_id, secret_key }) {
    return this.rest.post(
      '/payment/freekassa/params',
      {
        email,
        tariff_id,
      },
      {
        isAuth: true,
        headers: {
          'Purchase-Secret-Key': secret_key
        }
      }
    )
  }

  [PAYMENT.PAY_API_NAME[PAYMENT.MERCHANT.STRIPE]]({ email, tariff_id, secret_key }) {
    return this.rest.post(
      `/payment/stripe/params`,
      {
        email,
        tariff_id,
      },
      {
        isAuth: true,
        headers: {
          'Purchase-Secret-Key': secret_key
        }
      }
    )
  }
}
