{
    "web": {
        "why_use": "Por qué usar {productName}",
        "download_VPN": "¡Descargue {productName} y comience a usarlo hoy!",
        "getVPN": "Obtenga la app {productName}",
        "access": "Acceso seguro a contenido en cualquier parte del mundo",
        "jurisdiction": "La jurisdicción de la UE garantiza su confidencialidad",
        "anonymity": "Evite el seguimiento de IP para servicios publicitarios",
        "simple_conf": "Fácil de instalar y usar",
        "secure": " Pagos seguros et protection contre les hackers",
        "protection": "Protección contra publicidad intrusiva",
        "get_VPN_app_phone": "Obtenga la app {productName} para su dispositivo",
        "keep_safe": "Manténgase seguro y anónimo en línea",
        "different_device": "Obtener {productName} para un dispositivo diferente",
        "authorize": "Autoriza",
        "dont_have_an_account": "¿No tiene una cuenta?",
        "register": "Registrarse",
        "pass": "Contraseña",
        "sign_in": "Iniciar Sesión",
        "forgot_pass": "¿Olvidó su contraseña?",
        "email": "Correo electrónico",
        "instructions_for_macos": "Instrucciones para macOS",
        "instructions_for_windows": "Instrucciones para Windows",
        "current-plan": "Plan actual",
        "date": "Fecha",
        "price": "Precio",
        "price_from": "Precio desde",
        "next_due": "Próximo vencimiento",
        "payment": "Pago",
        "cancel": "Cancelar",
        "go_to_settings": "Ir a la Configuración",
        "ok": "Ok",
        "dialog_cancel-subscription_title-AppStore": "Usted puede cancelar la suscripción a través de la cuenta de Apple Store donde realizó la suscripción",
        "dialog_cancel-subscription_title-PlayMarket": "Usted puede cancelar la suscripción a través de la cuenta de Play Market donde realizó la suscripción",
        "dialog_cancel-subscription_title-sure": "¿Está seguro de que desea cancelar la suscripción?",
        "yes": "Sí",
        "no": "No",
        "subscription_canceled_successfully": "Suscripción cancelada con éxito",
        "dashboard_dialog_thanks-for-registration_title": "¡Gracias por registrarse!",
        "dashboard_dialog_thanks-for-registration_description_1": "Recuerde su contraseña para iniciar sesión.",
        "Password": "Contraseña",
        "dashboard_dialog_thanks-for-registration_description_2-1": "O",
        "dashboard_dialog_thanks-for-registration_description_2-2": "haga clic aquí",
        "dashboard_dialog_thanks-for-registration_description_2-3": "para establecer su propia contraseña.",
        "prices_date-of-next-payment": "Fecha del próximo cargo",
        "change_password": "Cambiar contraseña",
        "current_password": "Contraseña actual",
        "new_password": "Nueva Contraseña",
        "confirm-password": "Confirmar contraseña",
        "reset": "Restablecer",
        "email_address_associated": "Introduzca la dirección de correo electrónico asociada a su cuenta. Le enviaremos por correo un enlace para restablecer su contraseña.",
        "get-link": "Enviar correo electrónico",
        "sign-in": "Iniciar Sesión",
        "forget-password_dialog_email-link_title": "Le hemos enviado un correo electrónico con un enlace para restablecer su contraseña.",
        "i-have-promocode": "Tengo un código promocional",
        "promo": "Código promocional",
        "have_an_account": "Tengo una cuenta",
        "reset-password": "Restablecer contraseña",
        "state": {
            "register": "Regístrese"
        },
        "action": {
            "register": "Registrarse",
            "cancel": "Cancelar"
        },
        "notify_password-changed": "La contraseña ha sido cambiada",
        "prices_discount": "Descuento",
        "prices_email": "Correo electrónico",
        "prices_country": "Su país",
        "prices_pay": "Pagar",
        "prices_privacy_p-0": "Al enviar este formulario, usted acepta nuestros",
        "prices_privacy_p-1": "Términos de Servicio",
        "prices_privacy_p-2": "y",
        "prices_privacy_p-3": "Política de privacidad",
        "price_card": "Tarjeta",
        "price_internet-wallet": "Monederos de Internet",
        "price_cryptocurrency": "Criptomoneda",
        "prices_order-total": "Total de la Orden",
        "i-dont-have-promocode": "No tengo un código promocional",
        "dashboard_dialog_thanks-for-registration_description_1_promo": "El código promocional que introdujo no es válido",
        "prices_user-has-account_how-to-change-plan_1": "Para cambiar el plan tarifario, cancele la suscripción actual en la página de",
        "prices_user-has-account_how-to-change-plan_2": "Facturas",
        "prices_user-has-account_how-to-change-plan_3": ", luego compre otro plan",
        "instructions_for_linux": "Instrucciones para Linux",
        "prices_Step": "Paso",
        "tariff-name_Year": "Año",
        "tariff-name_Month": "Mes",
        "month": "mes",
        "prices_save": "Guardar",
        "prices_billedEvery": "facturado cada año",
        "prices_the-most-popular": "El más popular",
        "prices_Billed": "Facturado",
        "prices_every": "cada",
        "icon_check-circle-empty": "Círculo vacío",
        "icon_check-circle": "Casilla de verificación",
        "icon_like": "Me gusta",
        "icon_info": "Información",
        "icon_down": "Abajo",
        "icon_check": "Casilla de verificación",
        "prices_the-1-year-is-better": "El Plan de 1 Años representa el mayor ahorro para usted",
        "prices_choose-plan": "Elija su plan",
        "prices_Chose-Payment-Method": "Elija el método de pago",
        "prices_Why-choose-VPN": "¿Por qué elegir {productName}?",
        "prices_VPN-includes": "{productName} incluye",
        "prices_includes-support": "Atención al cliente 24/7",
        "prices_includes-money-back": "Garantía de devolución del dinero de {numberOfDays} días",
        "prices_includes-security": "Seguridad y cifrado de tipo bancario",
        "prices_includes-no-logs": "No hay registro de actividad y de conexión",
        "instruction": "Instrucciones",
        "apps": "Apps",
        "my-ip": "Mi IP",
        "terms": "Términos de uso",
        "return-refund-policy": "Política de Devoluciones y Reembolsos",
        "policy_and_procedure": "Aviso DMCA y Política y Procedimientos de Eliminación",
        "privacy_policy": "Política de Privacidad",
        "footer_support": "Soporte",
        "footer_sales-department": "Departamento de Ventas",
        "footer_company": "Compañía",
        "footer_contacts": "Contactos",
        "data-retention-policy": "Política de Retención de Datos",
        "pricing": "Precios",
        "servers": "Servidores",
        "knowledge-base": "Base de conocimientos",
        "dashboard": "Panel de Control",
        "to_home_page": "A la página de inicio",
        "icon_logo": "Logo",
        "icon_login": "Iniciar sesión",
        "icon_menu": "Menú",
        "icon_key": "Llave",
        "icon_logout": "Cerrar sesión",
        "log_out": "Cerrar sesión",
        "icon_lifebuoy": "Salvavidas",
        "knowledge_base": "Base de conocimientos",
        "icon_support": "Soporte",
        "downloads": "Descargas",
        "billings": "Facturas",
        "icon_eyeOpen": "Mostrar contraseña",
        "inactive": "Inactivo. Haga su pago para comenzar a usarlo",
        "icon_flag": "Bandera",
        "active_until": "Activo hasta",
        "servers_cta_title": "Comience la Navegación Segura hoy",
        "servers_cta_description": "Mantenga su internet seguro y privado",
        "downloads_instruction": "Instrucciones",
        "downloads_router": "Router",
        "downloads_openVPN_profiles": "Perfiles de OpenVPN",
        "tariff-name-long_Year": "Un Año",
        "tariff-name-long_Month": "Un Mes",
        "dialog_cancel-subscription_header": "Cancelar Suscripción",
        "web_payment_history": "Historial de pagos",
        "tariff": "Precio",
        "payment_method": "Método de Pago",
        "status": "Estado",
        "sum": "Suma",
        "tbody_is_empty_1": "Aquí verá información sobre sus pagos",
        "tbody_is_empty_2": "Hacer un pedido",
        "status_paid": "Pagado",
        "status_not-paid": "No Pagado",
        "my_services": "Mis Servicios",
        "private_and_secure": "Acceso a internet privado y seguro (VPN)",
        "active": "Activo",
        "downloads_apps_and_files": "Apps y Archivos",
        "downloads_for_mobile": "Móviles",
        "downloads_for_desktop": "Escritorios",
        "downloads_for_browsers": "Navegadores",
        "downloads_other_devices": "Otros dispositivos",
        "forget-password_dialog_email-link_header": "Revise su correo electrónico",
        "pageNotFoundTitle": "Página no encontrada",
        "pageNotFoundDescription": "Parece que esa página no existe. Por favor, regrese con nosotros.",
        "pageNotFoundAction": "Regrese al sitio web de {productName}",
        "home": {
            "mainTitle": {
                "highlited": "Su\nprotección",
                "rest": "digital"
            },
            "mainDescription": {
                "first": "de todos sus datos personales y ordenadores",
                "second": "contra ciberdelincuentes"
            },
            "mainButton": "Desde $2.5 por mes*",
            "mainButtonDescription": "*Reembolso de dinero garantizado",
            "secureTitle": {
                "first": "Realmente seguro",
                "second": "Internet"
            },
            "secureDescription": "VPN99 elimina todo lo que le hace único y reconocible en Internet. Oculta su dirección IP y cifra los datos para mantener su historial de navegación privado.",
            "secureCard": {
                "noRestrictionsTitle": "¡Sin prohibiciones!",
                "noRestrictionsDescription": "Todos los sitios de internet están disponibles para usted, incluso los bloqueados. Además, todos sus datos están protegidos de forma segura contra los estafadores",
                "anonimTitle": "¡Ahora usted es anónimo!",
                "anonimDescription": "¡Todas las conexiones son anónimas! No guardamos registros, así que no tenemos idea de lo que está haciendo en Internet ;)"
            },
            "devicesTitle": {
                "first": "¡Una suscripción",
                "second": "para todos los dispositivos!"
            },
            "devicesDescription": "MacOS, Windows, Android, iOS, extensiones para Chrome y Firefox, y mucho más",
            "serversTitle": {
                "first": "¡Una conexión rápida y estable",
                "second": "en cualquier lugar!"
            },
            "serversDescription": "Elija uno de los 17 servidores o confíe en el servidor recomendado - es el más cercano y, por ello, el más rápido. Podrá ver películas y programas de televisión con calidad HD sin restricciones.",
            "faqTitle": "Preguntas Frecuentes",
            "faq": {
                "whatIsVPNTitle": "¿Qué significa VPN?",
                "whatIsVPNDescription": {
                    "p1": "VPN significa Virtual Private Network, o Red Privada Virtual en español.",
                    "p2": "El objetivo principal de nuestro servicio es asegurar su seguridad y privacidad. Para hacer esto, {productName} encripta el tráfico que transmite a través de la red."
                },
                "rollUp": "Contraer",
                "more": "Leer",
                "howWorksTitle": "¿Cómo funciona una VPN?",
                "howWorksDescription": {
                    "p1": "Una VPN agrega una nueva interfaz de red al sistema que funciona como si usted estuviera conectando el dispositivo directamente a la red privada. También cambia su dirección IP y cifra todos los datos transmitidos. Una VPN permite recibir y transmitir información a través de redes públicas (a través de Internet, por ejemplo) como si su dispositivo estuviera conectado directamente a una red privada, aplicando las mismas políticas de seguridad y proporcionando acceso a todos los recursos de la red privada."
                },
                "whatDiferenceWithProxyTitle": "¿Cuál es la diferencia entre los servidores proxy y un servicio VPN?",
                "whatDiferenceWithProxyDescription": {
                    "p1": "Las conexiones VPN son mucho más estables y seguras, a diferencia del proxy. No todos los programas pueden trabajar con servidores proxy y muchos de ellos requieren una configuración adicional. Si está utilizando una VPN, no necesita configurar cada aplicación individualmente."
                },
                "whatCountriesTitle": "¿Qué países están disponibles?",
                "whatCountries": {
                    "first": "La lista actual de ubicaciones en varios países puede encontrarse",
                    "link": "aquí",
                    "second": "De forma que obtiene una dirección IP con la geolocalización de esos países cuando se conecta."
                },
                "logsTitle": "¿Guardan los archivos de registro?",
                "logs": {
                    "p1": "{productName} tiene una estricta política de no crear registros."
                }
            },
            "footerTitle": {
                "first": "Comience",
                "second": "a usar VPN99"
            },
            "footerDescription": "Descargue la app en su dispositivo"
        },
        "my_ip_address": "Mi dirección IP",
        "your_ip": "Esta es su dirección IP",
        "fail-payment_title": "¡Ups! Ocurrió un error.",
        "fail-payment_description": "Su suscripción no ha sido pagada. Quizás cometió un error al introducir los datos durante el pago. No se preocupe, puede intentarlo de nuevo.",
        "fail-payment_go-to-prices": "Vaya a planes tarifarios e inténtelo de nuevo",
        "success_congratulations-title": "¡Felicitaciones por su compra!",
        "success_congratulations-description": "Por favor, espere. Será redirigido a su cuenta personal, donde podrá administrar sus compras.",
        "success-payment_go-to-dashboard": "Inicie sesión ahora",
        "servers_title": "Acceso con un solo clic a más de 10 países",
        "servers_title_description": "Elija un servidor VPN en {continents_number}  continentes en uno de los {servers_number} países",
        "servers_locations_list_title": "Lista de ubicaciones de los servidores VPN",
        "servers_continent_all": "Todos los países",
        "allservers": "País",
        "continent_asia": "Asia",
        "continent_europe": "Europa",
        "continent_america": "América",
        "our_vpn_server": "Nuestros servidores VPN",
        "subscription_canceled_failure": "¡Ups! Ocurrió un error al cancelar. Por favor, póngase en contacto con el equipo de soporte.",
        "prices_trial-days": "+ {count} días de prueba",
        "days": "{count} días",
        "prices_trial": "Prueba",
        "pageForbidden": "Acceso prohibido",
        "pageForbiddenTitle": "Acceso prohibido",
        "pageForbiddenDescription": "Parece que el lugar al que intentó acceder está limitado.",
        "pageForbiddenAction": "Regrese al sitio web de {productName}",
        "private_finally": "Navegación en línea privada",
        "no_one_can_see": "Nadie puede ver lo que está haciendo",
        "no_restrictions_on_content_and_censorship": "Sin restricciones o censura en línea",
        "one_click_and_you_defend": "Un solo clic y estará protegido",
        "removes_everything": "{productName} elimina todo lo que le hace único y reconocible en línea, ocultando su dirección IP y cifrando sus datos para mantener su historial de navegación privado.",
        "enjoy_fast_and_stable_connection": "Disfrute de una conexión rápida y estable en cualquier lugar",
        "choose_your_server": "Elija su servidor entre {count} países y disfrute del servicio de VPN más rápido. Más servidores equivale a menos carga, y una amplia variedad de ubicaciones significa que es más probable que encuentre uno cercano",
        "easy_to_use_apps": "Aplicaciones fáciles de usar para todos sus dispositivos",
        "subscription_comes": "Una suscripción a {productName} para cada dispositivo que tenga: {appsList} y más",
        "money_back_guarantee": "Garantía de devolución del dinero",
        "faq_footer_description": "Puede encontrar otra información de su interés en nuestra",
        "faq_footer_knowledge-base": "Base de Conocimientos",
        "tryFree": "Pruébelo gratis",
        "year": "año",
        "icon_up": "Arriba",
        "status_failed": "Fallido",
        "status_refund": "Reembolso",
        "status_trial": "Prueba",
        "onHoldMessage": "Ocurrió un error y no hemos podido cobrar automáticamente la suscripción de {productName} para su dispositivo {platform}."
    },
    "css": "",
    "validator": {
        "is_empty": "El campo debe estar vacío",
        "is_not_empty": "Campo obligatorio",
        "is_email": "Compruebe que la entrada es correcta",
        "is_phone": "Introduzca el número correcto",
        "is_password": "La contraseña no puede contener {extraSymbols}",
        "is_password_has_enough_symbols": "La contraseña debe contener al menos 6 caracteres",
        "is_password_less_max_symbols": "La contraseña debe contener menos de 32 caracteres",
        "is_password_has_space": "La contraseña no puede contener un espacio en blanco",
        "is_not_empty_with_field_name": "El campo {fieldName} es obligatorio",
        "is_password_has_not_english_letters": "No se permiten letras no latinas"
    },
    "passwords-not-equal": "Las contraseñas no coinciden",
    "error": {
        "e500": {
            "new": {
                "text": "¡Ya sabemos de este problema y lo resolveremos pronto! Por favor, intente de nuevo más tarde.",
                "title": "500 — Error del Servidor"
            }
        },
        "eUnknown": {
            "new": {
                "text": "¡Ya sabemos de este problema y lo resolveremos pronto! Por favor, intente de nuevo más tarde.",
                "title": "500 — Error del Servidor"
            },
            "oops": {
                "text": "¡Ups! Ocurrió un error al cargar los datos del usuario. Intente cargando nuevamente la página."
            }
        },
        "e400": {
            "new": {
                "title": "Error"
            }
        }
    },
    "whitelabel": {
        "vpn99": {
            "web_be_safe": "Seguridad en línea al mejor precio",
            "web_online_security": "Desde sólo ${pricePerMonth} al mes"
        },
        "trustvpn": {
            "web_be_safe": "¡Proteja su privacidad ahora!",
            "web_online_security": "Velocidad sin precedentes, Proteja su Privacidad, Libertad Digital"
        }
    },
    "meta": {
        "descriptionDataRetentionPolicy": "Las situaciones y los períodos en que se conservarán sus datos. Todos sus datos se almacenan de forma segura de acuerdo con las directrices de la RGPD.",
        "descriptionMyIp": "Encuentre su Dirección IP, la dirección de su ordenador en línea. Vea cómo aparece su dirección IP",
        "descriptionPrivacyPolicy": "Nuestra Política de Privacidad incluye información sobre qué datos recopilamos y cómo los usamos, así como la forma en que usted puede controlar su información. Usamos cookies de acuerdo con la ley galesa, pero usted puede ajustar sus preferencias.",
        "descriptionTerms": "Al aceptar los Términos de Uso de {productName}, usted acepta los términos y condiciones, así como las responsabilidades de ambas partes descritas en el acuerdo. La compensación está disponible en ciertas circunstancias cuando los servicios de VPN no están disponibles o funcionan mal durante más de 12 horas consecutivas.",
        "descriptionAccountBilling": "Cuenta - Historial de pagos",
        "descriptionAccountPanel": "Cuenta - Panel de Control",
        "descriptionAccountPassword": "Cuenta - Cambiar contraseña",
        "descriptionDownload": "Descargar",
        "descriptionForgot": "Recuperar la contraseña",
        "descriptionReset": "Restablecer la contraseña",
        "descriptionHome": "Descargue e instale {productName} y obtendrá protección completa alrededor de todo el mundo. {productName}, altas velocidades y completo anonimato.",
        "descriptionPayFail": "Pago rechazado",
        "descriptionPaySuccess": "Pago realizado con éxito",
        "descriptionPrice": "Compre e instale {productName} y obtendrá protección completa alrededor de todo el mundo. Obtenga todos los beneficios de una VPN costosa. {productName}, altas velocidades y completo anonimato.",
        "descriptionServers": "Servidores",
        "titleDataRetentionPolicy": "Política de retención de datos de {productName}",
        "titleMyIp": "Encuentre su dirección IP en línea",
        "titlePrivacyPolicy": "La Política de Privacidad: las medidas que {productName} sigue para proteger su información privada",
        "titleTerms": "Términos de uso: los términos específicos que se aplican al aprovechar los servicios proporcionados por {productName}.",
        "titleAccountBilling": "Cuenta - Historial de pagos",
        "titleAccountPanel": "Cuenta - Panel de Control",
        "titleAccountPassword": "Cuenta - Cambiar contraseña",
        "titleDownload": "Descargar",
        "titleForgot": "Recuperar la contraseña",
        "titleReset": "Restablecer la contraseña",
        "titleHome": "Descargue {productName} hoy y obtendrá una cobertura confiable a un precio asequible.",
        "titleLogin": "Iniciar sesión",
        "titlePayFail": "Pago rechazado",
        "titlePaySuccess": "Pago realizado con éxito",
        "titlePrice": "Obtenga {productName} hoy y obtendrá una cobertura confiable a un precio asequible.",
        "titleRegister": "Regístrese",
        "titleServers": "Servidores",
        "titleApps": "Apps",
        "descriptionApps": "Apps",
        "descriptionLogin": "Iniciar sesión",
        "descriptionRegister": "Registrarse",
        "titleReturnRefundPolicy": "Política de Reembolso y Devoluciones",
        "descriptionReturnRefundPolicy": "Política de Reembolso y Devoluciones",
        "titlePolicyAndProcedure": "Aviso DMCA y Política y Procedimientos de Eliminación",
        "descriptionPolicyAndProcedure": "Aviso DMCA y Política y Procedimientos de Eliminación"
    },
    "getVPN": "Obtén {productName}",
    "signIn": "Acceder",
    "billings-page": {
        "waiting-processing": "Esperando una respuesta\ndel procesamiento del pago."
    },
    "servers": {
        "USDal": "Dallas, EE.UU."
    }
}
