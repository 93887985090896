export default class Country {
  constructor(rest) {
    this.rest = rest
  }

  fetchCountries() {
    return this.rest.get('/countries')
  }

  fetchServers() {
    return this.rest.get('/servers')
  }
}
