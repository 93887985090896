<template>
  <span
    v-if="!to"
    :style="
      `padding: ${paddingY} ${paddingX}; ` + !isColorExist
        ? `color: ${color}`
        : ''
    "
    class="link"
    :class="{
      link_nowrap: nowrap,
      link_underline: underline,
      link_bold: bold,
      'link-type_default': color === COLOR.DEFAULT,
      'link-type_black': color === COLOR.BLACK,
      'link-type_gray': color === COLOR.GRAY,
      'link-type_white': color === COLOR.WHITE,
      'link-type_strict-white': color === COLOR.STRICT_WHITE,
    }"
  >
    {{ label }}
  </span>
  <router-link
    v-else-if="!external"
    :style="
      `padding: ${paddingY} ${paddingX}; ` + !isColorExist
        ? `color: ${color}`
        : ''
    "
    :title="title || label"
    :target="external || blank ? '_blank' : '_self'"
    :to="to"
    class="link"
    :class="{
      link_nowrap: nowrap,
      link_underline: underline,
      link_bold: bold,
      'link-type_default': color === COLOR.DEFAULT,
      'link-type_black': color === COLOR.BLACK,
      'link-type_gray': color === COLOR.GRAY,
      'link-type_white': color === COLOR.WHITE,
      'link-type_strict-white': color === COLOR.STRICT_WHITE,
    }"
  >
    {{ label }}
  </router-link>
  <a
    v-else
    :title="title || label"
    :style="
      `padding: ${paddingY} ${paddingX}; ` + !isColorExist
        ? `color: ${color}`
        : ''
    "
    :target="external || blank ? '_blank' : '_self'"
    :href="to"
    class="link"
    :class="{
      link_nowrap: nowrap,
      link_underline: underline,
      link_bold: bold,
      'link-type_default': color === COLOR.DEFAULT,
      'link-type_black': color === COLOR.BLACK,
      'link-type_gray': color === COLOR.GRAY,
      'link-type_white': color === COLOR.WHITE,
      'link-type_strict-white': color === COLOR.STRICT_WHITE,
    }"
  >
    {{ label }}
  </a>
</template>

<script>
export default {
  name: 'Link',
  data() {
    return {
      COLOR: {
        DEFAULT: 'default',
        BLACK: 'black',
        GRAY: 'gray',
        WHITE: 'white',
        STRICT_WHITE: 'strict-white',
      },
    }
  },
  computed: {
    isColorExist: {
      get() {
        return Object.values(this.COLOR).includes(this.color)
      },
    },
  },
  props: {
    to: {
      required: true,
      type: [String, Object],
    },
    blank: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: 'link',
    },
    title: {
      type: [String, null],
      default: null,
    },
    paddingY: {
      type: [String, Number],
      default: 0,
    },
    paddingX: {
      type: [String, Number],
      default: 0,
    },
    color: {
      type: String,
      default: 'default',
    },
    nowrap: {
      type: Boolean,
      default: false,
    },
    underline: {
      type: Boolean,
      default: false,
    },
    bold: {
      type: Boolean,
      default: false,
    },
    external: {
      type: [Boolean, undefined],
      default: false,
    },
  },
  methods: {
    onClick() {
      this.$emit('onClick')
    },
  },
}
</script>

<style scoped></style>
