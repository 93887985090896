import ROUTE_NAME from './names'
import i18n from '@/i18n'
const $t = (val, params = {}) => i18n.global.t(val, params)

export default {
  [ROUTE_NAME.HOME]: {
    accessLevel: 'Any',
    title: {
      t: $t('meta.titleHome', {
        productName: process.env.VUE_APP__PRODUCT_NAME,
      }),
    },
    description: {
      t: $t('meta.descriptionHome', {
        productName: process.env.VUE_APP__PRODUCT_NAME,
      }),
    },
  },
  [ROUTE_NAME.PRICES]: {
    accessLevel: 'Any',
    title: {
      t: $t('meta.titlePrice', {
        productName: process.env.VUE_APP__PRODUCT_NAME,
      }),
    },
    description: {
      t: $t('meta.descriptionPrice', {
        productName: process.env.VUE_APP__PRODUCT_NAME,
      }),
    },
  },
  [ROUTE_NAME.PRICES_PROMO]: {
    accessLevel: 'Any',
    title: {
      t: $t('meta.titlePrice', {
        productName: process.env.VUE_APP__PRODUCT_NAME,
      }),
    },
    description: {
      t: $t('meta.descriptionPrice', {
        productName: process.env.VUE_APP__PRODUCT_NAME,
      }),
    },
  },
  [ROUTE_NAME.PRICES_CHARITABLE]: {
    accessLevel: 'Any',
    title: {
      t: $t('meta.titlePrice', {
        productName: process.env.VUE_APP__PRODUCT_NAME,
      }),
    },
    description: {
      t: $t('meta.descriptionPrice', {
        productName: process.env.VUE_APP__PRODUCT_NAME,
      }),
    },
  },
  [ROUTE_NAME.APPS]: {
    accessLevel: 'Any',
    title: {
      t: $t('meta.titleApps', {
        productName: process.env.VUE_APP__PRODUCT_NAME,
      }),
    },
    description: {
      t: $t('meta.descriptionApps', {
        productName: process.env.VUE_APP__PRODUCT_NAME,
      }),
    },
  },
  [ROUTE_NAME.SERVERS]: {
    accessLevel: 'Any',
    title: {
      t: $t('meta.titleServers', {
        productName: process.env.VUE_APP__PRODUCT_NAME,
      }),
    },
    description: {
      t: $t('meta.descriptionServers', {
        productName: process.env.VUE_APP__PRODUCT_NAME,
      }),
    },
  },
  [ROUTE_NAME.AUTH.LOGIN]: {
    accessLevel: 'Guest',
    title: {
      t: $t('meta.titleLogin', {
        productName: process.env.VUE_APP__PRODUCT_NAME,
      }),
    },
    description: {
      t: $t('meta.descriptionLogin', {
        productName: process.env.VUE_APP__PRODUCT_NAME,
      }),
    },
  },
  [ROUTE_NAME.AUTH.REGISTER]: {
    accessLevel: 'Guest',
    title: {
      t: $t('meta.titleRegister', {
        productName: process.env.VUE_APP__PRODUCT_NAME,
      }),
    },
    description: {
      t: $t('meta.descriptionRegister', {
        productName: process.env.VUE_APP__PRODUCT_NAME,
      }),
    },
  },
  [ROUTE_NAME.AUTH.FORGOT]: {
    accessLevel: 'Guest',
    title: {
      t: $t('meta.titleForgot', {
        productName: process.env.VUE_APP__PRODUCT_NAME,
      }),
    },
    description: {
      t: $t('meta.descriptionForgot', {
        productName: process.env.VUE_APP__PRODUCT_NAME,
      }),
    },
  },
  [ROUTE_NAME.ACCOUNT.PANEL]: {
    accessLevel: 'Authorized',
    title: {
      t: $t('meta.titleAccountPanel', {
        productName: process.env.VUE_APP__PRODUCT_NAME,
      }),
    },
    description: {
      t: $t('meta.descriptionAccountPanel', {
        productName: process.env.VUE_APP__PRODUCT_NAME,
      }),
    },
  },
  [ROUTE_NAME.MAGIC_LINK]: {
    accessLevel: 'Authorized',
    title: {
      t: $t('meta.magicLink', {
        productName: process.env.VUE_APP__PRODUCT_NAME,
      }),
    },
    description: {
      t: $t('meta.descriptionMagicLink', {
        productName: process.env.VUE_APP__PRODUCT_NAME,
      }),
    },
  },
  [ROUTE_NAME.ACCOUNT.DOWNLOADS]: {
    accessLevel: 'Authorized',
    title: {
      t: $t('meta.titleDownload', {
        productName: process.env.VUE_APP__PRODUCT_NAME,
      }),
    },
    description: {
      t: $t('meta.descriptionDownload', {
        productName: process.env.VUE_APP__PRODUCT_NAME,
      }),
    },
  },
  [ROUTE_NAME.ACCOUNT.BILLING]: {
    accessLevel: 'Authorized',
    title: {
      t: $t('meta.titleAccountBilling', {
        productName: process.env.VUE_APP__PRODUCT_NAME,
      }),
    },
    description: {
      t: $t('meta.descriptionAccountBilling', {
        productName: process.env.VUE_APP__PRODUCT_NAME,
      }),
    },
  },
  [ROUTE_NAME.ACCOUNT.CHANGE_PASSWORD]: {
    accessLevel: 'Authorized',
    title: {
      t: $t('meta.titleAccountPassword', {
        productName: process.env.VUE_APP__PRODUCT_NAME,
      }),
    },
    description: {
      t: $t('meta.descriptionAccountPassword', {
        productName: process.env.VUE_APP__PRODUCT_NAME,
      }),
    },
  },
  [ROUTE_NAME.PASSWORD.RESET]: {
    accessLevel: 'Guest',
    title: {
      t: $t('meta.titleReset', {
        productName: process.env.VUE_APP__PRODUCT_NAME,
      }),
    },
    description: {
      t: $t('meta.descriptionReset', {
        productName: process.env.VUE_APP__PRODUCT_NAME,
      }),
    },
  },
  [ROUTE_NAME.TERMS]: {
    accessLevel: 'Any',
    title: {
      t: $t('meta.titleTerms', {
        productName: process.env.VUE_APP__PRODUCT_NAME,
      }),
    },
    description: {
      t: $t('meta.descriptionTerms', {
        productName: process.env.VUE_APP__PRODUCT_NAME,
      }),
    },
  },
  [ROUTE_NAME.MY_IP]: {
    accessLevel: 'Any',
    title: {
      t: $t('meta.titleMyIp', {
        productName: process.env.VUE_APP__PRODUCT_NAME,
      }),
    },
    description: {
      t: $t('meta.descriptionMyIp', {
        productName: process.env.VUE_APP__PRODUCT_NAME,
      }),
    },
  },
  [ROUTE_NAME.DATA_RETENTION_POLICY]: {
    accessLevel: 'Any',
    title: {
      t: $t('meta.titleDataRetentionPolicy', {
        productName: process.env.VUE_APP__PRODUCT_NAME,
      }),
    },
    description: {
      t: $t('meta.descriptionDataRetentionPolicy', {
        productName: process.env.VUE_APP__PRODUCT_NAME,
      }),
    },
  },
  [ROUTE_NAME.RETURN_REFUND_POLICY]: {
    accessLevel: 'Any',
    title: {
      t: $t('meta.titleReturnRefundPolicy', {
        productName: process.env.VUE_APP__PRODUCT_NAME,
      }),
    },
    description: {
      t: $t('meta.descriptionReturnRefundPolicy', {
        productName: process.env.VUE_APP__PRODUCT_NAME,
      }),
    },
  },
  [ROUTE_NAME.POLICY_AND_PROCEDURE]: {
    accessLevel: 'Any',
    title: {
      t: $t('meta.titlePolicyAndProcedure', {
        productName: process.env.VUE_APP__PRODUCT_NAME,
      }),
    },
    description: {
      t: $t('meta.descriptionPolicyAndProcedure', {
        productName: process.env.VUE_APP__PRODUCT_NAME,
      }),
    },
  },
  [ROUTE_NAME.PRIVACY_POLICY]: {
    accessLevel: 'Any',
    title: {
      t: $t('meta.titlePrivacyPolicy', {
        productName: process.env.VUE_APP__PRODUCT_NAME,
      }),
    },
    description: {
      t: $t('meta.descriptionPrivacyPolicy', {
        productName: process.env.VUE_APP__PRODUCT_NAME,
      }),
    },
  },
  [ROUTE_NAME.EXTENSION_PRIVACY_POLICY]: {
    accessLevel: 'Any',
    title: {
      t: $t('meta.titlePrivacyPolicy', {
        productName: process.env.VUE_APP__PRODUCT_NAME,
      }),
    },
    description: {
      t: $t('meta.descriptionPrivacyPolicy', {
        productName: process.env.VUE_APP__PRODUCT_NAME,
      }),
    },
  },
  [ROUTE_NAME.PAYMENT_SUCCESS]: {
    accessLevel: 'Any',
    title: {
      t: $t('meta.titlePaySuccess', {
        productName: process.env.VUE_APP__PRODUCT_NAME,
      }),
    },
    description: {
      t: $t('meta.descriptionPaySuccess', {
        productName: process.env.VUE_APP__PRODUCT_NAME,
      }),
    },
  },
  [ROUTE_NAME.PAYMENT_FAILURE]: {
    accessLevel: 'Any',
    title: {
      t: $t('meta.titlePayFail', {
        productName: process.env.VUE_APP__PRODUCT_NAME,
      }),
    },
    description: {
      t: $t('meta.descriptionPayFail', {
        productName: process.env.VUE_APP__PRODUCT_NAME,
      }),
    },
  },
  [ROUTE_NAME.LOGIN_BY_TOKEN]: undefined,
  [ROUTE_NAME.ERROR.NOT_FOUND]: {
    accessLevel: 'Any',
    title: {
      t: $t('web.pageNotFoundTitle', {
        productName: process.env.VUE_APP__PRODUCT_NAME,
      }),
    },
    description: {
      t: $t('web.pageNotFoundTitle', {
        productName: process.env.VUE_APP__PRODUCT_NAME,
      }),
    },
  },
  [ROUTE_NAME.ERROR.FORBIDDEN]: {
    accessLevel: 'Any',
    title: {
      t: $t('web.pageForbidden', {
        productName: process.env.VUE_APP__PRODUCT_NAME,
      }),
    },
    description: {
      t: $t('web.pageForbidden', {
        productName: process.env.VUE_APP__PRODUCT_NAME,
      }),
    },
  },
  [ROUTE_NAME.TEST.ONBOARDING.CHAMELEON]: {
    accessLevel: 'Any',
    fullScreen: true,
    hideFeedBack: true,
    title: { t: 'Chameleon' },
    description: { t: 'Chameleon' },
  },
  [ROUTE_NAME.DEV.DATA.RESET]: undefined,
  [ROUTE_NAME.DEV.MODE.ENABLE]: undefined,
  [ROUTE_NAME.DEV.MODE.DISABLE]: undefined,
}
