{
    "web": {
        "why_use": "Зачем использовать {productName}",
        "download_VPN": "Загрузите {productName} и начните пользоваться уже сегодня!",
        "getVPN": "Купить {productName}",
        "access": "Доступ к любому контенту из любой точки мира",
        "jurisdiction": "Юрисдикция ЕС гарантирует конфиденциальность",
        "anonymity": " Полная анонимность  и отсутствие логов",
        "simple_conf": "Простая настройка и использование",
        "secure": " Безопасные платежи и защита от хакеров",
        "protection": "Защита от назойливой рекламы",
        "get_VPN_app_phone": "Получить приложение {productName} для вашего устройства",
        "keep_safe": "Сохраняйте безопасность и конфиденциальность в Интернете.",
        "different_device": "Получить {productName} на другое устройство",
        "authorize": "Вход",
        "dont_have_an_account": "Ещё нет аккаунта? ",
        "register": "Зарегистрироваться",
        "pass": "Пароль",
        "sign_in": "Войти",
        "forgot_pass": "Забыли пароль?",
        "email": "Почта",
        "instructions_for_macos": "Инструкция для MacOS",
        "instructions_for_windows": "Инструкция для Windows",
        "current-plan": "Ваш План",
        "date": "Дата",
        "price": "Стоимость",
        "price_from": "Следующая стоимость",
        "next_due": "Следующий платеж",
        "payment": "Способ оплаты",
        "cancel": "Отмена",
        "go_to_settings": "Перейти в настройки",
        "ok": "Ок",
        "dialog_cancel-subscription_title-AppStore": "Вы можете отменить подписку в Apple Store.",
        "dialog_cancel-subscription_title-PlayMarket": "Вы можете отменить подписку в Play Market.",
        "dialog_cancel-subscription_title-sure": "Вы уверены, что хотите отменить подписку?",
        "yes": "Да",
        "no": "Нет",
        "subscription_canceled_successfully": "Подписка успешно отменена",
        "dashboard_dialog_thanks-for-registration_title": "Спасибо за регистрацию!",
        "dashboard_dialog_thanks-for-registration_description_1": "Пожалуйста, запомните ваш пароль для входа в личный кабинет",
        "Password": "Пароль",
        "dashboard_dialog_thanks-for-registration_description_2-1": "Или",
        "dashboard_dialog_thanks-for-registration_description_2-2": "нажмите сюда,",
        "dashboard_dialog_thanks-for-registration_description_2-3": "чтобы изменить пароль",
        "prices_date-of-next-payment": "Дата следующего списания",
        "change_password": "Изменить пароль",
        "current_password": "Текущий пароль",
        "new_password": "Новый пароль",
        "confirm-password": "Подтвердите пароль",
        "reset": "Сброс",
        "email_address_associated": "Введите адрес электронной почты, связанный с вашей учетной записью. Мы вышлем вам ссылку для сброса пароля.",
        "get-link": "Отправить письмо",
        "sign-in": "Войти",
        "forget-password_dialog_email-link_title": "На указанный вами email адрес было отправлено письмо со ссылкой на восстановление пароля.",
        "i-have-promocode": "У меня есть промокод",
        "promo": "Промокод",
        "have_an_account": "У меня есть аккаунт",
        "reset-password": "Сбросить пароль",
        "state": {
            "register": "Зарегистрироваться"
        },
        "action": {
            "register": "Зарегистрироваться",
            "cancel": "Отменить"
        },
        "notify_password-changed": "Пароль изменен",
        "prices_discount": "Скидка",
        "prices_email": "Почта",
        "prices_country": "Ваша страна",
        "prices_pay": "Оплатить",
        "prices_privacy_p-0": "Оформляя заказ вы соглашаетесь с нашими",
        "prices_privacy_p-1": "Условиями использования",
        "prices_privacy_p-2": "и",
        "prices_privacy_p-3": "Политикой конфиденциальности",
        "price_card": "Карта",
        "price_internet-wallet": "Интернет кошелёк",
        "price_cryptocurrency": "Криптовалюта",
        "prices_order-total": "Итого",
        "i-dont-have-promocode": "У меня нет промокода",
        "dashboard_dialog_thanks-for-registration_description_1_promo": "Введенный промокод не был применен",
        "prices_user-has-account_how-to-change-plan_1": "Для смены тарифного плана отмените текущую подписку на странице",
        "prices_user-has-account_how-to-change-plan_2": "История платежей",
        "prices_user-has-account_how-to-change-plan_3": ", затем оформите новую",
        "instructions_for_linux": "Инструкция для Linux",
        "prices_Step": "Шаг",
        "tariff-name_Year": "Год",
        "tariff-name_Month": "Месяц",
        "tariff-name_6-months": "6 месяцев",
        "tariff-name_half-year": "Полгода",
        "period-for-free": "{period} бесплатно",
        "then": "затем",
        "month": "месяц",
        "prices_save": "Скидка",
        "prices_billedEvery": "спишется каждый",
        "prices_the-most-popular": "Наиболее популярен",
        "prices_Billed": "Стоимость",
        "prices_every": "{1} каждый|[2,*] каждые",
        "icon_check-circle-empty": "Пустой круг",
        "icon_check-circle": "Флажок",
        "icon_like": "Лайк",
        "icon_info": "Инфо",
        "icon_down": "Вниз",
        "icon_check": "Флажок",
        "prices_the-1-year-is-better": "1-летний план наиболее выгоден",
        "prices_choose-plan": "Выберите план",
        "prices_Chose-Payment-Method": "Выберите способ оплаты",
        "prices_Why-choose-VPN": "Почему стоит выбрать {productName}?",
        "prices_VPN-includes": "{productName} включает",
        "prices_includes-support": "24/7 клиентская поддержка",
        "prices_includes-money-back": "{numberOfDays}-дневная гарантия возврата",
        "prices_includes-security": "Безопасность и шифрование",
        "prices_includes-no-logs": "Нет логов просмотров и подключений",
        "instruction": "Инструкции",
        "apps": "Приложения",
        "my-ip": "Мой IP-адрес",
        "terms": "Условия использования",
        "return-refund-policy": "Политика возврата",
        "policy_and_procedure": "DMCA Уведомления и правила и процедуры удаления",
        "privacy_policy": "Политика конфиденциальности",
        "footer_support": "Поддержка",
        "footer_sales-department": "Отдел продаж",
        "footer_company": "Компания",
        "footer_contacts": "Контакты",
        "data-retention-policy": "Политика хранения данных",
        "pricing": "Цены",
        "servers": "Серверы",
        "knowledge-base": "База Знаний",
        "dashboard": "Главная",
        "to_home_page": "К домашней странице",
        "icon_logo": "Лого",
        "icon_login": "Войти",
        "icon_menu": "Меню",
        "icon_key": "Ключ",
        "icon_logout": "Выйти",
        "log_out": "Выйти",
        "icon_lifebuoy": "Спасательный круг",
        "knowledge_base": "База Знаний",
        "icon_support": "Поддержка",
        "downloads": "Загрузки",
        "billings": "Платежи",
        "icon_eyeOpen": "Показать пароль",
        "inactive": "Не активен. Оплатите, чтобы начать пользоваться",
        "icon_flag": "Флаг",
        "active_until": "Активно до",
        "servers_cta_title": "Начать безопасный просмотр уже сегодня",
        "servers_cta_description": "Сохраняйте безопасность и конфиденциальность в Интернете",
        "downloads_instruction": "Инструкция",
        "downloads_router": "Роутер",
        "downloads_openVPN_profiles": "OpenVPN профили",
        "tariff-name-long_Year": "Один год",
        "tariff-name-long_Month": "Один месяц",
        "dialog_cancel-subscription_header": "Отмена подписки",
        "web_payment_history": "История платежей",
        "tariff": "Тариф",
        "payment_method": "Способ оплаты",
        "status": "Статус",
        "sum": "Сумма",
        "tbody_is_empty_1": "Тут будет храниться информация о ваших платежах",
        "tbody_is_empty_2": "Оформить подписку",
        "status_paid": "Оплачено",
        "status_not-paid": "Не оплачено",
        "my_services": "Мои услуги",
        "private_and_secure": "Частный и безопасный доступ в Интернет (VPN)",
        "active": "Активно",
        "downloads_apps_and_files": "Приложения и файлы",
        "downloads_for_mobile": "Для смартфонов",
        "downloads_for_desktop": "Для компьютеров",
        "downloads_for_browsers": "Для браузеров",
        "downloads_other_devices": "Другие устройства",
        "forget-password_dialog_email-link_header": "Проверьте вашу почту",
        "pageNotFoundTitle": "Страница не найдена",
        "pageNotFoundDescription": "Похоже такой страницы не существует. Пожалуйста, возвращайтесь к нам.",
        "pageNotFoundAction": "Вернуться в {productName}",
        "home": {
            "mainTitle": {
                "highlited": "ВАША\nцифровая",
                "rest": "защита"
            },
            "mainDescription": {
                "first": "всех персональных",
                "second": "данных и компьютера от кибермошенников"
            },
            "mainButton": "от $2.50 в месяц*",
            "mainButtonDescription": "*все покупки с гарантией возврата",
            "secureTitle": {
                "first": "Действительно",
                "second": "безопасный интернет"
            },
            "secureDescription": "VPN99 убирает все, что делает вас уникальным и узнаваемым в интернете. Cкрывает IP-адрес, шифрует данные, чтобы сохранить вашу историю посещений в тайне.",
            "secureCard": {
                "noRestrictionsTitle": "Никаких запретов!",
                "noRestrictionsDescription": "Все сайты интернета вам доступны, даже заблокированные. А ещё все данные надёжно защищены от мошенников.",
                "anonimTitle": "Вы теперь - аноним!",
                "anonimDescription": "Анонимно буквально каждое подключение! Мы не храним логов, так что даже сами не знаем, что вы делаете в интернете ;)"
            },
            "devicesTitle": {
                "first": "Одна подписка",
                "second": "для всех устройств!"
            },
            "devicesDescription": "MacOS, Windows, Android, iOS, расширения для Chrome и Firefox и многое другое",
            "serversTitle": {
                "first": "Быстрое и стабильное",
                "second": "соединение в любом месте!"
            },
            "serversDescription": "Выберите один из 17 серверов или доверьтесь рекомендованному серверу - он ближайший, а потому самый быстрый. Вы сможете беспрепятственно смотреть фильмы и сериалы в HD качестве.",
            "faqTitle": "Часто задаваемые вопросы",
            "faq": {
                "whatIsVPNTitle": "Что такое VPN?",
                "whatIsVPNDescription": {
                    "p1": "VPN (Virtual Private Network) переводится как \"виртуальная частная сеть\".",
                    "p2": "Эта технология работает поверх общедоступного Интернета, поэтому подключиться к ней можно откуда угодно. Все передаваемые данные шифруются, что повышает вашу безопасность пребывания в сети."
                },
                "rollUp": "Свернуть",
                "more": "Читать",
                "howWorksTitle": "Как работает VPN?",
                "howWorksDescription": {
                    "p1": "VPN добавляет новый сетевой интерфейс в систему, который функционирует так, как будто вы подключили свое устройство к частной сети напрямую. Также он меняет ваш IP-адрес и шифрует все передаваемые данные. VPN обеспечивает приём и передачу информации через публичные сети (через сеть Internet, например) так же, как если бы ваше устройство было подключено к частной сети, непосредственно, применяя те же политики безопасности и обеспечивая доступ ко всем ресурсам частной сети."
                },
                "whatDiferenceWithProxyTitle": "В чем отличие прокси-серверов от VPN?",
                "whatDiferenceWithProxyDescription": {
                    "p1": "VPN-соединения гораздо более стабильны и зашифрованы, в отличие от прокси. Не все программы способны работать с прокси-серверами, и многие из них требуют дополнительной конфигурации. Если вы используете VPN, то вам нет нужды настраивать каждое приложение по отдельности."
                },
                "whatCountriesTitle": "Какие страны доступны для подключения?",
                "whatCountries": {
                    "first": "С актуальным списком локаций в различных странах можно ознакомиться",
                    "link": "здесь",
                    "second": "Соответственно, вы получаете IP-адрес с геолокацией этих стран при подключении."
                },
                "logsTitle": "Где и как вы храните логи?",
                "logs": {
                    "p1": "Логи мы не храним. Единственная информация, которая сохраняется на наших серверах это - IP-адрес, выданный клиенту."
                }
            },
            "footerTitle": {
                "first": "Начните",
                "second": "использовать VPN99"
            },
            "footerDescription": "Загрузите приложение для своего устройства"
        },
        "my_ip_address": "Мой IP-адрес",
        "your_ip": "Это ваш IP-адрес",
        "fail-payment_title": "Что-то пошло не так",
        "fail-payment_description": "Ваш тариф не оплачен. Возможно, вы ошиблись при вводе данных во время оплаты. Не страшно, можно попробовать снова.",
        "fail-payment_go-to-prices": "Выбрать план и попробовать еще раз",
        "success_congratulations-title": "Спасибо за вашу покупку!",
        "success_congratulations-description": "Пожалуйста, подождите. Сейчас мы вас перенаправим в ваш личный кабинет, где вы сможете управлять покупками.",
        "success-payment_go-to-dashboard": "Войти сейчас",
        "servers_title": "Доступ одним щелчком к 10+ странам",
        "servers_title_description": "Выбирай VPN-сервер на {continents_number} континентах и в одной из {servers_number} стран",
        "servers_locations_list_title": "Cписок локаций VPN серверов",
        "servers_continent_all": "Все страны",
        "allservers": "Страна",
        "continent_asia": "Азия",
        "continent_europe": "Европа",
        "continent_america": "Америка",
        "our_vpn_server": " Наши сервера",
        "subscription_canceled_failure": "При отмене подписки произошла ошибка. Пожалуйста, обратитесь в Службу поддержки.",
        "prices_trial-days": "+ {count} дня триал",
        "days": "{count} дня",
        "prices_trial": "Триал",
        "pageForbidden": "Доступ ограничен",
        "pageForbiddenTitle": "Доступ ограничен",
        "pageForbiddenDescription": "Похоже вы пыпались попасть на страницу с ограниченным доступом. Пожалуйста, возвращайтесь на сайт.",
        "pageForbiddenAction": "Вернуться в {productName}",
        "private_finally": "Приватный интернет сёрфинг",
        "no_one_can_see": "Никто не может видеть, что ты делаешь.",
        "no_restrictions_on_content_and_censorship": "Никакой цензуры и ограничений в сети",
        "one_click_and_you_defend": "Один клик и вы защищены",
        "removes_everything": "{productName} убирает все, что делает вас уникальным и узнаваемым в интернете. Cкрывает IP-адрес, шифрует данные, чтобы сохранить вашу историю посещений в тайне.",
        "enjoy_fast_and_stable_connection": "Наслаждайтесь быстрым и стабильным соединением в любом месте",
        "choose_your_server": "Выберите свой сервер в {count} странах и наслаждайтесь самым быстрым VPN сервисом. Наличие большего количества серверов означает меньшую нагрузку, а большое разнообразие местоположений означает, что у вас больше шансов найти один поблизости",
        "easy_to_use_apps": "Простые в использовании приложения для всех ваших устройств",
        "subscription_comes": "Одна подписка {productName} для всех ваших устройств: {appsList} и другие",
        "money_back_guarantee": "Гарантия возврата",
        "faq_footer_description": "Другую, интересующую вас информацию, вы найдете в нашей",
        "faq_footer_knowledge-base": "Базе знаний",
        "tryFree": "Попробовать бесплатно",
        "year": "год",
        "icon_up": "Вверх",
        "status_failed": "Ошибка",
        "status_refund": "Возврат",
        "status_trial": "Триал",
        "onHoldMessage": "Что-то пошло не так, и мы не смогли провести автоматическое списание средств за тариф от {productName} на вашем {platform} девайсе.",
        "period_mini_month": "мес",
        "period_mini_year": "год"
    },
    "css": "",
    "validator": {
        "is_empty": "Поле должно быть пустым",
        "is_not_empty": "Поле не должно быть пустым",
        "is_email": "Проверьте правильность ввода",
        "is_phone": "Введите корректный номер",
        "is_password": "Пароль не может содержать {extraSymbols}",
        "is_password_has_enough_symbols": "Пароль должен содержать от 6 символов",
        "is_password_less_max_symbols": "Пароль должен содержать до 32 символов",
        "is_password_has_space": "Пароль не должен содержать пробел",
        "is_not_empty_with_field_name": "Обязательное поле",
        "is_password_has_not_english_letters": "Не латинские буквы запрещены"
    },
    "passwords-not-equal": "Пароли не совпадают",
    "error": {
        "e500": {
            "new": {
                "text": "Мы уже знаем об этой проблеме и скоро ее решим! Пожалуйста, оставайтесь с нами и попробуйте еще раз позже.",
                "title": "500 — Ошибка сервера"
            }
        },
        "eUnknown": {
            "new": {
                "text": "Мы уже значем об этой проблеме и скоро ее решим! Пожалуйста, оставайтесь с нами и попробуйте еще раз позже.",
                "title": "500 — Ошибка сервера"
            },
            "oops": {
                "text": "Упс! При загрузке данных пользователя произошла ошибка. Пожалуйста, попробуйте перезагрузить страницу."
            }
        },
        "e400": {
            "new": {
                "title": "Ошибка"
            }
        }
    },
    "whitelabel": {
        "vpn99": {
            "web_be_safe": "Безопасность в сети по лучшей цене",
            "web_online_security": "Всего от ${pricePerMonth} в месяц"
        },
        "trustvpn": {
            "web_be_safe": "Защитите свою конфиденциальность в онлайне прямо сейчас!",
            "web_online_security": "Сверхбыстрая скорость, Защита конфиденциальности, Цифровая свобода"
        }
    },
    "meta": {
        "descriptionDataRetentionPolicy": "Как, для чего и как долго мы храним ваши данные. Все данные хранятся безопасно согласно регламенту GDPR.",
        "descriptionMyIp": "Узнайте свой IP адрес - адрес вашего компьютера в Интернете. Определить мой внешний айпи адрес",
        "descriptionPrivacyPolicy": "Политика конфиденциальности: какие ваши данные мы собираем и как их используем, как вы сами можете контролировать ваши данные. Файлы cookie используются в соответствии с Законом Уэльса, но вы сами можете ими управлять.",
        "descriptionTerms": "Предмет соглашения {productName} и условия использования VPN-сервиса, определения основных терминов, ответсвенность сторон. Условия предоставления компенсации в случае некорректной работы VPN-сервиса более, чем на 12 часов.",
        "descriptionAccountBilling": "Учётная запись - История платежей",
        "descriptionAccountPanel": "Учётная запись - Главная",
        "descriptionAccountPassword": "Учётная запись - Изменить пароль",
        "descriptionDownload": "Загрузки",
        "descriptionForgot": "Восстановление пароля",
        "descriptionReset": "Сбросить пароль",
        "descriptionHome": "Загрузите и установите {productName}, и вы получите полную защиту по всему миру. {productName}, высокую скорость и полную анонимность.",
        "descriptionPayFail": "Платёж отклонён",
        "descriptionPaySuccess": "Платёж прошёл успешно",
        "descriptionPrice": "Загрузите и установите {productName}, и вы получите полную защиту по всему миру. {productName}, высокую скорость и полную анонимность.",
        "descriptionServers": "Серверы",
        "titleDataRetentionPolicy": "Политика хранения данных {productName}",
        "titleMyIp": "Узнать мой IP адрес в Интернете",
        "titlePrivacyPolicy": "Политика конфиденциальности — как мы храним ваши данные на {productName}",
        "titleTerms": "Условия использования — определения основных терминов, {productName}",
        "titleAccountBilling": "Учётная запись - История платежей",
        "titleAccountPanel": "Учётная запись - Главная",
        "titleAccountPassword": "Учётная запись - Изменить пароль",
        "titleDownload": "Загрузки",
        "titleForgot": "Восстановление пароля",
        "titleReset": "Сбросить пароль",
        "titleHome": "Скачать недорогой и надежный VPN сервис {productName} - всего за ${price_per_month} в месяц",
        "titleLogin": "Авторизация",
        "titlePayFail": "Платёж отклонён",
        "titlePaySuccess": "Платёж прошёл успешно",
        "titlePrice": "Получите {productName} сегодня, и вы получите надежное покрытие по доступной цене",
        "titleRegister": "Регистрация",
        "titleServers": "Серверы",
        "titleApps": "Приложения",
        "descriptionApps": "Приложения",
        "descriptionLogin": "Вход",
        "descriptionRegister": "Регистрация",
        "titleReturnRefundPolicy": "Политика возврата",
        "descriptionReturnRefundPolicy": "Политика возврата",
        "titlePolicyAndProcedure": "Aviso de DMCA e Política de cancelamento & Procedimentos",
        "descriptionPolicyAndProcedure": "Aviso de DMCA e Política de cancelamento & Procedimentos"
    },
    "getVPN": "Купить {productName}",
    "signIn": "Войти",
    "billings-page": {
        "waiting-processing": "Ожидание ответа от\nпроцессинга платежей."
    },
    "servers": {
        "USDal": "США Даллас"
    }
}
