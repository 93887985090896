<template>
  <div v-if="opened" class="multi-language__list clickOutside_qdsqw24">
    <button
      v-for="item in items"
      :key="item.key"
      @submit="choseItem(item)"
      @click="choseItem(item)"
      class="dropdown__item"
    >
      <icon
        class="icon multi-language__icon"
        :name="$t('web.icon_flag')"
        width="20"
        height="20"
      >
        <component :is="item.component" />
      </icon>
      <span class="text">{{ item.label }}</span>
    </button>
  </div>
</template>

<script>
import Icon from '@/components/Icon'
export default {
  name: 'Dropdown',
  components: {
    Icon,
  },
  props: {
    opened: {
      default: false,
      type: Boolean,
    },
    items: {
      default: () => [],
      type: Array,
    },
  },
  methods: {
    choseItem(item) {
      this.$emit('choseItem', item)
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/global/variables';
@import '@/assets/scss/components/dropdown';

.multi-language {
  position: relative;

  &__list {
    position: absolute;
    top: 4.25rem;
    width: 13.5rem;
    z-index: 200;
    background-color: #fff;
    color: #5a5a5a;
    border: 1px solid #e1e1e1;
    border-radius: 0 0 0.25rem 0.25rem;
    overflow: hidden;
    box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.2);
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    body[dir='rtl'] & {
      right: -1rem;
    }
    body[dir='ltr'] & {
      left: -1rem;
    }
  }
  &__item {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    color: #3e3e3e;
    width: 100%;
    padding: 0.5rem 1.5rem;
    cursor: pointer;
  }

  &__item:hover {
    background-color: var(--main-bright-transparent);
  }

  &__item .text {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  &__icon {
    flex-shrink: 0;
    background-color: #a1a1a1;
    border-radius: 100%;
    overflow: hidden;
    body[dir='rtl'] & {
      margin-left: 0.75rem;
    }
    body[dir='ltr'] & {
      margin-right: 0.75rem;
    }
  }

  @media (min-width: 767.99px) and (max-width: 1023.98px) {
    &__list {
      top: $header-height-small;
      overflow: auto;
      max-height: calc(100vh - #{$header-height-small});
      body[dir='rtl'] & {
        left: -1rem;
        right: auto;
      }
      body[dir='ltr'] & {
        right: -1rem;
        left: auto;
      }
    }
  }
  @media (max-width: 767.98px) {
    &__list {
      top: $header-height-small;
      position: fixed;
      overflow: auto;
      max-height: calc(100vh - #{$header-height-small});
      width: 100%;
      body[dir] & {
        left: 0;
        right: 0;
      }
    }
  }
}
</style>
