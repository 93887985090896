{
    "web": {
        "why_use": "Warum Sie {productName} nutzen sollten",
        "download_VPN": "Laden Sie {productName} herunter und nutzen Sie es noch heute!",
        "getVPN": "Holen Sie sich die {productName}",
        "access": "Zugriff auf alle Inhalte von überall auf der Welt",
        "jurisdiction": "Die Gerichtsbarkeit der EU garantiert Ihnen Diskretion",
        "anonymity": "Verbindert IP-basiertes Tracking für Werbeservices",
        "simple_conf": "Einfache Installation und Bedienung",
        "secure": " Sichere Zahlungen  und Schutz vor Hackern",
        "protection": "Schutz gegen aufdringliche Werbung",
        "get_VPN_app_phone": "Holen Sie sich die {productName}-App für Ihr Gerät",
        "keep_safe": "Sicher und privat online bleiben",
        "different_device": "Holen Sie {productName} für ein anderes Gerät",
        "authorize": "Autorisierungen",
        "dont_have_an_account": "Sie haben noch kein Konto?",
        "register": "Registrieren",
        "pass": "Kennwort",
        "sign_in": "Anmelden",
        "forgot_pass": "Kennwort vergessen?",
        "email": "E-Mail",
        "instructions_for_macos": "Anleitungen für MacOS",
        "instructions_for_windows": "Anleitungen für Windows",
        "current-plan": "Aktueller Plan",
        "date": "Datum",
        "price": "Preis",
        "price_from": "Preis von",
        "next_due": "Nächstes Fälligkeitsdatum",
        "payment": "Zahlung",
        "cancel": "Abbrechen",
        "go_to_settings": "Zu den Einstellungen gehen",
        "ok": "Ok",
        "dialog_cancel-subscription_title-AppStore": "Sie können das Abonnement über das Apple Store-Konto kündigen, über das das Abonnement abgeschlossen wurde",
        "dialog_cancel-subscription_title-PlayMarket": "Sie können das Abonnement über das Play Market-Konto kündigen, über das das Abonnement abgeschlossen wurde",
        "dialog_cancel-subscription_title-sure": "Sind Sie sicher, dass Sie das Abonnement kündigen möchten?",
        "yes": "Ja",
        "no": "Nein",
        "subscription_canceled_successfully": "Abonnement erfolgreich gekündigt",
        "dashboard_dialog_thanks-for-registration_title": "Vielen Dank für die Registrierung!",
        "dashboard_dialog_thanks-for-registration_description_1": "Bitte merken Sie sich Ihr Passwort für den Log-In.",
        "Password": "Kennwort",
        "dashboard_dialog_thanks-for-registration_description_2-1": "Oder",
        "dashboard_dialog_thanks-for-registration_description_2-2": "klicken Sie hier",
        "dashboard_dialog_thanks-for-registration_description_2-3": "um Ihr eigenes Passwort festzulegen.",
        "prices_date-of-next-payment": "Datum der nächsten Geldabbuchung",
        "change_password": "Kennwort ändern",
        "current_password": "Aktuelles Kennwort",
        "new_password": "Neues Passwort",
        "confirm-password": "Passwort bestätigen",
        "reset": "Zurücksetzen",
        "email_address_associated": "Geben Sie die mit Ihrem Konto verknüpfte E-Mail-Adresse ein. Wir senden Ihnen einen Link zum Zurücksetzen Ihres Passworts per E-Mail zu.",
        "get-link": "E-Mail senden",
        "sign-in": "Anmelden",
        "forget-password_dialog_email-link_title": "Wir haben Ihnen eine E-Mail mit einem Link zum Zurücksetzen Ihres Passworts geschickt.",
        "i-have-promocode": "Ich habe einen Promo-Code",
        "promo": "Promo-Code",
        "have_an_account": "Ich habe ein Konto",
        "reset-password": "Passwort zurücksetzen",
        "state": {
            "register": "Anmelden"
        },
        "action": {
            "register": "Registrieren",
            "cancel": "Abbrechen"
        },
        "notify_password-changed": "Das Passwort wurde geändert",
        "prices_discount": "Rabatt",
        "prices_email": "E-Mail",
        "prices_country": "Ihr Land",
        "prices_pay": "Bezahlen",
        "prices_privacy_p-0": "Mit dem Absenden dieses Formulars stimmen Sie unseren",
        "prices_privacy_p-1": "Nutzungsbedinungen",
        "prices_privacy_p-2": "und",
        "prices_privacy_p-3": "Datenschutzrichtlinien",
        "price_card": "Karte",
        "price_internet-wallet": "Internet-Geldbörse",
        "price_cryptocurrency": "Kryptowährung",
        "prices_order-total": "Gesamtbetrag",
        "i-dont-have-promocode": "Ich habe keinen Promo-Code",
        "dashboard_dialog_thanks-for-registration_description_1_promo": "Der von Ihnen eingegebene Promo-Code ist nicht gültig",
        "prices_user-has-account_how-to-change-plan_1": "Um den Tarifplan zu ändern, kündigen Sie das aktuelle Abonnement auf der",
        "prices_user-has-account_how-to-change-plan_2": "Rechnungen-",
        "prices_user-has-account_how-to-change-plan_3": "Seite und erwerben Sie dann ein Neues.",
        "instructions_for_linux": "Anweisungen für Linux",
        "prices_Step": "Schritt",
        "tariff-name_Year": "Jahr",
        "tariff-name_Month": "Monat",
        "month": "Monat",
        "prices_save": "Speichern",
        "prices_billedEvery": "die Rechnung erfolgt jedes",
        "prices_the-most-popular": "Am beliebtesten",
        "prices_Billed": "Abgebucht",
        "prices_every": "Alles ",
        "icon_check-circle-empty": "Leeres Kontrollkästchen",
        "icon_check-circle": "Kontrollkästchen",
        "icon_like": "Gefällt mir",
        "icon_info": "Info",
        "icon_down": "Nach unten",
        "icon_check": "Kontrollkästchen",
        "prices_the-1-year-is-better": "Der 1-Jahres-Plan spart Ihnen am meisten",
        "prices_choose-plan": "Wählen Sie Ihren Plan",
        "prices_Chose-Payment-Method": "Zahlungsmethode wählen",
        "prices_Why-choose-VPN": "Warum {productName} wählen?",
        "prices_VPN-includes": "{productName} beinhaltet",
        "prices_includes-support": "Rund-um-die-Uhr Kundendienst",
        "prices_includes-money-back": "{numberOfDays}-Geld-zurück-Garantie",
        "prices_includes-security": "Hohe Sicherheits- & Verschlüsselungsstandards",
        "prices_includes-no-logs": "Keine Aktivitäts- und Verbindungslogs",
        "instruction": "Anleitung",
        "apps": "Apps",
        "my-ip": "Meine IP-Adresse",
        "terms": "Nutzungsbedingungen",
        "return-refund-policy": "Rückgabe- und Erstattungsbedingungen",
        "policy_and_procedure": "DMCA-Hinweis und Takedown-Richtlinie und -Verfahren",
        "privacy_policy": "Datenschutzerklärung",
        "footer_support": "Unterstützung",
        "footer_sales-department": "Verkaufsabteilung",
        "footer_company": "Unternehmen",
        "footer_contacts": "Ansprechpartner",
        "data-retention-policy": "Datenaufbewahrungsrichtlinie",
        "pricing": "Preisgestaltung",
        "servers": "Server",
        "knowledge-base": "Wissensdatenbank",
        "dashboard": "Kontrollzentrum",
        "to_home_page": "Zur Startseite",
        "icon_logo": "Logo",
        "icon_login": "Anmeldung",
        "icon_menu": "Menü",
        "icon_key": "Schlüssel",
        "icon_logout": "Abmeldung",
        "log_out": "Abmelden",
        "icon_lifebuoy": "Rettungsboje",
        "knowledge_base": "Wissensdatenbank",
        "icon_support": "Unterstützung",
        "downloads": "Downloads",
        "billings": "Rechnungen",
        "icon_eyeOpen": "Passwort anzeigen",
        "inactive": "Inaktiv. Nehmen Sie Ihre Zahlung vor, um den VPN zu nutzen.",
        "icon_flag": "Flagge",
        "active_until": "Aktiviert bis",
        "servers_cta_title": "Beginnen Sie noch heute mit sicherem Surfen",
        "servers_cta_description": "Halten Sie Ihr Internet sicher und privat",
        "downloads_instruction": "Anleitung",
        "downloads_router": "Router",
        "downloads_openVPN_profiles": "OpenVPN-Profile",
        "tariff-name-long_Year": "Ein Jahr",
        "tariff-name-long_Month": "Ein Monat",
        "dialog_cancel-subscription_header": "Abonnement kündigen",
        "web_payment_history": "Zahlungshistorie",
        "tariff": "Tarif",
        "payment_method": "Zahlungsweise",
        "status": "Status",
        "sum": "Summe",
        "tbody_is_empty_1": "Hier sehen Sie Informationen zu Ihren Zahlungen",
        "tbody_is_empty_2": "Eine Bestellung aufgeben",
        "status_paid": "Bezahlt",
        "status_not-paid": "Nicht bezahlt",
        "my_services": "Meine Dienste",
        "private_and_secure": "Privater und sicherer Internetzugang (VPN)",
        "active": "Aktiv",
        "downloads_apps_and_files": "Apps und Dateien",
        "downloads_for_mobile": "Mobiltelefone",
        "downloads_for_desktop": "Desktops",
        "downloads_for_browsers": "Browser",
        "downloads_other_devices": "Andere Geräte",
        "forget-password_dialog_email-link_header": "Überprüfen Sie Ihre E-Mail",
        "pageNotFoundTitle": "Seite nicht gefunden",
        "pageNotFoundDescription": "Es sieht so aus, als ob diese Seite nicht existiert. Bitte kommen Sie zu uns zurück.",
        "pageNotFoundAction": "Zurück zu {productName} Website",
        "home": {
            "mainTitle": {
                "highlited": "Ihr\ndigitaler",
                "rest": "Schutz"
            },
            "mainDescription": {
                "first": "für all Ihre persönlichen Daten und Computer",
                "second": "gegen Cyber-Betrüger"
            },
            "mainButton": "Ab $2,50 im Monat*",
            "mainButtonDescription": "*Geldrückgabe garantiert",
            "secureTitle": {
                "first": "Wirklich sicher",
                "second": "Internet"
            },
            "secureDescription": "VPN99 entfernt alles, was Sie im Internet einzigartig und wiedererkennbar macht. Es verbirgt Ihre IP-Adresse und verschlüsselt Daten, um Ihren Browserverlauf privat zu halten.",
            "secureCard": {
                "noRestrictionsTitle": "Keine Sperren!",
                "noRestrictionsDescription": "Alle Internetseiten stehen Ihnen zur Verfügung, auch die gesperrten. Außerdem sind alle Ihre Daten zuverlässig vor Betrügern geschützt.",
                "anonimTitle": "Sie sind jetzt anonym!",
                "anonimDescription": "Jede einzelne Verbindung ist anonym! Wir speichern keine Protokolle, sodass selbst wir keine Ahnung haben, was Sie im Internet treiben ;)"
            },
            "devicesTitle": {
                "first": "Ein Abonnement",
                "second": "für alle Geräte!"
            },
            "devicesDescription": "MacOS, Windows, Android, iOS, Erweiterungen für Chrome/Firefox und vieles mehr",
            "serversTitle": {
                "first": "Schnelle und stabile",
                "second": "Verbindung überall!"
            },
            "serversDescription": "Wählen Sie einen von 17 Servern oder vertrauen Sie dem empfohlenen Server - er ist der nächstgelegene und damit der schnellste. Sie können Filme und Fernsehsendungen in HD-Qualität unbekümmert ansehen.",
            "faqTitle": "Häufig gestellte Fragen",
            "faq": {
                "whatIsVPNTitle": "Was bedeutet VPN?",
                "whatIsVPNDescription": {
                    "p1": "VPN steht für Virtuelles Privates Netzwerk.",
                    "p2": "Der Hauptzweck unseres Dienstes ist es, Ihre Sicherheit und Privatsphäre zu gewährleisten. Um dies zu erreichen, verschlüsselt {productName} den Datenverkehr, den Sie über das Netzwerk übertragen."
                },
                "rollUp": "Nach oben",
                "more": "Lesen",
                "howWorksTitle": "Wie funktioniert ein VPN?",
                "howWorksDescription": {
                    "p1": "VPN fügt dem System eine neue Netzwerkschnittstelle hinzu, die so funktioniert, als ob Sie Ihr Gerät direkt mit dem privaten Netzwerk verbinden würden. Es ändert auch Ihre IP-Adresse und verschlüsselt alle übertragenen Daten. Ein VPN ermöglicht den Empfang und die Übertragung von Informationen über öffentliche Netze (z. B. über das Internet), so als wäre Ihr Gerät direkt mit einem privaten Netz verbunden, wobei dieselben Sicherheitsrichtlinien gelten und der Zugriff auf alle Ressourcen des privaten Netzes möglich ist."
                },
                "whatDiferenceWithProxyTitle": "Was ist der Unterschied zwischen Proxy-Servern und VPN-Diensten?",
                "whatDiferenceWithProxyDescription": {
                    "p1": "VPN-Verbindungen sind im Gegensatz zu Proxys wesentlich stabiler und verschlüsselter. Nicht alle Programme können mit Proxy-Servern arbeiten, und viele von ihnen erfordern eine weitere Konfiguration. Wenn Sie ein VPN verwenden, müssen Sie nicht jede Anwendung einzeln konfigurieren."
                },
                "whatCountriesTitle": "Welche Länder sind verfügbar?",
                "whatCountries": {
                    "first": "Die aktuelle Liste der Standorte in verschiedenen Ländern finden Sie",
                    "link": "hier",
                    "second": "Sie erhalten also eine IP-Adresse mit der Geolocation dieser Länder, wenn Sie sich verbinden."
                },
                "logsTitle": "Werden Protokolldateien gespeichert?",
                "logs": {
                    "p1": "{productName} hat eine strikte No-Logging-Politik."
                }
            },
            "footerTitle": {
                "first": "Starten",
                "second": "VPN99 verwenden"
            },
            "footerDescription": "Laden Sie die App auf Ihr Gerät herunter"
        },
        "my_ip_address": "Meine IP-Adresse",
        "your_ip": "Dies ist Ihre IP-Adresse",
        "fail-payment_title": "Ups! Etwas ist falsch.",
        "fail-payment_description": "Die Zahlung des Tarifs wurde nicht ausgeführt. Vielleicht haben Sie bei der Eingabe der Daten während der Zahlung einen Fehler gemacht. Machen Sie sich keine Sorgen, Sie können es noch einmal versuchen.",
        "fail-payment_go-to-prices": "Gehen Sie zu den Tarifplänen und versuchen Sie es erneut",
        "success_congratulations-title": "Herzlichen Glückwunsch zu Ihrem Einkauf!",
        "success_congratulations-description": "Bitte bleiben Sie dran. Sie werden zu Ihrem persönlichen Konto weitergeleitet, wo Sie Ihre Einkäufe verwalten können.",
        "success-payment_go-to-dashboard": "Jetzt anmelden",
        "servers_title": "Zugang zu 10+ Ländern mit einem Klick",
        "servers_title_description": "Wählen Sie einen VPN-Server auf {continents_number} Kontinenten in einem von {servers_number} Ländern",
        "servers_locations_list_title": "Liste der VPN-Server-Standorte",
        "servers_continent_all": "Alle Länder",
        "allservers": "Land",
        "continent_asia": "Asien",
        "continent_europe": "Europa",
        "continent_america": "Amerika",
        "our_vpn_server": "Unsere Server",
        "subscription_canceled_failure": "Ups! Es ist ein Fehler bei der Stornierung aufgetreten. Bitte kontaktieren Sie den Kundenservice.",
        "prices_trial-days": "+ {count} Tage Testversion",
        "days": "{count} Tage",
        "prices_trial": "Testversion",
        "pageForbidden": "Unerlaubter Zugang",
        "pageForbiddenTitle": "Unerlaubter Zugang",
        "pageForbiddenDescription": "Es scheint, dass die Seite, auf die Sie zugreifen wollten, eingeschränkt ist.",
        "pageForbiddenAction": "Zurück zu {productName} Webseite",
        "private_finally": "Privates Online-Surfen",
        "no_one_can_see": "Niemand kann sehen, was Sie tun",
        "no_restrictions_on_content_and_censorship": "Keine Online-Beschränkungen oder Zensur",
        "one_click_and_you_defend": "Ein Klick und Sie sind geschützt",
        "removes_everything": "{productName} entfernt alles, was Sie online einzigartig und erkennbar macht, indem es Ihre IP-Adresse verbirgt und Ihre Daten verschlüsselt. So wird Ihr Browserverlauf privat gehalten.",
        "enjoy_fast_and_stable_connection": "Genießen Sie überall eine schnelle und stabile Verbindung",
        "choose_your_server": "Wählen Sie Ihren Server aus {count} Ländern und genießen Sie den schnellsten VPN-Service. Mehr Server bedeuten weniger Last und eine große Auswahl an Standorten bedeutet, dass Sie mit größerer Wahrscheinlichkeit einen in Ihrer Nähe finden.",
        "easy_to_use_apps": "Benutzerfreundliche Apps für alle Ihre Geräte",
        "subscription_comes": "Ein {productName}-Abonnement für jedes Gerät, das Sie besitzen: {appsList} und mehr",
        "money_back_guarantee": "Geld-zurück-Garantie",
        "faq_footer_description": "Weitere Informationen, die Sie vielleicht interessieren, finden Sie in unserer",
        "faq_footer_knowledge-base": "Wissens-Datenbank",
        "tryFree": "Kostenlos testen",
        "year": "Jahr",
        "icon_up": "Nach oben",
        "status_failed": "Fehlgeschlagen",
        "status_refund": "Rückerstattung",
        "status_trial": "Testversion",
        "onHoldMessage": "Es ist etwas schief gelaufen und wir konnten Ihr Abonnement für {productName} auf Ihrem Gerät {platform} nicht automatisch abrechnen."
    },
    "css": "",
    "validator": {
        "is_empty": "Das Feld muss leer sein",
        "is_not_empty": "Erforderliches Feld",
        "is_email": "Überprüfen Sie die Eingabe auf Richtigkeit",
        "is_phone": "Geben Sie die richtige Zahl ein",
        "is_password": "Passwort darf keine {extraSymbols} enthalten",
        "is_password_has_enough_symbols": "Das Passwort muss mindestens 6 Zeichen enthalten",
        "is_password_less_max_symbols": "Kennwort muss weniger als 32 Zeichen enthalten",
        "is_password_has_space": "Passwort darf kein Leerzeichen enthalten",
        "is_not_empty_with_field_name": "Das Feld {fieldName} ist erforderlich",
        "is_password_has_not_english_letters": "Nicht-lateinische Buchstaben sind nicht erlaubt"
    },
    "passwords-not-equal": "Das Passwort stimmt nicht überein",
    "error": {
        "e500": {
            "new": {
                "text": "Wir kennen dieses Problem bereits und werden es bald lösen! Bitte versuchen Sie es später noch einmal.",
                "title": "500 - Server-Fehler"
            }
        },
        "eUnknown": {
            "new": {
                "text": "Wir kennen dieses Problem bereits und werden es bald lösen! Bitte versuchen Sie es später noch einmal.",
                "title": "500 - Server-Fehler"
            },
            "oops": {
                "text": "Ups! Beim Laden der Benutzerdaten ist ein Fehler aufgetreten. Bitte versuchen Sie, die Seite neu zu laden."
            }
        },
        "e400": {
            "new": {
                "title": "Fehler"
            }
        }
    },
    "whitelabel": {
        "vpn99": {
            "web_be_safe": "Onlinesicherheit zum Bestpreis",
            "web_online_security": "Ab nur ${pricePerMonth} pro Monat"
        },
        "trustvpn": {
            "web_be_safe": "Schützen Sie Ihre Privatsphäre im Internet!",
            "web_online_security": "Ultra-schnelle Geschwindigkeit, Schutz Ihrer Privatsphäre, Digitale Freiheit"
        }
    },
    "meta": {
        "descriptionDataRetentionPolicy": "Situationen und Zeiträume, in denen Ihre Daten aufbewahrt werden. Alle Ihre Daten werden in Übereinstimmung mit den GDPR-Richtlinien sicher gespeichert.",
        "descriptionMyIp": "Finden Sie Ihre IP-Adresse und die Adresse Ihres Computers online. Sehen Sie, wie Ihre IP-Adresse aussieht",
        "descriptionPrivacyPolicy": "Unsere Datenschutzrichtlinie enthält Informationen darüber, welche Daten wir sammeln und wie wir sie verwenden, sowie darüber, wie Sie Ihre Daten kontrollieren können. Wir verwenden Cookies in Übereinstimmung mit dem walisischen Gesetz, aber Sie können Ihre Einstellungen anpassen.",
        "descriptionTerms": "Indem Sie die Nutzungsbedingungen von {productName} akzeptieren, stimmen Sie den Bestimmungen und Bedingungen sowie den Verantwortlichkeiten beider Parteien zu, die in der Vereinbarung dargelegt sind. Eine Entschädigung ist unter bestimmten Umständen möglich, wenn die VPN-Dienste für mehr als 12 aufeinanderfolgende Stunden nicht verfügbar sind oder nicht richtig funktionieren.",
        "descriptionAccountBilling": "Konto - Zahlungsverlauf",
        "descriptionAccountPanel": "Konto - Überblick",
        "descriptionAccountPassword": "Konto - Passwort ändern",
        "descriptionDownload": "Herunterladen",
        "descriptionForgot": "Passwort wiederherstellen",
        "descriptionReset": "Passwort zurücksetzen",
        "descriptionHome": "Laden Sie {productName} herunter und installieren Sie es. Sie erhalten vollen Schutz auf der ganzen Welt. {productName}, hohe Geschwindigkeiten und vollständige Anonymität.",
        "descriptionPayFail": "Zahlung abgelehnt",
        "descriptionPaySuccess": "Die Zahlung war erfolgreich",
        "descriptionPrice": "Kaufen und installieren Sie {productName} und Sie erhalten vollen Schutz auf der ganzen Welt. Erhalten Sie alle Vorteile eines teuren VPNs. {productName}, hohe Geschwindigkeiten und völlige Anonymität",
        "descriptionServers": "Server",
        "titleDataRetentionPolicy": "Richtlinie zur Datenspeicherung",
        "titleMyIp": "Finden Sie heraus, was Ihre IP-Adresse ist",
        "titlePrivacyPolicy": "Datenschutzrichtlinie: Schritte, die {productName} unternimmt, um Ihre privaten Informationen zu schützen",
        "titleTerms": "Nutzungsbedingungen: die spezifischen Bedingungen, die für die Inanspruchnahme der von {productName} angebotenen Dienste gelten.",
        "titleAccountBilling": "Konto - Zahlungsverlauf",
        "titleAccountPanel": "Konto - Dashboard",
        "titleAccountPassword": "Konto - Passwort ändern",
        "titleDownload": "Herunterladen",
        "titleForgot": "Passwort wiederherstellen",
        "titleReset": "Kennwort zurücksetzen",
        "titleHome": "Laden Sie {productName} noch heute herunter und Sie erhalten einen zuverlässigen Versicherungsschutz zu einem günstigen Preis.",
        "titleLogin": "Anmelden",
        "titlePayFail": "Zahlung abgelehnt",
        "titlePaySuccess": "Die Zahlung war erfolgreich",
        "titlePrice": "Holen Sie sich noch heute {productName} und Sie erhalten einen zuverlässigen Versicherungsschutz zu einem günstigen Preis.",
        "titleRegister": "Anmelden",
        "titleServers": "Server",
        "titleApps": "Apps",
        "descriptionApps": "Anwendungen",
        "descriptionLogin": "Anmelden",
        "descriptionRegister": "Anmelden",
        "titleReturnRefundPolicy": "Rückgabe- und Erstattungsrichtlinien",
        "descriptionReturnRefundPolicy": "Rückgabe- und Erstattungsrichtlinien",
        "titlePolicyAndProcedure": "DMCA-Hinweis und Takedown-Richtlinie und -Verfahren",
        "descriptionPolicyAndProcedure": "DMCA-Hinweis und Takedown-Richtlinie und -Verfahren"
    },
    "getVPN": "{productName} kaufen",
    "signIn": "Anmelden",
    "billings-page": {
        "waiting-processing": "Wir warten auf eine Antwort\nvon der Zahlungsverarbeitung."
    },
    "servers": {
        "USDal": "США Даллас"
    }
}
