export default Object.freeze({
  TARIFF: {
    CLICKED: 'TARIFF__CLICKED',
  },
  LOCALE: {
    FIRST_LOAD_ROUTE_LOCALE_SET: 'LOCALE__FIRST_LOAD_ROUTE_LOCALE_SET',
  },
  ROUTER: {
    ON_ROUTE_CHANGED: 'ROUTER__ON_ROUTE_CHANGED',
    FIRST_LOAD: 'ROUTER__FIRST_LOAD',
  },
  APP: {
    SHAKE: 'APP__ON_SHAKE',
  },
})
