import i18n from '@/i18n'

const $t = (val, params = {}) => i18n.global.t(val, params)

const getPeriod = ({ period, numberOfPeriods }) => {
  // TODO: добавить плюрализацию (Включая скрипт по выгрузке переводов в таблицу)
  if (numberOfPeriods === 1) {
    return period[0].toUpperCase() + period.slice(1).toLowerCase()
  }
  return period[0].toUpperCase() + period.slice(1).toLowerCase() + 's'
}

export const tariffName = ({ period, numberOfPeriods, mini = false }) => {
  if (numberOfPeriods === 1 && period === 'year') {
    return mini ? $t('web.tariff-name_Year') : $t('web.tariff-name-long_Year')
  }
  if (numberOfPeriods === 1 && period === 'month') {
    return mini ? $t('web.tariff-name_Month') : $t('web.tariff-name-long_Month')
  }
  if (numberOfPeriods >= 24 && period === 'month') {
    const _numberOfPeriods = Number((numberOfPeriods / 12).toFixed(1))
    return `${_numberOfPeriods} ${getPeriod({
      period: 'year',
      numberOfPeriods: _numberOfPeriods,
    })}`
  }
  if (numberOfPeriods > 0) {
    return `${numberOfPeriods} ${getPeriod({ period, numberOfPeriods })}`
  }
  return null
}

export const fixedPeriod = ({ period, numberOfPeriods }) => {
  const _period = numberOfPeriods === 12 && period === 'month' ? 'year' : period
  const _numberOfPeriods =
    numberOfPeriods === 12 && period === 'month' ? 1 : numberOfPeriods
  return {
    period: _period,
    numberOfPeriods: _numberOfPeriods,
  }
}

export const convertDaysToMonths = ({ days }) => {
  switch (days) {
    case 183:
      return $t('web.tariff-name_6-months')
  }
}

export const convertDaysToYears = ({ days }) => {
  switch (+days) {
    case 183:
      return $t('web.tariff-name_half-year')
  }
  return null
}
