import { createStore } from 'vuex'
import user from './modules/user.js'
import country from './modules/country.js'
import app from './modules/app.js'
import apps from './modules/apps.js'
import paywall from './modules/paywall.js'
import config from './modules/config.js'
import whitelabel from './modules/whitelabel.js'
import clientServer from './modules/clientServer.js'

export default createStore({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    user,
    country,
    app,
    apps,
    paywall,
    config,
    clientServer,
    whitelabel,
  },
})
