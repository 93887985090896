import MERCHANT from './MERCHANT.js'
import PAYMENT_METHOD from './PAYMENT_METHOD.js'
import PAY_API_NAME from './PAY_API_NAME.js'
import SERVICE from './SERVICE.js'

export default Object.freeze({
  MERCHANT,
  PAYMENT_METHOD,
  PAY_API_NAME,
  SERVICE,
})
