import api from '@/api'
import i18n from '@/i18n'
import localizedCountries from '@/i18n/localizedCountries'

const $t = (val, params = {}) => i18n.global.t(val, params)

const state = () => ({
  list: null,
  servers: null,
  selectedCountry: null,
})

const getters = {
  UIServers(state) {
    const techologyMap = {
      extention: 'Extention',
      wireguard: 'Wireguard®',
      'l2tp-ipsec': 'L2TP/IPsec',
      openvpn: 'OpenVPN®',
      pptp: 'PPTP',
    }
    return state.servers?.map((server) => {
      const technologyList = server.technologyList.map(
        (technology) => techologyMap[technology] || technology
      )
      const codeMap = {
        UK: 'GB',
      }
      const serverCode =
        codeMap[server.code.toUpperCase()] || server.code.toUpperCase()
      const codeServerMap = {
        'US.DAL': $t('servers.USDal'),
      }
      return {
        ...server,
        enTitle: server.title,
        title:
          localizedCountries[i18n.global.locale]?.[serverCode] ||
          codeServerMap[serverCode] ||
          server.title,
        technologyList: technologyList,
        technologyString: technologyList.reduce((acc, technology) => {
          acc += `, ${technology}`
          return acc
        }),
      }
    })
  },
}

const actions = {
  fetchCountries({ commit }) {
    return api.country.fetchCountries().then(({ data }) => {
      commit(
        'setCountries',
        Object.entries(data).reduce((acc, [countryKey, countryConfig]) => {
          acc[countryKey] = {
            ...countryConfig,
            localizedTitles: Object.entries(localizedCountries).reduce(
              (localized, [locale, countries]) => {
                localized[locale] =
                  countries[countryConfig.code.toUpperCase()] || countryKey
                return localized
              },
              {}
            ),
          }
          return acc
        }, {})
      )
      return data
    })
  },

  fetchServers({ commit }) {
    return api.country.fetchServers().then(({ data }) => {
      const servers = data.sort((s1, s2) => (s1.title > s2.title ? 1 : -1))
      commit('setServes', servers)
      return servers
    })
  },

  setSelectedCountry({ commit }, data) {
    commit('setSelectedCountry', data)
  },
}

const mutations = {
  setCountries(state, data) {
    state.list = data
  },

  setServes(state, data) {
    state.servers = data
  },

  setSelectedCountry(state, data) {
    state.selectedCountry = data
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
