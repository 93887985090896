<template>
  <footer class="footer">
    <div class="footer__content footer__content--top">
      <div class="footer__container">
        <div class="row">
          <div class="col">
            <div class="footer__content-inner">
              <div
                v-for="column in computedRefs"
                :key="column.name"
                :class="`footer__item footer__item--${column.className}`"
              >
                <div class="footer__nav">
                  <div class="footer__nav-title">
                    <h4 class="title title-sm text-uppercase">
                      {{ column.name }}
                    </h4>
                  </div>
                  <ul class="no-style footer__nav-list">
                    <li
                      v-for="ref in column.hrefs"
                      :key="ref.href"
                      class="footer__nav-item"
                    >
                      <div>
                        <c-link
                          :to="ref.href"
                          class="footer__nav-link text text-md"
                          :external="ref.external"
                          :label="ref.label"
                          :color="isDesktop ? 'black' : 'gray'"
                        />
                      </div>
                      <div v-if="ref.sublabel">
                        <c-link
                          :to="ref.href"
                          class="footer__nav-sub-link text text-2xs"
                          :external="ref.external"
                          :label="ref.sublabel"
                          color="default"
                        />
                      </div>
                    </li>
                  </ul>
                </div>
              </div>

              <div class="footer__item footer__item--certificates">
                <ul class="no-style footer__certificates">
                  <li
                    v-for="img in images"
                    :key="img.src"
                    class="footer__certificates-item"
                  >
                    <img :src="img.src" :alt="img.alt" class="footer-logo" />
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="footer__content footer__content--bottom">
      <div>
        <div class="row">
          <div class="col">
            <div class="footer__content-inner">
              <div class="footer__copyright text text-sm">
                Copyright © {{ $moment().year() }}. {{ COMPANY_NAME }},
                {{ REGISTRATION_ADDRESS }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import CLink from '@/components/Link'

export default {
  name: 'Footer',
  components: {
    CLink,
  },
  data() {
    this.COMPANY_NAME = process.env.VUE_APP__COMPANY_NAME
    this.REGISTRATION_ADDRESS = process.env.VUE_APP__REGISTRATION_ADDRESS
    return {
      images: [
        {
          src: require('@/assets/image/footer/trustpilot.png'),
          alt: 'trustpilot',
        },
        {
          src: require('@/assets/image/footer/30_days_money_guarantee.png'),
          alt: '30_days_money_guarantee',
        },
        {
          src: require('@/assets/image/footer/mc_logo.png'),
          alt: 'mc_logo',
        },
        {
          src: require('@/assets/image/footer/visa_logo.png'),
          alt: 'visa_logo',
        },
      ],
    }
  },
  computed: {
    isDesktop: {
      get() {
        return this.$store.getters['app/isDesktopSize']
      },
    },
    refs: {
      get() {
        return [
          {
            name: process.env.VUE_APP__PRODUCT_NAME,
            className: 'vpn',
            content: [
/*              {
                label: this.$t('web.rinstruction'),
                href: process.env.VUE_APP__KNOWLEDGE_BASE_URL,
                sublabel: null,
                external: true,
                hide: process.env.VUE_APP__KNOWLEDGE_BASE_URL === 'NULL',
              },*/
              {
                label: this.$t('web.apps'),
                href: { name: this.$ROUTER.NAME.APPS },
                sublabel: null,
                external: false,
              },
              {
                label: this.$t('web.my-ip'),
                href: { name: this.$ROUTER.NAME.MY_IP },
                sublabel: null,
                external: false,
              },
            ],
          },
          {
            name: this.$t('web.footer_company'),
            className: 'company',
            content: [
              {
                label: this.$t('web.terms'),
                href: { name: this.$ROUTER.NAME.TERMS },
                sublabel: null,
                external: false,
              },
              {
                label: this.$t('web.data-retention-policy'),
                href: { name: this.$ROUTER.NAME.DATA_RETENTION_POLICY },
                sublabel: null,
                external: false,
              },
              {
                label: this.$t('web.return-refund-policy'),
                href: { name: this.$ROUTER.NAME.RETURN_REFUND_POLICY },
                sublabel: null,
                external: false,
              },
              {
                label: this.$t('web.policy_and_procedure'),
                href: { name: this.$ROUTER.NAME.POLICY_AND_PROCEDURE },
                sublabel: null,
                external: false,
              },
              {
                label: this.$t('web.privacy_policy'),
                href: { name: this.$ROUTER.NAME.PRIVACY_POLICY },
                sublabel: null,
                external: false,
              },
            ],
          },
          {
            name: this.$t('web.footer_contacts'),
            className: 'contacts',
            content: [
              {
                label: this.$t('web.footer_support'),
                href: `mailto:${process.env.VUE_APP__SUPPORT_EMAIL}`,
                sublabel: process.env.VUE_APP__SUPPORT_EMAIL,
                external: true,
              },
              {
                label: this.$t('web.footer_sales-department'),
                href: `mailto:${process.env.VUE_APP__SALES_EMAIL}`,
                sublabel: process.env.VUE_APP__SALES_EMAIL,
                external: true,
                hide: process.env.VUE_APP__SALES_EMAIL === 'NULL',
              },
            ],
          },
        ]
      },
    },
    computedRefs: {
      get() {
        return this.refs.map(({ name, content, className }) => ({
          name,
          hrefs: content?.filter((ref) => !ref.hide),
          className,
        }))
      },
    },
  },
}
</script>

<style lang="scss" scoped>
@import 'index';
</style>
