<template>
  <router-link
    v-if="!external"
    :title="title || value"
    :target="external ? '_blank' : '_self'"
    :to="to"
    class="c-button"
    :class="{
      'c-button--disabled': disabled,
      'c-button--compressed': compressed,
      'c-button--expanded': expand,
      'c-button--lg text-lg': size === 'lg',
      'c-button--md text-md': size === 'md',
      'c-button--accent': color === 'default' && !transparent,
      'c-button--black': color === 'black' && !transparent,
      'c-button--white': color === 'white' && !transparent,
      'c-button--white-transparent': color === 'white' && transparent,
      'c-button--default-transparent': color === 'default' && transparent,
    }"
  >
    <span class="c-button__label">
      {{ value }}
    </span>
  </router-link>
  <a
    v-else
    :title="title || value"
    :target="external ? '_blank' : '_self'"
    :href="to"
    class="c-button"
    :class="{
      'c-button--disabled': disabled,
      'c-button--compressed': compressed,
      'c-button--expanded': expand,
      'c-button--lg text-lg': size === 'lg',
      'c-button--md text-md': size === 'md',
      'c-button--accent': color === 'default' && !transparent,
      'c-button--black': color === 'black' && !transparent,
      'c-button--white': color === 'white' && !transparent,
      'c-button--white-transparent': color === 'white' && transparent,
      'c-button--default-transparent': color === 'default' && transparent,
    }"
  >
    <span class="c-button__label">
      {{ value }}
    </span>
  </a>
</template>

<script>
export default {
  name: 'LinkButton',

  props: {
    to: {
      required: true,
      type: [String, Object],
    },
    title: {
      type: String,
      default: '',
    },
    value: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    transparent: {
      type: Boolean,
      default: false,
    },
    compressed: {
      type: Boolean,
      default: false,
    },
    expand: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String,
      default: 'default',
    },
    size: {
      type: String,
      default: 'md', // md, lg
    },
    external: {
      type: [Boolean, undefined],
      default: false,
    },
  },
}
</script>

<style scoped></style>
