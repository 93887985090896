import store from '@/store'
const CryptoJS = require('crypto-js')
import { API } from '@/CONSTANTS'

export default class Auth {
  constructor(rest) {
    this.rest = rest
  }

  login(email, password) {
    const sPassword = CryptoJS.AES.encrypt(
      password,
      CryptoJS.enc.Utf8.parse(store.getters['config/pwdSecret']),
      { iv: CryptoJS.enc.Utf8.parse(store.getters['config/pwdVector']) }
    ).toString()
    return this.rest
      .post('auth/login', {
        email: email.toLowerCase().trim(),
        password: sPassword,
      })
      .then((res) => {
        if (res.success && typeof res.data?.token === 'string') {
          store.dispatch('user/setToken', res.data.token)
        }
        return res
      })
  }

  loginByToken(token) {
    return new Promise((resolve, reject) => {
      const headers = { Authorization: 'Bearer ' + token }
      this.rest
        .post(
          'auth/login-with-token',
          {},
          {
            headers,
          }
        )
        .then((res) => {
          if (res.success && typeof res.data?.token === 'string') {
            store.dispatch('user/setToken', res.data.token)
            store.dispatch('user/fetchUser')
            resolve(res)
          }
        })
        .catch((err) => {
          reject(err)
        })
    })
  }

  register(email, promoCode = undefined) {
    return this.rest
      .post('auth/register', {
        email: email.toLowerCase().trim(),
        promoCode: promoCode ? promoCode.trim() : undefined,
      })
      .then((res) => {
        if (
          (res.success || res.status === API.STATUS.PROMO_VALIDATION_FAILED) &&
          typeof res.data?.token === 'string'
        ) {
          store.dispatch('user/setToken', res.data.token)
        }
        return res
      })
  }

  sentPassword(email) {
    return this.rest.post('email/reset-password', {
      email: email.toLowerCase().trim(),
    })
  }

  reset(password, password_confirmation, token) {
    return this.rest
      .post('user/password/reset', {
        password,
        password_confirmation,
        token,
      })
      .then((res) => {
        if (res.success && typeof res.data?.token === 'string') {
          store.dispatch('user/setToken', res.data.token)
        }
        return res
      })
  }

  changePassword(current_password, password, password_confirmation) {
    return this.rest.post(
      '/user/password/change',
      {
        current_password,
        password,
        password_confirmation,
      },
      { isAuth: true }
    )
  }
}
