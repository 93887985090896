{
    "web": {
        "why_use": "Pourquoi utiliser {productName}",
        "download_VPN": "Téléchargez {productName} et commencez à l'utiliser dès aujourd'hui !",
        "getVPN": "Obtenez l'application {productName}",
        "access": "Accéder à n'importe quel contenu depuis le monde entier",
        "jurisdiction": "La juridiction de l'UE garantit la confidentialité",
        "anonymity": "Anonyme et absence de registres ",
        "simple_conf": "Facile à installer et à utiliser",
        "secure": " Paiements sécurisés et protection contre les hackers",
        "protection": "Protection contre la publicité intrusive Publicité",
        "get_VPN_app_phone": "Obtenez l'application {productName} pour votre téléphone",
        "keep_safe": "Naviguez sur internet en toute sécurité et en toute confidentialité",
        "different_device": "Obtenir {productName} pour un autre appareil",
        "authorize": "Autoriser",
        "dont_have_an_account": "Vous n'avez pas de compte ? ",
        "register": "S'inscrire",
        "pass": "Mot de passe",
        "sign_in": "Se connecter ",
        "forgot_pass": "Mot de passe oublié ?",
        "email": "Adresse e-mail ",
        "instructions_for_macos": "Instructions pour macOS",
        "instructions_for_windows": "Instructions pour Windows",
        "current-plan": "Forfait en cours",
        "date": "Date",
        "price": "Prix",
        "price_from": "Prix à partir de",
        "next_due": "Prochain paiement",
        "payment": "Paiement",
        "cancel": "Annuler",
        "go_to_settings": "Aller aux paramètres",
        "ok": "Ok",
        "dialog_cancel-subscription_title-AppStore": "Vous pouvez résilier votre abonnement en vous connectant à votre compte depuis l'Apple Store puis en vous rendant à la page de gestion de vos abonnements",
        "dialog_cancel-subscription_title-PlayMarket": "Vous pouvez résilier votre abonnement en vous connectant à votre compte depuis le Google Play Store puis en vous rendant à la page de gestion de vos abonnements",
        "dialog_cancel-subscription_title-sure": "Êtes-vous sûr de vouloir résilier votre abonnement ?",
        "yes": "Oui",
        "no": "Non",
        "subscription_canceled_successfully": "Votre abonnement a bien été résilié",
        "dashboard_dialog_thanks-for-registration_title": "Nous vous remercions pour votre inscription !",
        "dashboard_dialog_thanks-for-registration_description_1": "Veuillez mémoriser votre mot de passe afin de vous connecter",
        "Password": "Mot de passe",
        "dashboard_dialog_thanks-for-registration_description_2-1": "Ou",
        "dashboard_dialog_thanks-for-registration_description_2-2": "cliquez ici",
        "dashboard_dialog_thanks-for-registration_description_2-3": "pour configurer votre propre mot de passe.",
        "prices_date-of-next-payment": "Date du prochain prélèvement",
        "change_password": "Changer le mot de passe",
        "current_password": "Mot de passe actuel",
        "new_password": "Nouveau mot de passe",
        "confirm-password": "Confirmer le mot de passe",
        "reset": "Réinitialiser",
        "email_address_associated": "Saisissez l'adresse e-mail associée à votre compte. Nous vous enverrons par e-mail un lien pour réinitialiser votre mot de passe.",
        "get-link": "Envoyer l'e-mail",
        "sign-in": "Se connecter ",
        "forget-password_dialog_email-link_title": "Nous vous avons envoyé un e-mail avec un lien pour réinitialiser votre mot de passe.",
        "i-have-promocode": "J'ai un code promotionnel",
        "promo": "Code promotionnel",
        "have_an_account": "J'ai un compte",
        "reset-password": "Réinitialiser le mot de passe",
        "state": {
            "register": "S'inscrire"
        },
        "action": {
            "register": "S'inscrire",
            "cancel": "Annuler"
        },
        "notify_password-changed": "Le mot de passe a été modifié",
        "prices_discount": "Réduction",
        "prices_email": "Adresse e-mail ",
        "prices_country": "Votre pays ",
        "prices_pay": "Payer",
        "prices_privacy_p-0": "En envoyant ce formulaire, vous acceptez nos",
        "prices_privacy_p-1": "Conditions d' utilisation",
        "prices_privacy_p-2": "et notre",
        "prices_privacy_p-3": "Politique de confidentialité ",
        "price_card": "Carte",
        "price_internet-wallet": "Porte-monnaie virtuel",
        "price_cryptocurrency": "Cryptomonnaie",
        "prices_order-total": "Total de la commande",
        "i-dont-have-promocode": "Je n'ai pas de code promo",
        "dashboard_dialog_thanks-for-registration_description_1_promo": "Le code promotionnel que vous avez entré n'est pas valide",
        "prices_user-has-account_how-to-change-plan_1": "Pour changer de forfait, veuillez annuler l'abonnement en cours sur la",
        "prices_user-has-account_how-to-change-plan_2": "Factures",
        "prices_user-has-account_how-to-change-plan_3": "page, puis en acheter un nouveau",
        "instructions_for_linux": "Instructions pour Linux",
        "prices_Step": "Étape",
        "tariff-name_Year": "Année",
        "tariff-name_Month": "Mois",
        "month": "mois",
        "prices_save": "Sauvegarder",
        "prices_billedEvery": "facturé tous les",
        "prices_the-most-popular": "Le plus populaire",
        "prices_Billed": "Facturé",
        "prices_every": "Tous",
        "icon_check-circle-empty": "Case vide",
        "icon_check-circle": "Case à cocher",
        "icon_like": "Like",
        "icon_info": "Informations",
        "icon_down": "Vers le bas",
        "icon_check": "Cae à cocher",
        "prices_the-1-year-is-better": "Le forfait \"Année\" est le plus rentable",
        "prices_choose-plan": "Sélectionnez votre forfait",
        "prices_Chose-Payment-Method": "Sélectionnez un moyen de paiement",
        "prices_Why-choose-VPN": "Pourquoi choisir {productName} ?",
        "prices_VPN-includes": "{productName} inclut",
        "prices_includes-support": "Service client disponible 24h sur 24",
        "prices_includes-money-back": "Satisfait ou remboursé pendant {numberOfDays}-jours",
        "prices_includes-security": "Sécurité et cryptage de niveau bancaire",
        "prices_includes-no-logs": "Pas d'historique d'activité ni de connexion",
        "instruction": "Instructions",
        "apps": "Applications",
        "my-ip": "Mon IP",
        "terms": "Conditions d'utilisation",
        "return-refund-policy": "Politique de retour et de remboursement",
        "policy_and_procedure": "Politique et procédures de notification et de retrait du DMCA",
        "privacy_policy": "Politique de confidentialité",
        "footer_support": "Support",
        "footer_sales-department": "Département de ventes ",
        "footer_company": "Entreprise",
        "footer_contacts": "Contact",
        "data-retention-policy": "Politique de conservation des données",
        "pricing": "Prix",
        "servers": "Serveurs",
        "knowledge-base": "Base de connaissances",
        "dashboard": "Tableau de bord",
        "to_home_page": "Aller à la page d'accueil",
        "icon_logo": "Logo",
        "icon_login": "Connexion",
        "icon_menu": "Menu",
        "icon_key": "Clé",
        "icon_logout": "Déconnexion",
        "log_out": "Déconnexion",
        "icon_lifebuoy": "Bouée de sauvetage",
        "knowledge_base": "Base de connaissances",
        "icon_support": "Support",
        "downloads": "Téléchargements ",
        "billings": "Factures",
        "icon_eyeOpen": "Montrer le mot de passe",
        "inactive": "Inactif. Effectuez votre paiement pour commencer à utiliser VPN99",
        "icon_flag": "Signaler",
        "active_until": "Actif jusqu'au",
        "servers_cta_title": "Commencez à surfer en toute sécurité dès aujourd'hui",
        "servers_cta_description": "Utilisez internet en toute sécurité et protégez vos données",
        "downloads_instruction": "Instruction ",
        "downloads_router": "Routeur ",
        "downloads_openVPN_profiles": "profils OpenVPN ",
        "tariff-name-long_Year": "Un an",
        "tariff-name-long_Month": "Un mois",
        "dialog_cancel-subscription_header": "Annuler l'abonnement",
        "web_payment_history": "Historique des paiements ",
        "tariff": "Forfait",
        "payment_method": "Moyen de paiement",
        "status": "Statut",
        "sum": "Somme",
        "tbody_is_empty_1": "Ici, vous pourrez visualiser les informations concernant vos paiements",
        "tbody_is_empty_2": "Effectuer une commande",
        "status_paid": "Réglé",
        "status_not-paid": "Non réglé",
        "my_services": "Mes services",
        "private_and_secure": "Accès internet privé et sécurisé (VPN)",
        "active": "Actif",
        "downloads_apps_and_files": "Apps and Files",
        "downloads_for_mobile": "Mobiles ",
        "downloads_for_desktop": "Bureaux",
        "downloads_for_browsers": "Moteurs de recherche ",
        "downloads_other_devices": "Autres appareils ",
        "forget-password_dialog_email-link_header": "Veuillez consulter votre messagerie",
        "pageNotFoundTitle": "La page est introuvable",
        "pageNotFoundDescription": "Il semble que cette page n'existe pas. Veuillez revenir en arrière.",
        "pageNotFoundAction": "Retourner sur le site {productName}",
        "home": {
            "mainTitle": {
                "highlited": "La\nprotection",
                "rest": "digitale"
            },
            "mainDescription": {
                "first": "de toutes vos données personnelles et de vos ordinateurs",
                "second": "contre le piratage sur internet"
            },
            "mainButton": "À partir de 2,50$ par mois*",
            "mainButtonDescription": "*Satisfait ou remboursé",
            "secureTitle": {
                "first": "Pour naviguer",
                "second": "en toute sécurité"
            },
            "secureDescription": "VPN99 élimine toutes les informations qui permettent de vous identifier sur internet. Il masque votre adresse IP et encode vos données afin de garantir la confidentialité de votre historique de navigation.",
            "secureCard": {
                "noRestrictionsTitle": "Aucun site interdit !",
                "noRestrictionsDescription": "Tous les sites internet vous sont accessibles, même ceux qui sont bloqués. De plus, toutes vos données sont efficacement protégées contre les tentatives de piratage.",
                "anonimTitle": "Vous êtes désormais anonyme !",
                "anonimDescription": "Votre anonymat est garanti à chaque fois que vous vous connectez ! Nous ne conservons pas votre historique de navigation, donc même nous ne pouvons pas savoir ce que vous faites sur internet ;)"
            },
            "devicesTitle": {
                "first": "Un seul abonnement",
                "second": "utilisable sur tous vos appareils !"
            },
            "devicesDescription": "MacOS, Windows, Android, iOS, les extensions de Chrome et Firefox, et bien plus encore",
            "serversTitle": {
                "first": "Une connexion rapide et stable",
                "second": "partout dans le monde !"
            },
            "serversDescription": "Sélectionnez un des 17 serveurs disponibles ou optez pour le serveur recommandé - il s'agit du serveur le plus proche de chez vous et par conséquent du plus rapide. Vous pourrez alors tout à fait regarder des films et des séries télé en HD.",
            "faqTitle": "FAQ",
            "faq": {
                "whatIsVPNTitle": "Que signifie le terme VPN ?",
                "whatIsVPNDescription": {
                    "p1": "VPN est l'acronyme de Virtual Private Network. Soit Réseau Privé Virtuel en français.",
                    "p2": "Le but principal du service que nous proposons est de garantir votre sécurité et la confidentialité de vos données. Afin d'y parvenir, {productName} encode les données que vous transmettez sur le réseau."
                },
                "rollUp": "Retour vers le haut",
                "more": "Lire",
                "howWorksTitle": "Comment fonctionne un VPN ?",
                "howWorksDescription": {
                    "p1": "Un VPN ajoute une nouvelle interface réseau à votre système exactement comme si vous vous connectiez directement à un réseau privé. Il modifie aussi votre adresse IP et encode la totalité des données que vous transmettez sur internet. Un VPN permet de recevoir et de transmettre des informations disponibles sur des réseaux publics (comme internet, par exemple) depuis un réseau privé et, se faisant, il applique un protocole de sécurité adapté à ce type de réseau tout en vous permettant d'accéder à l'ensemble des ressources disponibles à partir de ce réseau privé."
                },
                "whatDiferenceWithProxyTitle": "Quelle est la différence entre un proxy et un VPN ?",
                "whatDiferenceWithProxyDescription": {
                    "p1": "Une connexion VPN est bien plus stable et mieux encodée que celle d'un proxy. Par ailleurs, de nombreux programmes ne fonctionnent pas avec un proxy et certains doivent être configurés manuellement. En utilisant un VPN, vous n'avez pas besoin de configurer vos applications individuellement."
                },
                "whatCountriesTitle": "Dans quels pays sont localisés les serveurs ?",
                "whatCountries": {
                    "first": "La liste actuelle des pays dans lesquels sont localisés les serveurs est disponible",
                    "link": "ici",
                    "second": "Vous pouvez donc obtenir une adresse IP qui va vous géolocaliser dans l'un de ces pays lorsque vous vous connectez."
                },
                "logsTitle": "Est-ce que vous conservez l'historique de navigation ?",
                "logs": {
                    "p1": "La politique de {productName} est de ne jamais conserver votre historique de navigation"
                }
            },
            "footerTitle": {
                "first": "Commencez",
                "second": "à utiliser VPN99"
            },
            "footerDescription": "Télécharger l'application sur votre appareil"
        },
        "my_ip_address": "Mon adresse IP",
        "your_ip": "Voici votre adresse IP",
        "fail-payment_title": "Oups! Nous avons rencontré une erreur.",
        "fail-payment_description": "Le paiement n'a pas pu être effectué. Peut-être avez-vous commis une erreur au moment d'entrer les informations relatives au moyen de paiement. Ne vous inquiétez pas, vous pouvez toujours réessayer.",
        "fail-payment_go-to-prices": "Rendez-vous à la page de sélection du forfait et essayez à nouveau",
        "success_congratulations-title": "Félicitations ! Votre achat a été validé !",
        "success_congratulations-description": "Veuillez patienter. Vous allez être redirigé vers la page de votre compte personnel d'où vous pourrez gérer vos achats.",
        "success-payment_go-to-dashboard": "Se connecter maintenant",
        "servers_title": "Accédez à plus de 10 pays en un seul clic",
        "servers_title_description": "Choisissez un serveur VPN sur {continents_number} continents dans un des {servers_number} pays",
        "servers_locations_list_title": "Liste de la localisation des serveurs VPN",
        "servers_continent_all": "Tous les pays ",
        "allservers": "Pays",
        "continent_asia": "Asie",
        "continent_europe": "Europe ",
        "continent_america": "Amérique",
        "our_vpn_server": "Nos serveurs VPN",
        "subscription_canceled_failure": "Oups ! Une erreur est survenue au moment de l'annulation. Veuillez contacter le service client.",
        "prices_trial-days": "+ {count} jours d'essai",
        "days": "{count} jours",
        "prices_trial": "Période d'essai",
        "pageForbidden": "Accès interdit",
        "pageForbiddenTitle": "Accès interdit",
        "pageForbiddenDescription": "Il semblerait que l'accès au contenu auquel vous tentez d'accéder est restreint.",
        "pageForbiddenAction": "Revenir sur le site internet {productName}",
        "private_finally": "Surfez en ligne toute confidentialité",
        "no_one_can_see": "Personne ne peut voir ce que vous faites",
        "no_restrictions_on_content_and_censorship": "Aucune restriction ou censure en ligne",
        "one_click_and_you_defend": "Un clic, et vous êtes protégé",
        "removes_everything": "{productName} supprime tout ce qui permet de vous identifier en ligne en masquant votre adresse IP et en cryptant vos données afin que votre historique de navigation soit confidentiel.",
        "enjoy_fast_and_stable_connection": "Profitez d'une connexion rapide et stable partout dans le monde",
        "choose_your_server": "Sélectionnez votre serveur dans un des {count} pays disponibles et profitez du service VPN le plus rapide au monde. Avec autant de serveurs, les chargements sont plus rapides et grâce à notre large éventail de pays disponibles, vous avez plus de chance de trouver un serveur à proximité de chez vous",
        "easy_to_use_apps": "Des applications faciles d'utilisation pour tous vos appareils",
        "subscription_comes": "Un même abonnement à {productName} pour tous vos appareils : {appList} et plus encore",
        "money_back_guarantee": "Satisfait ou remboursé",
        "faq_footer_description": "Vous trouverez plus d'informations susceptibles de vous intéresser dans notre",
        "faq_footer_knowledge-base": "Base de données",
        "tryFree": "Essayez-le gratuitement",
        "year": "année",
        "icon_up": "Haut",
        "status_failed": "Échec",
        "status_refund": "Remboursement",
        "status_trial": "Période d'essai",
        "onHoldMessage": "Une erreur est survenue et la page d'abonnement à {productName} n'a pas pu être chargée automatiquement sur votre appareil {plateform}."
    },
    "css": "",
    "validator": {
        "is_empty": "Ce champ doit être vide",
        "is_not_empty": "Champ obligatoire",
        "is_email": "Veuillez vérifier que la saisie est correcte",
        "is_phone": "[Commentaire à thread] Votre version d’Excel vous permet de lire ce commentaire à thread. Toutefois, les modifications qui y sont apportées seront supprimées si le fichier est ouvert dans une version plus récente d’Excel. En savoir plus : https://go.microsoft.com/fwlink/?linkid=870924 Commentaire : Depends of the context. Is this for a captcha or something like that ? Entrez le bon numéro",
        "is_password": "Le mot de passe ne peut pas contenir de {extraSymbols}",
        "is_password_has_enough_symbols": "Le mot de passe doit contenir au moins 6 caractères",
        "is_password_less_max_symbols": "Le mot de passe doit contenir moins de 32 caractères",
        "is_password_has_space": "Le mot de passe ne peut pas contenir d'espace",
        "is_not_empty_with_field_name": "Le champ {fieldName} ne peut être vide",
        "is_password_has_not_english_letters": "Les lettres non latines ne sont pas autorisées"
    },
    "passwords-not-equal": "Les mots de passe ne correspondent pas entre eux",
    "error": {
        "e500": {
            "new": {
                "text": "Nous avons déjà connaissance de ce problème et nous allons y remédier très bientôt ! Veuillez réessayer ultérieurement.",
                "title": "Erreur 500 - Serveur"
            }
        },
        "eUnknown": {
            "new": {
                "text": "Nous avons déjà connaissance de ce problème et nous allons y remédier très bientôt ! Veuillez réessayer ultérieurement.",
                "title": "Erreur 500 - Serveur"
            },
            "oops": {
                "text": "Oups ! Une erreur est survenue lors du chargement des données d'utilisateur. Veuillez essayer de recharger la page."
            }
        },
        "e400": {
            "new": {
                "title": "Erreur"
            }
        }
    },
    "whitelabel": {
        "vpn99": {
            "web_be_safe": "Votre sécurité en ligne au meilleur prix",
            "web_online_security": "À partir de seulement ${pricePerMonth} par mois"
        },
        "trustvpn": {
            "web_be_safe": "Protégez la confidentialité de vos données en ligne avec Trust VPN",
            "web_online_security": "Vitesse ultra-rapide, Protégez votre vie privée, Liberté numérique"
        }
    },
    "meta": {
        "descriptionDataRetentionPolicy": "Le contexte et la durée pendant laquelle vos données seront conservées. Toutes vos données sont stockées de façon sécurisée en accord avec les directives du RGPD.",
        "descriptionMyIp": "Trouvez votre adresse IP, l'adresse de votre ordinateur en ligne. Voyez à quoi ressemble votre adresse IP",
        "descriptionPrivacyPolicy": "Notre Politique de confidentialité contient des informations relatives au type de données que nous collectons, à la façon dont nous les utilisons ainsi qu'à la façon dont vous pouvez contrôler vos informations. Conformément aux lois en vigueur au Pays de Galles, nous utilisons des cookies, mais vous avez la possibilité de modifier vos préférences les concernant.",
        "descriptionTerms": "En acceptant les Conditions d'utilisations de {productName}, vous acceptez les conditions générales ainsi que les responsabilités inhérentes aux deux parties qui sont exposées dans cet accord. Des compensations sont envisageables sous certaines conditions et lorsque les services VPN demeurent indisponibles ou défaillants pendant plus de 12 heures d'affilée.",
        "descriptionAccountBilling": "Compte - Historique des paiements",
        "descriptionAccountPanel": "Compte - Tableau de bord",
        "descriptionAccountPassword": "Compte - Modifier le mot de passe",
        "descriptionDownload": "Télécharger",
        "descriptionForgot": "Récupérer le mot de passe",
        "descriptionReset": "Réinitialiser le mot de passe",
        "descriptionHome": "Téléchargez et installez {productName} pour bénéficier d'une protection complète partout dans le monde. Avec {productName}, naviguez rapidement et en toute confidentialité.",
        "descriptionPayFail": "Le paiement a été refusé",
        "descriptionPaySuccess": "Le paiement a été effectué avec succès",
        "descriptionPrice": "Achetez et installez {productName} pour bénéficier d'une protection complète partout dans le monde. Avec {productName}, naviguez rapidement et en toute confidentialité.",
        "descriptionServers": "Serveurs",
        "titleDataRetentionPolicy": "Politique de rétention des données de {productName}",
        "titleMyIp": "Découvrez quelle est votre adresse IP en ligne",
        "titlePrivacyPolicy": "La Politique de Confidentialité : Les engagements que {productName} prend pour protéger vos informations confidentielles",
        "titleTerms": "Conditions d'utilisations : les conditions spécifiques qui s'appliquent lorsque vous profitez des services fournis par {productName}.",
        "titleAccountBilling": "Compte - Historique des paiements",
        "titleAccountPanel": "Compte - Tableau de bord",
        "titleAccountPassword": "Compte - Modifier le mot de passe",
        "titleDownload": "Télécharger l'application sur votre appareil",
        "titleForgot": "Récupérer le mot de passe",
        "titleReset": "Réinitialiser le mot de passe",
        "titleHome": "Téléchargez {productName} dès aujourd'hui pour bénéficier d'une protection sûre à un prix abordable.",
        "titleLogin": "Se connecter",
        "titlePayFail": "Le paiement a été refusé",
        "titlePaySuccess": "Le paiement a été effectué avec succès",
        "titlePrice": "Obtenez {productName} dès aujourd'hui pour bénéficier d'une protection sûre à un prix abordable.",
        "titleRegister": "S'inscrire",
        "titleServers": "Serveurs",
        "titleApps": "Applications",
        "descriptionApps": "Applications",
        "descriptionLogin": "Se connecter",
        "descriptionRegister": "S'inscrire",
        "titleReturnRefundPolicy": "Politique de remboursement",
        "descriptionReturnRefundPolicy": "Politique de remboursement",
        "titlePolicyAndProcedure": "Politique et procédures de notification et de retrait du DMCA",
        "descriptionPolicyAndProcedure": "Politique et procédures de notification et de retrait du DMCA"
    },
    "getVPN": "Obtenez {productName}",
    "signIn": "Connexion",
    "billings-page": {
        "waiting-processing": "En attente d'une réponse du traitement du paiement."
    },
    "servers": {
        "USDal": "Dallas, États-Unis"
    }
}
