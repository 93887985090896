export default class Promo {
  constructor(rest) {
    this.rest = rest
  }

  redeemPromoCode(promo) {
    return this.rest.post(
      '/user/promo',
      {
        promoCode: promo,
      },
      {
        isAuth: true,
      }
    )
  }
}
