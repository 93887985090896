import { createI18n } from 'vue-i18n'

/**
 * Load locale messages
 *
 * The loaded `JSON` locale messages is pre-compiled by `@intlify/vue-i18n-loader`, which is integrated into `vue-cli-plugin-i18n`.
 * See: https://github.com/intlify/vue-i18n-loader#rocket-i18n-resource-pre-compilation
 */

const localesArrayEnv = process.env.VUE_APP__LOCALES.split(',')

function loadLocaleMessages() {
  const locales = require.context('./lang', true, /[A-Za-z0-9-_,\s]+\.json$/i)
  const messages = {}
  locales.keys().forEach((key) => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i)
    if (matched && matched.length > 1) {
      const locale = matched[1]
      if (localesArrayEnv.includes(locale)) {
        messages[locale] = getContent(locales(key).default) || {}
      }
    }
  })
  return messages
}

function getContent(lang) {
  return getMessagesReduce(lang)
}

function getMessages(obj) {
  if (typeof obj === 'function') {
    // WARNING: Похоже на костыль - чтобы пустые строки были английским вариантом
    if (
      obj({
        normalize: (s) => s,
        interpolate: (s) => s,
        named: (s) => s,
        list: (s) => s,
        plural: (s) => s,
      }).length !== 0
    ) {
      return obj
    } else {
      return null
    }
  } else {
    return getMessagesReduce(obj)
  }
}

function getMessagesReduce(obj) {
  const resObj = Object.entries(obj).reduce((acc, val) => {
    const res = getMessages(val[1])
    if (res !== null) {
      acc[val[0]] = res
    }
    return acc
  }, {})
  if (Object.values(resObj).length === 0) {
    return null
  }
  return resObj
}

export default createI18n({
  locale: process.env.VUE_APP__I18N_FALLBACK_LOCALE || 'en',
  fallbackLocale: process.env.VUE_APP__I18N_FALLBACK_LOCALE || 'en',
  messages: loadLocaleMessages(),
})
