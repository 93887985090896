const periodMap = {
  day: 0,
  week: 1,
  month: 2,
  year: 3,
}

const getPreparedTarrifs = (tariffs) => {
  const _tariffs = []
  Object.getOwnPropertyNames(tariffs).forEach((prop) => {
    _tariffs[prop] = tariffs[prop]
  })
  return _tariffs
}

const _sortBy = {
  period(tariffs, desc = false) {
    return getPreparedTarrifs(tariffs).sort((tariffA, tariffB) => {
      const periodCondition =
        (periodMap[tariffB.period] - periodMap[tariffA.period]) *
        (desc ? 1 : -1)
      if (periodCondition === 0) {
        return (
          (tariffB.numberOfPeriods - tariffA.numberOfPeriods) * (desc ? 1 : -1)
        )
      }
      return periodCondition
    })
  },
}

export const sortBy = (tariffs) => ({
  period() {
    const res = _sortBy.period(tariffs)
    Object.defineProperty(res, 'desc', {
      value: () => {
        let _res = res
        if (!this.reversed) {
          _res.reverse()
        }
        this.reversed = true
        return _res
      },
      enumerable: false,
      writable: true,
    })
    Object.defineProperty(res, 'asc', {
      value: () => {
        let _res = res
        if (this.reversed) {
          _res.reverse()
        }
        this.reversed = false
        return _res
      },
      enumerable: false,
      writable: true,
    })
    return res
  },
})
