export default class User {
  constructor(rest) {
    this.rest = rest
  }

  fetchUser() {
    return this.rest.get('/user', {}, { isAuth: true })
  }

  fetchRealIP() {
    return this.rest.get('/ip')
  }

  cancelSubscription(order_id) {
    return this.rest.post(
      '/user/subscription/cancel',
      {
        order_id,
      },
      { isAuth: true }
    )
  }

  fetchIP() {
    return this.rest.get('https://api.ipify.org', {}, { isThirdParty: true })
  }

  fetchCountryCode({ ip }) {
    return this.rest.get(
      `https://ipapi.co/${ip}/country_code`,
      {},
      { isThirdParty: true }
    )
  }
}
