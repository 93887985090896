<template>
  <div>
    <vue-final-modal
      :name="name"
      :lock-scroll="true"
      :click-to-close="false"
      :esc-to-close="false"
      :max-width="1000"
      v-model="showModal"
      classes="c-modal-container"
      content-class="c-modal-content"
    >
      <button class="c-modal__close" @click="showModal = false">
        <div>+</div>
      </button>
      <div class="c-modal__title title title-md text-bold color-main-second">
        Console
      </div>
      <div class="c-modal__content">
        <div v-for="(log, index) in cConsoleEverything" :key="index">
          <p
            v-if="log.value"
            @click="() => onLogClick(index)"
            class="log"
            :class="{
              'log--error': log.type === 'error',
              'log--debug': log.type === 'debug',
              'log--warn': log.type === 'warn',
              'log--log': log.type === 'log',
            }"
          >
            {{ log.value }}
          </p>
        </div>
      </div>
      <div class="c-modal__action">
        <c-button
          @submit="buttonSudmited"
          value="Ok"
          size="md"
          transparent
          noBorder
        ></c-button>
      </div>
    </vue-final-modal>
  </div>
</template>

<script>
import CButton from '@/components/Button'
import { EVENTS } from '@/CONSTANTS'
import eventBus from '@/utils/EventBus'

const MAX_LOG_SIZE = 80

export default {
  name: 'ConsoleModal',

  components: {
    CButton,
  },

  data() {
    return {
      showModal: false,
      consoleEverything: [],
      consoleEverythingOpenedIndexes: [],
    }
  },
  computed: {
    cConsoleEverything: {
      get() {
        return this.consoleEverything.map((log, index) => {
          const value = log.value.reduce((acc, logValue) => {
            let res = ''
            if (['string'].includes(typeof logValue)) {
              res = logValue
            } else if (['boolean', 'number'].includes(typeof logValue)) {
              res = logValue
            } else if (logValue === null) {
              res = 'null'
            } else if (logValue === undefined) {
              res = 'undefined'
            } else {
              try {
                res = this.consoleEverythingOpenedIndexes.includes(index)
                  ? JSON.stringify(logValue, null, 2)
                  : JSON.stringify(logValue)
              } catch {
                try {
                  res = this.consoleEverythingOpenedIndexes.includes(index)
                    ? JSON.stringify(Array.from(logValue), null, 2)
                    : JSON.stringify(Array.from(logValue))
                } catch {
                  res = ''
                }
              }
            }
            if (this.consoleEverythingOpenedIndexes.includes(index)) {
              acc += index === 0 ? res : `\n${res}`
            } else {
              acc += index === 0 ? res : ` ${res}`
            }
            return acc
          }, '')
          return {
            ...log,
            value: this.consoleEverythingOpenedIndexes.includes(index)
              ? value
              : value.slice(0, MAX_LOG_SIZE) +
                (value.length > MAX_LOG_SIZE ? '...' : ''),
          }
        })
      },
    },
  },

  props: {
    name: {
      type: String,
      default: 'error-modal',
    },
    modelValue: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: 'default',
    },
    title: {
      type: String,
      default: 'Error',
    },
    description: {
      type: [String, null],
      default: null,
    },
    buttons: {
      type: Array,
      defult: () => [],
    },
  },

  mounted() {
    eventBus.$on(EVENTS.APP.SHAKE, () => {
      this.consoleEverything = console.everything || []
      if (!this.showModal) {
        this.consoleEverythingOpenedIndexes = []
        this.showModal = true
      }
    })
  },
  beforeUnmount() {
    eventBus.$off(EVENTS.APP.SHAKE)
  },

  methods: {
    onLogClick(index) {
      const i = this.consoleEverythingOpenedIndexes.indexOf(index)
      if (~i) {
        this.consoleEverythingOpenedIndexes.splice(i, 1)
        return
      }
      this.consoleEverythingOpenedIndexes.push(index)
    },
    buttonSudmited() {
      this.showModal = false
    },
  },
}
</script>

<style lang="scss">
@import '@/assets/scss/global/variables';
.c-modal-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.c-modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  max-height: 96%;
  max-width: 500px;
  min-width: 400px;
  width: fit-content;
  margin: 0;
  border: none;
  border-radius: 0.25rem;
  background: #fff;
  overflow: hidden;
  p:not(:last-child) {
    margin-bottom: 0.5rem;
  }
  padding: 2rem 1rem 0 1rem !important;
  @media (max-width: 767.98px) {
    width: 98%;
    min-width: 0;
  }
}
.c-modal__title {
  body[dir='rtl'] & {
    margin: 0 0 1rem 2rem;
  }
  body[dir='ltr'] & {
    margin: 0 2rem 1rem 0;
  }
}
.c-modal__content {
  flex-grow: 1;
  max-height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
}
.c-modal__action {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-top: 1px solid #d1d1d1;
  body[dir='rtl'] & {
    margin: 2rem -2rem 0 -3rem;
  }
  body[dir='ltr'] & {
    margin: 2rem -3rem 0 -2rem;
  }
  > * {
    flex-grow: 1;
  }
}
.c-modal__close {
  position: absolute;
  top: 0;
  padding: 0.5rem;
  line-height: 1.4rem;
  font-size: 2rem;
  transition: color $transTimeFast $easeDefault;
  body[dir='rtl'] & {
    left: 0;
  }
  body[dir='ltr'] & {
    right: 0;
  }
  & > div {
    transform: rotate(45deg);
    width: 1.4rem;
    height: 1.4rem;
  }
  &:hover {
    color: var(--main);
  }
}

.log {
  font-size: 10px;
  padding: 6px;
  margin-bottom: 4px;
  &--error {
    background-color: #ffbec8;
  }
  &--warn {
    background-color: #fff9be;
  }
  &--log {
    background-color: #fafafa;
  }
  &--debug {
    background-color: #bedbff;
  }
}
</style>
