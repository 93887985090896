<template>
  <div class="mmenu" :class="{ 'mmenu--opened': opened }">
    <div class="mmenu__content">
      <div v-if="token">
        <div v-if="$store.state.user.email" class="mmenu__item text-center">
          <span class="mmenu__email text text-sm mb-2">
            {{ $store.state.user.email }}
          </span>
        </div>
        <div v-if="$store.state.user.isPremium">
          <div class="mmenu__item text-center">
            <span class="text-sm"
              >{{
                $store.getters['user/payedUntil']
                  ? $t('web.active_until') +
                    ' ' +
                    $moment($store.getters['user/payedUntil']).format('LL')
                  : $t('web.active')
              }}
            </span>
          </div>
        </div>
        <div v-else>
          <div class="mmenu__item text-center">
            <span class="text-sm mb-2">{{ $t('web.inactive') }}</span>
          </div>
        </div>
        <div
          v-if="
            !$store.state.user.hasSubscription &&
            !$store.getters['user/onHoldOrder']
          "
        >
          <div class="mmenu__item text-center">
            <c-button
              @submit="goToPrices"
              :value="
                $t('getVPN', {
                  productName: PRODUCT_NAME,
                })
              "
            ></c-button>
          </div>
        </div>

        <div class="mmenu__divider my-2"></div>

        <nav class="mmenu__nav">
          <div class="mmenu__item">
            <c-link
              bold
              @click="close"
              color="strict-white"
              class="mmenu__nav-link text text-xl"
              :to="{ name: $ROUTER.NAME.ACCOUNT.PANEL }"
              :label="$t('web.dashboard')"
            />
          </div>
          <div class="mmenu__item">
            <c-link
              bold
              @click="close"
              color="strict-white"
              class="mmenu__nav-link text text-xl"
              :to="{ name: $ROUTER.NAME.ACCOUNT.DOWNLOADS }"
              :label="$t('web.downloads')"
            />
          </div>
          <div class="mmenu__item">
            <c-link
              bold
              @click="close"
              color="strict-white"
              class="mmenu__nav-link text text-xl"
              :to="{ name: $ROUTER.NAME.ACCOUNT.BILLING }"
              :label="$t('web.billings')"
            />
          </div>
        </nav>

        <div class="mmenu__divider mt-2 mb-3"></div>

        <div class="mmenu__item">
          <c-link
            bold
            color="strict-white"
            @click="close"
            class="mmenu__nav-link text text-sm"
            :to="{ name: $ROUTER.NAME.PROMO_CODE }"
            :label="$t('web.activate_promo')"
          />
        </div>
        <div class="mmenu__item">
          <c-link
            bold
            color="strict-white"
            @click="close"
            class="mmenu__nav-link text text-sm"
            :to="{ name: $ROUTER.NAME.ACCOUNT.CHANGE_PASSWORD }"
            :label="$t('web.change_password')"
          />
        </div>
        <div class="mmenu__item">
          <c-link
            bold
            color="strict-white"
            class="mmenu__nav-link text text-sm"
            @click="logout"
            :label="$t('web.log_out')"
          />
        </div>

      </div>
      <div v-else>
        <nav class="mmenu__nav">
          <div class="mmenu__item">
            <c-link
              bold
              @click="close"
              color="strict-white"
              class="mmenu__nav-link text text-xl"
              :to="{ name: $ROUTER.NAME.PRICES }"
              :label="$t('web.pricing')"
            />
          </div>
          <div class="mmenu__item">
            <c-link
              bold
              @click="close"
              color="strict-white"
              class="mmenu__nav-link text text-xl"
              :to="{ name: $ROUTER.NAME.APPS }"
              :label="$t('web.apps')"
            />
          </div>
          <div class="mmenu__item">
            <c-link
              bold
              @click="close"
              color="strict-white"
              class="mmenu__nav-link text text-xl"
              :to="{ name: $ROUTER.NAME.SERVERS }"
              :label="$t('web.servers')"
            />
          </div>
        </nav>

        <div class="mmenu__action">
          <div class="mmenu__item">
            <link-button
              :to="{ name: $ROUTER.NAME.PRICES }"
              size="lg"
              expand
              @click="close"
              :value="
                $t('getVPN', {
                  productName: PRODUCT_NAME,
                })
              "
            />
          </div>
          <div class="mmenu__item">
            <link-button
              size="lg"
              color="black"
              expand
              @click="close"
              :to="{ name: $ROUTER.NAME.AUTH.LOGIN }"
              :value="$t('web.sign_in')"
            />
          </div>
        </div>
      </div>
      <div class="text text-md mb-2 mt-3 text-center">
        {{ SUPPORT_EMAIL }}
      </div>
    </div>
  </div>
</template>

<script>
import CButton from '@/components/Button'
import CLink from '@/components/Link'
import LinkButton from '@/components/LinkButton'
export default {
  name: 'Menu',
  components: {
    CButton,
    LinkButton,
    CLink,
  },
  props: {
    opened: {
      default: false,
      type: Boolean,
    },
  },
  computed: {
    token: {
      get() {
        return this.$store.getters['user/token']
      },
    },
    isDesktop: {
      get() {
        return this.$store.getters['app/isDesktopSize']
      },
    },
    PRODUCT_NAME: {
      get() {
        return process.env.VUE_APP__PRODUCT_NAME
      },
    },
    SUPPORT_EMAIL: {
      get() {
        return process.env.VUE_APP__SUPPORT_EMAIL
      },
    },
  },
  watch: {
    opened(value) {
      this.blurBackground(value)
      document.body.style.overflow = value ? 'hidden' : 'auto'
    },
    isDesktop(value) {
      if (value) {
        this.close()
      }
    },
  },
  methods: {
    goToPrices() {
      this.$router.push({ name: this.$ROUTER.NAME.PRICES })
      this.close()
    },
    logout() {
      this.close()
      setTimeout(() => {
        this.$store.dispatch('user/reset')
        this.$router.push({ name: this.$ROUTER.NAME.HOME })
      }, 200)
    },
    blurBackground(value) {
      if (value) {
        document.getElementsByClassName('page')[0].style.filter = 'blur(6px)'
        document.getElementsByClassName('footer')[0].style.filter = 'blur(6px)'
      } else {
        document.getElementsByClassName('page')[0].style.filter = 'none'
        document.getElementsByClassName('footer')[0].style.filter = 'none'
      }
    },
    close() {
      this.$emit('close')
    },
  },
}
</script>

<style lang="scss" scoped>
@import 'index.scss';
</style>
