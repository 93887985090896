{
    "web": {
        "why_use": "{productName} का उपयोग क्यों करें",
        "download_VPN": "{productName} डाउनलोड करें और आज ही उपयोग शुरू करें!",
        "getVPN": "{productName} पाएँ ",
        "access": "किसी भी कंटैंट तक पहुँच दुनियाभर में कहीं से भी",
        "jurisdiction": "EU का अधिकार क्षेत्र गोपनीयता की गारंटी देता है",
        "anonymity": "सम्पूर्ण अनामिता एवं लॉग्स की अनुपस्थिति ",
        "simple_conf": "आसान सेटअप एवं उपयोग",
        "secure": " सुरक्षित भुगतान एवं हैकरों से सुरक्षा",
        "protection": "हस्तक्षेप करने वाली एडवरटाइज़िंग से सुरक्षा",
        "get_VPN_app_phone": "अपने डिवाइस के लिए {productName} प्राप्त करें",
        "keep_safe": "ऑनलाइन होने पर सुरक्षित एवं गोपनीय रहें",
        "different_device": "किसी अन्य डिवाइस के लिए {productName} प्राप्त करें ",
        "authorize": "प्राधिकार",
        "dont_have_an_account": "खाता नहीं है?",
        "register": "साइन अप",
        "pass": "पासवर्ड",
        "sign_in": "साइन इन",
        "forgot_pass": "अपना पासवर्ड भूल गए?",
        "email": "ईमेल",
        "instructions_for_macos": "MacOS के लिए निर्देश",
        "instructions_for_windows": "Windows के लिए निर्देश",
        "current-plan": "वर्तमान प्लान",
        "date": "दिनांक",
        "price": "मूल्य",
        "price_from": "मूल्य शुरू होता है",
        "next_due": "अगला देय तिथि",
        "payment": "भुगतान",
        "cancel": "रद्द करें",
        "go_to_settings": "सेटिंग्स में जाएं",
        "ok": "Ok",
        "dialog_cancel-subscription_title-AppStore": "आप Apple स्टोर अकाउंट के माध्यम से सदस्यता को रद्द कर सकते हैं जहां सदस्यता ली गई थी",
        "dialog_cancel-subscription_title-PlayMarket": "आप Play Market अकाउंट के माध्यम से सदस्यता को रद्द कर सकते हैं जहां सदस्यता ली गई थी",
        "dialog_cancel-subscription_title-sure": "क्या आप वाकई सदस्यता रद्द करना चाहते हैं?",
        "yes": "हाँ",
        "no": "नहीं",
        "subscription_canceled_successfully": "सदस्यता सफलतापूर्वक रद्द कर दी गई",
        "dashboard_dialog_thanks-for-registration_title": "पंजीकरण के लिए धन्यवाद!",
        "dashboard_dialog_thanks-for-registration_description_1": "कृपया लॉगिन करने के लिए अपना पासवर्ड याद रखें।",
        "Password": "पासवर्ड",
        "dashboard_dialog_thanks-for-registration_description_2-1": "या",
        "dashboard_dialog_thanks-for-registration_description_2-2": "यहां क्लिक करें",
        "dashboard_dialog_thanks-for-registration_description_2-3": "अपना खुद का पासवर्ड सेट करने के लिए।",
        "prices_date-of-next-payment": "पैसे डेबिट होने की अगली तिथि",
        "change_password": "पासवर्ड बदलें",
        "current_password": "वर्तमान पासवर्ड",
        "new_password": "नया पासवर्ड",
        "confirm-password": "पासवर्ड की पुष्टि करें",
        "reset": "रीसेट",
        "email_address_associated": "अपने खाते से सम्बद्ध ईमेल एड्रैस दर्ज करें। हम आपका पासवर्ड रीसेट करने के लिए एक लिंक ईमेल करेंगे।",
        "get-link": "ईमेल भेजें",
        "sign-in": "साइन इन",
        "forget-password_dialog_email-link_title": "हमने आपके पासवर्ड को रीसेट करने हेतु एक लिंक के साथ ईमेल भेजा है।",
        "i-have-promocode": "मेरे पास प्रोमो कोड हैं",
        "promo": "प्रोमो कोड",
        "have_an_account": "मेरे पास अकाउंट है",
        "reset-password": "पासवर्ड रीसेट करें",
        "state": {
            "register": "अनसब्सक्राइब"
        },
        "action": {
            "register": "साइन अप",
            "cancel": "रद्द करें"
        },
        "notify_password-changed": "पासवर्ड बदला जा चुका है",
        "prices_discount": "छूट",
        "prices_email": "ईमेल",
        "prices_country": "आपका देश",
        "prices_pay": "भुगतान करें",
        "prices_privacy_p-0": "इस फॉर्म को जमा करने से आप हमारी",
        "prices_privacy_p-1": "सेवा की शर्तें",
        "prices_privacy_p-2": "और",
        "prices_privacy_p-3": "गोपनीयता नीति",
        "price_card": "कार्ड",
        "price_internet-wallet": "इंटरनेट वॉलेट",
        "price_cryptocurrency": "क्रिप्टोकरेंसी",
        "prices_order-total": "ऑर्डर का योग",
        "i-dont-have-promocode": "मेरे पास प्रोमो कोड नहीं है",
        "dashboard_dialog_thanks-for-registration_description_1_promo": "आपके द्वारा दर्ज किया गया प्रोमो कोड मान्य नहीं है",
        "prices_user-has-account_how-to-change-plan_1": "टैरिफ प्लान को बदलने के लिए,",
        "prices_user-has-account_how-to-change-plan_2": "बिल्लिंग्स पेज पर जाकर वर्तमान सदस्यता रद्द करें",
        "prices_user-has-account_how-to-change-plan_3": "और फिर एक नया खरीदें",
        "instructions_for_linux": "Linux के लिए निर्देश",
        "prices_Step": "चरण",
        "tariff-name_Year": "वर्ष",
        "tariff-name_Month": "माह",
        "month": "महीना",
        "prices_save": "सेव करें ",
        "prices_billedEvery": "बिल हर",
        "prices_the-most-popular": "सबसे लोकप्रिय",
        "prices_Billed": "बिल",
        "prices_every": "प्रत्येक",
        "icon_check-circle-empty": "रिक्त सर्कल",
        "icon_check-circle": "चेकबॉक्स",
        "icon_like": "लाइक",
        "icon_info": "इन्फो",
        "icon_down": "नीचे",
        "icon_check": "चेकबॉक्स",
        "prices_the-1-year-is-better": "1 वर्षों के प्लान का मतलब है आपके लिए सबसे बड़ी बचत",
        "prices_choose-plan": "अपना प्लान चुनें",
        "prices_Chose-Payment-Method": "भुगतान का माध्यम चुनें",
        "prices_Why-choose-VPN": "{productName} को क्यों चुनें?",
        "prices_VPN-includes": "{productName} में शामिल है ",
        "prices_includes-support": "24/7 ग्राहक सहायता",
        "prices_includes-money-back": "{numberOfDays}-दिन की पैसे वापस करने की गारंटी",
        "prices_includes-security": "बैंक स्तरीय सुरक्षा एवं एंक्रिप्शन",
        "prices_includes-no-logs": "कोई गतिविधि या कनैक्शन लॉग नहीं",
        "instruction": "निर्देश",
        "apps": "एप्स",
        "my-ip": "मेरा IP",
        "terms": "उपयोग की शर्तें",
        "return-refund-policy": "वापसी एवं रिफ़ंड नीति ",
        "policy_and_procedure": "DMCA नोटिस एवं टेकडाउन नीति एवं प्रक्रियाएँ ",
        "privacy_policy": "गोपनीयता नीति से सहमत हूँ।",
        "footer_support": "सहायता",
        "footer_sales-department": "सेल्स विभाग",
        "footer_company": "कंपनी",
        "footer_contacts": "संपर्क",
        "data-retention-policy": "डेटा अवधारण नीति",
        "pricing": "दरें",
        "servers": "सर्वर",
        "knowledge-base": "ज्ञान आधार",
        "dashboard": "डैशबोर्ड",
        "to_home_page": "होम पेज पर",
        "icon_logo": "लोगो",
        "icon_login": "लॉगिन ",
        "icon_menu": "मेनू ",
        "icon_key": "की",
        "icon_logout": "लॉग आउट ",
        "log_out": "लॉग आउट",
        "icon_lifebuoy": "लाइफबॉय",
        "knowledge_base": "ज्ञान आधार",
        "icon_support": "सहायता",
        "downloads": "डाउनलोड्स",
        "billings": "बिलिंग",
        "icon_eyeOpen": "पासवर्ड दिखाएँ",
        "inactive": "असक्रिय। इसका उपयोग शुरू करने के लिए अपना भुगतान करें",
        "icon_flag": "झंडा",
        "active_until": "तक सक्रिय",
        "servers_cta_title": "आज ही सुरक्षित ब्राउज़िंग शुरू करें",
        "servers_cta_description": "अपने इंटरनेट को निजी एवं सुरक्षित रखें",
        "downloads_instruction": "निर्देश",
        "downloads_router": "राउटर",
        "downloads_openVPN_profiles": "OpenVPN प्रोफ़ाइलें",
        "tariff-name-long_Year": "एक वर्ष",
        "tariff-name-long_Month": "एक महीना",
        "dialog_cancel-subscription_header": "सब्स्क्रिप्शन रद्द करें",
        "web_payment_history": "भुगतान हिस्ट्री",
        "tariff": "दर सूची",
        "payment_method": "भुगतान माध्यम",
        "status": "स्टेटस",
        "sum": "कुल",
        "tbody_is_empty_1": "यहां आपको अपने भुगतानों की जानकारी दिखाई देगी",
        "tbody_is_empty_2": "आर्डर दें",
        "status_paid": "भुगतान किया गया",
        "status_not-paid": "नहीं चुकाया हुआ",
        "my_services": "मेरी सेवाएँ",
        "private_and_secure": "निजी एवं सुरक्षित इंटरनेट एक्सैस (VPN)",
        "active": "सक्रीय",
        "downloads_apps_and_files": "एप एवं फ़ाइलें",
        "downloads_for_mobile": "मोबाइल",
        "downloads_for_desktop": "डेस्कटॉप",
        "downloads_for_browsers": "ब्राउज़र",
        "downloads_other_devices": "अन्य डिवाइस",
        "forget-password_dialog_email-link_header": "अपने ईमेल की जाँच करें",
        "pageNotFoundTitle": "पेज नहीं मिला",
        "pageNotFoundDescription": "लगता है कि ऐसा कोई पेज मौजूद नहीं है। कृपया हमसे संपर्क करें।",
        "pageNotFoundAction": "{productName} वेबसाइट पर लौटें",
        "home": {
            "mainTitle": {
                "highlited": "आपकी\nडिजिटल",
                "rest": "सुरक्षा"
            },
            "mainDescription": {
                "first": "आपके सभी व्यक्तिगत डेटा और कंप्यूटर की",
                "second": "साइबर जालसाजों के खिलाफ"
            },
            "mainButton": "$2.50 प्रति माह से शुरू *",
            "mainButtonDescription": "*पैसे रिफंड की गारंटी",
            "secureTitle": {
                "first": "वाकई सुरक्षित",
                "second": "इंटरनेट"
            },
            "secureDescription": "VPN99 वह सब कुछ हटा देता है जो आपको इंटरनेट पर अनोखा और पहचान करने योग्य बनाता है। यह आपके IP एड्रेस को छुपाता है और आपके ब्राउज़िंग इतिहास को निजी रखने के लिए डेटा को एन्क्रिप्ट करता है।",
            "secureCard": {
                "noRestrictionsTitle": "कोई प्रतिबंध नहीं!",
                "noRestrictionsDescription": "सभी इंटरनेट साइटें आपके लिए उपलब्ध हैं, यहां तक कि अवरुद्ध साइट्स भी। इसके अलावा, आपका सारा डेटा धोखादड़ी करने वालों से मज़बूती से सुरक्षित है।",
                "anonimTitle": "अब आप गुमनाम हैं!",
                "anonimDescription": "हर एक कनेक्शन गुमनाम है! हम लॉग्स स्टोर नहीं करते हैं, इसलिए हमें यह भी पता नहीं है कि आप इंटरनेट पर क्या कर रहे हैं;)"
            },
            "devicesTitle": {
                "first": "एक सब्सक्रिप्शन",
                "second": "सभी उपकरणों के लिए!"
            },
            "devicesDescription": "MacOS, Windows, Android, iOS, Chrome और Firefox के एक्सटेंशन , और बहुत कुछ",
            "serversTitle": {
                "first": "तेज़ और स्थिर",
                "second": "कनेक्शन कहीं भी!"
            },
            "serversDescription": "17 सर्वरों में से एक चुनें या अनुशंसित सर्वर पर भरोसा करें - यह निकटतम है और इसलिए सबसे तेज़ है। आप HD क्वालिटी में फिल्में और टीवी शो फ्री में देख पाएंगे।",
            "faqTitle": "सामान्य प्रश्न",
            "faq": {
                "whatIsVPNTitle": "VPN का क्या मतलब है?",
                "whatIsVPNDescription": {
                    "p1": "VPN का मतलब वर्चुअल प्राइवेट नेटवर्क है।",
                    "p2": "हमारी सेवा का मुख्य उद्देश्य आपकी सुरक्षा और गोपनीयता सुनिश्चित करना है। ऐसा करने के लिए, {productName} आपके द्वारा नेटवर्क पर प्रसारित किए जाने वाले ट्रैफ़िक को एन्क्रिप्ट करता है।"
                },
                "rollUp": "ऊपर रोल करें",
                "more": "पढ़ें",
                "howWorksTitle": "एक VPN कैसे काम करता है?",
                "howWorksDescription": {
                    "p1": "VPN सिस्टम में एक नया नेटवर्क इंटरफेस जोड़ता है जो इस तरह से कार्य करता है जैसे कि आपने अपने डिवाइस को सीधे निजी नेटवर्क से कनेक्ट किया है। यह आपके IP एड्रेस को भी बदलता है और सभी प्रेषित डेटा को एन्क्रिप्ट करता है। एक VPN सार्वजनिक नेटवर्क (उदाहरण के लिए, इंटरनेट के माध्यम से) पर उसी तरह जानकारी प्राप्त करने और प्रसारित करने की अनुमति देता है जैसे कि आपका डिवाइस सीधे एक निजी नेटवर्क से जुड़ा हुआ हो ,समान सुरक्षा नीतियों को लागू करते हुए और निजी नेटवर्क पर सभी संसाधनों तक पहुंच प्रदान करने के लिए।"
                },
                "whatDiferenceWithProxyTitle": "प्रॉक्सी सर्वर और VPN सेवा में क्या अंतर है?",
                "whatDiferenceWithProxyDescription": {
                    "p1": "प्रॉक्सी के विपरीत, VPN-कनेक्शन बहुत अधिक स्थिर और एन्क्रिप्टेड होते हैं। सभी प्रोग्राम प्रॉक्सी सर्वर के साथ काम करने में सक्षम नहीं होते हैं और उनमें से कई को और कॉन्फ़िगरेशन की आवश्यकता होती है। यदि आप VPN का उपयोग कर रहे हैं, तो आपको प्रत्येक एप्लिकेशन को व्यक्तिगत रूप से कॉन्फ़िगर करने की आवश्यकता नहीं है।"
                },
                "whatCountriesTitle": "कौन से देश उपलब्ध हैं?",
                "whatCountries": {
                    "first": "विभिन्न देशों में लोकेशन की वर्तमान सूची पाई जा सकती है",
                    "link": "यहां",
                    "second": "इसलिए, जब आप कनेक्ट होते हैं तो आपको इन देशों के जिओ लोकेशन के साथ एक IP अड्रेस मिलता है।"
                },
                "logsTitle": "क्या आप लॉग फ़ाइल सहेजते हैं?",
                "logs": {
                    "p1": "{productName} की एक सख्त नो-लॉगिंग पॉलिसी है।"
                }
            },
            "footerTitle": {
                "first": "शुरू करें",
                "second": "VPN99 का उपयोग करना"
            },
            "footerDescription": "अपने डिवाइस पर ऐप डाउनलोड करें"
        },
        "my_ip_address": "मेरा IP एड्रैस",
        "your_ip": "आपका IP एड्रैस यह है",
        "fail-payment_title": "ओहो! कुछ गड़बड़ है।",
        "fail-payment_description": "आपके टैरिफ का भुगतान नहीं हुआ है। शायद भुगतान के दौरान डेटा दर्ज करते समय आपने गलती की। चिंता न करें, आप फिर से प्रयास कर सकते हैं।",
        "fail-payment_go-to-prices": "टैरिफ प्लान्स पर जाएं और फिर से प्रयास करें",
        "success_congratulations-title": "आपकी खरीद पर बधाई!",
        "success_congratulations-description": "कृपया प्रतीक्षा करें। आपको अपने व्यक्तिगत अकाउंट पर पुनर्निर्देशित किया जाएगा, जहाँ आप अपनी खरीद का प्रबंधन कर सकते हैं।",
        "success-payment_go-to-dashboard": "अभी साइन इन करें",
        "servers_title": "10+ देशों में वन क्लिक एक्सैस",
        "servers_title_description": "continents_number continents in one of {servers_number} countries में से किसी एक में एक VPN सर्वर चुनें",
        "servers_locations_list_title": "VPN सर्वर लोकेशनों की सूची",
        "servers_continent_all": "सभी देश",
        "allservers": "देश",
        "continent_asia": "एशिया",
        "continent_europe": "यूरोप",
        "continent_america": "अमरीका ",
        "our_vpn_server": "हमारे VPN सर्वर",
        "subscription_canceled_failure": "उफ़! रद्द करते समय एक त्रुटि हुई थी। कृपया समर्थन से संपर्क करें।",
        "prices_trial-days": "+ {count} दिनों का ट्रायल",
        "days": "{count} दिन",
        "prices_trial": "ट्रायल",
        "pageForbidden": "निषिद्ध पहुंच",
        "pageForbiddenTitle": "निषिद्ध पहुंच",
        "pageForbiddenDescription": "ऐसा लगता है कि जहां आपने पहुंच प्राप्त करने का प्रयास किया वह सीमित है।",
        "pageForbiddenAction": "{productName} वेबसाइट पर लौटें",
        "private_finally": "निजी ऑनलाइन सर्फिंग",
        "no_one_can_see": "आप क्या कर रहे हैं ये कोई भी नहीं देख सकता",
        "no_restrictions_on_content_and_censorship": "कोई सेंसरशिप या ऑनलाइन प्रतिबंध नहीं",
        "one_click_and_you_defend": "एक क्लिक, और आप सुरक्षित हैं",
        "removes_everything": "{productName} आपके IP एड्रेस को छिपाकर और आपके ब्राउज़िंग इतिहास को निजी रखने के लिए आपके डेटा को एन्क्रिप्ट करके, वह सब कुछ हटा देता है जो आपको ऑनलाइन अद्वितीय और पहचानने योग्य बनाता है।",
        "enjoy_fast_and_stable_connection": "कहीं भी एक तेज़ और स्थिर कनैक्शन का आनंद उठाएँ",
        "choose_your_server": "{count} देशों में अपना सर्वर चुनें और सबसे तेज़ VPN सेवा का आनंद उठाएँ। अधिक सर्वरों का मतलब है कम लोड, और लोकेशनों के बाहुल्य विकल्पों का मतलब है कि आपको आसपास की एक लोकेशन प्राप्त करने की अधिक संभावना है।",
        "easy_to_use_apps": "आपके सभी डिवाइसों के लिए यूजर फ़्रेंडली एप्स",
        "subscription_comes": "आपके स्वामित्व वाले प्रत्येक डिवाइस के लिए एक {productName} सब्सक्रिप्शन: {appsList} और बहुत कुछ",
        "money_back_guarantee": "मनी बैक गारंटी",
        "faq_footer_description": "आप अन्य जानकारी पा सकते हैं जिससे आपको रुचि आए हमारे",
        "faq_footer_knowledge-base": "नॉलेज बेस में",
        "tryFree": "फ्री में आज़माएँ",
        "year": "साल",
        "icon_up": "अप",
        "status_failed": "असफल",
        "status_refund": "रिफंड",
        "status_trial": "ट्रायल",
        "onHoldMessage": "कुछ गलत हो गया है और हम आपके {platform} उपकरण पर {productName} सब्सक्रिप्शन के लिए स्वचालित रूप से आपसे शुल्क लेने में असमर्थ रहे हैं।"
    },
    "css": "",
    "validator": {
        "is_empty": "फील्ड रिक्त होना चाहिए",
        "is_not_empty": "आवश्यक फील्ड",
        "is_email": "इनपुट सही है यह जाँच करें",
        "is_phone": "सही संख्या दर्ज करें",
        "is_password": "पासवर्ड में {extraSymbols} नहीं हो सकते",
        "is_password_has_enough_symbols": "पासवर्ड में कम से कम 6 अक्षर होने चाहिए",
        "is_password_less_max_symbols": "पासवर्ड में कम से कम 32 अक्षर होने चाहिए",
        "is_password_has_space": "पासवर्ड में स्पेस मार्क नहीं हो सकता है",
        "is_not_empty_with_field_name": "{fieldName} फ़ील्ड आवश्यक है",
        "is_password_has_not_english_letters": "गैर-लैटिन अक्षरों की अनुमति नहीं है"
    },
    "passwords-not-equal": "पासवर्ड बेमेल",
    "error": {
        "e500": {
            "new": {
                "text": "हम इस समस्या के बारे में पहले से ही जानते हैं और जल्द ही इसका समाधान करेंगे! बाद में पुन: प्रयास करें।",
                "title": "500 — सर्वर त्रुटि"
            }
        },
        "eUnknown": {
            "new": {
                "text": "हम इस समस्या के बारे में पहले से ही जानते हैं और जल्द ही इसका समाधान करेंगे! बाद में पुन: प्रयास करें।",
                "title": "500 — सर्वर त्रुटि"
            },
            "oops": {
                "text": "ओह! उपयोगकर्ता डेटा लोड करते समय एक त्रुटि हुई थी। कृपया पेज को पुनः लोड करने का प्रयास करें।"
            }
        },
        "e400": {
            "new": {
                "title": "त्रुटि"
            }
        }
    },
    "whitelabel": {
        "vpn99": {
            "web_be_safe": "ऑनलाइन सुरक्षा सर्वोत्तम क़ीमत पर ",
            "web_online_security": "केवल ${pricePerMonth} प्रति माह से शुरू"
        },
        "trustvpn": {
            "web_be_safe": "अपनी ऑनलाइन निजता को अभी सुरक्षित करें।",
            "web_online_security": "अत्यधिक तेज गति, अपनी निजता को सुरक्षित करें, डिजिटल आज़ादी "
        }
    },
    "meta": {
        "descriptionDataRetentionPolicy": "स्थिति और अवधि जब आपके डेटा को संभलकर रखा जाएगा । आपके सभी डेटा को GDPR दिशानिर्देशों के अनुसार सुरक्षित रूप से संग्रहीत किया गया है।",
        "descriptionMyIp": "अपने आईपी एड्रेस, अपने कंप्यूटर के एड्रेस का पता लगाएं ऑनलाइन। देखें आपका आईपी एड्रेस कैसा दिखता है",
        "descriptionPrivacyPolicy": "हमारी गोपनीयता नीति में यह जानकारी शामिल है कि हम किस डेटा को एकत्र करते हैं और हम उसका उपयोग कैसे करते हैं, साथ ही साथ आप अपनी जानकारी को कैसे नियंत्रित कर सकते हैं। हम वेल्श(Welsh) कानून के अनुसार कुकीज़ का उपयोग करते हैं, लेकिन आप अपनी प्राथमिकताओं को समायोजित कर सकते हैं।",
        "descriptionTerms": "की उपयोग की शर्तें स्वीकार करके, आप नियमों और शर्तों के साथ-साथ समझौते में उल्लिखित दोनों पक्षों की जिम्मेदारियों से सहमत हो रहे हैं। VPN सेवाएं अनुपलब्ध होने या लगातार 12 घंटों से अधिक समय तक खराब होने पर कुछ परिस्थितियों में मुआवजा उपलब्ध है।",
        "descriptionAccountBilling": "खाता - भुगतान इतिहास",
        "descriptionAccountPanel": "खाता - डैशबोर्ड",
        "descriptionAccountPassword": "खाता - पासवर्ड बदलें",
        "descriptionDownload": "डाउनलोड करें",
        "descriptionForgot": "पासवर्ड रिस्टोर करें",
        "descriptionReset": "पासवर्ड रिसेट करें",
        "descriptionHome": "{productName} को डाउनलोड और इंस्टॉल करें और आपको दुनिया में कहीं भी पूर्ण सुरक्षा मिलेगी। {productName}, तेज़ गति और पूर्ण गुमनामी।",
        "descriptionPayFail": "भुगतान अस्वीकृत",
        "descriptionPaySuccess": "भुगतान सफल रहा",
        "descriptionPrice": "{productName} खरीदें और इंस्टॉल करें और आपको दुनिया भर में पूर्ण सुरक्षा मिलेगी। एक महंगे VPN वाले सभी लाभ प्राप्त करें। {productName}, तेज़ गति और पूरी गुमनामी",
        "descriptionServers": "सर्वर",
        "titleDataRetentionPolicy": "डेटा अवधारण नीति",
        "titleMyIp": "ऑनलाइन पता लगाएं कि आपका आईपी एड्रेस क्या है ",
        "titlePrivacyPolicy": "गोपनीयता नीति: आपकी निजी जानकारी की सुरक्षा के लिए जो कदम {productName} लेते हैं",
        "titleTerms": "उपयोग की शर्तें: विशिष्ट शर्तें जो {productName} द्वारा प्रदान की गई सेवाओं का लाभ लेते समय लागू होती हैं।",
        "titleAccountBilling": "खाता - भुगतान इतिहास",
        "titleAccountPanel": "खाता - डैशबोर्ड",
        "titleAccountPassword": "खाता - पासवर्ड बदलें",
        "titleDownload": "डाउनलोड करें",
        "titleForgot": "पासवर्ड रिस्टोर करें",
        "titleReset": "पासवर्ड रिसेट करें",
        "titleHome": "{productName} को आज ही डाउनलोड करें और आपको मिलेगा किफायती मूल्य पर एक विश्वसनीय कवरेज।",
        "titleLogin": "साइन इन करें",
        "titlePayFail": "भुगतान अस्वीकृत",
        "titlePaySuccess": "भुगतान सफल रहा",
        "titlePrice": "{productName} प्राप्त करें और आपको मिलेगा किफायती मूल्य पर एक विश्वसनीय कवरेज।",
        "titleRegister": "साइन अप करें",
        "titleServers": "सर्वर",
        "titleApps": "ऍप्स",
        "descriptionApps": "ऍप्स",
        "descriptionLogin": "साइन इन",
        "descriptionRegister": "साइन अप",
        "titleReturnRefundPolicy": "वापसी रिफंड नीति",
        "descriptionReturnRefundPolicy": "वापसी रिफंड नीति",
        "titlePolicyAndProcedure": "DMCA नोटिस एवं टेकडाउन नीति एवं प्रक्रियाएँ ",
        "descriptionPolicyAndProcedure": "DMCA नोटिस एवं टेकडाउन नीति एवं प्रक्रियाएँ "
    },
    "getVPN": "{productName} पाएं",
    "signIn": "साइन इन करें",
    "billings-page": {
        "waiting-processing": "भुगतान प्रसंस्करण से प्रतिक्रिया की प्रतीक्षा हो रही है।"
    },
    "servers": {
        "USDal": "USA डेल्लास"
    }
}
