{
    "web": {
        "why_use": "Waarom {productName} gebruiken",
        "download_VPN": "Download {productName} en begin het vandaag nog te gebruiken!",
        "getVPN": "{productName} Downloaden",
        "access": "Veilige toegang tot alle content van overal ter wereld",
        "jurisdiction": "De jurisdictie van de EU garandeert geheimhouding",
        "anonymity": "Tracking op basis van IP voorkomen voor advertentie diensten",
        "simple_conf": "Gemakkelijke installatie en gebruik",
        "secure": "Veilige betalingen en bescherming tegen hackers",
        "protection": "Bescherming tegen indringende advertenties",
        "get_VPN_app_phone": "Download {productName}  voor je mobiele telefoon",
        "keep_safe": "Blijf veilig en privé online",
        "different_device": "{productName} Downloaden voor een ander apparaat",
        "authorize": "Autoriseert",
        "dont_have_an_account": "Nog geen account?",
        "register": "Registreren",
        "pass": "Wachtwoord",
        "sign_in": "Inloggen",
        "forgot_pass": "Wachtwoord vergeten?",
        "email": "E-mail",
        "instructions_for_macos": "Instructies voor MacOS",
        "instructions_for_windows": "Instructies voor Windows",
        "current-plan": "Huidige plan",
        "date": "Datum",
        "price": "Prijs",
        "price_from": "Prijs vanaf",
        "next_due": "Volgende betaling",
        "payment": "Betaling",
        "cancel": "Annuleren",
        "go_to_settings": "Ga naar Instellingen",
        "ok": "Ok",
        "dialog_cancel-subscription_title-AppStore": "U kunt het abonnement opzeggen via het Apple Store account waarop het abonnement is geacctiveerd.",
        "dialog_cancel-subscription_title-PlayMarket": "U kunt het abonnement opzeggen via het Play Market account waarop het abonnement is geacctiveerd.",
        "dialog_cancel-subscription_title-sure": "Weet u zeker dat u het abonnement wilt opzeggen?",
        "yes": "Ja",
        "no": "Nee",
        "subscription_canceled_successfully": "Abonnement succesvol opgezegd",
        "dashboard_dialog_thanks-for-registration_title": "Bedankt voor de inschrijving!",
        "dashboard_dialog_thanks-for-registration_description_1": "Onthoud uw wachtwoord om in te loggen.",
        "Password": "Wachtwoord",
        "dashboard_dialog_thanks-for-registration_description_2-1": "Of",
        "dashboard_dialog_thanks-for-registration_description_2-2": "Klik hier",
        "dashboard_dialog_thanks-for-registration_description_2-3": "om uw eigen wachtwoord in te stellen.",
        "prices_date-of-next-payment": "Datum van de volgende afschrijving van het geld",
        "change_password": "Wachtwoord wijzigen",
        "current_password": "Huidig wachtwoord",
        "new_password": "Nieuw Wachtwoord",
        "confirm-password": "Wachtwoord bevestigen",
        "reset": "Opnieuw instellen",
        "email_address_associated": "Voer het e-mailadres in dat gelinkt is aan je account. We zullen een e-mail versturen met een link om je wachtwoord opnieuw in te stellen.",
        "get-link": "E-mail verzenden",
        "sign-in": "Inloggen",
        "forget-password_dialog_email-link_title": "We hebben een e-mail verstuurd met een link om je wachtwoord opnieuw in te stellen.",
        "i-have-promocode": "Ik heb een kortingscode",
        "promo": "Kortingscode",
        "have_an_account": "Ik heb een account",
        "reset-password": "Wachtwoord opnieuw instellen",
        "state": {
            "register": "Registreren"
        },
        "action": {
            "register": "Registreren",
            "cancel": "Annuleren"
        },
        "notify_password-changed": "Wachtwoord is veranderd",
        "prices_discount": "Korting",
        "prices_email": "E-mail",
        "prices_country": "Jouw land",
        "prices_pay": "Betalen",
        "prices_privacy_p-0": "Door dit formulier in te dienen, ga je akkoord met onze",
        "prices_privacy_p-1": "Gebruiksvoorwaarden",
        "prices_privacy_p-2": "en",
        "prices_privacy_p-3": "Privacyverklaring",
        "price_card": "Kaart",
        "price_internet-wallet": "Internet Portemonnee",
        "price_cryptocurrency": "Cryptocurrency",
        "prices_order-total": "Totaal bestelling",
        "i-dont-have-promocode": "Ik heb geen kortingscode",
        "dashboard_dialog_thanks-for-registration_description_1_promo": "De promotiecode die u heeft ingevoerd, is niet geldig",
        "prices_user-has-account_how-to-change-plan_1": "Om het tariefplan te wijzigen, annuleert u het huidige abonnement op de",
        "prices_user-has-account_how-to-change-plan_2": "Rekeningen",
        "prices_user-has-account_how-to-change-plan_3": "pagina en schaf vervolgens een nieuwe aan",
        "instructions_for_linux": "Instructies voor Linux",
        "prices_Step": "Stap",
        "tariff-name_Year": "Jaar",
        "tariff-name_Month": "Maand",
        "month": "maand",
        "prices_save": "Opslaan",
        "prices_billedEvery": "Gefactureerd elke",
        "prices_the-most-popular": "Het meest populair",
        "prices_Billed": "In factuur gebracht",
        "prices_every": "elke",
        "icon_check-circle-empty": "Lege Cirkel",
        "icon_check-circle": "Selectievakje",
        "icon_like": "Like",
        "icon_info": "Info",
        "icon_down": "Omlaag",
        "icon_check": "Selectievakje",
        "prices_the-1-year-is-better": "Het 1-Jaren Plan betekent het meeste voordeel voor jou",
        "prices_choose-plan": "Kies je abonnement",
        "prices_Chose-Payment-Method": "Betalingsmethode kiezen",
        "prices_Why-choose-VPN": "Waarom voor {productName} kiezen?",
        "prices_VPN-includes": "{productName} bevat",
        "prices_includes-support": "24/7 klantenservice",
        "prices_includes-money-back": "{numberOfDays}-dagen geld-terug-garantie",
        "prices_includes-security": "Bankwaardige beveiliging & encryptie",
        "prices_includes-no-logs": "Geen activiteit & connectie logs",
        "instruction": "Instructies",
        "apps": "Apps",
        "my-ip": "Mijn IP",
        "terms": "Gebruiksvoorwaarden",
        "return-refund-policy": "Retour- & Terugbetalingsbeleid",
        "policy_and_procedure": "DMCA-kennisgeving en Beleid en Procedures voor Verwijdering",
        "privacy_policy": "Privacyverklaring",
        "footer_support": "Ondersteuning",
        "footer_sales-department": "Verkoopafdeling",
        "footer_company": "Bedrijf",
        "footer_contacts": "Contactpersonen",
        "data-retention-policy": "Gegevens retentiebeleid",
        "pricing": "Prijzen",
        "servers": "Servers",
        "knowledge-base": "Kennisbasis",
        "dashboard": "Dashboard",
        "to_home_page": "Naar startpagina",
        "icon_logo": "Logo",
        "icon_login": "Inloggen",
        "icon_menu": "Menu",
        "icon_key": "Sleutel",
        "icon_logout": "Uitloggen",
        "log_out": "Uitloggen",
        "icon_lifebuoy": "Reddingsboei",
        "knowledge_base": "Kennisbasis",
        "icon_support": "Ondersteuning",
        "downloads": "Downloads",
        "billings": "Facturen",
        "icon_eyeOpen": "Wachtwoord Weergeven",
        "inactive": "Inactief. Voldoe de betaling om te beginnen met gebruiken",
        "icon_flag": "Vlag",
        "active_until": "Actief tot",
        "servers_cta_title": "Begin Vandaag Veilig te Browsen",
        "servers_cta_description": "Houd je internet veilig en privé",
        "downloads_instruction": "Instructie",
        "downloads_router": "Router",
        "downloads_openVPN_profiles": "OPENVPN-profielen",
        "tariff-name-long_Year": "Een Jaar",
        "tariff-name-long_Month": "Een Maand",
        "dialog_cancel-subscription_header": "Abonnement Annuleren",
        "web_payment_history": "Betaalgeschiedenis",
        "tariff": "Tarief",
        "payment_method": "Betaalmethode",
        "status": "Status",
        "sum": "Som",
        "tbody_is_empty_1": "Hier ziet u informatie over uw betalingen",
        "tbody_is_empty_2": "Een bestelling plaatsen",
        "status_paid": "Betaald",
        "status_not-paid": "Niet Betaald",
        "my_services": "Mijn Diensten",
        "private_and_secure": "Privé en veilige internettoegang (VPN)",
        "active": "Actief",
        "downloads_apps_and_files": "Apps en Bestanden",
        "downloads_for_mobile": "Mobiele apparaten",
        "downloads_for_desktop": "Desktops",
        "downloads_for_browsers": "Browsers",
        "downloads_other_devices": "Andere Apparaten",
        "forget-password_dialog_email-link_header": "Controleer Je E-mail",
        "pageNotFoundTitle": "Pagina niet Gevonden",
        "pageNotFoundDescription": "Het lijkt alsof deze pagina niet bestaat. Gelieve terug te keren naar ons.",
        "pageNotFoundAction": "Terugkeren naar {productName} website",
        "home": {
            "mainTitle": {
                "highlited": "Jouw\ndigitale",
                "rest": "bescherming"
            },
            "mainDescription": {
                "first": "van al je persoonlijke data en computers",
                "second": "tegen cybercriminelen"
            },
            "mainButton": "Vanaf $2.50 per maand*",
            "mainButtonDescription": "*terugbetalingsgarantie",
            "secureTitle": {
                "first": "Echt beveiligd",
                "second": "Internet"
            },
            "secureDescription": "VPN99 verwijdert alles dat jou uniek en herkenbaar maakt op het internet. Het verbergt je IP-adres en versleutelt je data zodat je browsergeschiedenis privé blijft.",
            "secureCard": {
                "noRestrictionsTitle": "Niets is ontoegankelijk!",
                "noRestrictionsDescription": "Alle internetsites zijn beschikbaar voor je, zelfs degene die geblokkeerd zijn. Bovendien zijn al je data op een betrouwbare manier beschermd tegen fraudeurs.",
                "anonimTitle": "Je bent nu anoniem!",
                "anonimDescription": "Elke verbinding is anoniem! We bewaren geen logboeken, dus zelfs wij hebben er geen idee van wat je op het internet doet ;)"
            },
            "devicesTitle": {
                "first": "Eén abonnement",
                "second": "voor alle apparaten!"
            },
            "devicesDescription": "MacOS, Windows, Android, iOS, extensies voor Chrome en Firefox, en nog veel meer",
            "serversTitle": {
                "first": "Snelle en stabiele",
                "second": "verbinding overal!"
            },
            "serversDescription": "Kies een van 17 servers of vertrouw de aanbevolen server - deze is het dichtst en daarom ook het snelst. Je kunt vrij naar films en tv-programma's kijken in HD kwaliteit.",
            "faqTitle": "FAQ",
            "faq": {
                "whatIsVPNTitle": "Wat betekent VPN?",
                "whatIsVPNDescription": {
                    "p1": "VPN staat voor Virtual Private Network.",
                    "p2": "Het hoofddoel van onze dienst is om voor jouw veiligheid en privacy te zorgen. Om dit te doen, versleutelt {productName} het verkeer dat je over het netwerkt verzendt."
                },
                "rollUp": "Oprollen",
                "more": "Lezen",
                "howWorksTitle": "Hoe werkt een VPN?",
                "howWorksDescription": {
                    "p1": "VPN voegt een nieuwe netwerkinterface toe aan het systeem dat dienstdoet alsof je het apparaat rechtstreeks met een privénetwerk verbond. Het wijzigt ook je IP-adres en versleutelt alle verzonden data. Een VPN maakt het mogelijk dat informatie ontvangen en verzonden wordt via openbare netwerken (via het internet bijvoorbeeld) alsof je apparaat rechtstreeks verbonden was met een privénetwerk, waardoor dezelfde beveiligingsbeleiden worden toegepast en je toegang krijgt tot alle bronnen op het privénetwerk."
                },
                "whatDiferenceWithProxyTitle": "Wat is het verschil tussen proxyservers en een VPN-dienst?",
                "whatDiferenceWithProxyDescription": {
                    "p1": "In tegenstelling tot proxy zijn VPN-verbindingen veel sneller en versleuteld. Niet alle programma's kunnen werken met proxyservers en velen vereisen nog verdere configuratie. Als je een VPN gebruikt, hoef je niet je elke applicatie afzonderlijk te configureren."
                },
                "whatCountriesTitle": "Welke landen zijn beschikbaar?",
                "whatCountries": {
                    "first": "De huidige lijst met locaties in verschillende landen staat",
                    "link": "hier",
                    "second": "Dus je krijgt een IP-adres met de geolocatie van deze landen wanneer je verbinding maakt."
                },
                "logsTitle": "Slaat u logbestanden op?",
                "logs": {
                    "p1": "{productName} heeft een strikt beleid dat geen logboekregistratie vereist."
                }
            },
            "footerTitle": {
                "first": "Start",
                "second": "met het gebruiken van VPN99"
            },
            "footerDescription": "Download de app op je apparaat"
        },
        "my_ip_address": "Mijn IP-adres",
        "your_ip": "Dit is je IP-adres",
        "fail-payment_title": "Oeps! Er is iets mis gegaan.",
        "fail-payment_description": "Uw tarief is niet betaald. Wellicht heeft u een fout gemaakt bij het invoeren van gegevens tijdens de betaling. Maakt u zich geen zorgen, u kunt het opnieuw proberen.",
        "fail-payment_go-to-prices": "Ga naar tariefplannen en probeer het opnieuw",
        "success_congratulations-title": "Gefeliciteerd met uw aankoop!",
        "success_congratulations-description": "Een moment alstublieft. U wordt doorgestuurd naar uw persoonlijke account, waar u uw aankopen kunt beheren.",
        "success-payment_go-to-dashboard": "Log nu in",
        "servers_title": "Een klik om toegang te krijgen tot 10+ landen",
        "servers_title_description": "Kies een VPN-server op {continents_number} continenten in een van {servers_number} landen",
        "servers_locations_list_title": "Lijst met locaties VPN-server",
        "servers_continent_all": "Alle landen",
        "allservers": "Land",
        "continent_asia": "Azië",
        "continent_europe": "Europa",
        "continent_america": "Verenigde Staten",
        "our_vpn_server": "Onze servers",
        "subscription_canceled_failure": "Oeps! Er is iets fout gegaan bij het annuleren. Neem contact op met de klantenservice.",
        "prices_trial-days": "+ {count} dagen proefperiode",
        "days": "{count} dagen",
        "prices_trial": "Proefperiode",
        "pageForbidden": "Verboden toegang",
        "pageForbiddenTitle": "Verboden toegang",
        "pageForbiddenDescription": "Het lijkt erop dat de toegang beperkt is.",
        "pageForbiddenAction": "Keer terug naar de {productName} website",
        "private_finally": "Privé online surfen",
        "no_one_can_see": "Niemand kan zien wat je doet",
        "no_restrictions_on_content_and_censorship": "Geen beperkingen of censuur ",
        "one_click_and_you_defend": "Eén klik en je bent beschermd",
        "removes_everything": "{productName} verwijdert alles wat jou online uniek en herkenbaar maakt, door je IP adres te verbergen, je data te versleutelen en je browsergeschiedenis privé te houden.",
        "enjoy_fast_and_stable_connection": "Geniet overal van een snelle en stabiele connectie",
        "choose_your_server": "Kies je server in {count} landen en geniet van de snelste VPN-service. Meer servers betekent minder laden, plus een groot aantal locaties betekent dat er meer kans is dat je er één vindt in je buurt",
        "easy_to_use_apps": "Gebruiksvriendelijke app voor al je apparaten",
        "subscription_comes": "Één {productName} abonnement voor al je apparaten: {appsList} en meer",
        "money_back_guarantee": "Geldteruggarantie",
        "faq_footer_description": "Je kunt meer interessante informatie terugvinden in onze",
        "faq_footer_knowledge-base": "Kennisbank",
        "tryFree": "Probeer gratis",
        "year": "jaar",
        "icon_up": "Omhoog",
        "status_failed": "Mislukt",
        "status_refund": "Retourbetaling",
        "status_trial": "Proefperiode",
        "onHoldMessage": "Er is iets fout gegaan en het is helaas niet gelukt om je {productName} abonnement op je {platform} toestel automatisch in rekening te brengen."
    },
    "css": "",
    "validator": {
        "is_empty": "Het veld moet leeg zijn",
        "is_not_empty": "Verplicht veld",
        "is_email": "Controleer of de invoer correct is",
        "is_phone": "Voer het juiste nummer in",
        "is_password": "Wachtwoord mag geen {extraSymbols} bevatten",
        "is_password_has_enough_symbols": "Wachtwoord moet minimaal 6 tekens bevatten",
        "is_password_less_max_symbols": "Wachtwoord moet minder dan 32 tekens bevatten",
        "is_password_has_space": "Wachtwoord mag geen spatie bevatten",
        "is_not_empty_with_field_name": "Het veld {fieldName} is verplicht",
        "is_password_has_not_english_letters": "Niet-Latijnse letters zijn niet toegestaan"
    },
    "passwords-not-equal": "Wachtwoord komt niet overeen",
    "error": {
        "e500": {
            "new": {
                "text": "We zijn al op de hoogte van dit probleem en lossen het binnenkort op! Probeer het opnieuw.",
                "title": "500 — Serverfout"
            }
        },
        "eUnknown": {
            "new": {
                "text": "We zijn al op de hoogte van dit probleem en lossen het binnenkort op! Probeer het opnieuw.",
                "title": "500 — Serverfout"
            },
            "oops": {
                "text": "Oeps! Er is iets fout gegaan bij het laden van de gebruikersgegevens. Probeer de pagina opnieuw te laden."
            }
        },
        "e400": {
            "new": {
                "title": "Foutmelding"
            }
        }
    },
    "whitelabel": {
        "vpn99": {
            "web_be_safe": "Online beveiliging tegen de beste prijs",
            "web_online_security": "Vanaf slechts ${pricePerMonth} per maand"
        },
        "trustvpn": {
            "web_be_safe": "Bescherm je online privacy nu!",
            "web_online_security": "Ultrasnel, Bescherm je privacy, Digitale Vrijheid"
        }
    },
    "meta": {
        "descriptionDataRetentionPolicy": "De situaties en periodes dat uw gegevens worden bewaard. Al uw gegevens worden veilig opgeslagen in overeenstemming met de AVG-richtlijnen.",
        "descriptionMyIp": "Vind online jouw IP-adres, het adres van je computer. Zie hoe jouw IP-adres verschijnt",
        "descriptionPrivacyPolicy": "Ons privacybeleid bevat informatie over welke gegevens we verzamelen en hoe we deze gebruiken, evenals hoe uw eigen informatie beheerd kan worden. Wij gebruiken cookies in overeenstemming met de Welshe wetgeving, maar u kunt uw voorkeuren aanpassen.",
        "descriptionTerms": "Door de gebruiksvoorwaarden van {productName} te accepteren, gaat u akkoord met de algemene voorwaarden, evenals met de verantwoordelijkheden van beide partijen zoals uiteengezet in de overeenkomst. Compensatie is beschikbaar in bepaalde omstandigheden wanneer VPN-services gedurende meer dan 12 opeenvolgende uren niet beschikbaar zijn of niet goed werken.",
        "descriptionAccountBilling": "Account - Betalingsgeschiedenis",
        "descriptionAccountPanel": "Account - Dashboard",
        "descriptionAccountPassword": "Account - Wachtwoord wijzigen",
        "descriptionDownload": "Download",
        "descriptionForgot": "Wachtwoord herstellen",
        "descriptionReset": "Wachtwoord opnieuw instellen",
        "descriptionHome": "Download en installeer {productName} en je krijgt overal ter wereld volledige bescherming. {productName}, hoge snelheden en volledige anonimiteit.",
        "descriptionPayFail": "Betaling geweigerd",
        "descriptionPaySuccess": "Betaling gelukt",
        "descriptionPrice": "Download en installeer {productName} en je krijgt overal ter wereld volledige bescherming. Geniet van alle voordelen van een dure VPN. {productName}, hoge snelheden en volledige anonimiteit",
        "descriptionServers": "Servers",
        "titleDataRetentionPolicy": "Beleid voor het bewaren van gegevens",
        "titleMyIp": "Achterhaal online wat jouw IP-adres is",
        "titlePrivacyPolicy": "Het privacybeleid: de stappen die {productName} neemt om uw privégegevens te beschermen",
        "titleTerms": "Gebruiksvoorwaarden: de specifieke voorwaarden die van toepassing zijn bij het gebruik maken van de diensten aangeboden door {productName}.",
        "titleAccountBilling": "Account - Betalingsgeschiedenis",
        "titleAccountPanel": "Account - Dashboard",
        "titleAccountPassword": "Account - Wachtwoord wijzigen",
        "titleDownload": "Download",
        "titleForgot": "Wachtwoord herstellen",
        "titleReset": "Wachtwoord opnieuw instellen",
        "titleHome": "Download {productName} vandaag en krijg betrouwbare bescherming voor een aantrekkelijke prijs.",
        "titleLogin": "Aanmelden",
        "titlePayFail": "Betaling geweigerd",
        "titlePaySuccess": "Betaling geslaagd",
        "titlePrice": "Koop {productName} vandaag en krijg betrouwbare bescherming voor een aantrekkelijke prijs.",
        "titleRegister": "Aanmelden",
        "titleServers": "Servers",
        "titleApps": "Apps",
        "descriptionApps": "Apps",
        "descriptionLogin": "Aanmelden",
        "descriptionRegister": "Registreren",
        "titleReturnRefundPolicy": "Terugbetalingsbeleid",
        "descriptionReturnRefundPolicy": "Terugbetalingsbeleid",
        "titlePolicyAndProcedure": "DMCA-kennisgeving en Beleid en Procedures voor Verwijdering",
        "descriptionPolicyAndProcedure": "DMCA-kennisgeving en Beleid en Procedures voor Verwijdering"
    },
    "getVPN": "Koop {productName}",
    "signIn": "Aanmelden",
    "billings-page": {
        "waiting-processing": "Wachten op een reactie over de verwerking van de betaling."
    },
    "servers": {
        "USDal": "VS Dallas"
    }
}
