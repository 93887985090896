<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="_width"
    :height="_height"
    :aria-labelledby="name"
    :style="`padding: ${
      isNaN(padding) ? padding : `${padding}px`
    }; max-height: ${isNaN(maxHeight) ? maxHeight : `${maxHeight}px`}; ${
      fill ? `fill: ${color}` : ''
    }`"
    role="img"
    ref="icon"
    style="direction: ltr"
  >
    <title :id="name" :lang="$i18n.locale">{{ name }}</title>
    <g ref="slotContainer">
      <slot />
    </g>
  </svg>
</template>

<script>
export default {
  name: 'Icon',
  props: {
    name: {
      type: String,
      default: 'icon',
    },
    width: {
      type: [Number, String],
      default: 18,
    },
    height: {
      type: [Number, String],
      default: 18,
    },
    maxHeight: {
      type: [Number, String],
      default: 'auto',
    },
    padding: {
      type: [Number, String],
      default: 0,
    },
    color: {
      type: [String, null],
      default: null,
    },
    stroke: {
      type: Boolean,
      default: false,
    },
    fill: {
      type: Boolean,
      default: false,
    },
    resize: {
      type: Boolean,
      default: true,
    },
    resizeMultiplier: {
      type: [Number, String],
      default: 1,
    },
    resizeMultiplierTablet: {
      type: [Number, String],
      default: 0.9,
    },
    resizeMultiplierMobile: {
      type: [Number, String],
      default: 0.8,
    },
  },
  computed: {
    _color: {
      get() {
        return this.color || '#000000'
      },
    },
    _width: {
      get() {
        if (!this.resize) {
          return this.width
        }
        const wisthString = this.width.toString()
        const widthNumber = parseFloat(wisthString)
        const widthRest = wisthString.slice(widthNumber.toString().length)
        return widthNumber * this.realResizeMultiplier + widthRest
      },
    },
    _height: {
      get() {
        if (!this.resize) {
          return this.height
        }
        const heightString = this.height.toString()
        const heightNumber = parseFloat(heightString)
        const heightRest = heightString.slice(heightNumber.toString().length)
        return heightNumber * this.realResizeMultiplier + heightRest
      },
    },
    realResizeMultiplier: {
      get() {
        if (this.$store.getters['app/isTabletSize']) {
          return +this.resizeMultiplierTablet
        }
        if (this.$store.getters['app/isMobileSize']) {
          return +this.resizeMultiplierMobile
        }
        return +this.resizeMultiplier
      },
    },
  },
  mounted() {
    if (this.stroke) {
      this.$refs.icon.style.color = this._color
      this.$refs.icon.style.stroke = 'currentColor'
    }
  },
}
</script>

<style lang="scss" scoped>
.icon-container {
  display: inline;
}
</style>
