import ROUTE_NAME from './names'
export default {
  [ROUTE_NAME.HOME]: '',
  [ROUTE_NAME.PRICES]: 'prices',
  [ROUTE_NAME.PRICES_PROMO]: 'prices-promo',
  [ROUTE_NAME.PRICES_CHARITABLE]: 'prices/jhdfhjs6545fk',
  [ROUTE_NAME.APPS]: 'apps',
  [ROUTE_NAME.SERVERS]: 'servers',
  [ROUTE_NAME.AUTH.LOGIN]: 'auth/login',
  [ROUTE_NAME.AUTH.REGISTER]: 'auth/register',
  [ROUTE_NAME.AUTH.FORGOT]: 'auth/forgot',
  [ROUTE_NAME.ACCOUNT.PANEL]: 'account/panel',
  [ROUTE_NAME.ACCOUNT.DOWNLOADS]: 'account/downloads',
  [ROUTE_NAME.ACCOUNT.BILLING]: 'account/billing',
  [ROUTE_NAME.ACCOUNT.CHANGE_PASSWORD]: 'account/password',
  [ROUTE_NAME.PASSWORD.RESET]: 'password/reset/:token',
  [ROUTE_NAME.TERMS]: 'terms',
  [ROUTE_NAME.MY_IP]: 'myip',
  [ROUTE_NAME.DATA_RETENTION_POLICY]: 'data-retention-policy',
  [ROUTE_NAME.PAYMENT_SUCCESS]: 'payment/success',
  [ROUTE_NAME.PAYMENT_FAILURE]: 'payment/fail',
  [ROUTE_NAME.RETURN_REFUND_POLICY]: 'terms#refund',
  [ROUTE_NAME.POLICY_AND_PROCEDURE]:
    'terms#dmca-notice-and-takedown-policy-and-procedures',
  [ROUTE_NAME.PRIVACY_POLICY]: 'privacy-policy',
  [ROUTE_NAME.EXTENSION_PRIVACY_POLICY]: 'extension/privacy-policy',
  [ROUTE_NAME.LOGIN_BY_TOKEN]: 't',
  [ROUTE_NAME.ERROR.FORBIDDEN]: '403',
  [ROUTE_NAME.ERROR.NOT_FOUND]: '/:pathMatch(.*)*',
  [ROUTE_NAME.TEST.ONBOARDING.CHAMELEON]: 'chameleon',
  [ROUTE_NAME.DEV.DATA.RESET]: 'dev/data/reset',
  [ROUTE_NAME.DEV.MODE.ENABLE]: 'dev/mode/enable',
  [ROUTE_NAME.DEV.MODE.DISABLE]: 'dev/mode/disable',
  [ROUTE_NAME.PROMO_CODE]: 'promo-code',
  [ROUTE_NAME.MAGIC_LINK]: 'mgx/:link',
}
