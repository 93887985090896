import api from '@/api'
import { tariffName, fixedPeriod } from '@/utils/tariff'
import { sortBy } from '@/utils/paywall'
import MERCHANT from '../../CONSTANTS/PAYMENT/MERCHANT'
import store from '@/store'
import getSymbolFromCurrency from 'currency-symbol-map'

const state = () => ({
  tariffs: [],
  merchants: null,
  paywall: null,
  currentPaywallTariff: undefined,
})

const getters = {
  enabledMerchants(state) {
    return (
      state.merchants?.reduce((acc, cur) => {
        if (cur.active) {
          acc.push(cur.name)
        }
        return acc
      }, []) ||
      store.state.whitelabel.config?.merchants ||
      []
    )
  },
  paywallTariffs(state) {
    const arr = [
      ...(state.paywall?.tariffs.map((tariff) => {
        return {
          ...tariff,
          title: tariffName({
            period: tariff.period,
            numberOfPeriods: tariff.numberOfPeriods,
          }),
          titleMini: tariffName({
            period: tariff.period,
            numberOfPeriods: tariff.numberOfPeriods,
            mini: true,
          }),
        }
      }) || []),
    ]
    Object.defineProperty(arr, 'sortBy', {
      value: sortBy(arr),
      enumerable: false,
      writable: true,
    })
    return arr
  },
}

const actions = {
  fetchMerchants({ commit }) {
    return api.payment.getMerchants().then(({ data }) => {
      const res = Array.isArray(data) ? data : []
      commit('setMerchants', res)
      return res
    })
  },
  fetchTariffs({ commit }) {
    Promise.all([
      api.payment.getTariffs(),
      api.payment.getWhitelistedTariffs(),
    ])
      .then((values) => values.map((v) => v.data))
      .then((values) => values.reduce((a, b) => a.concat(b)))
      .then((data) => {
        const monthTariffs = data.filter(
          ({ period, numberOfPeriods }) =>
            numberOfPeriods === 1 && period === 'month'
        )
        const tariffs = data.map((tariff) => {
          let monthTariff = monthTariffs.find(
            ({ currency }) =>
              String(currency).toLowerCase() ==
              String(tariff.currency).toLowerCase()
          )

          let oldPrice = 0,
            discount = 0,
            discountPercent = 0

          if (
            tariff.isWhitelisted ||
            (tariff.period == 'month' && tariff.numberOfPeriods == 1)
          ) {
            oldPrice = 12.95
            discount = !tariff.discount ? 0 : tariff.discount
            discountPercent =
              discount > 0
                ? 0
                : +parseInt(((discount / oldPrice) * 100).toFixed())
          } else {
            oldPrice =
              monthTariff.id === tariff.id
                ? monthTariff.price
                : +parseFloat(
                    (monthTariff.price * tariff.numberOfPeriods).toFixed(2)
                  )
            discount =
              monthTariff.id === tariff.id
                ? 0
                : +parseFloat((oldPrice - tariff.price).toFixed(2))
            discountPercent =
              monthTariff.id === tariff.id
                ? 0
                : +parseInt(((discount / oldPrice) * 100).toFixed())
            if (discountPercent < 0) {
              discountPercent = 0
            }
          }

          const { period, numberOfPeriods } = fixedPeriod({
            period: tariff.period,
            numberOfPeriods: +tariff.numberOfPeriods,
          })

          if (!tariff.pricePerMonth) {
            tariff = {
              ...tariff,
              pricePerMonth: tariff.price / tariff.numberOfPeriods,
            }
          }

          return {
            id: tariff.id,
            title: tariff.title,
            price: +tariff.price,
            pricePerMonth: +tariff.pricePerMonth,
            trialDays: +tariff.trialDays || null,
            oldPrice,
            discount,
            discountPercent,
            period,
            numberOfPeriods,
            stripeTariffCode: tariff.stripeTariffCode,
            purchaseSecretKey: tariff.purchase_secret_key,
            isWhitelisted: tariff.isWhitelisted,
            isPromoted: tariff.isPromoted,
            currency: String(tariff.currency).toUpperCase(),
            currencySymbol: getSymbolFromCurrency(
              String(tariff.currency).toUpperCase()
            ),
          }
        })

        commit('setTariffs', tariffs)
        return tariffs
      })
  },
  setPaywall({ commit, state }, { paywallName }) {
    const paywallConfig = require(`@/whitelabel/${process.env.VUE_APP__APP_NAME}/data/paywallConfigs/${paywallName}.json`)

    let paywalledTariffs =
      paywallConfig.tariffs?.[
        process.env.VUE_APP__MODE === 'production' ? 'prod' : 'test'
      ].reduce((acc, paywallTariff) => {
        const tariff = state.tariffs.find(
          (tariff) => tariff.stripeTariffCode === paywallTariff.stripeCode
        )

        if (tariff) {
          acc.push({
            ...tariff,
            merchants: paywallTariff.merchants.map((key) => MERCHANT[key]),
          })
        }

        return acc
      }, []) || []

    // recalculate discount after tariff set by paywall

    let monthTariff = paywalledTariffs.find(
      ({ numberOfPeriods, period }) => numberOfPeriods == 1 && period == 'month'
    )

    let yearTariff = paywalledTariffs.find(
      ({ numberOfPeriods, period }) =>
        period == 'year' || (numberOfPeriods >= 12 && period == 'month')
    )

    if (monthTariff && yearTariff) {
      if (
        String(monthTariff.currency).toLowerCase() ==
        String(yearTariff.currency).toLowerCase()
      ) {
        let yearPeriod =
          yearTariff.numberOfPeriods >= 12 ? yearTariff.numberOfPeriods : 12

        let oldPrice = parseFloat((monthTariff.price * yearPeriod).toFixed(2))

        let discount = +parseFloat((oldPrice - yearTariff.price).toFixed(2))
        let discountPercent = +parseInt(((discount / oldPrice) * 100).toFixed())

        yearTariff = {
          ...yearTariff,
          oldPrice,
          discount,
          discountPercent,
        }

        let newPaywalledTariffs = [monthTariff, yearTariff]

        paywalledTariffs = newPaywalledTariffs
      }
    }

    let whitelistedTariffs =
      state.tariffs.reduce((tariffs, nextTarif) => {
        let availableMerchants = []
        if (nextTarif.stripeTariffCode) {
          availableMerchants.push('merchant_stripe')
        }
        if (nextTarif.isWhitelisted === true) {
          availableMerchants.push('merchant_free-kassa')

          tariffs.push({
            ...nextTarif,
            merchants: availableMerchants,
          })
        }

        return tariffs
      }, []) || []

    let promotedTariffs =
      state.tariffs.reduce((tariffs, nextTarif) => {
        let availableMerchants = []
        if (nextTarif.stripeTariffCode) {
          availableMerchants.push('merchant_stripe')
        }
        if (nextTarif.isPromoted === true) {
          availableMerchants.push('merchant_free-kassa')

          tariffs.push({
            ...nextTarif,
            merchants: availableMerchants,
          })
        }

        return tariffs
      }, []) || []

    let finalTariffs = paywalledTariffs.concat(whitelistedTariffs)
    if (paywallName === 'secret_plans') {
      finalTariffs = finalTariffs.concat(promotedTariffs)
    }
    commit('setPaywall', {
      ...paywallConfig,
      tariffs: finalTariffs,
    })
  },
  setCurrentTariff({ commit }, data) {
    commit('setCurrentTariff', data)
  },
}

const mutations = {
  setTariffs(state, data) {
    state.tariffs = data
  },
  setMerchants(state, data) {
    state.merchants = data
  },
  setPaywall(state, data) {
    state.paywall = data
  },
  setCurrentTariff(state, { currentTariff }) {
    state.currentPaywallTariff = currentTariff
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
