{
    "web": {
        "why_use": "{productName}을 사용하는 이유",
        "download_VPN": "{productName} 를 다운로드하고 오늘 시작하세요!",
        "getVPN": "{productName} 다운로드",
        "access": "모든 콘텐츠에 대한 액세스 권한전세계 어디서나",
        "jurisdiction": "유럽 연합 (EU) 보장의 관할권비밀유지",
        "anonymity": "완전한 익명성및 로그 부재 ",
        "simple_conf": "간편한 설정 및사용",
        "secure": " 안전한 결제 및 해커로부터 보호",
        "protection": "침입과 광고로부터보호 ",
        "get_VPN_app_phone": "당신의 기기에 {productName} 앱 다운로드",
        "keep_safe": "온라인 보안 및 비공개 유지",
        "different_device": "다른 기기에서 {productName} 다운로드",
        "authorize": "승인",
        "dont_have_an_account": "계정이 있습니까?",
        "register": "가입",
        "pass": "비밀번호",
        "sign_in": "로그인",
        "forgot_pass": "비밀번호를 잊어 버렸습니까?",
        "email": "이메일",
        "instructions_for_macos": "MacOs용 설명서",
        "instructions_for_windows": "Windows용 설명서",
        "current-plan": "현재 계획",
        "date": "날짜",
        "price": "가격",
        "price_from": "가격",
        "next_due": "다음 기한",
        "payment": "지불",
        "cancel": "취소",
        "go_to_settings": "설정으로 바로 가기",
        "ok": "확인",
        "dialog_cancel-subscription_title-AppStore": "구독이 이루어진 Apple Store 계정을 통해 구독을 취소 할 수 있습니다",
        "dialog_cancel-subscription_title-PlayMarket": "구독이 이루어진 Play Market 계정을 통해 구독을 취소 할 수 있습니다",
        "dialog_cancel-subscription_title-sure": "구독을 취소 하시겠습니까?",
        "yes": "네",
        "no": "아니오",
        "subscription_canceled_successfully": "구독이 성공적으로 취소되었습니다",
        "dashboard_dialog_thanks-for-registration_title": "등록 해주셔서 감사합니다!",
        "dashboard_dialog_thanks-for-registration_description_1": "로그인하시려면 비밀번호를 기억하세요",
        "Password": "패스워드",
        "dashboard_dialog_thanks-for-registration_description_2-1": "또는",
        "dashboard_dialog_thanks-for-registration_description_2-2": "여기를 클릭",
        "dashboard_dialog_thanks-for-registration_description_2-3": "자신의 암호를 설정합니다.",
        "prices_date-of-next-payment": "다음 인출 날짜",
        "change_password": "비밀번호 변경",
        "current_password": "현재 비밀번호",
        "new_password": "새로운 비밀번호 ",
        "confirm-password": "비밀번호 확인",
        "reset": "재설정",
        "email_address_associated": "계정과 관련된 이메일 주소를 입력하십시오. 비밀번호 재설정 링크를 이메일로 보내드립니다.",
        "get-link": "이메일 보내기",
        "sign-in": "로그인",
        "forget-password_dialog_email-link_title": "비밀번호 재설정 링크가 포함된 이메일을 보냈습니다",
        "i-have-promocode": "프로모션 코드가 있습니다",
        "promo": "프로모션 코드",
        "have_an_account": "계정이 있습니다",
        "reset-password": "비밀번호 재설정",
        "state": {
            "register": "가입하기"
        },
        "action": {
            "register": "가입",
            "cancel": "취소"
        },
        "notify_password-changed": "비밀번호가 변경되었습니다",
        "prices_discount": "할인",
        "prices_email": "이메일",
        "prices_country": "귀하의 국가",
        "prices_pay": "결제",
        "prices_privacy_p-0": "이 양식을 제출함으로써 귀하는",
        "prices_privacy_p-1": "서비스   약관",
        "prices_privacy_p-2": "및",
        "prices_privacy_p-3": "개인 정보 정책에 동의합니다",
        "price_card": "카드",
        "price_internet-wallet": "인터넷 지갑",
        "price_cryptocurrency": "암호 화폐",
        "prices_order-total": "모든 항목",
        "i-dont-have-promocode": "프로모션 코드가 없습니다",
        "dashboard_dialog_thanks-for-registration_description_1_promo": "입력 한 프로모션 코드가 유효하지 않습니다",
        "prices_user-has-account_how-to-change-plan_1": "요금제를 변경하려면 현재 구독을 취소",
        "prices_user-has-account_how-to-change-plan_2": "청구",
        "prices_user-has-account_how-to-change-plan_3": "페이지, 다음 새 것을 구입",
        "instructions_for_linux": "Linux용 설명서",
        "prices_Step": "단계",
        "tariff-name_Year": "년",
        "tariff-name_Month": "월",
        "month": "월",
        "prices_save": "저장",
        "prices_billedEvery": "청구",
        "prices_the-most-popular": "가장 인기있는",
        "prices_Billed": "결제되었습니다",
        "prices_every": "모든",
        "icon_check-circle-empty": "빈 원",
        "icon_check-circle": "체크박스",
        "icon_like": "좋다",
        "icon_info": "정보",
        "icon_down": "아래에",
        "icon_check": "체크박스",
        "prices_the-1-year-is-better": "1년 플랜이 가장 저렴합니다",
        "prices_choose-plan": "플랜 선택",
        "prices_Chose-Payment-Method": "결제 수단을 선택",
        "prices_Why-choose-VPN": "왜 {productName} 선택하셨나요?",
        "prices_VPN-includes": "{productName} 포함",
        "prices_includes-support": "365일 고객 지원",
        "prices_includes-money-back": "{numberOfDays}일 환불 보장",
        "prices_includes-security": "은행급 보안&암호화",
        "prices_includes-no-logs": "비활성화&연결 로그",
        "instruction": "사용 방법",
        "apps": "앱",
        "my-ip": "나의 IP",
        "terms": "이용 약관",
        "return-refund-policy": "반품 및 환불 정책",
        "policy_and_procedure": "DMCA 통지 및 게시 중단 정책 및 절차 ",
        "privacy_policy": "개인 정보 보호 정책",
        "footer_support": "고객 지원",
        "footer_sales-department": "영업 부서",
        "footer_company": "회사",
        "footer_contacts": "연락처",
        "data-retention-policy": "데이터 보존 정책",
        "pricing": "가격 정책",
        "servers": "서버",
        "knowledge-base": "지식 베이스 ",
        "dashboard": "대시보드",
        "to_home_page": "홈페이지로",
        "icon_logo": "로고",
        "icon_login": "로그인",
        "icon_menu": "메뉴",
        "icon_key": "키",
        "icon_logout": "로그아웃",
        "log_out": "로그아웃",
        "icon_lifebuoy": "라이프부이",
        "knowledge_base": "지식 베이스 ",
        "icon_support": "고객 지원",
        "downloads": "다운로드",
        "billings": "결제",
        "icon_eyeOpen": "비밀번호 표시",
        "inactive": "비활성. 사용하려면 결제해보세요",
        "icon_flag": "깃발",
        "active_until": "때까지 활성화 상태",
        "servers_cta_title": "오늘부터 안전하게 인터넷을 이용하세요",
        "servers_cta_description": "인터넷을 안전하게 보안을 유지하면서 이용하세요",
        "downloads_instruction": "지침",
        "downloads_router": "라우터",
        "downloads_openVPN_profiles": "OpenVPN 프로필",
        "tariff-name-long_Year": "일 년",
        "tariff-name-long_Month": "한 달",
        "dialog_cancel-subscription_header": "구독 취소",
        "web_payment_history": "결제 내역",
        "tariff": "관세",
        "payment_method": "결제 방법",
        "status": "상태",
        "sum": "합계",
        "tbody_is_empty_1": "여기에서 결제에 대한 정보를 볼 수 있습니다.",
        "tbody_is_empty_2": "주문",
        "status_paid": "유료",
        "status_not-paid": "미 결제 상태",
        "my_services": "내 서비스",
        "private_and_secure": "보안을 유지하면서 인터넷 액세스(VPN)",
        "active": "활동적인",
        "downloads_apps_and_files": "앱과 파일",
        "downloads_for_mobile": "모바일",
        "downloads_for_desktop": "데스크톱",
        "downloads_for_browsers": "브라우저",
        "downloads_other_devices": "기타 기기",
        "forget-password_dialog_email-link_header": "이메일을 확인하세요",
        "pageNotFoundTitle": "페이지를 찾을 수 없음",
        "pageNotFoundDescription": "페이지가 존재하지 않습니다. 저희에게 다시 돌아 오시기 바랍니다.",
        "pageNotFoundAction": "{productName} 웹사이트로 돌아가기",
        "home": {
            "mainTitle": {
                "highlited": "당신의\n디지털",
                "rest": "보호"
            },
            "mainDescription": {
                "first": "모든 개인 데이터 및 컴퓨터",
                "second": "사이버 사기꾼에 대한"
            },
            "mainButton": "월 $2.50부터*",
            "mainButtonDescription": "*환불 보장",
            "secureTitle": {
                "first": "정말 안전한",
                "second": "인터넷"
            },
            "secureDescription": "VPN99는 인터넷에서 귀하를 특정지어 알아볼 수 있게 만드는 모든 것을 제거합니다. IP 주소를 숨기고 데이터를 암호화하여 인터넷 사용 기록을 비공개로 유지합니다.",
            "secureCard": {
                "noRestrictionsTitle": "금지 사항이 없습니다!",
                "noRestrictionsDescription": "차단된 사이트를 포함한 모든 인터넷 사이트를 사용할 수 있습니다. 모든 데이터는 위험으로부터 안전하게 보호됩니다.",
                "anonimTitle": "이제 익명입니다!",
                "anonimDescription": "모든 단일 연결은 익명입니다! 저희는 로그를 저장하지 않기 때문에 인터넷에서 무엇을 하는지 모릅니다 ;)"
            },
            "devicesTitle": {
                "first": "1회 구독",
                "second": "모든 장치에 대해!"
            },
            "devicesDescription": "MacOS, Windows, Android, iOS, Chrome 및 Firefox용 확장 등",
            "serversTitle": {
                "first": "빠르고 안정적",
                "second": "어디서나 연결!"
            },
            "serversDescription": "17개의 서버 중 하나를 선택하거나 권장 서버를 신뢰합니다. - 이 서버는 가장 가까워서 속도가 빠릅니다. HD 화질로 영화와 TV 프로그램을 자유롭게 시청할 수 있습니다.",
            "faqTitle": "자주하는 질문",
            "faq": {
                "whatIsVPNTitle": "VPN은 무슨 뜻 입니까?",
                "whatIsVPNDescription": {
                    "p1": "VPN은 가상 사설망 입니다.",
                    "p2": "당사 서비스의 주요 목적은 귀하의 보안과 개인 정보를 보장하는 것입니다. 이를 위해 {productName}은(는) 네트워크를 통해 전송하는 트래픽을 암호화합니다."
                },
                "rollUp": "롤업",
                "more": "읽다",
                "howWorksTitle": "VPN은 어떻게 작동합니까?",
                "howWorksDescription": {
                    "p1": "VPN은 장치를 전용 네트워크에 직접 연결한 것처럼 작동하는 새로운 네트워크 인터페이스를 시스템에 추가합니다. 또한 IP 주소를 변경하고 전송된 모든 데이터를 암호화합니다. VPN을 사용하면 장치가 전용 네트워크에 직접 연결된 것처럼 공용 네트워크를 통해 정보를 수신 및 전송할 수 있으므로 동일한 보안 정책을 적용하고 전용 네트워크의 모든 리소스에 액세스할 수 있습니다."
                },
                "whatDiferenceWithProxyTitle": "프록시 서버와 VPN 서비스의 차이점은 무엇입니까?",
                "whatDiferenceWithProxyDescription": {
                    "p1": "VPN 연결은 프록시와 달리 훨씬 더 안정적이고 암호화됩니다. 모든 프로그램이 프록시 서버에서 작동할 수 있는 것은 아니며 많은 프로그램에서 추가 구성이 필요합니다. VPN을 사용하는 경우 각 애플리케이션을 개별적으로 구성할 필요가 없습니다."
                },
                "whatCountriesTitle": "사용 가능한 국가는 무엇입니까?",
                "whatCountries": {
                    "first": "다양한 국가의 현재 위치 목록을 찾을 수 있습니다.",
                    "link": "여기",
                    "second": "따라서 연결할 때 이러한 국가의 지리적 위치가 포함된 IP 주소를 얻습니다."
                },
                "logsTitle": "로그 파일을 저장합니까?",
                "logs": {
                    "p1": "{productName}에는 엄격한 로깅 금지 정책이 있습니다."
                }
            },
            "footerTitle": {
                "first": "시작",
                "second": "VPN99 사용"
            },
            "footerDescription": "기기에 앱 다운로드"
        },
        "my_ip_address": "내 IP 주소",
        "your_ip": "이것은 당신의 IP 주소 입니다.",
        "fail-payment_title": "죄송합니다! 오류가 발생하였습니다.",
        "fail-payment_description": "요금이 결제되지 않았습니다. 결제 중에 데이터를 입력할 때 실수했을 수도 있습니다. 걱정하지 마십시오. 다시 시도할 수 있습니다.",
        "fail-payment_go-to-prices": "요금 플랜으로 이동하여 다시 시도하십시오.",
        "success_congratulations-title": "결제를 축하합니다!",
        "success_congratulations-description": "기다려주세요. 결제를 관리할 수 있는 개인 계정으로 이동합니다.",
        "success-payment_go-to-dashboard": "지금 로그인",
        "servers_title": "10개국 이상에서 원클릭으로 액세스",
        "servers_title_description": "{servers_number}개 국가 중 하나의 {continents_number} 대륙에서 VPN 서버를 선택하세요",
        "servers_locations_list_title": "VPN 서버 위치 목록",
        "servers_continent_all": "모든 국가",
        "allservers": "국가",
        "continent_asia": "아시아",
        "continent_europe": "유럽",
        "continent_america": "미국",
        "our_vpn_server": "VPN 서버",
        "subscription_canceled_failure": "앗! 취소하는 중에 오류가 발생했습니다. 지원팀에 문의하세요.",
        "prices_trial-days": "{count}일 체험",
        "days": "{count}일",
        "prices_trial": "재판",
        "pageForbidden": "접근 금지",
        "pageForbiddenTitle": "접근 금지",
        "pageForbiddenDescription": "접근을 시도한 곳이 제한된 것 같습니다.",
        "pageForbiddenAction": "{productName} 웹사이트로 돌아가기",
        "private_finally": "개인 온라인 서핑",
        "no_one_can_see": "아무도 귀하가 하는 일을 볼 수 없습니다",
        "no_restrictions_on_content_and_censorship": "온라인 제한이나 검열은 없습니다",
        "one_click_and_you_defend": "원 클릭으로 귀하는 보호받을 수 있습니다",
        "removes_everything": "{productName}는 인터넷 사용 기록을 비공개로 유지하기 위해 IP 주소를 숨기고 데이터를 암호화하여 온라인에서 귀하를 알아볼 수 있게 만드는 모든 것을 제거합니다.",
        "enjoy_fast_and_stable_connection": "어디서나 빠르고 안정적으로 연결하고 즐기십시오",
        "choose_your_server": "{count}개국에서 서버를 선택하고 가장 빠른 VPN 서비스를 즐겨보세요. 서버가 많을수록 로드 시간이 줄어들고, 위치가 넓어지면 근처에서 찾을 가능성이 높아집니다.",
        "easy_to_use_apps": "모든 장치와 호환되고 사용자 친화적인 앱",
        "subscription_comes": "소유한 모든 기기에 대한 하나의 {productName} 구독: {appsList} 등",
        "money_back_guarantee": "환불 보증",
        "faq_footer_description": "관심 있는 다른 정보를 찾을 수 있습니다.",
        "faq_footer_knowledge-base": "기술 자료",
        "tryFree": "무료로 사용해 보세요",
        "year": "년도",
        "icon_up": "위로",
        "status_failed": "실패한",
        "status_refund": "환불",
        "status_trial": "체험",
        "onHoldMessage": "문제가 발생하여 귀하의 {platform} 기기에서 {productName} 구독료를 자동으로 청구할 수 없습니다."
    },
    "css": "",
    "validator": {
        "is_empty": "필드는 비어 있어야합니다",
        "is_not_empty": "필수 필드",
        "is_email": "입력이 올바른지 확인하세요",
        "is_phone": "정확한 번호를 입력하세요",
        "is_password": "비밀번호는 {extraSymbols}를 포함 할 수 없습니다",
        "is_password_has_enough_symbols": "비밀번호는 6 자 이상이어야합니다",
        "is_password_less_max_symbols": "비밀번호는 32 자 미만이어야합니다",
        "is_password_has_space": "비밀번호에는 공백 표시가 포함될 수 없습니다.",
        "is_not_empty_with_field_name": "{fieldName} 필드는 필수입니다.",
        "is_password_has_not_english_letters": "라틴어가 아닌 문자는 허용되지 않습니다"
    },
    "passwords-not-equal": "비밀번호 불일치",
    "error": {
        "e500": {
            "new": {
                "text": "우리는 이미 이 문제를 알고 있으며 곧 해결할 것입니다! 나중에 다시 시도 해주세요.",
                "title": "500 - 서버 오류"
            }
        },
        "eUnknown": {
            "new": {
                "text": "우리는 이미 이 문제를 알고 있으며 곧 해결할 것입니다! 나중에 다시 시도 해주십시오.",
                "title": "500 - 서버 오류"
            },
            "oops": {
                "text": "앗! 사용자 데이터를 로드하는 중에 오류가 발생했습니다. 페이지를 새로고침해 보세요."
            }
        },
        "e400": {
            "new": {
                "title": "오류"
            }
        }
    },
    "whitelabel": {
        "vpn99": {
            "web_be_safe": "최저가로 온라인 보안 유지",
            "web_online_security": "월 ${pricePerMonth} 부터"
        },
        "trustvpn": {
            "web_be_safe": "지금 바로 온라인 사생활을 보호하세요",
            "web_online_security": "초고속 인터넷 이용 속도, 개인 정보 보안 유지, 디지털에서 누리는 자유 "
        }
    },
    "meta": {
        "descriptionDataRetentionPolicy": "귀하의 데이터가 보관되는 상황 및 기간. 모든 데이터는 GDPR 지침에 따라 안전하게 저장됩니다.",
        "descriptionMyIp": "컴퓨터의 온라인 주소인 IP 주소를 찾으세요. IP 주소가 무엇인지 보세요.",
        "descriptionPrivacyPolicy": "당사의 개인 정보 보호 정책에는 당사가 수집하는 데이터, 사용 방법 및 귀하의 정보를 관리할 수 있는 방법에 대한 정보가 포함됩니다. 우리는 웨일스 법에 따라 쿠키를 사용하지만 귀하는 선호도를 조정할 수 있습니다.",
        "descriptionTerms": "{productName}의 이용 약관에 동의하면 이용 약관과 계약에 명시된 양 당사자의 책임에 동의하는 것입니다. VPN 서비스를 사용할 수 없거나 연속 12 시간 이상 오작동하는 특정 상황에서 보상을 받을 수 있습니다.",
        "descriptionAccountBilling": "계정 - 결제 내역",
        "descriptionAccountPanel": "계정 - 대시보드",
        "descriptionAccountPassword": "계정 - 비밀번호 변경",
        "descriptionDownload": "다운로드",
        "descriptionForgot": "비밀번호 복원",
        "descriptionReset": "비밀번호 재설정",
        "descriptionHome": "{productName}을(를) 다운로드하여 설치하면 전 세계에서 완전한 보호를 받을 수 있습니다. {productName}, 고속 및 완전한 익명성.",
        "descriptionPayFail": "결제 거부됨",
        "descriptionPaySuccess": "결제 성공",
        "descriptionPrice": "{productName}을(를) 구매하고 설치하면 전 세계에서 완전한 보호를 받을 수 있습니다. 값비싼 VPN의 모든 이점을 누리십시오. {productName}, 고속 및 완전한 익명성",
        "descriptionServers": "서버",
        "titleDataRetentionPolicy": "데이터 보존 정책",
        "titleMyIp": "귀하의 IP 주소가 온라인 상태인지 확인하세요. ",
        "titlePrivacyPolicy": "개인 정보 보호 정책 {productName}이 개인 정보를 보호하기 위해 취하는 단계",
        "titleTerms": "이용 약관 {productName}에서 제공하는 서비스를 이용할 때 적용되는 특정 약관.",
        "titleAccountBilling": "계정 - 결제 내역",
        "titleAccountPanel": "계정 - 대시보드",
        "titleAccountPassword": "계정 - 비밀번호 변경",
        "titleDownload": "다운로드",
        "titleForgot": "비밀번호 복원",
        "titleReset": "암호를 재설정",
        "titleHome": "지금 {productName}을(를) 다운로드하면 저렴한 가격으로 안정적인 서비스를 받을 수 있습니다.",
        "titleLogin": "로그인",
        "titlePayFail": "결제 거부됨",
        "titlePaySuccess": "결제 성공",
        "titlePrice": "지금 {productName}을(를) 구입하면 저렴한 가격으로 안정적인 보장을 받을 수 있습니다.",
        "titleRegister": "가입하기",
        "titleServers": "서버",
        "titleApps": "앱",
        "descriptionApps": "앱",
        "descriptionLogin": "로그인",
        "descriptionRegister": "가입하기",
        "titleReturnRefundPolicy": "반품 환불 정책",
        "descriptionReturnRefundPolicy": "반품 환불 정책",
        "titlePolicyAndProcedure": "DMCA 통지 및 게시 중단 정책 및 절차 ",
        "descriptionPolicyAndProcedure": "DMCA 통지 및 게시 중단 정책 및 절차 "
    },
    "getVPN": "{productName} 가져오기",
    "signIn": "가입하기",
    "billings-page": {
        "waiting-processing": "응답을 기다리는 중\n결제 처리에서."
    },
    "servers": {
        "USDal": "미국 달라스"
    }
}
