<template>
  <div>
    <vue-final-modal
      :name="name"
      :lock-scroll="true"
      :click-to-close="true"
      :esc-to-close="true"
      :max-width="400"
      v-model="_showModal"
      classes="c-modal-container"
      content-class="c-modal-content"
    >
      <button class="c-modal__close" @click="_showModal = false">
        <div>+</div>
      </button>
      <div
        class="c-modal__title title title-md text-bold"
        :class="{
          'color-main-second': type === 'default',
          'color-error': type === 'error',
        }"
      >
        {{ title }}
      </div>
      <div class="c-modal__content" v-if="description">
        <p>
          {{ description }}
        </p>
      </div>
      <div class="c-modal__action">
        <c-button
          v-for="(button, index) in buttons"
          :key="index"
          @submit="buttonSudmited(index)"
          :value="button.value"
          size="md"
          transparent
          noBorder
          :disabled="!isButtonsEnabled"
        ></c-button>
      </div>
    </vue-final-modal>
  </div>
</template>

<script>
import CButton from '@/components/Button'

export default {
  name: 'Modal',

  components: {
    CButton,
  },

  data() {
    return {
      isButtonsEnabled: true,
    }
  },

  props: {
    name: {
      type: String,
      default: 'error-modal',
    },
    modelValue: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: 'default',
    },
    title: {
      type: String,
      default: 'Error',
    },
    description: {
      type: [String, null],
      default: null,
    },
    buttons: {
      type: Array,
      defult: () => [],
    },
  },

  emits: ['update:modelValue'],

  computed: {
    _showModal: {
      get() {
        return this.modelValue
      },
      set(value) {
        this.$emit('update:modelValue', value)
      },
    },
  },

  watch: {
    showAfterRegistrationDialog(value) {
      if (value) {
        this.init()
      }
    },
  },

  methods: {
    switchButtons(isEnabled = true) {
      this.isButtonsEnabled = isEnabled
    },
    buttonSudmited(index) {
      if (this.buttons[index].onSubmited) {
        this.switchButtons(false)
        this.buttons[index].onSubmited().finally(() => {
          this._showModal = false
          this.switchButtons(true)
        })
      } else {
        this._showModal = false
      }
    },
  },
}
</script>

<style lang="scss">
@import '@/assets/scss/global/variables';
.c-modal-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.c-modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  max-height: 90%;
  max-width: 500px;
  min-width: 400px;
  width: fit-content;
  margin: 0 1rem;
  border: none;
  border-radius: 0.25rem;
  background: #fff;
  overflow: hidden;
  p:not(:last-child) {
    margin-bottom: 0.5rem;
  }
  body[dir='rtl'] & {
    padding: 2rem 2rem 0 3rem;
  }
  body[dir='ltr'] & {
    padding: 2rem 3rem 0 2rem;
  }
  @media (max-width: 767.98px) {
    width: 90%;
    min-width: 0;
  }
}
.c-modal__title {
  body[dir='rtl'] & {
    margin: 0 0 1rem 2rem;
  }
  body[dir='ltr'] & {
    margin: 0 2rem 1rem 0;
  }
}
.c-modal__content {
  flex-grow: 1;
}
.c-modal__action {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-top: 1px solid #d1d1d1;
  body[dir='rtl'] & {
    margin: 2rem -2rem 0 -3rem;
  }
  body[dir='ltr'] & {
    margin: 2rem -3rem 0 -2rem;
  }
  > * {
    flex-grow: 1;
  }
}
.c-modal__close {
  position: absolute;
  top: 0;
  padding: 0.5rem;
  line-height: 1.4rem;
  font-size: 2rem;
  transition: color $transTimeFast $easeDefault;
  body[dir='rtl'] & {
    left: 0;
  }
  body[dir='ltr'] & {
    right: 0;
  }
  & > div {
    transform: rotate(45deg);
    width: 1.4rem;
    height: 1.4rem;
  }
  &:hover {
    color: var(--main);
  }
}
</style>
