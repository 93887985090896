export default class MagicLink {
  constructor(rest) {
    this.rest = rest
  }

  check(link) {
    return this.rest.get('/magic_links/check', { link })
  }

  execute(link) {
    return this.rest.post('/magic_links/execute', { link }, { isAuth: true })
  }
}
