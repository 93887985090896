import MERCHANT from './MERCHANT'

export default Object.freeze({
  CARD: {
    NAME: 'CARD',
    MERCHANTS: [MERCHANT.STRIPE, MERCHANT.COMMERCE],
  },
  INTERNET_WALLET: {
    NAME: 'INTERNET_WALLET',
    MERCHANTS: [MERCHANT.FREE_KASSA],
  },
  CRYPTOCURRENCY: {
    NAME: 'CRYPTOCURRENCY',
    MERCHANTS: [MERCHANT.FREE_KASSA],
  },
})
