export const getDateFromString = (dateString) => {
  if (!dateString) {
    return null
  }
  const _dateString = dateString.trim()
  if (_dateString.includes(' ') && !_dateString.includes('Z')) {
    return new Date(_dateString.replace(' ', 'T') + 'Z')
  }
  if (_dateString.includes(' ') && _dateString.includes('Z')) {
    return new Date(_dateString.replace(' ', 'T'))
  }
  if (_dateString.includes('T') && !_dateString.includes('Z')) {
    return new Date(_dateString + 'Z')
  }
  if (!_dateString.includes('T') && !_dateString.includes('Z')) {
    return new Date(_dateString + 'T00:00:00Z')
  }
  return new Date(_dateString)
}
