import api from '@/api'
import { tariffName, fixedPeriod } from '@/utils/tariff'
import { getDateFromString } from '@/utils/date'
import i18n from '@/i18n'
import { PAYMENT } from '@/CONSTANTS'
const $t = (val, params = {}) => i18n.global.t(val, params)

const state = () => ({
  id: null,
  email: '',
  token: localStorage.getItem('token') || null,
  currentPlans: null,
  transactions: null,
  isPremium: false,
  hasSubscription: false,
  locale: process.env.vue_app__i18n_fallback_locale,
  realIP: null,
  payedUntil: null,
  nextPayDate: null,
  onHoldOrders: null,
  location: {
    countryCode: '',
    IP: '',
  },
  error: '',
})

const getters = {
  token(state) {
    return state.token
  },
  exist(state) {
    return !!state.email && !!state.id
  },
  error(state) {
    return state.error
  },
  onHoldOrder(state) {
    if (
      !state.onHoldOrders ||
      state.onHoldOrders.length === 0 ||
      state.payedUntil
    ) {
      return null
    }
    const platform =
      state.onHoldOrders[0].service === PAYMENT.SERVICE.APPLE
        ? 'iOS'
        : state.onHoldOrders[0].service === PAYMENT.SERVICE.GOOGLE
        ? 'Android'
        : undefined
    return platform
      ? {
          platform,
        }
      : null
  },
  transactions(state) {
    return (
      state.transactions?.map((transaction) => {
        const { period, numberOfPeriods } = fixedPeriod({
          period: transaction.tariff.period,
          numberOfPeriods: transaction.tariff.numberOfPeriods,
        })
        return Object.assign(transaction, {
          status: transaction.isTrial ? 'trial' : transaction.status,
          title: tariffName({
            period,
            numberOfPeriods,
          }),
        })
      }) || []
    )
  },
  currentPlans(state) {
    return (
      state.currentPlans?.map((plan) => {
        const { period, numberOfPeriods } = fixedPeriod({
          period: plan.period,
          numberOfPeriods: plan.numberOfPeriods,
        })
        return Object.assign(plan, {
          name: tariffName({
            period,
            numberOfPeriods,
          }),
        })
      }) || null
    )
  },
  payedUntil(state) {
    const date = state.payedUntil
    if (!date) {
      return null
    }
    if (date instanceof Date) {
      return date
    } else if (typeof date === 'string') {
      try {
        return getDateFromString(date)
      } catch (error) {
        return null
      }
    }
    return null
  },
  nextPayDate(state) {
    const date = state.nextPayDate
    if (!date) {
      return null
    }
    if (date instanceof Date) {
      return date
    } else if (typeof date === 'string') {
      try {
        return getDateFromString(date)
      } catch (error) {
        return null
      }
    }
    return null
  },
}

const actions = {
  fetchUser({ commit }) {
    return new Promise((resolve, reject) => {
      api.user
        .fetchUser()
        .then(({ data }) => {
          commit('setUser', {
            id: data.id,
            email: data.email,
            currentPlans: data.plans.map((plan) => {
              const _plan = { ...plan }
              if (_plan.paymentMethod?.date) {
                _plan.paymentMethod.date = getDateFromString(
                  _plan.paymentMethod.date
                )
              }
              // TODO: here
              if (_plan.priceFrom) {
                _plan.nextPrice = _plan.priceFrom
              }
              if (_plan.nextPrice?.date) {
                _plan.nextPrice.date = getDateFromString(_plan.nextPrice.date)
              }
              if (_plan.subscription?.date) {
                _plan.subscription.date = getDateFromString(
                  _plan.subscription.date
                )
              }
              if (_plan.unsubscription?.date) {
                _plan.unsubscription.date = getDateFromString(
                  _plan.unsubscription.date
                )
              }
              return _plan
            }),
            transactions: data.transactions.map((transaction) => {
              const _transaction = {
                ...transaction,
                currency: transaction.currency || 'USD',
              }
              if (_transaction.created) {
                _transaction.created = getDateFromString(_transaction.created)
              }
              return _transaction
            }),
            isPremium: data.status === 'ENABLED',
            hasSubscription: data.isSubscribing,
            nextPayDate: data.nextPayDate,
            payedUntil: data.payedUntil,
            onHoldOrders: data.onHoldOrders,
          })
          resolve(data)
        })
        .catch((error) => {
          commit('setError', $t('error.eUnknown.oops.text'))
          reject(error)
        })
    })
  },
  fetchRealIP({ commit }) {
    return api.user.fetchRealIP().then(({ data }) => {
      commit('setRealIP', data)
      return data
    })
  },

  fetchCountryCode({ commit, state }) {
    return new Promise((resolve) => {
      const country = JSON.parse(localStorage.getItem('country'))
      if (country && country.data.countryCode) {
        commit('setCountryCode', country.data.countryCode)
        return resolve(country.data.countryCode)
      }
      api.user
        .fetchIP()
        .then((ip) => {
          commit('setIP', ip.data)
          const country = JSON.parse(localStorage.getItem('country'))

          if (country && country.ip === state.IP && country.data.countryCode) {
            commit('setCountryCode', country.data.countryCode)
            return resolve(country.data.countryCode)
          } else {
            api.user
              .fetchCountryCode({
                ip: ip.data,
              })
              .then((res) => {
                commit('setCountryCode', res.data)
                return resolve(res.data)
              })
              .catch(() => {
                commit('setCountryCode', window.navigator.language.slice(-2))
                return resolve(window.navigator.language.slice(-2))
              })
              .finally(() => {
                localStorage.setItem(
                  'country',
                  JSON.stringify({
                    ip: state.location.IP,
                    data: {
                      countryCode: state.location.countryCode.toUpperCase(),
                    },
                  })
                )
              })
          }
        })
        .catch(() => {
          commit('setCountryCode', window.navigator.language.slice(-2))
          return resolve(window.navigator.language.slice(-2))
        })
    })
  },
  setCountryCode({ commit, state }, countryCode) {
    localStorage.setItem(
      'country',
      JSON.stringify({
        ip: state.location.IP,
        data: {
          countryCode: countryCode,
        },
      })
    )
    commit('setCountryCode', countryCode)
  },
  setLocale({ commit }, locale) {
    localStorage.setItem('locale', locale)
    commit('setLocale', locale)
  },
  setToken({ commit }, token) {
    localStorage.setItem('token', token)
    commit('setToken', token)
  },
  updatePlan({ commit }, { id, newCurrentPlan }) {
    commit('updatePlan', { id, newCurrentPlan })
  },
  resetToken({ commit }) {
    localStorage.removeItem('token')
    commit('resetToken')
  },
  reset({ commit }) {
    localStorage.removeItem('token')
    localStorage.removeItem('password')
    commit('reset')
  },
}

const mutations = {
  setUser(state, data) {
    Object.assign(state, data)
  },

  setToken(state, token) {
    state.token = token
  },

  setError(state, error) {
    state.error = error
  },

  resetToken(state) {
    state.token = null
  },

  updatePlan(state, { id, newCurrentPlan }) {
    const index = state.currentPlans.findIndex((plan) => plan.id === id)
    state.currentPlans.splice(index, 1, newCurrentPlan)
  },

  reset(state) {
    Object.assign(state, {
      id: null,
      email: '',
      token: null,
      currentPlans: null,
      isPremium: false,
      hasSubscription: false,
    })
  },

  setEmail(state, email) {
    state.email = email
  },

  setLocale(state, locale) {
    state.locale = locale
  },

  setCountryCode(state, countryCode) {
    state.location = {
      ...state.location,
      countryCode,
    }
  },

  setIP(state, IP) {
    state.location = {
      ...state.location,
      IP,
    }
  },

  setRealIP(state, realIP) {
    state.realIP = realIP
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
