{
    "web": {
        "why_use": "Mengapa menggunakan {productName}",
        "download_VPN": "Unduh {productName} dan mulai gunakan sekarang juga!",
        "getVPN": "Dapatkan aplikasi {productName}",
        "access": "Akses aman ke konten dari mana pun di seluruh dunia",
        "jurisdiction": "Yuridiksi Uni Eropa menjamin kerahasiaan",
        "anonymity": "Mencegah pelacakan berbasis IP untuk layanan iklan",
        "simple_conf": "Pengaturan dan pengunaan mudah",
        "secure": "Pembayaran aman dan perlindungan dari peretas",
        "protection": "Perlindungan terhadap iklan yang mengganggu",
        "get_VPN_app_phone": "Dapatkan aplikasi {productName} untuk ponsel Anda",
        "keep_safe": "Tetap aman dan privasi.",
        "different_device": "Dapatkan aplikasi {productName} untuk perangkat yang berbeda\n",
        "authorize": "Mengotorisasi",
        "dont_have_an_account": "Belum memiliki akun?",
        "register": "Daftar",
        "pass": "Kata Sandi",
        "sign_in": "Masuk",
        "forgot_pass": "Lupa kata sandi Anda?",
        "email": "Email",
        "instructions_for_macos": "Petunjuk untuk MacOS",
        "instructions_for_windows": "Petunjuk untuk Windows",
        "current-plan": "Paket Saat Ini",
        "date": "Tanggal",
        "price": "Harga",
        "price_from": "Harga mulai dari",
        "next_due": "Jatuh tempo berikutnya",
        "payment": "Pembayaran",
        "cancel": "Batal",
        "go_to_settings": "Masuk ke Pengaturan",
        "ok": "Oke",
        "dialog_cancel-subscription_title-AppStore": "Anda dapat membatalkan langganan melalui akun Apple Store tempat langganan dibuat",
        "dialog_cancel-subscription_title-PlayMarket": "Anda dapat membatalkan langganan melalui akun Play Market tempat langganan dibuat",
        "dialog_cancel-subscription_title-sure": "Anda yakin ingin membatalkan langganan?",
        "yes": "Ya",
        "no": "Tidak",
        "subscription_canceled_successfully": "Langganan berhasil dibatalkan",
        "dashboard_dialog_thanks-for-registration_title": "Terima kasih sudah mendaftar!",
        "dashboard_dialog_thanks-for-registration_description_1": "Harap ingat kata sandi Anda untuk masuk.",
        "Password": "Kata sandi",
        "dashboard_dialog_thanks-for-registration_description_2-1": "Atau",
        "dashboard_dialog_thanks-for-registration_description_2-2": "klik di sini",
        "dashboard_dialog_thanks-for-registration_description_2-3": "untuk mengatur kata sandi Anda sendiri.",
        "prices_date-of-next-payment": "Tanggal pendebitan uang berikutnya",
        "change_password": "Ubah kata sandi",
        "current_password": "Kata sandi saat ini",
        "new_password": "Kata sandi baru",
        "confirm-password": "Konfirmasikan Kata Sandi",
        "reset": "Atur ulang",
        "email_address_associated": "Masukkan alamat email yang terkait dengan akun Anda. Kami akan mengirimkan tautan  kepada Anda melaui email untuk mengatur ulang kata sandi Anda.",
        "get-link": "Kirim Email",
        "sign-in": "Masuk",
        "forget-password_dialog_email-link_title": "Kami telah mengirimkan tautan kepada Anda melalui email untuk mengatur ulang kata sandi Anda.",
        "i-have-promocode": "Saya memiliki kode promo",
        "promo": "Kode Promo",
        "have_an_account": "Saya memiliki akun",
        "reset-password": "Atur Ulang Kata Sandi",
        "state": {
            "register": "Daftar"
        },
        "action": {
            "register": "Daftar",
            "cancel": "Batal"
        },
        "notify_password-changed": "Kata sandi Anda telah diubah",
        "prices_discount": "Diskon",
        "prices_email": "Email",
        "prices_country": "Negara Anda",
        "prices_pay": "Bayar",
        "prices_privacy_p-0": "Dengan mengirimkan formulir ini, Anda menyetujui",
        "prices_privacy_p-1": "Ketentuan  Layanan",
        "prices_privacy_p-2": "dan",
        "prices_privacy_p-3": "Kebijakan Privasi kami",
        "price_card": "Kartu",
        "price_internet-wallet": "Dompet Internet",
        "price_cryptocurrency": "Mata uang kripto",
        "prices_order-total": "Total pesanan",
        "i-dont-have-promocode": "Saya tidak memiliki kode promo",
        "dashboard_dialog_thanks-for-registration_description_1_promo": "Kode promo yang Anda masukkan tidak valid",
        "prices_user-has-account_how-to-change-plan_1": "Untuk mengubah paket tarif, batalkan langganan saat ini di",
        "prices_user-has-account_how-to-change-plan_2": "Penagihan",
        "prices_user-has-account_how-to-change-plan_3": "halaman, lalu beli yang baru",
        "instructions_for_linux": "Instruksi untuk Linux",
        "prices_Step": "Langkah",
        "tariff-name_Year": "Tahun",
        "tariff-name_Month": "Bulan",
        "month": "bulan",
        "prices_save": "Simpan",
        "prices_billedEvery": "ditagih setiap",
        "prices_the-most-popular": "Paling populer",
        "prices_Billed": "Ditagihkan",
        "prices_every": "setiap",
        "icon_check-circle-empty": "Lingkaran Kosong",
        "icon_check-circle": "Kotak centang",
        "icon_like": "Suka",
        "icon_info": "Info",
        "icon_down": "Terputus",
        "icon_check": "Kotak centang",
        "prices_the-1-year-is-better": "Paket 1 Tahun berarti penghematan terbesar untuk Anda",
        "prices_choose-plan": "Pilih paket Anda",
        "prices_Chose-Payment-Method": "Pilih Metode Pembayaran",
        "prices_Why-choose-VPN": "Mengapa memilih {productName}?",
        "prices_VPN-includes": "{productName} mencakup",
        "prices_includes-support": "Dukungan pelanggan 24/7",
        "prices_includes-money-back": "Garansi ​{numberOfDays}​ hari uang kembali",
        "prices_includes-security": "Keamanan dan enkripsi kelas bank",
        "prices_includes-no-logs": "Tidak ada pencatatan aktivitas dan koneksi",
        "instruction": "Petunjuk",
        "apps": "Aplikasi",
        "my-ip": "IP saya",
        "terms": "Syarat & Ketentuan",
        "return-refund-policy": "Kebijakan Retur & Pengembalian Dana",
        "policy_and_procedure": "Kebijakan dan Prosedur Pemberitahuan dan Penghentian DCMA\n",
        "privacy_policy": "Kebijakan privasi",
        "footer_support": "Dukungan",
        "footer_sales-department": "Departemen penjualan",
        "footer_company": "Perusahaan",
        "footer_contacts": "Kontak",
        "data-retention-policy": "Kebijakan Penyimpanan Data",
        "pricing": "Harga",
        "servers": "Server",
        "knowledge-base": "Basis Pengetahuan",
        "dashboard": "Dasbor",
        "to_home_page": "Ke halaman baranda",
        "icon_logo": "Logo",
        "icon_login": "Masuk",
        "icon_menu": "Menu",
        "icon_key": "Kunci",
        "icon_logout": "Keluar",
        "log_out": "Keluar",
        "icon_lifebuoy": "Lifebuoy",
        "knowledge_base": "Basis Pengetahuan",
        "icon_support": "Dukungan",
        "downloads": "Mengunduh",
        "billings": "Penagihan",
        "icon_eyeOpen": "Tampilkan kata sandi",
        "inactive": "Tidak aktif. Lakukan pembayaran untuk mulai menggunakannya",
        "icon_flag": "Bendera",
        "active_until": "Aktif hingga",
        "servers_cta_title": "Mulai Penelurusan Aman Sekarang",
        "servers_cta_description": "Buat internet Anda tetap aman dan privasi",
        "downloads_instruction": "Petunjuk",
        "downloads_router": "Router",
        "downloads_openVPN_profiles": "Profil OpenVPN",
        "tariff-name-long_Year": "Satu Tahun",
        "tariff-name-long_Month": "Satu Bulan",
        "dialog_cancel-subscription_header": "Batalkan Langganan",
        "web_payment_history": "Riwayat pembayaran ",
        "tariff": "Tarif",
        "payment_method": "Metode pembayaran",
        "status": "Status",
        "sum": "Jumlah",
        "tbody_is_empty_1": "Di sini Anda akan melihat informasi tentang pembayaran Anda",
        "tbody_is_empty_2": "Lakukan pemesanan",
        "status_paid": "Dibayarkan",
        "status_not-paid": "Belum Dibayar",
        "my_services": "Layanan saya",
        "private_and_secure": "Akses internet privasi dan aman (VPN)",
        "active": "Aktif",
        "downloads_apps_and_files": "Aplikasi dan File",
        "downloads_for_mobile": "Seluler",
        "downloads_for_desktop": "Desktop",
        "downloads_for_browsers": "Peramban",
        "downloads_other_devices": "Perangkat lain",
        "forget-password_dialog_email-link_header": "Periksa email Anda",
        "pageNotFoundTitle": "Halaman tidak ditemukan",
        "pageNotFoundDescription": "Sepertinya tidak ada halaman seperti itu. Silakan kembali kepada kami.",
        "pageNotFoundAction": "Kembali ke website {productName}",
        "home": {
            "mainTitle": {
                "highlited": "Perlindungan",
                "rest": "digital Anda"
            },
            "mainDescription": {
                "first": "untuk semua data pribadi dan komputer Anda",
                "second": "terhadap penipu dunia maya"
            },
            "mainButton": "Mulai dari $0,250 per bulan*",
            "mainButtonDescription": "*garansi uang kembali",
            "secureTitle": {
                "first": "Internet",
                "second": "yang benar-benar aman"
            },
            "secureDescription": "VPN99 menghapus semua yang membuat Anda unik dan dapat dikenali di internet. Ini menyembunyikan alamat IP Anda dan mengenkripsi data untuk menjaga riwayat penelusuran Anda tetap pribadi.",
            "secureCard": {
                "noRestrictionsTitle": "Tidak ada pembatasan!",
                "noRestrictionsDescription": "Semua situs internet tersedia untuk Anda, bahkan yang diblokir. Selain itu, semua data Anda dilindungi dengan andal dari penipu.",
                "anonimTitle": "Anda sekarang anonim!",
                "anonimDescription": "Setiap koneksi tunggal adalah anonim! Kami tidak menyimpan log, bahkan kami tidak mengetahui apa yang Anda lakukan di internet ;)"
            },
            "devicesTitle": {
                "first": "Satu langganan",
                "second": "untuk semua perangkat!"
            },
            "devicesDescription": "MacOS, Windows, Android, iOS, ekstensi untuk Chrome dan Firefox, dan banyak lagi",
            "serversTitle": {
                "first": "Koneksi cepat dan stabil",
                "second": "di mana saja!"
            },
            "serversDescription": "Pilih salah satu dari 17 server atau percayai server yang direkomendasikan - yaitu server terdekat dan karena itu yang tercepat. Anda akan dapat dengan bebas menonton film dan acara TV dalam kualitas HD.",
            "faqTitle": "Pertanyaan Umum",
            "faq": {
                "whatIsVPNTitle": "Apa yang dimaksud VPN?",
                "whatIsVPNDescription": {
                    "p1": "VPN adalah singkatan dari Virtual Private Network.",
                    "p2": "Tujuan utama layanan kami adalah memastikan keamanan dan privasi Anda. Untuk ini, ​{productName}​ mengenkripsi lalu lintas yang Anda kirimkan melalui jaringan."
                },
                "rollUp": "Gulung",
                "more": "Baca",
                "howWorksTitle": "Bagaimana cara kerja VPN?",
                "howWorksDescription": {
                    "p1": "VPN menambahkan antarmuka jaringan baru ke sistem yang berfungsi seolah-olah Anda menghubungkan perangkat Anda langsung ke jaringan pribadi. VPN juga mengubah alamat IP Anda dan mengenkripsi semua data yang dikirim. VPN memungkinkan informasi untuk diterima dan dikirim melalui jaringan publik (melalui internet, misalnya) seolah-olah perangkat Anda terhubung ke jaringan pribadi, secara langsung, menerapkan kebijakan keamanan yang sama dan menyediakan akses ke semua sumber daya di jaringan pribadi."
                },
                "whatDiferenceWithProxyTitle": "Apa perbedaan antara server proxy dan layanan VPN?",
                "whatDiferenceWithProxyDescription": {
                    "p1": "Koneksi VPN jauh lebih stabil dan terenkripsi, tidak seperti proxy. Tidak semua program dapat berjalan dengan server proxy dan banyak dari program itu memerlukan konfigurasi lebih lanjut. Jika Anda menggunakan VPN, maka Anda tidak perlu mengonfigurasi masing-masing aplikasi satu per satu."
                },
                "whatCountriesTitle": "Di negara mana VPN tersedia?",
                "whatCountries": {
                    "first": "Daftar lokasi saat ini di berbagai negara dapat ditemukan",
                    "link": "di sini",
                    "second": "Jadi, Anda mendapatkan alamat IP dengan geolokasi negara-negara ini saat Anda terhubung."
                },
                "logsTitle": "Apakah Anda menyimpan file log?",
                "logs": {
                    "p1": "{productName} memiliki kebijakan larangan tanpa pencatatan yang ketat."
                }
            },
            "footerTitle": {
                "first": "Mulai",
                "second": "menggunakan VPN99"
            },
            "footerDescription": "Unduh aplikasi di perangkat Anda"
        },
        "my_ip_address": "Alamat IP saya",
        "your_ip": "Ini adalah alamat IP Anda",
        "fail-payment_title": "Ups! Terjadi kesalahan.",
        "fail-payment_description": "Tarif Anda belum dibayar. Mungkin Anda melakukan kesalahan saat memasukkan data saat pembayaran. Jangan khawatir, Anda bisa mencoba lagi.",
        "fail-payment_go-to-prices": "Masuk ke paket tarif dan coba lagi",
        "success_congratulations-title": "Selamat atas pembelian Anda!",
        "success_congratulations-description": "Silakan tunggu. Anda akan diarahkan ke akun pribadi Anda, di mana Anda dapat mengelola pembelian Anda.",
        "success-payment_go-to-dashboard": "Masuk sekarang",
        "servers_title": "Akses satu klik ke 10+ negara",
        "servers_title_description": "Pilih satu server di {continents_number} benua di salah satu {servers_number} negara",
        "servers_locations_list_title": "Daftar lokasi server VPN",
        "servers_continent_all": "Semua negara",
        "allservers": "Negara",
        "continent_asia": "Asia",
        "continent_europe": "Eropa",
        "continent_america": "Amerika",
        "our_vpn_server": "Server kami",
        "subscription_canceled_failure": "Ups! Terjadi kesalahan saat membatalkan. Silakan hubungi dukungan.",
        "prices_trial-days": "+ percobaan {count} hari",
        "days": "{count} hari",
        "prices_trial": "Percobaan",
        "pageForbidden": "Akses Dibatasi",
        "pageForbiddenTitle": "Akses Dibatasi",
        "pageForbiddenDescription": "Tampaknya bahwa tempat Anda mencoba mendapatkan akses dibatasi.",
        "pageForbiddenAction": "Kembali ke situs web {productName}",
        "private_finally": "Perambahan online pribadi",
        "no_one_can_see": "Tidak seorang pun yang bisa melihat apa yang sedang Anda lakukan",
        "no_restrictions_on_content_and_censorship": "Tidak ada batasan online atau penyensoran",
        "one_click_and_you_defend": "Satu klik dan Anda terlindungi",
        "removes_everything": "{productName} menghapus semua yang membuat Anda unik dan dapat dikenali secara online, dengan menyembunyikan alamat IP Anda dan mengenkripsi data Anda untuk membuat riwayat penelusuran Anda privasi.",
        "enjoy_fast_and_stable_connection": "Nikmati koneksi yang cepat dan stabil di mana saja",
        "choose_your_server": "Pilih server Anda di {count} negara dan nikmati layanan VPN tercepat. Semakin banyak server berarti semakin sedikit muatan, dan banyaknya lokasi berarti Anda semakin mungkin untuk menemukan yang terdekat",
        "easy_to_use_apps": "Aplikasi ramah pengguna untuk semua perangkat Anda",
        "subscription_comes": "Satu langganan {productName} untuk setiap perangkat yang Anda miliki: {appsList} dan lainnya",
        "money_back_guarantee": "Jaminan uang kembali",
        "faq_footer_description": "Anda dapat menemukan informasi lain yang menarik untuk Anda di",
        "faq_footer_knowledge-base": "Basis Pengetahuan kami",
        "tryFree": "Coba Gratis",
        "year": "tahun",
        "icon_up": "Naik",
        "status_failed": "Gagal",
        "status_refund": "Pengembalian dana",
        "status_trial": "Percobaan",
        "onHoldMessage": "Terjadi kesalahan dan kami belum dapat membebankan biaya secara otomatis kepada Anda untuk langganan {productName} pada perangkat {platform} Anda."
    },
    "css": "",
    "validator": {
        "is_empty": "Kolom ini harus kosong",
        "is_not_empty": "Kolom ini wajib diisi",
        "is_email": "Pastikan masukan sudah benar",
        "is_phone": "Masukkan nomor yang benar",
        "is_password": "Kata sandi tidak boleh mengandung {extraSymbols}",
        "is_password_has_enough_symbols": "Kata sandi setidaknya harus 6 Karakter.",
        "is_password_less_max_symbols": "Kata sandi harus terdiri kurang dari 32 karakter",
        "is_password_has_space": "Kata sandi tidak boleh mengandung spasi",
        "is_not_empty_with_field_name": "Kolom {fieldName} wajib diisi",
        "is_password_has_not_english_letters": "Selain huruf Latin tidak diperbolehkan"
    },
    "passwords-not-equal": "Kata sandi tidak sesuai",
    "error": {
        "e500": {
            "new": {
                "text": "Kami sudah mengetahui tentang masalah ini dan akan segera menyelesaikannya! Silakan coba lagi.",
                "title": "500 — Server Error"
            }
        },
        "eUnknown": {
            "new": {
                "text": "Kami sudah mengetahui tentang masalah ini dan akan segera menyelesaikannya! Silakan coba lagi.",
                "title": "500 — Server Error"
            },
            "oops": {
                "text": "Ups! Terjadi kesalahan saat memuat data Anda. Silakan coba muat ulang halaman."
            }
        },
        "e400": {
            "new": {
                "title": "Kesalahan"
            }
        }
    },
    "whitelabel": {
        "vpn99": {
            "web_be_safe": "Keamanan online dengan harga terbaik",
            "web_online_security": "Mulai dari ${pricePerMonth} per bulan"
        },
        "trustvpn": {
            "web_be_safe": "Tetap aman dengan Trust VPN",
            "web_online_security": "Kecepatan ultracpat, Menjaga privasi Anda, Kebebasan digital"
        }
    },
    "meta": {
        "descriptionDataRetentionPolicy": "Situasi dan periode penyimpanan data Anda akan tetap dipertahankan. Semua data Anda disimpan dengan aman sesuai dengan pedoman GDPR.",
        "descriptionMyIp": "Cari tahu Alamat IP Anda, alamat komputer Anda yang online. Lihat apakah Alamat IP Anda muncul sebagai ",
        "descriptionPrivacyPolicy": "Kebijakan Privasi kami mencakup informasi tentang data apa yang kami kumpulkan dan bagaimana kami menggunakannya, serta bagaimana Anda dapat mengontrol informasi Anda. Kami menggunakan cookie sesuai dengan hukum Welsh, tetapi Anda dapat menyesuaikan preferensi Anda.",
        "descriptionTerms": "Dengan menerima Syarat Penggunaan {productName}, Anda menyetujui syarat dan ketentuan, serta tanggung jawab kedua belah pihak yang diuraikan dalam perjanjian ini. Kompensasi tersedia dalam keadaan tertentu ketika layanan VPN tidak tersedia atau tidak berfungsi selama lebih dari 12 jam berturut-turut.",
        "descriptionAccountBilling": "Akun - Riwayat pembayaran",
        "descriptionAccountPanel": "Akun - Dasbor",
        "descriptionAccountPassword": "Akun - Ubah kata sandi",
        "descriptionDownload": "Unduh",
        "descriptionForgot": "Pulihkan kata sandi",
        "descriptionReset": "Atur ulang kata sandi",
        "descriptionHome": "Unduh dan instal ​{productName}​​ dan Anda akan mendapatkan perlindungan penuh di seluruh dunia. ​{productName}​​, kecepatan tinggi dan anonimitas penuh.",
        "descriptionPayFail": "Pembayaran ditolak",
        "descriptionPaySuccess": "Pembayaran telah berhasil.",
        "descriptionPrice": "Beli dan instal ​{productName}​ dan Anda akan mendapatkan perlindungan penuh di seluruh dunia. Dapatkan semua manfaat VPN yang mahal. ​​{productName}​​, kecepatan tinggi dan anonimitas penuh",
        "descriptionServers": "Server",
        "titleDataRetentionPolicy": "Kebijakan Penyimpanan Data {productName}",
        "titleMyIp": "Cari tahu alamat IP Anda mana yang online",
        "titlePrivacyPolicy": "Kebijakan Privasi: langkah-langkah yang diambil {productName} untuk melindungi informasi pribadi Anda",
        "titleTerms": "Syarat Penggunaan: persyaratan khusus yang berlaku saat memanfaatkan layanan yang disediakan oleh {productName}.",
        "titleAccountBilling": "Akun - Riwayat pembayaran",
        "titleAccountPanel": "Akun - Dasbor",
        "titleAccountPassword": "Akun - Ubah kata sandi",
        "titleDownload": "Unduh",
        "titleForgot": "Pulihkan kata sandi",
        "titleReset": "Atur ulang kata sandi",
        "titleHome": "Unduh ​{productName}​ sekarang dan Anda akan mendapatkan perlindungan yang andal dengan harga terjangkau.",
        "titleLogin": "Masuk",
        "titlePayFail": "Pembayaran ditolak",
        "titlePaySuccess": "Pembayaran berhasil.",
        "titlePrice": "Dapatkan ​​{productName}​​ sekarang dan Anda akan mendapatkan perlindungan yang andal dengan harga terjangkau",
        "titleRegister": "Daftar",
        "titleServers": "Server",
        "titleApps": "Aplikasi",
        "descriptionApps": "Aplikasi",
        "descriptionLogin": "Masuk",
        "descriptionRegister": "Daftar",
        "titleReturnRefundPolicy": "Kebijakan Pengembalian Dana",
        "descriptionReturnRefundPolicy": "Kebijakan Pengembalian Dana",
        "titlePolicyAndProcedure": "Kebijakan dan Prosedur Pemberitahuan dan Penghentian DCMA\n",
        "descriptionPolicyAndProcedure": "Kebijakan dan Prosedur Pemberitahuan dan Penghentian DCMA\n"
    },
    "getVPN": "Dapatkan {productName}",
    "signIn": "Masuk",
    "billings-page": {
        "waiting-processing": "Menunggu respons\ndari pemrosesan pembayaran"
    },
    "servers": {
        "USDal": "Dallas AS"
    }
}
