export default Object.freeze({
  GOOGLE_PLAY: 'Google Play',
  APP_STORE: 'App Store',
  MAC_APP_STORE: 'Mac App Store',
  WINDOWS_APP_STORE: 'Windows Apps',
  LINUX_APP_STORE: 'Linux Apps',
  CHROME_WEB_STORE: 'Chrome Web Store',
  FIREFOX_WEB_STORE: 'Add-ons for Firefox',
  OPERA_WEB_STORE: 'Add-ons for Opera',
  EDGE_WEB_STORE: 'Add-ons for Microsoft Edge',
})
