export default Object.freeze({
  KNOWLEDGE_BASE_URL:
    process.env.VUE_APP__KNOWLEDGE_BASE_URL === 'NULL'
      ? null
      : process.env.VUE_APP__KNOWLEDGE_BASE_URL || null,
  IOS_APP_URL:
    process.env.VUE_APP__IOS_APP_URL === 'NULL'
      ? null
      : process.env.VUE_APP__IOS_APP_URL || null,
  ANDROID_APP_URL:
    process.env.VUE_APP__ANDROID_APP_URL === 'NULL'
      ? null
      : process.env.VUE_APP__ANDROID_APP_URL || null,
  EXTENSION_CHROME_URL:
    process.env.VUE_APP__EXTENSION_CHROME_URL === 'NULL'
      ? null
      : process.env.VUE_APP__EXTENSION_CHROME_URL || null,
  EXTENSION_FIREFOX_URL:
    process.env.VUE_APP__EXTENSION_FIREFOX_URL === 'NULL'
      ? null
      : process.env.VUE_APP__EXTENSION_FIREFOX_URL || null,
  EXTENSION_OPERA_URL:
    process.env.VUE_APP__EXTENSION_OPERA_URL === 'NULL'
      ? null
      : process.env.VUE_APP__EXTENSION_OPERA_URL || null,
  EXTENSION_EDGE_URL:
    process.env.VUE_APP__EXTENSION_EDGE_URL === 'NULL'
      ? null
      : process.env.VUE_APP__EXTENSION_EDGE_URL || null,
  MACOS_APP_URL:
    process.env.VUE_APP__MACOS_APP_URL === 'NULL'
      ? null
      : process.env.VUE_APP__MACOS_APP_URL || null,
  LINUX_APP_URL:
    process.env.VUE_APP__LINUX_APP_URL === 'NULL'
      ? null
      : process.env.VUE_APP__LINUX_APP_URL || null,
  WINDOWS_APP_URL:
    process.env.VUE_APP__WINDOWS_APP_URL === 'NULL'
      ? null
      : process.env.VUE_APP__WINDOWS_APP_URL || null,
  PRODUCT_NAME: process.env.VUE_APP__PRODUCT_NAME,
  APP_NAME: process.env.VUE_APP__APP_NAME,
  IS_DEV: JSON.parse(process.env.VUE_APP__IS_DEV || 'false'),
})
