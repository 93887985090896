{
    "web": {
        "why_use": "Neden {productName} kullanmalısınız",
        "download_VPN": "{productName} ürününü indirin ve bugün kullanmaya başlayın!",
        "getVPN": "{productName} edinin",
        "access": "Dünyanın her yerinden içeriğe güvenli erişim",
        "jurisdiction": "AB'nin yargı yetkisi gizliliği garanti eder",
        "anonymity": "Reklam hizmetleri için IP tabanlı izlemeyi önler",
        "simple_conf": "Kolay kurulum ve kullanım",
        "secure": "Güvenli ödemeler ve bilgisayar korsanlarından koruma",
        "protection": "İzinsiz reklamcılığa karşı koruma",
        "get_VPN_app_phone": "Cihazınız için {productName} edinin",
        "keep_safe": "Çevrimiçi ortamda güvende ve gizli kalın.",
        "different_device": "Farklı bir cihaz için {productName} edinin",
        "authorize": "Oturum Aç",
        "dont_have_an_account": "Hesabınız yok mu?",
        "register": "Kaydol",
        "pass": "Şifre",
        "sign_in": "Oturum Aç",
        "forgot_pass": "Şifrenizi mi unuttunuz?",
        "email": "E-posta",
        "instructions_for_macos": "MacOS için talimatlar",
        "instructions_for_windows": "Windows için talimatlar",
        "current-plan": "Mevcut Plan",
        "date": "Tarih",
        "price": "Fiyat",
        "price_from": "En Düşük",
        "next_due": "Sonraki borç",
        "payment": "Ödeme",
        "cancel": "İptal",
        "go_to_settings": "Ayarlara Git",
        "ok": "Tamam",
        "dialog_cancel-subscription_title-AppStore": "Aboneliği, aboneliğin yapıldığı Apple Store hesabı üzerinden iptal edebilirsiniz",
        "dialog_cancel-subscription_title-PlayMarket": "Aboneliği, aboneliğin yapıldığı Play Market hesabı üzerinden iptal edebilirsiniz",
        "dialog_cancel-subscription_title-sure": "Aboneliği iptal etmek istediğinizden emin misiniz?",
        "yes": "Evet",
        "no": "Hayır",
        "subscription_canceled_successfully": "Abonelik başarıyla iptal edildi",
        "dashboard_dialog_thanks-for-registration_title": "Kayıt olduğunuz için teşekkür ederiz!",
        "dashboard_dialog_thanks-for-registration_description_1": "Lütfen giriş yapmak için şifrenizi hatırlayın.",
        "Password": "Şifre",
        "dashboard_dialog_thanks-for-registration_description_2-1": "Veya",
        "dashboard_dialog_thanks-for-registration_description_2-2": "buraya tıklayarak",
        "dashboard_dialog_thanks-for-registration_description_2-3": "kendi şifrenizi belirleyin.",
        "prices_date-of-next-payment": "Bir sonraki para borçlandırma tarihi",
        "change_password": "Şifre Değiştir",
        "current_password": "Mevcut Şifre",
        "new_password": "Yeni Şifre",
        "confirm-password": "Şifreyi Onayla",
        "reset": "Sıfırla",
        "email_address_associated": "Hesabınızla ilişkili e-posta adresini girin. Size, şifrenizi sıfırlamak için bağlantı içeren bir e-posta göndereceğiz.",
        "get-link": "E-posta Gönder",
        "sign-in": "Oturum Aç",
        "forget-password_dialog_email-link_title": "Size, şifrenizi sıfırlamanız için bağlantı içeren bir e-posta gönderdik.",
        "i-have-promocode": "Promosyon kodum var",
        "promo": "Promosyon Kodu",
        "have_an_account": "Hesabım var",
        "reset-password": "Şifreyi Sıfırla",
        "state": {
            "register": "Kaydol"
        },
        "action": {
            "register": "Kaydol",
            "cancel": "İptal"
        },
        "notify_password-changed": "Şifre değiştirildi",
        "prices_discount": "İndirim",
        "prices_email": "E-posta",
        "prices_country": "Ülkeniz",
        "prices_pay": "Ödeme Yap",
        "prices_privacy_p-0": "Bu formu göndererek, aşağıdakileri kabul etmiş olursunuz:",
        "prices_privacy_p-1": "Hizmet Koşulları",
        "prices_privacy_p-2": "ve",
        "prices_privacy_p-3": "Gizlilik Politikası",
        "price_card": "Kart",
        "price_internet-wallet": "İnternet Cüzdanı",
        "price_cryptocurrency": "Kripto para",
        "prices_order-total": "Sipariş toplamı",
        "i-dont-have-promocode": "Promosyon kodum yok",
        "dashboard_dialog_thanks-for-registration_description_1_promo": "Girdiğiniz promosyon kodu geçerli değil",
        "prices_user-has-account_how-to-change-plan_1": "Tarife planını değiştirmek için, Faturalandırmalar sayfasındaki mevcut aboneliği iptal edin",
        "prices_user-has-account_how-to-change-plan_2": "ve ardından",
        "prices_user-has-account_how-to-change-plan_3": "yeni bir tane satın alın",
        "instructions_for_linux": "Linux için talimatlar",
        "prices_Step": "Adım",
        "tariff-name_Year": "Yıl",
        "tariff-name_Month": "Ay",
        "month": "ay",
        "prices_save": "Kaydet",
        "prices_billedEvery": "her yıl faturalandırılır",
        "prices_the-most-popular": "En popüler",
        "prices_Billed": "Faturalandırılan",
        "prices_every": "her",
        "icon_check-circle-empty": "Boş Daire",
        "icon_check-circle": "Onay kutusu",
        "icon_like": "Beğen",
        "icon_info": "Bilgi",
        "icon_down": "Aşağı",
        "icon_check": "Onay kutusu",
        "prices_the-1-year-is-better": "1 Yıllık Plan, sizin için en büyük tasarruf anlamına gelir",
        "prices_choose-plan": "Planınızı Seçin",
        "prices_Chose-Payment-Method": "Ödeme Yöntemini Seçin",
        "prices_Why-choose-VPN": "Neden {productName} seçmelisiniz?",
        "prices_VPN-includes": "{productName} şunları içerir",
        "prices_includes-support": "7/24 müşteri desteği",
        "prices_includes-money-back": "{numberOfDays} gün para iade garantisi",
        "prices_includes-security": "Banka kalitesinde güvenlik ve şifreleme",
        "prices_includes-no-logs": "Etkinlik ve bağlantı kaydı yok",
        "instruction": "Talimatlar",
        "apps": "Uygulamalar",
        "my-ip": "IP adresim",
        "terms": "Hükümler ve Koşullar",
        "return-refund-policy": "İade ve Geri Ödeme Politikası",
        "policy_and_procedure": "DMCA Uyar ve Kaldır Politikası ve Prosedürleri",
        "privacy_policy": "Gizlilik Politikası",
        "footer_support": "Destek",
        "footer_sales-department": "Satış Departmanı",
        "footer_company": "Şirket",
        "footer_contacts": "İletişim",
        "data-retention-policy": "Veri Saklama Politikası",
        "pricing": "Ücretlendirme",
        "servers": "Sunucular",
        "knowledge-base": "Bilgi Bankası",
        "dashboard": "Kontrol Paneli",
        "to_home_page": "Ana sayfaya geri dön",
        "icon_logo": "Logo",
        "icon_login": "Giriş",
        "icon_menu": "Menü",
        "icon_key": "Anahtar",
        "icon_logout": "Çıkış",
        "log_out": "Çıkış Yap",
        "icon_lifebuoy": "Can simidi",
        "knowledge_base": "Bilgi Bankası",
        "icon_support": "Destek",
        "downloads": "İndirilenler",
        "billings": "Faturalar",
        "icon_eyeOpen": "Şifreyi Göster",
        "inactive": "Etkin değil. Kullanmaya başlamak için ödemenizi yapın",
        "icon_flag": "Bayrak",
        "active_until": "Şu tarihe kadar etkin",
        "servers_cta_title": "Güvenli Göz Atmaya Bugün Başlayın",
        "servers_cta_description": "İnternetinizi güvende ve gizli tutun",
        "downloads_instruction": "Talimat",
        "downloads_router": "Yönlendirici (Router)",
        "downloads_openVPN_profiles": "OpenVPN profilleri",
        "tariff-name-long_Year": "Bir Yıl",
        "tariff-name-long_Month": "Bir Ay",
        "dialog_cancel-subscription_header": "Aboneliği İptal Et",
        "web_payment_history": "Ödeme geçmişi",
        "tariff": "Tarife",
        "payment_method": "Ödeme yöntemi",
        "status": "Durum",
        "sum": "Toplam",
        "tbody_is_empty_1": "Burada ödemelerinizle ilgili bilgileri göreceksiniz",
        "tbody_is_empty_2": "Sipariş ver",
        "status_paid": "Ödendi",
        "status_not-paid": "Ödenmedi",
        "my_services": "Hizmetlerim",
        "private_and_secure": "Özel ve güvenli internet erişimi (VPN)",
        "active": "Aktif",
        "downloads_apps_and_files": "Uygulamalar ve Dosyalar",
        "downloads_for_mobile": "Mobil",
        "downloads_for_desktop": "Masaüstü Bilgisayarlar",
        "downloads_for_browsers": "Tarayıcılar",
        "downloads_other_devices": "Diğer cihazlar",
        "forget-password_dialog_email-link_header": "E-postanızı kontrol edin",
        "pageNotFoundTitle": "Sayfa Bulunamadı",
        "pageNotFoundDescription": "Öyle bir sayfa yok gibi görünüyor. Lütfen bize geri dönün.",
        "pageNotFoundAction": "{productName} web sitesine geri dönün",
        "home": {
            "mainTitle": {
                "highlited": "Dijital",
                "rest": "korumanız"
            },
            "mainDescription": {
                "first": "dijital olarak",
                "second": "korunması."
            },
            "mainButton": "Ayda 2,50 $'dan başlayan fiyatlarla*",
            "mainButtonDescription": "*para iadesi garantili",
            "secureTitle": {
                "first": "Gerçekten güvenli",
                "second": "İnternet"
            },
            "secureDescription": "VPN99, sizi İnternette benzersiz ve tanınır kılan her şeyi kaldırır. IP adresinizi gizler ve tarama geçmişinizi gizli tutmak için verileri şifreler.",
            "secureCard": {
                "noRestrictionsTitle": "Yasak yok!",
                "noRestrictionsDescription": "Engellenenler de dahil olmak üzere tüm İnternet siteleri sizin için erişilebilir. Ayrıca, tüm verileriniz dolandırıcılardan güvenilir bir şekilde korunur.",
                "anonimTitle": "Artık anonimsiniz!",
                "anonimDescription": "Her bir bağlantı anonimdir! Günlükleri saklamıyoruz, bu yüzden internette ne yaptığınız hakkında hiçbir fikrimiz yok ;)"
            },
            "devicesTitle": {
                "first": "Tüm cihazlar için",
                "second": "tek abonelik!"
            },
            "devicesDescription": "MacOS, Windows, Android, iOS, Chrome ve Firefox için uzantılar ve çok daha fazlası",
            "serversTitle": {
                "first": "Her yerde hızlı",
                "second": "ve kararlı bağlantı!"
            },
            "serversDescription": "17 sunucudan birini seçin veya önerilen sunucuya güvenin. Önerilen sunucu en yakın ve dolayısıyla en hızlı olandır. Filmleri ve TV şovlarını HD kalitesinde ücretsiz olarak izleyebileceksiniz.",
            "faqTitle": "SSS",
            "faq": {
                "whatIsVPNTitle": "VPN ne anlama geliyor?",
                "whatIsVPNDescription": {
                    "p1": "VPN, (Virtual Private Network) Sanal Özel Ağ anlamına gelir.",
                    "p2": "Hizmetimizin temel amacı güvenliğinizi ve gizliliğinizi sağlamaktır. Bunu yapmak için, {productName} ağ üzerinden ilettiğiniz trafiği şifreler."
                },
                "rollUp": "Toplanın",
                "more": "Okuyun",
                "howWorksTitle": "VPN nasıl çalışır?",
                "howWorksDescription": {
                    "p1": "VPN, sisteme yeni bir ağ arabirimi ekler ve cihazınızı doğrudan özel ağa bağlıyormuşsunuz gibi çalışır. Ayrıca IP adresinizi değiştirir ve iletilen tüm verileri şifreler. VPN, tıpkı cihazınız özel bir ağa bağlıymış gibi, aynı güvenlik politikalarını uygulayarak ve özel ağdaki tüm kaynaklara erişim sağlayarak, bilgilerin genel ağlar üzerinden (örneğin internet yoluyla) alınmasına ve iletilmesine olanak tanır."
                },
                "whatDiferenceWithProxyTitle": "Proxy sunucuları ile VPN hizmeti arasındaki fark nedir?",
                "whatDiferenceWithProxyDescription": {
                    "p1": "VPN bağlantıları, proxy'den farklı olarak çok daha kararlıdır ve şifrelidir. Tüm programlar proxy sunucularla çalışamaz ve birçoğu daha fazla yapılandırma gerektirir. VPN kullanıyorsanız, o halde her uygulamayı ayrı ayrı yapılandırmanıza gerek yok."
                },
                "whatCountriesTitle": "Hangi ülkeler mevcut?",
                "whatCountries": {
                    "first": "Çeşitli ülkelerdeki mevcut konum listesi",
                    "link": "burada bulunabilir.",
                    "second": "Böylece, bağlandığınızda bu ülkelerin coğrafi konumu ile bir IP adresi alırsınız."
                },
                "logsTitle": "Günlük dosyalarını kaydediyor musunuz?",
                "logs": {
                    "p1": "{productName} katı bir günlük tutmama politikasına sahip."
                }
            },
            "footerTitle": {
                "first": "VPN99",
                "second": "kullanmaya başlayın"
            },
            "footerDescription": "Uygulamayı cihazınıza indirin"
        },
        "my_ip_address": "IP adresim",
        "your_ip": "Bu sizin IP adresiniz",
        "fail-payment_title": "Eyvah! Bir şeyler ters gibi.",
        "fail-payment_description": "Tarifeniz ödenmedi. Belki de ödeme sırasında veri girerken bir hata yaptınız. Merak etmeyin, tekrar deneyebilirsiniz.",
        "fail-payment_go-to-prices": "Tarife planlarına gidin ve tekrar deneyin",
        "success_congratulations-title": "Satın aldığınız için tebrikler!",
        "success_congratulations-description": "Lütfen bekleyin. Satın alımlarınızı yönetebileceğiniz kişisel hesabınıza yönlendirileceksiniz.",
        "success-payment_go-to-dashboard": "Şimdi giriş yapın",
        "servers_title": "10'dan fazla ülkeye tek tıkla erişim",
        "servers_title_description": "{continents_number} kıtada {servers_number} ülkeden birinde bir VPN sunucusu seçin",
        "servers_locations_list_title": "VPN sunucu konumlarının listesi",
        "servers_continent_all": "Tüm ülkeler",
        "allservers": "Ülke",
        "continent_asia": "Asya",
        "continent_europe": "Avrupa",
        "continent_america": "Amerika",
        "our_vpn_server": "Sunucularımız",
        "subscription_canceled_failure": "Tühh! İptal edilirken bir hata oluştu. Lütfen destek ile iletişime geçin.",
        "prices_trial-days": "+ {count} günlük deneme",
        "days": "{count} gün",
        "prices_trial": "Deneme",
        "pageForbidden": "Yasak Erişim",
        "pageForbiddenTitle": "Yasak Erişim",
        "pageForbiddenDescription": "Görünüşe göre erişmeye çalıştığınız yer kısıtlanmış.",
        "pageForbiddenAction": "{productName} web sitesine dönün",
        "private_finally": "Özel çevrimiçi gezinme",
        "no_one_can_see": "Ne yaptığını kimse göremez",
        "no_restrictions_on_content_and_censorship": "Çevrimiçi kısıtlama veya sansür yok",
        "one_click_and_you_defend": "Tek tık, ve korunursun",
        "removes_everything": "{productName}, göz atma geçmişinizi gizli tutmak için IP adresinizi gizleyerek ve verilerinizi şifreleyerek sizi benzersiz yapan ve çevrimiçi olarak tanınabilir kılan her şeyi kaldırır.",
        "enjoy_fast_and_stable_connection": "Her yerde hızlı ve kararlı bağlantının keyfini çıkarın",
        "choose_your_server": "{count} ülkede sunucunuzu seçin ve en hızlı VPN hizmetinin keyfini çıkarın. Daha fazla sunucu, daha az yük anlamına gelir ve çok çeşitli konumlar, yakınlarda bir tane bulma olasılığınızın daha yüksek olduğu anlamına gelir",
        "easy_to_use_apps": "Tüm cihazlarınız için kullanıcı dostu uygulamalar",
        "subscription_comes": "Sahip olduğunuz her cihaz için sadece tek bir {productName} aboneliği: {appsList} ve daha fazlası",
        "money_back_guarantee": "Para iade garantisi",
        "faq_footer_description": "Sizi ilgilendiren diğer bilgileri şurada bulabilirsiniz:",
        "faq_footer_knowledge-base": "Bilgi Tabanı",
        "tryFree": "Ücretsiz Deneyin",
        "year": "yıl",
        "icon_up": "Yukarı",
        "status_failed": "Başarısız",
        "status_refund": "İade",
        "status_trial": "Deneme",
        "onHoldMessage": "Bir şeyler ters gitti ve {platform} cihazınızda {productName} aboneliği için sizden otomatik olarak ücret alamadık."
    },
    "css": "",
    "validator": {
        "is_empty": "Alan boş olmalıdır",
        "is_not_empty": "Zorunlu alan",
        "is_email": "Girişin doğru olup olmadığını kontrol edin",
        "is_phone": "Doğru numarayı girin",
        "is_password": "Şifre {extraSymbols} içeremez",
        "is_password_has_enough_symbols": "Şifre en az 6 karakterden oluşmalıdır",
        "is_password_less_max_symbols": "Şifre 32 karakterden az olmalıdır",
        "is_password_has_space": "Şifre boşluk içeremez",
        "is_not_empty_with_field_name": "{fieldName} alanı zorunludur",
        "is_password_has_not_english_letters": "Latin olmayan harflere izin verilmez"
    },
    "passwords-not-equal": "Şifre eşleşmiyor",
    "error": {
        "e500": {
            "new": {
                "text": "Bu sorunu zaten biliyoruz ve yakında çözeceğiz! Lütfen daha sonra tekrar deneyin.",
                "title": "500 — Sunucu Hatası"
            }
        },
        "eUnknown": {
            "new": {
                "text": "Bu sorunu zaten biliyoruz ve yakında çözeceğiz! Lütfen daha sonra tekrar deneyin.",
                "title": "500 — Sunucu Hatası"
            },
            "oops": {
                "text": "Tühh! Kullanıcı verileri yüklenirken bir hata oluştu. Lütfen sayfayı yeniden yüklemeyi deneyin."
            }
        },
        "e400": {
            "new": {
                "title": "Hata"
            }
        }
    },
    "whitelabel": {
        "vpn99": {
            "web_be_safe": "En iyi fiyata çevrimiçi güvenlik",
            "web_online_security": "Ayda sadece ${pricePerMonth} 'dan başlayan fiyatlarla"
        },
        "trustvpn": {
            "web_be_safe": "Çevrimiçi gizliliğinizi hemen koruyun!",
            "web_online_security": "Ultra yüksek hız, Gizliliğinizi koruyun, Dijital Özgürlük"
        }
    },
    "meta": {
        "descriptionDataRetentionPolicy": "Verilerinizin saklanacağı durumlar ve süreler. Tüm verileriniz, GDPR yönergelerine uygun olarak güvenli bir şekilde saklanır.",
        "descriptionMyIp": "IP Adresinizi, bilgisayarınızın adresini çevrimiçi olarak bulun. IP Adresinizin nasıl göründüğüne bakın",
        "descriptionPrivacyPolicy": "Gizlilik Politikamız, hangi verileri topladığımıza ve bunları nasıl kullandığımıza ve ayrıca bilgilerinizi nasıl kontrol edebileceğinize ilişkin bilgileri içerir. Çerezleri Galler yasalarına uygun olarak kullanıyoruz, ancak tercihlerinizi ayarlayabilirsiniz.",
        "descriptionTerms": "{productName}'in Kullanım Koşullarını kabul ederek, her iki tarafın da sözleşmede belirtilen sorumluluklarının yanı sıra hüküm ve koşulları da kabul etmiş olursunuz. VPN hizmetlerinin arka arkaya 12 saatten fazla süreyle kullanılamadığı veya arızalandığı belirli durumlarda tazminat mevcuttur.",
        "descriptionAccountBilling": "Hesap - Ödeme geçmişi",
        "descriptionAccountPanel": "Hesap - Kontrol Paneli",
        "descriptionAccountPassword": "Hesap - Şifreyi değiştir",
        "descriptionDownload": "İndir",
        "descriptionForgot": "Şifreyi yenile",
        "descriptionReset": "Şifreyi sıfırla",
        "descriptionHome": "{productName}'i indirip yükleyin ve dünya genelinde tam koruma elde edin. {productName}, yüksek hızlar ve tam anonimlik..",
        "descriptionPayFail": "Ödeme reddedildi",
        "descriptionPaySuccess": "Ödeme başarılı oldu",
        "descriptionPrice": "{productName} satın alıp yükleyin ve dünya genelinde tam koruma elde edin. Pahalı bir VPN'nin tüm avantajlarından yararlanın. {productName}, yüksek hızlar ve tam anonimlik.",
        "descriptionServers": "Sunucular",
        "titleDataRetentionPolicy": "Veri Saklama Politikası",
        "titleMyIp": "IP Adresinizin çevrimiçi ne olduğunu öğrenin",
        "titlePrivacyPolicy": "Gizlilik Politikası: {productName}'in gizli bilgilerinizi korumak için attığı adımlar",
        "titleTerms": "Kullanım Koşulları: {productName} tarafından sağlanan hizmetlerden yararlanılırken geçerli olan özel koşullar.",
        "titleAccountBilling": "Hesap - Ödeme geçmişi",
        "titleAccountPanel": "Hesap - Kontrol Paneli",
        "titleAccountPassword": "Hesap - Şifreyi değiştir",
        "titleDownload": "İndir",
        "titleForgot": "Şifreyi yenile",
        "titleReset": "Şifreyi sıfırla",
        "titleHome": "{productName}'i bugün indirin ve uygun fiyata güvenilir kapsama alın.",
        "titleLogin": "Giriş yap",
        "titlePayFail": "Ödeme reddedildi",
        "titlePaySuccess": "Ödeme başarılı oldu",
        "titlePrice": "{productName}'i bugün alın ve uygun fiyata güvenilir sigorta kapsamına sahip olun",
        "titleRegister": "Kaydol",
        "titleServers": "Sunucular",
        "titleApps": "Uygulamalar",
        "descriptionApps": "Uygulamalar",
        "descriptionLogin": "Oturum Aç",
        "descriptionRegister": "Kaydol",
        "titleReturnRefundPolicy": "İade ve Geri Ödeme Politikası",
        "descriptionReturnRefundPolicy": "İade ve Geri Ödeme Politikası",
        "titlePolicyAndProcedure": "DMCA Уведомления и правила и процедуры удаления",
        "descriptionPolicyAndProcedure": "DMCA Уведомления и правила и процедуры удаления"
    },
    "getVPN": "{productName} Alın",
    "signIn": "Oturum Aç",
    "billings-page": {
        "waiting-processing": "Ödeme işlemlerinden\nyanıt bekleniyor."
    },
    "servers": {
        "USDal": "ABD Dallas"
    }
}
