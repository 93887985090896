const state = () => ({
  config: null,
})

const actions = {
  async fetchConfig({ commit }) {
    return commit(
      'setConfig',
      await (
        await import('@/whitelabel/config')
      ).default
    )
  },
}

const mutations = {
  setConfig(state, config) {
    state.config = config
  },
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
}
