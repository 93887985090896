<template>
  <div class="multi-language">
    <div class="dropdown__current clickOutside_qdsqw23" @click="switchOpened">
      <icon class="icon" :name="$t('web.icon_flag')" width="18" height="18">
        <component :is="currentLanguage.component" />
      </icon>
      <span class="px-2 text text-md">{{ currentLanguage.key }}</span>
      <icon
        class="icon dropdown__down-icon"
        :class="{ 'dropdown__down-icon--reverse': opened }"
        :name="$t('web.icon_down')"
        width="10"
        height="10"
      >
        <down />
      </icon>
    </div>
    <dropdown :items="languages" :opened="opened" @choseItem="choseLanguage" />
  </div>
</template>

<script>
import Icon from '@/components/Icon'
import Down from '@/assets/image/icons/svg/down.svg'
import Dropdown from './Dropdown'
import { defineAsyncComponent } from 'vue'
export default {
  name: 'MultiLanguage',
  components: {
    Icon,
    Down,
    Dropdown,
  },
  data() {
    this.LANGUAGE_LABELS = {
      en: 'English',
      ru: 'Русский',
      nl: 'Nederlands',
      fr: 'Français',
      hi: 'िन्दी',
      id: 'Bahasa indonesia',
      pt: 'Português',
      ar: 'العربية',
      de: 'Deutsch',
      ko: '한국어',
      es: 'Español',
      vi: 'Tiếng việt',
      tr: 'Türkçe',
      ja: '日本語',
      zh: '中国人',
      he: 'עִברִית',
    }
    return {
      opened: false,
    }
  },
  computed: {
    currentLanguage: {
      get() {
        const locale = this.$i18n.locale
        this.$moment.locale(`${locale}-${locale.toUpperCase()}`)
        return {
          component: defineAsyncComponent(() =>
            import(`@/assets/image/flags/${this.$i18n.locale}.svg`)
          ),
          key: this.$i18n.locale,
          label: this.LANGUAGE_LABELS[this.$i18n.locale],
        }
      },
    },
    isDesktop: {
      get() {
        return this.$store.getters['app/isDesktopSize']
      },
    },
    isMobile: {
      get() {
        return this.$store.getters['app/isMobileSize']
      },
    },
    languages: {
      get() {
        return this.$i18n.availableLocales.reduce((acc, locale) => {
          if (this.currentLanguage.key === locale) {
            return acc
          }
          acc.push({
            component: defineAsyncComponent(() =>
              import(`@/assets/image/flags/${locale}.svg`)
            ),
            key: locale,
            label: this.LANGUAGE_LABELS[locale],
          })
          return acc
        }, [])
      },
    },
  },
  watch: {
    isDesktop() {
      this.opened = false
    },
    opened(value) {
      if (this.isMobile) {
        document.body.style.overflow = value ? 'hidden' : 'auto'
      }
    },
  },
  mounted() {
    // TODO создать один компонент
    this.$nextTick(() => {
      document.addEventListener('touchstart', this.onTouchStart, false)
      document.addEventListener('touchend', this.onTouchEnd, false)
      document.addEventListener('touchcancel', this.onTouchCancel, false)
      document.addEventListener('click', this.onClick, false)
    })
  },
  unmounted() {
    document.removeEventListener('touchstart', this.onTouchStart)
    document.removeEventListener('touchend', this.onTouchEnd)
    document.removeEventListener('touchcancel', this.onTouchCancel)
    document.removeEventListener('click', this.onClick)
  },
  methods: {
    choseLanguage(language) {
      this.$i18n.locale = language.key
      this.$router.push({
        name: this.$route.name,
        params: {
          ...this.$route.params,
          locale: [language.key],
        },
        query: this.$route.query,
      })
      this.$store.dispatch('user/setLocale', language.key)
      this.switchOpened()
    },
    onTouchStart(e) {
      if (!this.opened) {
        return
      }
      this.clientY = e.changedTouches[0].clientY
      this.clientX = e.changedTouches[0].clientX
      this.radius = Math.max(
        (e.changedTouches[0].radiusX + e.changedTouches[0].radiusY) / 2,
        30
      )
    },

    onTouchEnd(e) {
      if (!this.opened) {
        return
      }
      if (
        this.clientY &&
        this.clientX &&
        this.radius &&
        (Math.abs(this.clientY - e.changedTouches[0].clientY) > this.radius ||
          Math.abs(this.clientX - e.changedTouches[0].clientX) > this.radius)
      ) {
        return
      }
      let element = e.target
      let outside = true
      while (element) {
        outside =
          !Array.from(element.classList).includes('clickOutside_qdsqw23') &&
          !Array.from(element.classList).includes('clickOutside_qdsqw24')
        if (!outside || element.tagName === 'BODY') {
          break
        }
        element = element.parentNode
      }

      if (outside) {
        this.switchOpened()
      }
    },

    onTouchCancel() {
      if (!this.opened) {
        return
      }
      this.switchOpened()
    },

    onClick(e) {
      if (!this.opened) {
        return
      }
      let element = e.target
      let outside = true
      while (element) {
        outside =
          !Array.from(element.classList).includes('clickOutside_qdsqw23') &&
          !Array.from(element.classList).includes('clickOutside_qdsqw24')
        if (!outside || element.tagName === 'BODY') {
          break
        }
        element = element.parentNode
      }

      if (outside) {
        this.switchOpened()
      }
    },

    switchOpened() {
      this.opened = !this.opened
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/global/variables';
@import '@/assets/scss/components/dropdown';
.multi-language {
  position: relative;
  text-transform: capitalize;

  &__current {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    cursor: pointer;
  }

  &__current .icon {
    width: 0.625rem;
    height: 0.625rem;
    body[dir='rtl'] & {
      margin-right: 0.4375rem;
    }
    body[dir='ltr'] & {
      margin-left: 0.4375rem;
    }
  }
}
</style>
