export default {
  HOME: 'Home',
  PRICES: 'Prices',
  PRICES_PROMO: 'Prices Promo',
  PRICES_CHARITABLE: 'Prices Charitable',
  APPS: 'Apps',
  SERVERS: 'Servers',
  AUTH: {
    LOGIN: 'Auth Login',
    REGISTER: 'Auth Register',
    FORGOT: 'Auth Forgot',
  },
  ACCOUNT: {
    PANEL: 'Account Panel',
    DOWNLOADS: 'Account Downloads',
    BILLING: 'Account Billing',
    CHANGE_PASSWORD: 'Account ChangePassword',
  },
  PASSWORD: {
    RESET: 'Password Reset',
  },
  MY_IP: 'My IP',
  DATA_RETENTION_POLICY: 'Data Retention Policy',
  RETURN_REFUND_POLICY: 'Return Refund Policy',
  POLICY_AND_PROCEDURE: 'Policy and Procedure',
  TERMS: 'Terms',
  PRIVACY_POLICY: 'Privacy Policy',
  EXTENSION_PRIVACY_POLICY: 'Extension Privacy Policy',
  LOGIN_BY_TOKEN: 'LoginByToken',
  PAYMENT_SUCCESS: 'PaymentSuccess',
  PAYMENT_FAILURE: 'PaymentFailure',
  ERROR: {
    NOT_FOUND: 'Not Found',
    FORBIDDEN: 'Forbidden',
  },
  TEST: {
    ONBOARDING: {
      CHAMELEON: 'Onboarding Chameleon',
    },
  },
  DEV: {
    DATA: {
      RESET: 'DEV Reset Data',
    },
    MODE: {
      ENABLE: 'DEV Enable Dev Mode',
      DISABLE: 'DEV Disable Dev Mode',
    },
  },
  PROMO_CODE: 'Promo Code',
  MAGIC_LINK: 'Magic Link',
}
