import { createRouter, createWebHistory } from 'vue-router'
import i18n from '@/i18n'
import api from '@/api'
import store from '@/store'
import _ from './names'
import META from './meta'
import PATH from './paths'
import PROPS from './props'
import COMPONENTS from './components'
import { APP_NAME } from '@/whitelabel/config'
import eventBus from '@/utils/EventBus'
import { EVENTS } from '@/CONSTANTS'
import * as Sentry from '@sentry/browser'
import { clog } from '@/utils/logger'

const localeRegexp = i18n.global.availableLocales.join('|')

const route = (name) => {
  return {
    path: PATH[name],
    name: name,
    meta: META[name],
    component: COMPONENTS[name],
    ...(PROPS[name] || {}),
  }
}

const mainRoutes = [
  route(_.HOME),
  route(_.PRICES),
  route(_.PAYMENT_SUCCESS),
  route(_.PAYMENT_FAILURE),
  route(_.APPS),
  route(_.SERVERS),
  route(_.AUTH.LOGIN),
  route(_.AUTH.REGISTER),
  route(_.AUTH.FORGOT),
  route(_.ACCOUNT.PANEL),
  route(_.ACCOUNT.DOWNLOADS),
  route(_.ACCOUNT.BILLING),
  route(_.ACCOUNT.CHANGE_PASSWORD),
  route(_.PASSWORD.RESET),
  route(_.TERMS),
  route(_.MY_IP),
  route(_.DATA_RETENTION_POLICY),
  route(_.RETURN_REFUND_POLICY),
  route(_.POLICY_AND_PROCEDURE),
  route(_.PRIVACY_POLICY),
  route(_.EXTENSION_PRIVACY_POLICY),
  route(_.ERROR.FORBIDDEN),
  route(_.DEV.DATA.RESET),
  route(_.PROMO_CODE),
  route(_.MAGIC_LINK),
]

switch (process.env.VUE_APP__APP_NAME) {
  case APP_NAME.VPN99:
    mainRoutes.push(route(_.TEST.ONBOARDING.CHAMELEON))
    mainRoutes.push(route(_.PRICES_PROMO))
    mainRoutes.push(route(_.PRICES_CHARITABLE))
    break
  case APP_NAME.TRUST_VPN:
    break
}

const getPath = (name) => {
  return '/' + PATH[name]
}

const routes = [
  {
    path: `/:locale(${localeRegexp})*`,
    component: () => import('../views/base'),
    children: [
      ...mainRoutes,
      {
        path: PATH[_.LOGIN_BY_TOKEN],
        name: _.LOGIN_BY_TOKEN,
        beforeEnter: async (to, from, next) => {
          const token = to.query.token
          const redirect = to.query.redirect
          let path = null
          let redirectNext = undefined
          if (redirect) {
            try {
              path = new URL(redirect).pathname
            } catch {
              path = null
            }
          }
          if (token) {
            try {
              const { success } = await api.auth.loginByToken(token)
              if (success && !path) {
                path = getPath(_.ACCOUNT.PANEL)
              }
            } catch {
              redirectNext = path
              path = getPath(_.AUTH.LOGIN)
            }
            return next({
              path: path,
              [redirectNext && 'query']: {
                redirect: redirectNext,
              },
            })
          }
          return next({
            path: getPath(_.AUTH.LOGIN),
            [path && 'query']: {
              redirect: path,
            },
          })
        },
      },
      {
        path: PATH[_.DEV.MODE.ENABLE],
        name: _.DEV.MODE.ENABLE,
        beforeEnter: async (to, from, next) => {
          store.dispatch('app/setDeveloperMode')
          clog('Окей, братан, флаг тебе в руки!')
          return next({
            path: getPath(_.HOME),
          })
        },
      },
      {
        path: PATH[_.DEV.MODE.DISABLE],
        name: _.DEV.MODE.DISABLE,
        beforeEnter: async (to, from, next) => {
          clog('Бай, бай, Амиго!')
          store.dispatch('app/unsetDeveloperMode')
          return next({
            path: getPath(_.HOME),
          })
        },
      },
    ],
  },
  route(_.ERROR.NOT_FOUND),
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from) {
    if (to.hash) {
      return {
        el: to.hash,
        behavior: 'smooth',
      }
    }
    if (to.name === from.name) {
      return
    }
    return { left: 0, top: 0, behavior: 'auto' }
  },
})
//
router.beforeEach((_to, from, next) => {
  let to = _to
  const hashIndex = to.path.indexOf('#')
  if (~hashIndex) {
    to.hash = to.path.slice(hashIndex)
  }
  if (to.meta?.accessLevel === 'Guest' && store.getters['user/token']) {
    next({ name: _.ACCOUNT.PANEL })
  }
  if (to.meta?.accessLevel === 'Authorized' && !store.getters['user/token']) {
    const redirect = to.params.locale[0]
      ? to.path.slice(0, 1) + to.path.slice(4)
      : to.path
    next({
      name: _.AUTH.LOGIN,
      query: {
        redirect: redirect,
      },
    })
  }
  if (to.name === _.HOME && !from.name && store.getters['user/token']) {
    next({
      name: _.ACCOUNT.PANEL,
      query: to.query,
      params: to.params,
    })
  }

  if (!from.name) {
    const locale = to.params.locale?.[0]
    if (locale) {
      i18n.global.locale = locale
      eventBus.$emit(EVENTS.LOCALE.FIRST_LOAD_ROUTE_LOCALE_SET, {
        locale: i18n.global.locale,
      })
    } else {
      i18n.global.locale =
        localStorage.getItem('locale') ||
        process.env.VUE_APP__I18N_FALLBACK_LOCALE
    }
    eventBus.$emit(EVENTS.ROUTER.FIRST_LOAD, {
      route: to,
    })
  }

  setMetaData({
    title: to.meta?.title,
    description: to.meta?.description,
    locale: i18n.global.locale,
  })

  if (!i18n.global.availableLocales.includes(to.path.split('/')[1])) {
    const nextPath =
      to.path.length > 1
        ? `/${i18n.global.locale}${to.path}`
        : `/${i18n.global.locale}`
    return next({ path: nextPath, query: to.query, hash: to.hash })
  }
  return next()
})

router.afterEach((to) => {
  eventBus.$emit(EVENTS.ROUTER.ON_ROUTE_CHANGED, {
    route: to,
  })
})

function setMetaData({ title, description, locale }) {
  window.document.title = title
    ? `${title.t} — ${process.env.VUE_APP__PRODUCT_NAME}`
    : process.env.VUE_APP__PRODUCT_NAME

  document.getElementsByTagName('meta')['description'].content = description
    ? description.t
    : process.env.VUE_APP__PRODUCT_NAME

  document.documentElement.lang = locale
}

router.onError((error) => {
  if (!error?.message) {
    return
  }
  if (/Loading chunk .* failed/i.test(error.message)) {
    Sentry.captureMessage('Chunk Error Resolved in router listener')
    window.location.reload()
  }
})

export default router

export const ROUTER = {
  NAME: _,
  PATH,
}
