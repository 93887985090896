{
    "web": {
        "why_use": "Tại sao nên dùng {productName}",
        "download_VPN": "Tải {productName} và bắt đầu sử dụng hôm nay!",
        "getVPN": "Tải {productName}",
        "access": "Truy cập an toàn vào nội dung từ bất kỳ đâu trên thế giới",
        "jurisdiction": "Cơ quan pháp lý Liên minh Châu Âu bảo đảm tính bảo mật",
        "anonymity": "Hoàn toàn ẩn danh và không có nhật ký",
        "simple_conf": "Dễ cài đặt và sử dụng",
        "secure": "Thanh toán an toàn và bảo vệ khỏi tin tặc",
        "protection": "Bảo vệ khỏi quảng cáo xâm nhập",
        "get_VPN_app_phone": "Tải {productName} cho thiết bị của bạn",
        "keep_safe": "Lên mạng an toàn và bảo mật",
        "different_device": "Tải {productName} cho một thiết bị khác",
        "authorize": "Ủy quyền",
        "dont_have_an_account": "Không có tài khoản?",
        "register": "Đăng ký",
        "pass": "Mật khẩu",
        "sign_in": "Đăng nhập",
        "forgot_pass": "Quên mật khẩu?",
        "email": "Email",
        "instructions_for_macos": "Hướng dẫn dành cho MacOS",
        "instructions_for_windows": "Hướng dẫn dành cho Windows",
        "current-plan": "Gói hiện tại",
        "date": "Ngày",
        "price": "Giá",
        "price_from": "Giá từ",
        "next_due": "Hết hạn vào",
        "payment": "Thanh toán",
        "cancel": "Hủy",
        "go_to_settings": "Di chuyển đến Cài đặt",
        "ok": "OK",
        "dialog_cancel-subscription_title-AppStore": "Bạn có thể hủy đăng ký thuê bao qua tài khoản Apple Store là nơi bạn thực hiện mua gói thuê bao",
        "dialog_cancel-subscription_title-PlayMarket": "Bạn có thể hủy đăng ký thuê bao qua tài khoản Play Market là nơi bạn thực hiện mua gói thuê bao",
        "dialog_cancel-subscription_title-sure": "Bạn có chắc chắn muốn hủy thuê bao không?",
        "yes": "Có",
        "no": "Không",
        "subscription_canceled_successfully": "Đã hủy thuê bao thành công",
        "dashboard_dialog_thanks-for-registration_title": "Cảm ơn bạn đã đăng ký!",
        "dashboard_dialog_thanks-for-registration_description_1": "Vui lòng nhớ mật khẩu để đăng nhập.",
        "Password": "Mật khẩu",
        "dashboard_dialog_thanks-for-registration_description_2-1": "hoặc",
        "dashboard_dialog_thanks-for-registration_description_2-2": "nhấn ở đây",
        "dashboard_dialog_thanks-for-registration_description_2-3": "để đặt mật khẩu của bạn.",
        "prices_date-of-next-payment": "Thời gian cho lần thanh toán tiếp theo",
        "change_password": "Thay đổi mật khẩu",
        "current_password": "Mật khẩu hiện tại",
        "new_password": "Mật khẩu mới",
        "confirm-password": "Xác nhận mật khẩu",
        "reset": "Thiết lập lại",
        "email_address_associated": "Nhập địa chỉ email đã liên kết với tài khoản của bạn. Chúng tôi sẽ gửi đường dẫn đặt lại mật khẩu.",
        "get-link": "Gửi email",
        "sign-in": "Đăng nhập",
        "forget-password_dialog_email-link_title": "Chúng tôi đã gửi email có đường dẫn để đặt lại mật khẩu.",
        "i-have-promocode": "Tôi có mã ưu đãi",
        "promo": "Mã ưu đãi",
        "have_an_account": "Tôi có một tài khoản",
        "reset-password": "Đặt lại mật khẩu",
        "state": {
            "register": "Đăng ký"
        },
        "action": {
            "register": "Đăng ký",
            "cancel": "Hủy"
        },
        "notify_password-changed": "Đã thay đổi mật khẩu",
        "prices_discount": "Giảm giá",
        "prices_email": "Email",
        "prices_country": "Quốc gia của bạn",
        "prices_pay": "Thanh toán",
        "prices_privacy_p-0": "Khi gửi mẫu đơn này, bạn đồng ý với",
        "prices_privacy_p-1": "Điều khoản & Điều kiện",
        "prices_privacy_p-2": "và",
        "prices_privacy_p-3": "Chính sách Bảo mật",
        "price_card": "Thẻ",
        "price_internet-wallet": "Ví internet",
        "price_cryptocurrency": "Tiền mã hóa",
        "prices_order-total": "Tổng đơn hàng",
        "i-dont-have-promocode": "Tôi không có mã ưu đãi",
        "dashboard_dialog_thanks-for-registration_description_1_promo": "Mã ưu đãi bạn nhập vào không đúng",
        "prices_user-has-account_how-to-change-plan_1": "Để thay đổi gói dịch vụ, hủy thuê bao hiện tại trên trang",
        "prices_user-has-account_how-to-change-plan_2": "Hóa đơn",
        "prices_user-has-account_how-to-change-plan_3": "sau đó mua mới",
        "instructions_for_linux": "Hướng dẫn cho Linux",
        "prices_Step": "Bước",
        "tariff-name_Year": "Năm",
        "tariff-name_Month": "Tháng",
        "month": "tháng",
        "prices_save": "Lưu",
        "prices_billedEvery": "thu phí mỗi năm",
        "prices_the-most-popular": "Phổ biến nhất",
        "prices_Billed": "Đã thanh toán",
        "prices_every": "mỗi",
        "icon_check-circle-empty": "Vòng trống",
        "icon_check-circle": "Ô chọn",
        "icon_like": "Thích",
        "icon_info": "Thông tin",
        "icon_down": "Xuống",
        "icon_check": "Ô chọn",
        "prices_the-1-year-is-better": "Gói 1 năm giúp bạn tiết kiệm tối ưu nhất",
        "prices_choose-plan": "Chọn gói dịch vụ",
        "prices_Chose-Payment-Method": "Chọn phương thức thanh toán",
        "prices_Why-choose-VPN": "Tại sao nên chọn {productName}?",
        "prices_VPN-includes": "{productName} bao gồm",
        "prices_includes-support": "Hỗ trợ khách hàng 24/7",
        "prices_includes-money-back": "cam kết {numberOfDays} ngày hoàn tiền",
        "prices_includes-security": "Bảo mật & mã hóa chuẩn ngân hàng",
        "prices_includes-no-logs": "Không lưu nhật ký hoạt động & kết nối",
        "instruction": "Hướng dẫn",
        "apps": "Ứng dụng",
        "my-ip": "IP của tôi",
        "terms": "Điều khoản & Điều kiện",
        "return-refund-policy": "Chính sách Hoàn trả & Hoàn tiền",
        "policy_and_procedure": "Thông báo DMCA và Chính sách và Quy trình Gỡ bỏ",
        "privacy_policy": "Chính sách bảo mật",
        "footer_support": "Hỗ trợ",
        "footer_sales-department": "Bộ phận kinh doanh",
        "footer_company": "Công ty",
        "footer_contacts": "Liên hệ",
        "data-retention-policy": "Chính sách Lưu trữ Dữ liệu",
        "pricing": "Giá",
        "servers": "Máy chủ",
        "knowledge-base": "Cơ sở kiến thức",
        "dashboard": "Bảng điều khiển",
        "to_home_page": "Về trang chủ",
        "icon_logo": "Logo",
        "icon_login": "Đăng nhập",
        "icon_menu": "Menu",
        "icon_key": "Khóa",
        "icon_logout": "Thoát",
        "log_out": "Đăng xuất",
        "icon_lifebuoy": "Bảo vệ",
        "knowledge_base": "Cơ sở kiến thức",
        "icon_support": "Hỗ trợ",
        "downloads": "Tải",
        "billings": "Hóa đơn",
        "icon_eyeOpen": "Hiển thị mật khẩu",
        "inactive": "Không hoạt động. Thanh toán để bắt đầu sử dụng.",
        "icon_flag": "Cờ",
        "active_until": "Hoạt động đến",
        "servers_cta_title": "Bắt đầu duyệt web an toàn ngay hôm nay",
        "servers_cta_description": "Đảm bảo Internet của bạn an toàn và riêng tư",
        "downloads_instruction": "Hướng dẫn",
        "downloads_router": "Router",
        "downloads_openVPN_profiles": "Mở hồ sơ VPN",
        "tariff-name-long_Year": "Một năm",
        "tariff-name-long_Month": "Một tháng",
        "dialog_cancel-subscription_header": "Hủy đăng ký thuê bao",
        "web_payment_history": "Lịch sử thanh toán",
        "tariff": "Thuế phí",
        "payment_method": "Phương thức thanh toán",
        "status": "Trạng thái",
        "sum": "Tổng",
        "tbody_is_empty_1": "Tại đây bạn có thể xem được thông tin thanh toán",
        "tbody_is_empty_2": "Đặt mua",
        "status_paid": "Đã thanh toán",
        "status_not-paid": "Chưa thanh toán",
        "my_services": "Dịch vụ của tôi",
        "private_and_secure": "Truy cập internet bảo mật và an toàn (VPN)",
        "active": "Hoạt động",
        "downloads_apps_and_files": "Ứng dụng và tập tin",
        "downloads_for_mobile": "Di động",
        "downloads_for_desktop": "Desktops",
        "downloads_for_browsers": "Trình duyệt",
        "downloads_other_devices": "Những thiết bị khác",
        "forget-password_dialog_email-link_header": "Kiểm tra email của bạn",
        "pageNotFoundTitle": "Không tìm thấy trang",
        "pageNotFoundDescription": "Dường như trang ngày không tồn tại. Vui lòng quay trở lại.",
        "pageNotFoundAction": "Trở lại trang {productName}",
        "home": {
            "mainTitle": {
                "highlited": "Bảo\nvệ",
                "rest": "kỹ thuật số"
            },
            "mainDescription": {
                "first": "tất cả dữ liệu cá nhân và máy tính của bạn",
                "second": "trước tin tặc lừa đảo"
            },
            "mainButton": "Chỉ từ $2,5 mỗi tháng*",
            "mainButtonDescription": "*cam kết hoàn tiền",
            "secureTitle": {
                "first": "Internet",
                "second": "thực sự an toàn"
            },
            "secureDescription": "VPN99 xóa bỏ tất cả những dấu tích riêng biệt và nhận diện của bạn trên Internet. VPN99 ẩn địa chỉ IP của bạn và mã hóa dữ liệu để đảm bảo lịch sử lướt web của bạn bảo mật và riêng tư.",
            "secureCard": {
                "noRestrictionsTitle": "Không còn rào cản!",
                "noRestrictionsDescription": "Tất cả các trang trên internet đều sẵn sàng, thậm chỉ cả những trang bị chặn. Ngoài ra, đảm bảo bảo vệ dữ liệu của bạn trước tội phạm lừa đảo.",
                "anonimTitle": "Giờ đây bạn hoàn toàn ẩn danh!",
                "anonimDescription": "Tất cả mọi kết nối đều ẩn danh! Chúng tôi không lưu log do đó sẽ không hề biết bạn làm gì trên Internet :)"
            },
            "devicesTitle": {
                "first": "Một thuê bao",
                "second": "cho tất cả mọi thiết bị!"
            },
            "devicesDescription": "MacOS, Windows, Android, iOS, phần mở rộng cho Chrome và Firefox, và nhiều hơn thế nữa",
            "serversTitle": {
                "first": "Kết nối nhanh và ổn định",
                "second": "đến tất cả mọi nơi!"
            },
            "serversDescription": "Chọn từ một trong số 17 máy chủ hoặc tin tưởng vào máy chủ được khuyến nghị - đây là máy chủ gần bạn nhất, do đó là nhanh nhất. Bạn có thể tự do xem các bộ phim hoặc các chương trình truyền hình với chất lượng HD.",
            "faqTitle": "Hỏi đáp thường gặp",
            "faq": {
                "whatIsVPNTitle": "VPN có nghĩa là gì",
                "whatIsVPNDescription": {
                    "p1": "VPN viết tắt cho Virtual Private Network - Mạng Riêng Ảo.",
                    "p2": "Dịch vụ của chúng tôi hướng đến mục tiêu chính là đảm bảo cho sự an toàn, bảo mật và riêng tư của bạn. Để làm điều đó, {productName} mã hóa traffic bạn truyền đi trong mạng."
                },
                "rollUp": "Cuộn lên",
                "more": "Đọc",
                "howWorksTitle": "VPN hoạt động thế nào?",
                "howWorksDescription": {
                    "p1": "VPN thêm một giao diện mạng mới vào hệ thống. Mạng được thêm có chức năng như thể bạn kết nối thiết bị của mình trực tiếp với mạng riêng. VPN cũng thay đổi địa chỉ IP của bạn và mã hóa tất cả dữ liệu được truyền. VPN cho phép nhận và truyền thông tin qua các mạng công cộng (chẳng hạn như qua Internet) giống như khi thiết bị của bạn được kết nối trực tiếp với mạng riêng, một cách trực tiếp, có áp dụng cùng chính sắc bảo mật vào cấp quyền truy cập vào tất cả các tài nguyên trên mạng riêng."
                },
                "whatDiferenceWithProxyTitle": "Khác nhau giữa các máy chủ proxy và dịch vụ VPN là gì?",
                "whatDiferenceWithProxyDescription": {
                    "p1": "Không như proxy, kết nối qua VPN ổn định hơn nhiều và được mã hóa. Không phải tất cả các chương trình đều có thể hoạt động qua máy chủ proxy và nhiều chương trình đòi hỏi phải thiết lập cấu hình. Nếu bạn đang dùng VPN, bạn sẽ không cần phải cài đặt từng ứng dụng riêng."
                },
                "whatCountriesTitle": "Có những quốc gia nào khả dụng?",
                "whatCountries": {
                    "first": "Có thể xem danh sách vị trí ở những quốc gia khác nhau",
                    "link": "tại đây",
                    "second": "Như vậy, bạn nhận được địa chỉ IP với vị trí địa lý của những quốc gia này khi kết nối."
                },
                "logsTitle": "{productName} có lưu tệp log không?",
                "logs": {
                    "p1": "{productName} có chính sách không lưu log rất nghiêm ngặt."
                }
            },
            "footerTitle": {
                "first": "Bắt đầu",
                "second": "sử dụng VPN99"
            },
            "footerDescription": "Tải ứng dụng về thiết bị của bạn"
        },
        "my_ip_address": "Địa chỉ IP của tôi",
        "your_ip": "Đây là địa chỉ IP của bạn",
        "fail-payment_title": "Ối! Có lỗi.",
        "fail-payment_description": "Bạn chưa trả phí Có lẽ bạn đã mắc lỗi khi nhập dữ liệu trong lúc thanh toán. Đừng lo lắn, bạn có thể thử lại.",
        "fail-payment_go-to-prices": "Di chuyển đến các gói phí và thử lại",
        "success_congratulations-title": "Chúng mừng bạn đã mua thành công!",
        "success_congratulations-description": "Vui lòng đợi. Bạn sẽ được điều hướng đến tài khoản cá nhân để bạn có thể quản lý các khoản mua của bạn.",
        "success-payment_go-to-dashboard": "Đăng nhập ngay",
        "servers_title": "Một cú nhấp chuột để truy cập hơn 10 quốc gia",
        "servers_title_description": "Chọn một máy chủ VPN từ {continents_number} châu lục trong số {servers_number} quốc gia",
        "servers_locations_list_title": "Danh sách các vị trí máy chủ VPN",
        "servers_continent_all": "Tất cả các quốc gia",
        "allservers": "Quốc gia",
        "continent_asia": "Châu Á",
        "continent_europe": "Châu Âu",
        "continent_america": "Châu Mỹ",
        "our_vpn_server": "Máy chủ của chúng tôi",
        "subscription_canceled_failure": "Ối! Có lỗi xảy ra khi hủy đăng ký. Xin vui lòng liên hệ hỗ trợ.",
        "prices_trial-days": "+ {count} ngày dùng thử",
        "days": "{count} ngày",
        "prices_trial": "Dùng thử",
        "pageForbidden": "Truy cập bị cản",
        "pageForbiddenTitle": "Truy cập bị cản",
        "pageForbiddenDescription": "Hình như trang bạn muốn truy cập bị hạn chế.",
        "pageForbiddenAction": "Quay trở lại trang của {productName}",
        "private_finally": "Lướt web an toàn, bảo mật",
        "no_one_can_see": "Không ai có thể thấy bạn đang làm gì",
        "no_restrictions_on_content_and_censorship": "Không có hạn chế hoặc kiểm duyệt trực tuyến",
        "one_click_and_you_defend": "Bạn được bảo vệ chỉ với 1 cú nhấp chuột",
        "removes_everything": "{productName} gỡ bỏ toàn bộ thông tin cá nhân để nhận diện bạn và khi bạn trực tuyến bằng cách ẩn địa chỉ IP và mã hóa dữ liệu để giữ riêng tư lịch sử lướt web của bạn.",
        "enjoy_fast_and_stable_connection": "Trải nghiệm kết nối nhanh và ổn định mọi nơi.",
        "choose_your_server": "Chọn máy chủ của bạn ở {count} quốc gia và tận hưởng dịch vụ VPN nhanh nhất. Nhiều máy chủ hơn có nghĩa là tải ít hơn và một loạt các vị trí có nghĩa là bạn có nhiều khả năng tìm thấy một máy chủ gần đó hơn",
        "easy_to_use_apps": "Ứng dụng thân thiện với tất cả các thiết bị của bạn",
        "subscription_comes": "Một giấy phép đăng ký {productName} cho tất cả mọi thiết bị bạn sở hữu: {appsList} và hơn thế nữa",
        "money_back_guarantee": "Cam kết hoàn tiền",
        "faq_footer_description": "Bạn có thể tìm thấy những thông tin bạn qua tâm tại",
        "faq_footer_knowledge-base": "Kho Kiến Thức của chúng tôi",
        "tryFree": "Thử miễn phí",
        "year": "năm",
        "icon_up": "Lên trên",
        "status_failed": "Thất bại",
        "status_refund": "Hoàn tiền",
        "status_trial": "Dùng thử",
        "onHoldMessage": "Có lỗi xảy ra và chúng tôi không thể tự động thu phí đăng ký {productName} trênt thiết bị {platform} của bạn."
    },
    "css": "",
    "validator": {
        "is_empty": "Phải để trống trường này",
        "is_not_empty": "Trường bắt buộc",
        "is_email": "Kiểm tra liệu đã nhập đúng",
        "is_phone": "Nhập số đúng",
        "is_password": "Mật khẩu không thể có các ký tự như {extraSymbols}",
        "is_password_has_enough_symbols": "Mật khẩu phải có ít nhất 6 ký tự",
        "is_password_less_max_symbols": "Mật khẩu phải có ít hơn 32 ký tự",
        "is_password_has_space": "Mật khẩu không được có khoảng trắng",
        "is_not_empty_with_field_name": "{fieldName} là trường bắt buộc",
        "is_password_has_not_english_letters": "Không được phép dùng các kí tự không phải là chữ cái tiếng La-tin"
    },
    "passwords-not-equal": "Mật khẩu không trùng khớp",
    "error": {
        "e500": {
            "new": {
                "text": "Chúng tôi đã biết vấn đề này và sẽ xử lý sớm! Vui lòng thử lại sau.",
                "title": "500 — Lỗi máy chủ"
            }
        },
        "eUnknown": {
            "new": {
                "text": "Chúng tôi đã biết vấn đề này và sẽ xử lý sớm! Vui lòng thử lại sau.",
                "title": "500 — Lỗi máy chủ"
            },
            "oops": {
                "text": "Ối! Có lỗi xảy ra khi tải dữ liệu người dùng. Xin vui lòng tải lại trang."
            }
        },
        "e400": {
            "new": {
                "title": "Lỗi"
            }
        }
    },
    "whitelabel": {
        "vpn99": {
            "web_be_safe": "An toàn, bảo mật khi trực tuyến với giá tốt nhất",
            "web_online_security": "Chỉ từ {pricePerMonth} USD mỗi tháng"
        },
        "trustvpn": {
            "web_be_safe": "Bảo vệ sự riêng tư trực tuyến của bạn ngay bây giờ!",
            "web_online_security": "Tốc độ cực nhanh, bảo đảm an toàn riêng tư và bảo mật, tự do số hóa"
        }
    },
    "meta": {
        "descriptionDataRetentionPolicy": "Hiện trạng và thời gian dữ liệu của bạn sẽ được giữ lại. Tất cả dữ liệu của bạn được lưu trữ an toàn, tuân theo hướng dẫn GDPR.",
        "descriptionMyIp": "Tìm địa chỉ IP của bạn, địa chỉ trực tuyến của máy tính của bạn. Hãy xem địa chỉ IP của bạn hiển thị thế nào",
        "descriptionPrivacyPolicy": "Chính Sách Bảo Mật của chúng tôi bao gồm những thông tin về dữ liệu chúng tôi thu thập và cách chúng tôi sử dụng thông tin đó cũng như cách bạn có thể quản lý thông tin của mình. Chúng tôi sử dụng cookies theo quy định của điều luật Welsh. Tuy vậy bạn có thể tùy chỉnh theo ý muốn.",
        "descriptionTerms": "Khi chấp nhận Điều Khoản Sử Dụng {productName}, bạn đồng ý với những điều khoản và điều kiện cũng như trách nhiệm của cả hai bên được định rõ trong thỏa thuận. Trong một số trường hợp sẽ được bồi thường khi các dịch vụ VPN không khả dụng hoặc không thể vận hành trong vòng 12 giờ liên tục.",
        "descriptionAccountBilling": "Tài khoản - Lịch sử thanh toán",
        "descriptionAccountPanel": "Tài khoản - Bảng điều khiển",
        "descriptionAccountPassword": "Tài khoản - Thay đổi mật khẩu",
        "descriptionDownload": "Tải",
        "descriptionForgot": "Khôi phục mật khẩu",
        "descriptionReset": "Đặt lại mật khẩu",
        "descriptionHome": "Tải và cài đặt {productName} và bạn sẽ nhận được lớp bảo vệ toàn diện toàn cầu. {productName}, tốc độ cao và hoàn toàn ẩn danh.",
        "descriptionPayFail": "Thanh toán bị từ chối",
        "descriptionPaySuccess": "Thanh toán thành công.",
        "descriptionPrice": "Mua và cài đặt {productName} và bạn nhận được bảo vệ toàn diện trên toàn cầu. Nhận được tất cả những lợi ích của một VPN đắt đỏ. {productName}, tốc độ cao và hoàn toàn ẩn danh",
        "descriptionServers": "Các máy chủ",
        "titleDataRetentionPolicy": "Chính Sách Lưu Dữ Liệu {productName}",
        "titleMyIp": "Xác định bạn đang trực tuyến với địa chỉ IP nào",
        "titlePrivacyPolicy": "Chính Sách Bảo Mật: các bước {productName} thực hiện để bảo vệ thông tin riêng tư của bạn",
        "titleTerms": "Điều Khoản Sử Dụng: các điều khoản cụ thể áp dụng khi sử dụng dịch vụ của {productName}.",
        "titleAccountBilling": "Tài khoản - Lịch sử thanh toán",
        "titleAccountPanel": "Tài khoản - Bảng điều khiển",
        "titleAccountPassword": "Tài khoản - Thay đổi mật khẩu",
        "titleDownload": "Tải",
        "titleForgot": "Khôi phục mật khẩu",
        "titleReset": "Khôi phục mật khẩu",
        "titleHome": "Tải {productName} ngay hôm nay và bạn có trong tay lớp bảo vệ tin cậy với giá cả phải chăng.",
        "titleLogin": "Đăng nhập",
        "titlePayFail": "Thanh toán bị từ chối",
        "titlePaySuccess": "Thanh toán thành công.",
        "titlePrice": "Mua {productName} ngay hôm nay và bạn có trong tay lớp bảo vệ tin cậy với giá cả phải chăng.",
        "titleRegister": "Đăng ký",
        "titleServers": "Máy chủ",
        "titleApps": "Ứng dụng",
        "descriptionApps": "Ứng dụng",
        "descriptionLogin": "Đăng nhập",
        "descriptionRegister": "Đăng ký",
        "titleReturnRefundPolicy": "Chính Sách Hoàn Tiền",
        "descriptionReturnRefundPolicy": "Chính Sách Hoàn Tiền",
        "titlePolicyAndProcedure": "DMCA Uyar ve Kaldır Politikası ve Prosedürleri",
        "descriptionPolicyAndProcedure": "DMCA Uyar ve Kaldır Politikası ve Prosedürleri"
    },
    "getVPN": "Dùng {productName}",
    "signIn": "Đăng nhập",
    "billings-page": {
        "waiting-processing": "Đợi phản hồi\ntừ quy trình xử lý thanh toán."
    },
    "servers": {
        "USDal": "HOA KỲ Dallas"
    }
}
