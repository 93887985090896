export default Object.freeze({
  IOS: 'iOS',
  ANDROID: 'Android',
  WINDOWS: 'Windows',
  MACOS: 'MacOS',
  LINUX: 'Linux',
  CHROME: 'Chrome',
  FIREFOX: 'Firefox',
  OPERA: 'Opera',
  EDGE: 'Edge',
  ROUTER: 'Router',
  OPEN_VPN_PROFILES: 'OpenVPN profiles',
})
