import I18N from '@/whitelabel/i18n'
import { APP_NAME } from '../../whitelabel/config'

export default {
  install(app, i18n) {
    app.config.globalProperties.$wt = function (
      key,
      params = {},
      appName = null
    ) {
      const _appName = appName || process.env.VUE_APP__APP_NAME
      return (
        I18N(i18n)[_appName]?.[key](params) ||
        I18N(i18n)[APP_NAME.VPN99][key](params)
      )
    }
  },
}
