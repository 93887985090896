<template>
  <router-link
    v-if="!external"
    :title="title"
    :target="external || blank ? '_blank' : '_self'"
    :to="to"
    :style="`height: ${height}px`"
    class="slot-link"
  >
    <slot />
  </router-link>
  <a
    v-else
    :title="title"
    :target="external || blank ? '_blank' : '_self'"
    :href="to"
    :style="`height: ${height}px`"
    class="slot-link"
  >
    <slot />
  </a>
</template>

<script>
export default {
  name: 'Link',
  props: {
    to: {
      required: true,
      type: [String, Object],
    },
    blank: {
      type: Boolean,
      default: false,
    },
    title: {
      type: [String, null],
      default: null,
    },
    external: {
      type: [Boolean, undefined],
      default: false,
    },
    height: {
      type: [Number, String, null],
      default: null,
    },
  },
}
</script>

<style scoped lang="scss">
@import '@/assets/scss/global/variables';

.slot-link {
  display: inline-flex;
  max-width: fit-content;
  width: fit-content;
  transition: all $transTimeFast $easeDefault;
  &:hover {
    filter: brightness(120%);
    opacity: 0.9;
  }
}
</style>
