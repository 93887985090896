const state = () => ({
  successStatuses: localStorage.getItem('successStatuses')
    ? JSON.parse(localStorage.getItem('successStatuses'))
    : {},
})

const getters = {}

const actions = {
  setSuccessStatus({ commit, state }, { key, value }) {
    commit('setSuccessStatus', { key, value })
    localStorage.setItem(
      'successStatuses',
      JSON.stringify(state.successStatuses)
    )
  },
  getSuccessStatus({ state }, key) {
    return state.successStatuses[key]
  },
}

const mutations = {
  setSuccessStatus(state, { key, value }) {
    state.successStatuses = Object.assign({}, state.successStatuses, {
      [key]: value,
    })
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
