<template>
  <header class="header">
    <div>
      <div class="row">
        <div class="header__content">
          <div class="header__item header__item--logo">
            <slot-link
              :to="
                token
                  ? { name: $ROUTER.NAME.ACCOUNT.PANEL }
                  : { name: $ROUTER.NAME.HOME }
              "
              class="header__logo"
              :title="token ? $t('web.dashboard') : $t('web.to_home_page')"
            >
              <img :src="logo" alt="Logotype" class="header__logo-img" />
            </slot-link>
          </div>
          <div class="header__item header__item--nav">
            <nav class="header__nav">
              <div class="header__nav-group header__nav-group--left">
                <div class="header__nav-list">
                  <div
                    v-for="item in computedNav"
                    :key="item.href"
                    class="header__nav-item text text-md"
                  >
                    <slot-link
                      :to="item.href"
                      class="header__icon"
                      :external="item.external"
                      :title="item.label"
                    >
                      <icon
                        class="icon"
                        :name="item.icon.name"
                        :width="item.icon.size"
                        :height="item.icon.size"
                        :padding="item.icon.padding"
                      >
                        <component :is="item.icon.component" />
                      </icon>
                    </slot-link>
                    <c-link
                      bold
                      class="header__link"
                      paddingY="1rem"
                      color="white"
                      :to="item.href"
                      :external="item.external"
                      :label="item.label"
                    />
                  </div>
                </div>
              </div>
              <div class="header__nav-group header__nav-group--right">
                <div class="header__nav-list">
                  <div class="header__nav-item text text-md">
                    <multiLanguage />
                  </div>
                  <div
                    v-if="
                      !token ||
                      (!$store.state.user.hasSubscription &&
                        !$store.getters['user/onHoldOrder'])
                    "
                    class="header__nav-item text text-md"
                  >
                    <link-button
                      :to="{ name: $ROUTER.NAME.PRICES }"
                      :value="
                        $t('getVPN', {
                          productName: PRODUCT_NAME,
                        })
                      "
                      color="white"
                      compressed
                      size="lg"
                    />
                  </div>
                  <div v-if="!token" class="header__nav-item text text-md">
                    <link-button
                      :to="{ name: $ROUTER.NAME.AUTH.LOGIN }"
                      :value="$t('web.sign_in')"
                      color="white"
                      transparent
                      compressed
                      size="lg"
                    />
                  </div>
                  <div v-if="token" class="header__nav-item text text-md">
                    <dropdown
                      :items="dropdownItems"
                      :label="$store.state.user.email || 'More'"
                    >
                    </dropdown>
                  </div>
                </div>
              </div>
            </nav>
          </div>

          <div class="header__nav-list header__nav-list--mobile">
            <div class="header__nav-item header__item--multiLanguage">
              <multiLanguage />
            </div>
            <div
              v-if="!token"
              class="header__nav-item header__item--login text text-md"
            >
              <slot-link
                @click="
                  () => {
                    menuOpened = false
                  }
                "
                :to="{ name: $ROUTER.NAME.AUTH.LOGIN }"
                class="header__nav-link"
              >
                <icon
                  class="nav-link__icon"
                  :resize="false"
                  :name="$t('web.icon_login')"
                  width="20"
                  height="20"
                >
                  <login />
                </icon>
              </slot-link>
            </div>
            <div class="header__nav-item header__item--mmenu mmenu__btn">
              <button
                type="button"
                @click="
                  () => {
                    menuOpened = !menuOpened
                  }
                "
                class="header__nav-link header__nav-link--mmenu"
              >
                <icon
                  class="nav-link__icon"
                  :resize="false"
                  :name="$t('web.icon_menu')"
                  width="20"
                  height="20"
                >
                  <i-menu />
                </icon>
              </button>
              <c-menu
                @close="
                  () => {
                    menuOpened = false
                  }
                "
                :opened="menuOpened"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import SlotLink from '@/components/SlotLink'
import CLink from '@/components/Link'
import LinkButton from '@/components/LinkButton'
import Icon from '@/components/Icon'
import MultiLanguage from '@/components/MultiLanguage'
import Dropdown from '@/components/Dropdown'
import Login from '@/assets/image/icons/svg/login.svg'
import IMenu from '@/assets/image/icons/svg/menu.svg'
import CMenu from '@/components/Menu'
import { defineAsyncComponent } from 'vue'

export default {
  name: 'Header',
  components: {
    SlotLink,
    Icon,
    CLink,
    Login,
    LinkButton,
    MultiLanguage,
    Dropdown,
    IMenu,
    CMenu,
  },
  data() {
    return {
      menuOpened: false,
      logo: require(`@/whitelabel/${process.env.VUE_APP__APP_NAME}/image/main/logo.png`),
    }
  },
  computed: {
    token: {
      get() {
        return this.$store.getters['user/token']
      },
    },
    dropdownItems: {
      get() {
        return [
          {
            icon: {
              component: defineAsyncComponent(() =>
                import(`@/assets/image/icons/svg/discount.svg`)
              ),
              name: this.$t('web.icon_discount'),
            },
            label: this.$t('web.activate_promo'),
            onClick: () => {
              this.$router.push({
                name: this.$ROUTER.NAME.PROMO_CODE,
              })
            },
          },
          {
            icon: {
              component: defineAsyncComponent(() =>
                import(`@/assets/image/icons/svg/key.svg`)
              ),
              name: this.$t('web.icon_key'),
            },
            label: this.$t('web.change_password'),
            onClick: () => {
              this.$router.push({
                name: this.$ROUTER.NAME.ACCOUNT.CHANGE_PASSWORD,
              })
            },
          },
          {
            icon: {
              component: defineAsyncComponent(() =>
                import(`@/assets/image/icons/svg/logout.svg`)
              ),
              name: this.$t('web.icon_logout'),
            },
            label: this.$t('web.log_out'),
            onClick: () => {
              this.logout()
            },
          },
          {
            divider: true,
          },
          this.KNOWLEDGE_BASE_LINK && {
            icon: {
              component: defineAsyncComponent(() =>
                import(`@/assets/image/icons/svg/lifebuoy.svg`)
              ),
              name: this.$t('web.icon_lifebuoy'),
            },
            label: this.$t('web.knowledge_base'),
            onClick: () => {
              window.open(this.KNOWLEDGE_BASE_LINK, '_blank')
            },
          },
          {
            icon: {
              component: defineAsyncComponent(() =>
                import(`@/assets/image/icons/svg/support.svg`)
              ),
              name: this.$t('web.icon_support'),
            },
            label: this.SUPPORT_EMAIL,
            onClick: () => {
              window.open(`mailto:${this.SUPPORT_EMAIL}`, '_blank')
            },
          },
        ].filter((item) => item)
      },
    },
    computedNav: {
      get() {
        return this.token
          ? this.nav.isAuthenticated.filter((item) => !item.hide)
          : this.nav.isNotAuthenticated.filter((item) => !item.hide)
      },
    },
    nav: {
      get() {
        return {
          isNotAuthenticated: [
            {
              href: { name: this.$ROUTER.NAME.PRICES },
              label: this.$t('web.pricing'),
              icon: {
                component: defineAsyncComponent(() =>
                  import(`@/assets/image/icons/svg/discount.svg`)
                ),
                name: this.$t('web.pricing'),
                padding: 12,
                size: 48,
              },
            },
            {
              href: { name: this.$ROUTER.NAME.APPS },
              label: this.$t('web.apps'),
              icon: {
                component: defineAsyncComponent(() =>
                  import(`@/assets/image/icons/svg/add.svg`)
                ),
                name: this.$t('web.apps'),
                padding: 13,
                size: 46,
              },
            },
            {
              href: { name: this.$ROUTER.NAME.SERVERS },
              label: this.$t('web.servers'),
              icon: {
                component: defineAsyncComponent(() =>
                  import(`@/assets/image/icons/svg/servers.svg`)
                ),
                name: this.$t('web.servers'),
                padding: 13,
                size: 46,
              },
            },
            /*{
              href: process.env.VUE_APP__KNOWLEDGE_BASE_URL,
              label: this.$t('web.knowledge-base'),
              external: true,
              hide: process.env.VUE_APP__KNOWLEDGE_BASE_URL === 'NULL',
              icon: {
                component: defineAsyncComponent(() =>
                  import(`@/assets/image/icons/svg/book.svg`)
                ),
                name: this.$t('web.knowledge-base'),
                padding: 12,
                size: 48,
              },
            },*/
          ],
          isAuthenticated: [
            {
              href: { name: this.$ROUTER.NAME.ACCOUNT.PANEL },
              label: this.$t('web.dashboard'),
              icon: {
                component: defineAsyncComponent(() =>
                  import(`@/assets/image/icons/svg/dashboard.svg`)
                ),
                name: this.$t('web.dashboard'),
                padding: 13,
                size: 48,
              },
            },
            {
              href: { name: this.$ROUTER.NAME.ACCOUNT.DOWNLOADS },
              label: this.$t('web.downloads'),
              icon: {
                component: defineAsyncComponent(() =>
                  import(`@/assets/image/icons/svg/download.svg`)
                ),
                name: this.$t('web.downloads'),
                padding: 13,
                size: 46,
              },
            },
            {
              href: { name: this.$ROUTER.NAME.ACCOUNT.BILLING },
              label: this.$t('web.billings'),
              icon: {
                component: defineAsyncComponent(() =>
                  import(`@/assets/image/icons/svg/invoice.svg`)
                ),
                name: this.$t('web.billings'),
                padding: 13,
                size: 48,
              },
            },
          ],
        }
      },
    },
    PRODUCT_NAME: {
      get() {
        return process.env.VUE_APP__PRODUCT_NAME
      },
    },
/*    KNOWLEDGE_BASE_LINK: {
      get() {
        return process.env.VUE_APP__KNOWLEDGE_BASE_URL === 'NULL'
          ? null
          : process.env.VUE_APP__KNOWLEDGE_BASE_URL || null
      },
    },*/
    SUPPORT_EMAIL: {
      get() {
        return process.env.VUE_APP__SUPPORT_EMAIL
      },
    },
  },
  methods: {
    logout() {
      this.$store.dispatch('user/reset')
      this.$router.push({ name: this.$ROUTER.NAME.HOME })
    },
  },
}
</script>

<style lang="scss" scoped>
@import 'index.scss';
@import '@/assets/scss/components/dropdown';
</style>
