<template>
  <div class="dropdown">
    <div
      class="dropdown__current"
      :class="`clickOutside_${id}`"
      @click="switchOpened"
    >
      <span class="text text-md px-2 dropdown-current__span">
        {{ label }}
      </span>
      <icon
        class="dropdown__down-icon"
        :class="{ 'dropdown__down-icon--reverse': opened }"
        :name="$t('web.icon_down')"
        width="10"
        height="10"
      >
        <down />
      </icon>
    </div>
    <div v-if="opened" class="dropdown__list" :class="`clickOutside_${id + 1}`">
      <div v-for="item in items" :key="item.label">
        <div v-if="item.divider" class="dropdown__list-divider"></div>
        <button
          v-else
          @submit="clickItem(item)"
          @click="clickItem(item)"
          class="dropdown__item"
        >
          <icon
            v-if="item.icon"
            class="icon dropdown__icon"
            :name="item.icon.name"
            width="20"
            color="var(--new-main-second)"
            fill
            height="20"
          >
            <component :is="item.icon.component" />
          </icon>
          <span class="text">{{ item.label }}</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import Icon from '@/components/Icon'
import Down from '@/assets/image/icons/svg/down.svg'
export default {
  name: 'Dropdown',
  components: {
    Icon,
    Down,
  },
  data() {
    return {
      opened: false,
      id: Math.floor(Math.random() * (10 ** 6 - 1 - 10 ** 5) + 10 ** 5),
    }
  },
  props: {
    items: {
      default: [],
      type: () => [],
    },
    closeByClick: {
      default: true,
      type: Boolean,
    },
    label: {
      default: 'Select Item',
      type: String,
    },
  },
  mounted() {
    this.$nextTick(() => {
      document.addEventListener('touchstart', this.onTouchStart, false)
      document.addEventListener('touchend', this.onTouchEnd, false)
      document.addEventListener('touchcancel', this.onTouchCancel, false)
      document.addEventListener('click', this.onClick, false)
    })
  },
  unmounted() {
    document.removeEventListener('touchstart', this.onTouchStart)
    document.removeEventListener('touchend', this.onTouchEnd)
    document.removeEventListener('touchcancel', this.onTouchCancel)
    document.removeEventListener('click', this.onClick)
  },
  methods: {
    clickItem(item) {
      item.onClick()
      if (this.closeByClick) {
        this.opened = false
      }
    },
    onTouchStart(e) {
      if (!this.opened) {
        return
      }
      this.clientY = e.changedTouches[0].clientY
      this.clientX = e.changedTouches[0].clientX
      this.radius = Math.max(
        (e.changedTouches[0].radiusX + e.changedTouches[0].radiusY) / 2,
        30
      )
    },

    onTouchEnd(e) {
      if (!this.opened) {
        return
      }
      if (
        this.clientY &&
        this.clientX &&
        this.radius &&
        (Math.abs(this.clientY - e.changedTouches[0].clientY) > this.radius ||
          Math.abs(this.clientX - e.changedTouches[0].clientX) > this.radius)
      ) {
        return
      }
      let element = e.target
      let outside = true
      while (element) {
        outside =
          !Array.from(element.classList).includes(`clickOutside_${this.id}`) &&
          !Array.from(element.classList).includes(`clickOutside_${this.id + 1}`)
        if (!outside || element.tagName === 'BODY') {
          break
        }
        element = element.parentNode
      }

      if (outside) {
        this.switchOpened()
      }
    },

    onTouchCancel() {
      if (!this.opened) {
        return
      }
      this.switchOpened()
    },

    onClick(e) {
      if (!this.opened) {
        return
      }
      let element = e.target
      let outside = true
      while (element) {
        outside =
          !Array.from(element.classList).includes(`clickOutside_${this.id}`) &&
          !Array.from(element.classList).includes(`clickOutside_${this.id + 1}`)
        if (!outside || element.tagName === 'BODY') {
          break
        }
        element = element.parentNode
      }

      if (outside) {
        this.switchOpened()
      }
    },

    switchOpened() {
      this.opened = !this.opened
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/components/dropdown';
</style>
