import ROUTE_NAME from './names'
export default {
  [ROUTE_NAME.HOME]: () => import('../views/home/variations/oldDesign'),
  [ROUTE_NAME.PRICES]: () => import('../views/prices'),
  [ROUTE_NAME.PRICES_PROMO]: () => import('../views/prices'),
  [ROUTE_NAME.PRICES_CHARITABLE]: () => import('../views/prices'),
  [ROUTE_NAME.APPS]: () => import('../views/apps'),
  [ROUTE_NAME.PAYMENT_SUCCESS]: () => import('../views/payment/success'),
  [ROUTE_NAME.PAYMENT_FAILURE]: () => import('../views/payment/failure'),
  [ROUTE_NAME.SERVERS]: () => import('../views/servers'),
  [ROUTE_NAME.AUTH.LOGIN]: () => import('../views/auth/signIn'),
  [ROUTE_NAME.AUTH.REGISTER]: () => import('../views/auth/signUp'),
  [ROUTE_NAME.AUTH.FORGOT]: () => import('../views/auth/forgetPassword'),
  [ROUTE_NAME.ACCOUNT.PANEL]: () => import('../views/account/dashboard'),
  [ROUTE_NAME.ACCOUNT.DOWNLOADS]: () => import('../views/account/downloads'),
  [ROUTE_NAME.ACCOUNT.BILLING]: () => import('../views/account/billing'),
  [ROUTE_NAME.ACCOUNT.CHANGE_PASSWORD]: () =>
    import('../views/auth/changePassword'),
  [ROUTE_NAME.PASSWORD.RESET]: () => import('../views/auth/resetPassword'),
  [ROUTE_NAME.TERMS]: () => import('../views/articles'),
  [ROUTE_NAME.MY_IP]: () => import('../views/myIP'),
  [ROUTE_NAME.DATA_RETENTION_POLICY]: () => import('../views/articles'),
  [ROUTE_NAME.RETURN_REFUND_POLICY]: () => import('../views/articles'),
  [ROUTE_NAME.POLICY_AND_PROCEDURE]: () => import('../views/articles'),
  [ROUTE_NAME.PRIVACY_POLICY]: () => import('../views/articles'),
  [ROUTE_NAME.EXTENSION_PRIVACY_POLICY]: () =>
    import('../views/articles/v2.MarkdownReader'),
  [ROUTE_NAME.LOGIN_BY_TOKEN]: undefined,
  [ROUTE_NAME.ERROR.NOT_FOUND]: () => import('../views/errors/template'),
  [ROUTE_NAME.ERROR.FORBIDDEN]: () => import('../views/errors/template'),
  [ROUTE_NAME.TEST.ONBOARDING.CHAMELEON]: () =>
    import('../views/ABTests/Onboarding/Chameleon'),
  [ROUTE_NAME.DEV.DATA.RESET]: () => import('../views/dev/DataReset'),
  [ROUTE_NAME.DEV.MODE.ENABLE]: undefined,
  [ROUTE_NAME.DEV.MODE.DISABLE]: undefined,
  [ROUTE_NAME.PROMO_CODE]: () => import('../views/promoCode'),
  [ROUTE_NAME.MAGIC_LINK]: () => import('../views/magicLink'),
}
