<template>
  <button
    type="submit"
    @click="onButtonClick"
    class="c-button"
    :class="{
      'c-button--disabled': disabled,
      'c-button--compressed': compressed,
      'c-button--loading': loading,
      'c-button--lg text-lg': size === 'lg',
      'c-button--md text-md': size === 'md',
      'c-button--sm text-md': size === 'sm',
      'c-button--white-transparent': color === 'white' && transparent,
      'c-button--accent-transparent': color === 'default' && transparent,
      'c-button--accent': color === 'default' && !transparent,
      'c-button--no-border': noBorder,
    }"
    :title="title || value"
  >
    <span v-if="!loading" class="c-button__label">
      {{ value }}
    </span>
    <div v-else class="lds-ellipsis">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  </button>
</template>

<script>
export default {
  name: 'Button',

  props: {
    title: {
      type: String,
      default: '',
    },
    value: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    compressed: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: 'md', // md, lg
    },
    transparent: {
      type: Boolean,
      default: false,
    },
    noBorder: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String,
      default: 'default',
    },
  },
  methods: {
    onButtonClick() {
      this.$emit('submit')
    },
  },
}
</script>

<style lang="scss" scoped>
.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 67px;
  height: 100%;
}
.lds-ellipsis div {
  position: absolute;
  top: 50%;
  width: 11px;
  height: 11px;
  border-radius: 50%;
  background: #ffffffa0;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
  animation: lds-ellipsis1 0.6s infinite;
  body[dir='rtl'] & {
    right: 8px;
  }
  body[dir='ltr'] & {
    left: 8px;
  }
}
.lds-ellipsis div:nth-child(2) {
  animation: lds-ellipsis2 0.6s infinite;
  body[dir='rtl'] & {
    right: 8px;
  }
  body[dir='ltr'] & {
    left: 8px;
  }
}
.lds-ellipsis div:nth-child(3) {
  animation: lds-ellipsis2 0.6s infinite;
  body[dir='rtl'] & {
    right: 32px;
  }
  body[dir='ltr'] & {
    left: 32px;
  }
}
.lds-ellipsis div:nth-child(4) {
  animation: lds-ellipsis3 0.6s infinite;
  body[dir='rtl'] & {
    right: 56px;
  }
  body[dir='ltr'] & {
    left: 56px;
  }
}
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0) translate(0, -18px);
    opacity: 0;
  }
  100% {
    transform: scale(1) translate(0, -50%);
    opacity: 1;
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1) translate(0, -50%);
    opacity: 1;
  }
  100% {
    transform: scale(0) translate(0, -18px);
    opacity: 0;
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, -50%);
  }
  100% {
    transform: translate(24px, -50%);
  }
}
</style>
