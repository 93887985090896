export default class Payment {
  constructor(rest) {
    this.rest = rest
  }

  getTariffs() {
    return this.rest.get('/payment/tariffs')
  }

  getWhitelistedTariffs() {
    return this.rest.get('/payment/whitelisted_tariffs', {}, { isAuth: true })
  }

  getMerchants() {
    return this.rest.get('/payment/merchants')
  }
}
