<template>
  <Header v-if="!isLoading && !fullScreen" />
  <router-view v-if="!isLoading" />
  <Footer v-if="!isLoading && !fullScreen" />
  <Loader v-if="isLoading" />
  <CRegisterModal />
  <ConsoleModal />
  <CModal
    v-model="showModal"
    :title="$store.state.app.dialogData.title"
    :description="$store.state.app.dialogData.description"
    :type="$store.state.app.dialogData.type"
    :buttons="$store.state.app.dialogData.buttons"
  />
  <notifications group="message" position="top right" width="400" />
</template>

<script>
import Footer from '@/components/Footer'
import Header from '@/components/Header'
import Loader from '@/components/Loader'
import CModal from '@/components/Modal'
import CRegisterModal from '@/views/auth/signUp/Modal'
import ConsoleModal from '@/components/Modal/Console'
import eventBus from '@/utils/EventBus'
import { EVENTS } from '@/CONSTANTS'
import api from '@/api'
import { clog, toSaveLogs } from '@/utils/logger'
import ShakeDetector from 'shake-detector'

export default {
  name: 'App',
  components: {
    Header,
    Footer,
    Loader,
    CModal,
    CRegisterModal,
    ConsoleModal,
  },
  data() {
    this.resizeTimer = null
    this.prevInnerWidth = window.innerWidth
    this.excludeRoutes = [
      this.$ROUTER.NAME.PAYMENT_SUCCESS,
      this.$ROUTER.NAME.PAYMENT_FAILURE,
      this.$ROUTER.NAME.ACCOUNT.CHANGE_PASSWORD,
    ]
    return {
      isLoading: true,
    }
  },
  computed: {
    fullScreen: {
      get() {
        return this.$route.meta.fullScreen || false
      },
    },
    toShowConsoleOnShake: {
      get() {
        return (
          this.$store.state.app.isDeveloperMode &&
          this.$store.getters['app/isMobile']
        )
      },
    },
    hideFeedBack: {
      get() {
        return !this.$route.name || this.$route.meta.hideFeedBack || false
      },
    },
    showModal: {
      get() {
        return this.$store.state.app.showDialog
      },
      set(value) {
        if (!value) {
          this.$store.dispatch('app/closeDialog')
        }
      },
    },
  },
  beforeMount() {
    this.initAppCommands()
    if (!this.hideFeedBack) {
      //this.showFeedBack()
    }
    this.init()
    eventBus.$on(EVENTS.ROUTER.ON_ROUTE_CHANGED, () => {
      this.update()
    })
    this.onTabFocused()
  },
  mounted() {
    if (this.toShowConsoleOnShake) {
      this.createShaker()
    }
    if (this.$store.state.app.isDeveloperMode) {
      toSaveLogs()
    }
  },
  beforeUnmount() {
    if (this.onResized) {
      window.removeEventListener('resize', this.onResized)
    }
    if (this.handleVisibilityChange) {
      document.removeEventListener(
        this.visibilityChange,
        this.handleVisibilityChange
      )
    }
    eventBus.$off(EVENTS.ROUTER.ON_ROUTE_CHANGED)
    eventBus.$off(EVENTS.ROUTER.FIRST_LOAD)
  },
  watch: {
    '$store.state.app.isDeveloperMode': function () {
      if (this.toShowConsoleOnShake) {
        this.createShaker()
      }
    },
    ['$i18n.locale'](value) {
      this.setBodyStyles(value)
      window.zE && window.zE('webWidget', 'setLocale', value)
    },
    hideFeedBack(value) {
      if (!value) {
        //this.showFeedBack()
      }
    },
  },
  methods: {
    createShaker() {
      if (!this.shakeDetector) {
        this.shakeDetector = new ShakeDetector({
          threshold: 20,
          debounceDelay: 1500,
        })
        this.$nextTick(() => {
          let platform = window.navigator.platform
          if (['iPhone', 'iPad', 'iPod'].includes(platform)) {
            this.shakeDetector.requestPermission().then(() => {
              this.shakeDetector.start()
            })
          } else {
            this.shakeDetector.confirmPermissionGranted()
            this.shakeDetector.start()
          }
          window.addEventListener(ShakeDetector.SHAKE_EVENT, () => {
            eventBus.$emit(EVENTS.APP.SHAKE)
          })
        })
      }
    },
    showFeedBack() {
      if (this.lockFeedBack) {
        return
      }
      this.lockFeedBack = true
      ;(function (e, t, s) {
        var n =
            (window.zE =
            window.zEmbed =
              function () {
                n._.push(arguments)
              }),
          a = (n.s = e.createElement(t)),
          r = e.getElementsByTagName(t)[0]
        ;(n.set = function (e) {
          n.set._.push(e)
        }),
          (n._ = []),
          (n.set._ = []),
          (a.async = true),
          a.setAttribute('charset', 'utf-8'),
          (a.src =
            'https://static.zdassets.com/ekr/asset_composer.js?key=' + s),
          (n.t = +new Date()),
          (a.type = 'text/javascript'),
          r.parentNode.insertBefore(a, r)
      })(document, 'script', '0edefbfd-a6c1-48b4-b3c5-47a2f276659e')
      window.zE('webWidget', 'setLocale', this.$i18n.locale)
    },
    initAppCommands() {
      window.iamdeveloper = () => {
        this.$store.dispatch('app/setDeveloperMode')
        clog('Окей, братан, флаг тебе в руки!')
      }
    },
    onTabFocused() {
      let hidden
      if (typeof document.hidden !== 'undefined') {
        hidden = 'hidden'
        this.visibilityChange = 'visibilitychange'
      } else if (typeof document.msHidden !== 'undefined') {
        hidden = 'msHidden'
        this.visibilityChange = 'msvisibilitychange'
      } else if (typeof document.webkitHidden !== 'undefined') {
        hidden = 'webkitHidden'
        this.visibilityChange = 'webkitvisibilitychange'
      }
      this.handleVisibilityChange = () => {
        if (!document[hidden]) {
          if (this.isFocusTimeout()) {
            return
          }
          this.update()
        }
      }
      if (
        typeof document.addEventListener === 'undefined' ||
        hidden === undefined
      ) {
        window.onfocus = () => {
          if (this.isFocusTimeout()) {
            return
          }
          this.update()
        }
      } else {
        document.addEventListener(
          this.visibilityChange,
          this.handleVisibilityChange,
          false
        )
      }
      this.startFocusTimeout()
    },
    isFocusTimeout() {
      if (this.focusTimeout) {
        return true
      }
      this.startFocusTimeout()
      return false
    },
    startFocusTimeout() {
      this.focusTimeout = setTimeout(() => {
        this.focusTimeout = null
      }, 20 * 1000)
    },
    update() {
      clog('data-update')
      const token = localStorage.getItem('token')
      const password = localStorage.getItem('password')
      switch (this.$route.name) {
        case this.$ROUTER.NAME.SERVERS:
          this.fetchServers()
          break
        case this.$ROUTER.NAME.HOME:
          this.fetchServers()
          this.fetchTariffs()
          break
        case this.$ROUTER.NAME.PRICES:
          this.fetchCountries()
          this.fetchTariffs()
          this.fetchMerchants()
          break
      }
      if (token) {
        this.fetchUser()
      }
      if (password) {
        this.resolveAfterRegistrationDialog()
      }
    },
    init() {
      const locale = this.getLocale()
      const wait = []
      wait.push(this.$store.dispatch('user/setLocale', locale))
      wait.push(this.setBodyStyles(locale))

      eventBus.$on(EVENTS.LOCALE.FIRST_LOAD_ROUTE_LOCALE_SET, ({ locale }) => {
        this.setBodyStyles(locale)
      })
      eventBus.$on(EVENTS.ROUTER.FIRST_LOAD, ({ route }) => {
        if (route.query.a) {
          this.setPostbackUser(route.query)
        }
      })

      wait.push(this.setAppSettings())
      wait.push(this.fetchTariffs())
      wait.push(this.fetchMerchants())
      wait.push(this.fetchCountries())
      wait.push(this.fetchServers())
      wait.push(this.fetchCountryCode())
      wait.push(this.fetchRealIP())
      wait.push(this.fetchConfig())
      const token = localStorage.getItem('token')
      if (token) {
        wait.push(this.fetchUser())
      }
      Promise.all(wait).then(() => {
        this.isLoading = false
        this.resolveAfterRegistrationDialog()
      })
    },
    setPostbackUser(params) {
      api.postback.setParams(params)
    },
    resolveAfterRegistrationDialog() {
      if (!this.excludeRoutes.includes(this.$route.name)) {
        this.$store.dispatch('app/setShowAfterRegistrationDialog', true)
      }
    },
    setBodyStyles(locale) {
      return new Promise((resolve) => {
        if (locale === 'ar' || locale === 'he') {
          document.body.style.direction = 'rtl'
          document.body.setAttribute('dir', 'rtl')
        } else {
          document.body.style.direction = 'ltr'
          document.body.setAttribute('dir', 'ltr')
        }
        resolve()
      })
    },
    setAppSettings() {
      return new Promise((resolve) => {
        window.addEventListener('resize', this.onResized)
        this.onResized()
        resolve()
      })
    },
    onResized() {
      this.$store.dispatch('app/setWindowWidth', window.innerWidth)
      this.$store.dispatch('app/setWindowHeight', window.innerHeight)
    },
    getLocale() {
      const locale = localStorage.getItem('locale')
      if (locale) {
        return (
          this.$i18n.availableLocales.find((l) => l === locale) ||
          process.env.VUE_APP__I18N_FALLBACK_LOCALE
        )
      }
      let language = window.navigator
        ? window.navigator.language
        : process.env.VUE_APP__I18N_FALLBACK_LOCALE
      language = language.substr(0, 2).toLowerCase()
      if (['ru', 'uk', 'be'].some((el) => el === language)) {
        return (
          this.$i18n.availableLocales.find((l) => l === 'ru') ||
          process.env.VUE_APP__I18N_FALLBACK_LOCALE
        )
      }

      return this.$i18n.locale
    },
    fetchTariffs() {
      return new Promise((resolve) => {
        this.$store
          .dispatch('paywall/fetchTariffs')
          .then(() => {})
          .catch(() => {})
          .finally(() => {
            resolve()
          })
      })
    },
    fetchMerchants() {
      return new Promise((resolve) => {
        this.$store
          .dispatch('paywall/fetchMerchants')
          .then(() => {})
          .catch(() => {})
          .finally(() => {
            resolve()
          })
      })
    },
    fetchCountries() {
      return new Promise((resolve) => {
        this.$store
          .dispatch('country/fetchCountries')
          .then(() => {})
          .catch(() => {})
          .finally(() => {
            resolve()
          })
      })
    },
    fetchServers() {
      return new Promise((resolve) => {
        this.$store
          .dispatch('country/fetchServers')
          .then(() => {})
          .catch(() => {})
          .finally(() => {
            resolve()
          })
      })
    },
    fetchCountryCode() {
      return new Promise((resolve) => {
        this.$store
          .dispatch('user/fetchCountryCode')
          .then(() => {})
          .catch(() => {})
          .finally(() => {
            resolve()
          })
      })
    },
    fetchUser() {
      return new Promise((resolve) => {
        this.$store
          .dispatch('user/fetchUser')
          .then(() => {})
          .catch(() => {})
          .finally(() => {
            resolve()
          })
      })
    },
    fetchRealIP() {
      return new Promise((resolve) => {
        this.$store
          .dispatch('user/fetchRealIP')
          .then(() => {})
          .catch(() => {})
          .finally(() => {
            resolve()
          })
      })
    },
    fetchConfig() {
      return new Promise((resolve) => {
        this.$store
          .dispatch('whitelabel/fetchConfig')
          .then(() => {})
          .catch(() => {})
          .finally(() => {
            resolve()
          })
      })
    },
  },
}
</script>

<style lang="scss">
@import 'index';
@include font-face(
  'SFUIDisplay',
  './fonts/SFUIDisplay/SFUIDisplay-Regular',
  normal,
  normal
);
@include font-face(
  'SFUIDisplay',
  './fonts/SFUIDisplay/SFUIDisplay-Medium',
  500,
  normal
);
@include font-face(
  'SFUIDisplay',
  './fonts/SFUIDisplay/SFUIDisplay-Bold',
  bold,
  normal
);
</style>
