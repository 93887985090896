import i18n from '@/i18n'
import { ENV, APPS } from '@/CONSTANTS'
const $t = (val, params = {}) => i18n.global.t(val, params)

const state = () => ({})

const getters = {
  list() {
    const downloadsList = []
    if (ENV.IOS_APP_URL)
      downloadsList.push({
        name: APPS.DEVICE_NAME.IOS,
        label: APPS.DEVICE_NAME.IOS,
        device: APPS.DEVICE_TYPE.MOBILE,
        published: !!ENV.IOS_APP_URL,
        link: {
          href: ENV.IOS_APP_URL,
          label: APPS.STORE_NAME.APP_STORE,
        },
      })
    if (ENV.ANDROID_APP_URL)
      downloadsList.push({
        name: APPS.DEVICE_NAME.ANDROID,
        label: APPS.DEVICE_NAME.ANDROID,
        device: APPS.DEVICE_TYPE.MOBILE,
        published: !!ENV.ANDROID_APP_URL,
        link: {
          href: ENV.ANDROID_APP_URL,
          label: APPS.STORE_NAME.GOOGLE_PLAY,
        },
      })
    if (ENV.MACOS_APP_URL)
      downloadsList.push({
        name: APPS.DEVICE_NAME.MACOS,
        label: APPS.DEVICE_NAME.MACOS,
        device: APPS.DEVICE_TYPE.DESKTOP,
        published: !!ENV.MACOS_APP_URL,
        link: {
          href: ENV.MACOS_APP_URL,
          label: APPS.STORE_NAME.MAC_APP_STORE,
        },
      })
    if (ENV.WINDOWS_APP_URL || ENV.KNOWLEDGE_BASE_URL)
      downloadsList.push({
        name: APPS.DEVICE_NAME.WINDOWS,
        label: APPS.DEVICE_NAME.WINDOWS,
        device: APPS.DEVICE_TYPE.DESKTOP,
        published: !!ENV.WINDOWS_APP_URL,
        link: {
          href: 'https://trustvpn.getvpngn.com/win' || ENV.KNOWLEDGE_BASE_URL,
          label: ENV.WINDOWS_APP_URL
            ? APPS.STORE_NAME.WINDOWS_APP_STORE
            : $t('web.downloads_instruction'),
        },
      })
    /*   if (ENV.LINUX_APP_URL || ENV.KNOWLEDGE_BASE_URL)
      downloadsList.push({
        name: APPS.DEVICE_NAME.LINUX,
        label: APPS.DEVICE_NAME.LINUX,
        device: APPS.DEVICE_TYPE.DESKTOP,
        published: !!ENV.LINUX_APP_URL,
        link: {
          href: ENV.LINUX_APP_URL || ENV.KNOWLEDGE_BASE_URL,
          label: ENV.WINDOWS_APP_URL
            ? APPS.STORE_NAME.LINUX_APP_STORE
            : $t('web.downloads_instruction'),
        },
      })*/
    if (ENV.EXTENSION_CHROME_URL)
      downloadsList.push({
        name: APPS.DEVICE_NAME.CHROME,
        label: APPS.DEVICE_NAME.CHROME,
        device: APPS.DEVICE_TYPE.EXTENSION,
        published: !!ENV.EXTENSION_CHROME_URL,
        link: {
          href: ENV.EXTENSION_CHROME_URL,
          label: APPS.STORE_NAME.CHROME_WEB_STORE,
        },
      })
/*    if (ENV.EXTENSION_FIREFOX_URL)
      downloadsList.push({
        name: APPS.DEVICE_NAME.FIREFOX,
        label: APPS.DEVICE_NAME.FIREFOX,
        device: APPS.DEVICE_TYPE.EXTENSION,
        published: !!ENV.EXTENSION_FIREFOX_URL,
        link: {
          href: ENV.EXTENSION_FIREFOX_URL,
          label: APPS.STORE_NAME.FIREFOX_WEB_STORE,
        },
      })
    if (ENV.EXTENSION_OPERA_URL)
      downloadsList.push({
        name: APPS.DEVICE_NAME.OPERA,
        label: APPS.DEVICE_NAME.OPERA,
        device: APPS.DEVICE_TYPE.EXTENSION,
        published: !!ENV.EXTENSION_OPERA_URL,
        link: {
          href: ENV.EXTENSION_OPERA_URL,
          label: APPS.STORE_NAME.OPERA_WEB_STORE,
        },
      })
    if (ENV.EXTENSION_EDGE_URL)
      downloadsList.push({
        name: APPS.DEVICE_NAME.EDGE,
        label: APPS.DEVICE_NAME.EDGE,
        device: APPS.DEVICE_TYPE.EXTENSION,
        published: !!ENV.EXTENSION_EDGE_URL,
        link: {
          href: ENV.EXTENSION_EDGE_URL,
          label: APPS.STORE_NAME.EDGE_WEB_STORE,
        },
      })*/
    if (ENV.KNOWLEDGE_BASE_URL)
      downloadsList.push({
        name: APPS.DEVICE_NAME.ROUTER,
        label: $t('web.downloads_router'),
        device: APPS.DEVICE_TYPE.OTHER,
        link: {
          href: ENV.KNOWLEDGE_BASE_URL,
          label: $t('web.downloads_instruction'),
        },
      })
    downloadsList.push({
      name: APPS.DEVICE_NAME.OPEN_VPN_PROFILES,
      label: $t('web.downloads_openVPN_profiles'),
      device: APPS.DEVICE_TYPE.OTHER,
      link: {
        href: ENV.KNOWLEDGE_BASE_URL,
        label: $t('web.downloads_instruction'),
      },
    })
    return downloadsList
  },
}

export default {
  namespaced: true,
  state,
  getters,
}
