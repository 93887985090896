{
    "web": {
        "why_use": "למה להשתמש ב- {productName}",
        "download_VPN": "הורד את {productName} והתחל להשתמש בו היום!",
        "getVPN": "קבל את {productName}",
        "access": "גישה בטוחה לתוכן מכל מקום בעולם",
        "jurisdiction": "סמכות השיפוט של האיחוד האירופי מבטיחה סודיות",
        "anonymity": "מניעת מעקב שירותי המודעות המבוסס על ה-IP",
        "simple_conf": "התקנה ושימוש קל",
        "secure": "תשלומים מאובטחים והגנה מפני האקרים",
        "protection": "הגנה מפני פרסום פולשני",
        "get_VPN_app_phone": "קבל את {productName} עבור המכשיר שלך",
        "keep_safe": "הישאר מבוטח ופרטי באינטרנט.",
        "different_device": "קבל את {productName} עבור מכשיר שונה",
        "authorize": "התחבר",
        "dont_have_an_account": "אין לך משתמש?",
        "register": "הירשם",
        "pass": "סיסמה",
        "sign_in": "התחבר",
        "forgot_pass": "שכחת סיסמה?",
        "email": "דואר אלקטרוני",
        "instructions_for_macos": "הוראות עבור MacOS",
        "instructions_for_windows": "הוראות עבור Windows",
        "current-plan": "התוכנית הנוכחית",
        "date": "תאריך",
        "price": "מחיר",
        "price_from": "מחיר מ-",
        "next_due": "התשלום הבא",
        "payment": "תשלום",
        "cancel": "ביטול",
        "go_to_settings": "עבור להגדרות",
        "ok": "אוקיי",
        "dialog_cancel-subscription_title-AppStore": "אתה יכול לבטל את המנוי דרך חשבון ה- Apple Store שדרכו נעשה המנוי",
        "dialog_cancel-subscription_title-PlayMarket": "תוכל לבטל את המנוי דרך חשבון ה- Play Market שדרכו נעשה המנוי",
        "dialog_cancel-subscription_title-sure": "האם אתה בטוח שברצונך לבטל את המנוי?",
        "yes": "כן",
        "no": "לא",
        "subscription_canceled_successfully": "המנוי בוטל בהצלחה",
        "dashboard_dialog_thanks-for-registration_title": "תודה על ההרשמה!",
        "dashboard_dialog_thanks-for-registration_description_1": "אנא זכור את הסיסמה שלך כדי להתחבר.",
        "Password": "סיסמה",
        "dashboard_dialog_thanks-for-registration_description_2-1": "או",
        "dashboard_dialog_thanks-for-registration_description_2-2": "לחץ כאן",
        "dashboard_dialog_thanks-for-registration_description_2-3": "כדי להגדיר סיסמה משלך.",
        "prices_date-of-next-payment": "התאריך הבא לחיוב הכסף",
        "change_password": "החלף סיסמה",
        "current_password": "סיסמה נוכחית",
        "new_password": "סיסמה חדשה",
        "confirm-password": "אשר סיסמה",
        "reset": "איפוס",
        "email_address_associated": "הזן את כתובת הדוא\"ל המשויכת לחשבונך. אנו נשלח לך בדוא\"ל קישור לאיפוס הסיסמה שלך.",
        "get-link": "שלח מייל",
        "sign-in": "התחבר",
        "forget-password_dialog_email-link_title": "שלחנו לך מייל עם קישור לאיפוס הסיסמה שלך.",
        "i-have-promocode": "יש לי קוד הנחה",
        "promo": "קוד הנחה",
        "have_an_account": "יש לי משתמש",
        "reset-password": "אפס סיסמה",
        "state": {
            "register": "הירשם"
        },
        "action": {
            "register": "הירשם",
            "cancel": "ביטול"
        },
        "notify_password-changed": "הסיסמה שונתה",
        "prices_discount": "התנתקות",
        "prices_email": "דואר אלקטרוני",
        "prices_country": "המדינה שלך",
        "prices_pay": "שלם",
        "prices_privacy_p-0": "על ידי שליחת טופס זה, אתה מסכים ל",
        "prices_privacy_p-1": "תנאי השירות",
        "prices_privacy_p-2": "גם",
        "prices_privacy_p-3": "מדיניות פרטיות",
        "price_card": "כרטיס",
        "price_internet-wallet": "ארנק דיגיטלי",
        "price_cryptocurrency": "מטבעות קריפטו",
        "prices_order-total": "סך כל ההזמנה",
        "i-dont-have-promocode": "אין לי קוד הנחה",
        "dashboard_dialog_thanks-for-registration_description_1_promo": "קוד ההנחה שהזנת אינו תקף",
        "prices_user-has-account_how-to-change-plan_1": "כדי לשנות את תוכנית התעריפים, בטל את המנוי הנוכחי ב-",
        "prices_user-has-account_how-to-change-plan_2": "חיובים",
        "prices_user-has-account_how-to-change-plan_3": "עמוד, אחרי זה רכוש אחד חדש",
        "instructions_for_linux": "הוראות עבור Linux",
        "prices_Step": "שלב",
        "tariff-name_Year": "שנה",
        "tariff-name_Month": "חודש",
        "month": "חודש",
        "prices_save": "שמור",
        "prices_billedEvery": "חיוב כל",
        "prices_the-most-popular": "הכי פופלארי",
        "prices_Billed": "מחויב",
        "prices_every": "כל",
        "icon_check-circle-empty": "עיגול ריק",
        "icon_check-circle": "תיבת סימון",
        "icon_like": "כמו",
        "icon_info": "מידע",
        "icon_down": "למטה",
        "icon_check": "תיבת סימון",
        "prices_the-1-year-is-better": "תוכנית לשנה אחת פירושה החיסכון הגדול ביותר עבורך",
        "prices_choose-plan": "בחר את התוכנית שלך",
        "prices_Chose-Payment-Method": "בחר סוג תשלום",
        "prices_Why-choose-VPN": "למה לבחור {productName}?",
        "prices_VPN-includes": "{productName} כולל",
        "prices_includes-support": "שירות לקוחות 24/7",
        "prices_includes-money-back": "{numberOfDays}-ימים להחזר כספי באחריות",
        "prices_includes-security": "אבטחה והצפנה ברמה בנקאית",
        "prices_includes-no-logs": "ללא בעיות חיבור ופעילות",
        "instruction": "הוראות",
        "apps": "אפליקציות",
        "my-ip": "ה- IP שלי",
        "terms": "תנאים והגבלות",
        "return-refund-policy": "מדיניות החזרה והחזרים",
        "policy_and_procedure": "מדיניות ונהלים של DMCA באמצעות הודעה והסרה",
        "privacy_policy": "מדיניות הפרטיות",
        "footer_support": "תמיכה",
        "footer_sales-department": "מחלקת מכירות",
        "footer_company": "חברה",
        "footer_contacts": "אנשי קשר",
        "data-retention-policy": "מדיניות שמירת נתונים",
        "pricing": "מחירים",
        "servers": "שרתים",
        "knowledge-base": "ידע בסיסי",
        "dashboard": "לוּחַ מַחווָנִים",
        "to_home_page": "לעמוד הבית",
        "icon_logo": "לוגו",
        "icon_login": "כניסה למערכת",
        "icon_menu": "תפריט",
        "icon_key": "מפתח",
        "icon_logout": "התנתקות",
        "log_out": "התנתקות",
        "icon_lifebuoy": "גלגל הצלה",
        "knowledge_base": "ידע בסיסי",
        "icon_support": "תמיכה",
        "downloads": "הורדות",
        "billings": "חיובים",
        "icon_eyeOpen": "הצג סיסמה",
        "inactive": "לֹא פָּעִיל. בצע את התשלום שלך כדי להתחיל להשתמש בו",
        "icon_flag": "לאותת",
        "active_until": "פעיל עד",
        "servers_cta_title": "התחל עוד היום בגלישה בטוחה",
        "servers_cta_description": "שמור על האינטרנט שלך בטוח ופרטי",
        "downloads_instruction": "הוראות",
        "downloads_router": "נתב",
        "downloads_openVPN_profiles": "פרופילי OpenVPN",
        "tariff-name-long_Year": "שנה אחת",
        "tariff-name-long_Month": "חודש אחד",
        "dialog_cancel-subscription_header": "בטל מנוי",
        "web_payment_history": "היסטוריית תשלום",
        "tariff": "תעריף",
        "payment_method": "אמצעי תשלום",
        "status": "סטטוס",
        "sum": "סה\"כ",
        "tbody_is_empty_1": "כאן תראה מידע אודות התשלומים שלך",
        "tbody_is_empty_2": "בצע הזמנה",
        "status_paid": "שולם",
        "status_not-paid": "לא שולם",
        "my_services": "השירותים שלי",
        "private_and_secure": "גישה פרטית ומאובטחת לאינטרנט (VPN)",
        "active": "פעיל",
        "downloads_apps_and_files": "אפליקציות וקבצים",
        "downloads_for_mobile": "מוביילים",
        "downloads_for_desktop": "שולחנות עבודה",
        "downloads_for_browsers": "דפדפנים",
        "downloads_other_devices": "מכשירים אחרים",
        "forget-password_dialog_email-link_header": "בדוק את הדואר האלקטרוני שלך",
        "pageNotFoundTitle": "העמוד לא נמצא",
        "pageNotFoundDescription": "נראה שאין דף כזה. אנא חזור אלינו.",
        "pageNotFoundAction": "חזור ל {productName} אתר",
        "home": {
            "mainTitle": {
                "highlited": "הדיגיטלי\nשלך",
                "rest": "הגנה"
            },
            "mainDescription": {
                "first": "מכל הנתונים האישיים והמחשבים שלך",
                "second": "נגד רמאי סייבר"
            },
            "mainButton": "החל מ- 2.50 $ לחודש*",
            "mainButtonDescription": "*החזר כספי מובטח",
            "secureTitle": {
                "first": "מאוד מאובטח",
                "second": "אינטרנט"
            },
            "secureDescription": "VPN99 מסיר את כל מה שעושה אותך ייחודי ומזוהה באינטרנט. הוא מסתיר את כתובת ה- IP שלך ומצפין נתונים כדי לשמור על היסטוריית הגלישה שלך פרטית.",
            "secureCard": {
                "noRestrictionsTitle": "ללא איסורים!",
                "noRestrictionsDescription": "כל אתרי האינטרנט זמינים עבורך, אפילו אתרים חסומים. יתר על כן, כל הנתונים שלך מוגנים באופן אמין מפני רמאים.",
                "anonimTitle": "עכשיו אתה אנונימי",
                "anonimDescription": "כל חיבור הוא אנונימי! אנחנו לא שומרים יומנים, אז אפילו לנו אין מושג מה אתה עושה באינטרנט;)"
            },
            "devicesTitle": {
                "first": "מנוי אחד",
                "second": "עבור כל המכשירים!"
            },
            "devicesDescription": "MacOS, Windows, Android, iOS, הרחבות עבור Chrome, Firefox ועוד",
            "serversTitle": {
                "first": "מהיר ויציב",
                "second": "חיבור לכל מקום!"
            },
            "serversDescription": "בחר אחד מתוך 17 שרתים או סמוך על השרת המומלץ - הוא הקרוב ביותר ולכן גם המהיר ביותר. תוכל לצפות בחופשיות בסרטים ותכניות טלוויזיה באיכות HD.",
            "faqTitle": "שאלות נפוצות",
            "faq": {
                "whatIsVPNTitle": "מה המשמעות של VPN?",
                "whatIsVPNDescription": {
                    "p1": "VPN משמעותו רשת וירטואלית פרטית.",
                    "p2": "המטרה העיקרית של השירות שלנו היא להבטיח לך אבטחה ופרטיות. בשביל לעשות זאת, {productName} מצפין את התנועה שאתה משדר דרך הרשת."
                },
                "rollUp": "גלול למעלה",
                "more": "קרא",
                "howWorksTitle": "איך VPN עובד?",
                "howWorksDescription": {
                    "p1": "VPN מוסיף ממשק רשת חדש למערכת שמתפקדת כאילו חיברת את המכשיר ישירות לרשת הפרטית. הוא גם משנה את כתובת ה- IP שלך ומצפין את כל הנתונים המועברים. VPN מאפשר לקבל מידע ולהעביר אותו ברשתות ציבוריות (דרך האינטרנט, למשל) ממש כאילו המכשיר שלך מחובר לרשת פרטית ומיישם ישירות את אותן מדיניות האבטחה ומתן גישה לכל המשאבים ברשת הפרטית."
                },
                "whatDiferenceWithProxyTitle": "מה ההבדל בין שירותי proxy לבין שירותי VPN?",
                "whatDiferenceWithProxyDescription": {
                    "p1": "חיבורי VPN הרבה יותר יציבים ומוצפנים, בניגוד ל- proxy. לא כל התוכנות מסוגלות לעבוד עם שרתי proxy ורבות מהן דורשות תצורה נוספת. אם אתה משתמש ב- VPN, אין צורך להגדיר כל יישום בנפרד."
                },
                "whatCountriesTitle": "אילו מדינות זמינות?",
                "whatCountries": {
                    "first": "ניתן למצוא את רשימת המיקומים הנוכחית במדינות השונות",
                    "link": "כאן",
                    "second": "אז אתה מקבל כתובת IP עם המיקום הגיאוגרפי של המדינות אליהן אתה מתחבר."
                },
                "logsTitle": "האם אתה שומר קבצי כניסה?",
                "logs": {
                    "p1": "ל{productName} יש מדיניות קפדנית ללא רישום."
                }
            },
            "footerTitle": {
                "first": "התחל",
                "second": "להשתמש ב VPN99"
            },
            "footerDescription": "הורד את האפליקציה במכשיר שלך"
        },
        "my_ip_address": "כתובת ה- IP שלי",
        "your_ip": "זוהי כתובת ה- IP שלך",
        "fail-payment_title": "אופס! משהו השתבש.",
        "fail-payment_description": "התעריף שלך לא שולם. אולי טעית בעת הזנת הנתונים במהלך התשלום. אל תדאג, תוכל לנסות שוב.",
        "fail-payment_go-to-prices": "עבור אל תוכניות התעריפים ונסה שוב",
        "success_congratulations-title": "ברכות על הרכישה!",
        "success_congratulations-description": "אנא המתן. אתה תנותב לחשבון האישי שלך, שבו תוכל לנהל את הרכישות שלך.",
        "success-payment_go-to-dashboard": "התחבר עכשיו",
        "servers_title": "גישה בלחיצה אחת ל10 מדינות ומעלה",
        "servers_title_description": "בחר שרת VPN המופעל ב {continents_number} יבשות באחת מ {servers_number} היבשות",
        "servers_locations_list_title": "רשימת מיקומי שרתי ה- VPN",
        "servers_continent_all": "כל המדינות",
        "allservers": "מדינה",
        "continent_asia": "אסיה",
        "continent_europe": "אירופה",
        "continent_america": "אמריקה",
        "our_vpn_server": "השרתים שלנו",
        "subscription_canceled_failure": "אופס! אירעה שגיאה בעת הביטול. אנא צור קשר עם התמיכה.",
        "prices_trial-days": "{count} ימי ניסיון",
        "days": "{count} ימים",
        "prices_trial": "ניסיון",
        "pageForbidden": "גישה אסורה",
        "pageForbiddenTitle": "גישה אסורה",
        "pageForbiddenDescription": "נראה שהמקום אליו ניסית לקבל גישה הינו מוגבל.",
        "pageForbiddenAction": "חזור לאתר של {productName}",
        "private_finally": "גלישה פרטית באינטרנט",
        "no_one_can_see": "אף אחד לא יכול לראות מה אתה עושה",
        "no_restrictions_on_content_and_censorship": "אין הגבלות מקוונות או צנזורה",
        "one_click_and_you_defend": "קליק אחד ואתה מוגן",
        "removes_everything": "{productName} מסיר את כל מה שהופך אותך לייחודי וניתן לזיהוי מקוון על ידי הסתרת כתובת ה-IP שלך והצפנת הנתונים שלך כדי לשמור על פרטיות היסטוריית הגלישה שלך.",
        "enjoy_fast_and_stable_connection": "תהנה מחיבור מהיר ויציב בכל מקום",
        "choose_your_server": "בחר את השרת שלך ב {count} מדינות ותיהנה משירות ה-VPN המהיר ביותר. יותר שרתים פירושם פחות עומס ומגוון רחב של מיקומים פירושם סיכוי גבוה יותר שתמצא אחד בקרבת מקום",
        "easy_to_use_apps": "אפליקציות ידידותיות למשתמש עבור כל המכשירים שלך",
        "subscription_comes": "מנוי אחד ל-{productName} לכל מכשיר שבבעלותך: {appsList} ועוד",
        "money_back_guarantee": "אחריות להחזר כספי",
        "faq_footer_description": "אתה יכול למצוא מידע אחר שמעניין אותך אצלנו",
        "faq_footer_knowledge-base": "בסיס ידע",
        "tryFree": "נסה בחנם",
        "year": "שנה",
        "icon_up": "למעלה",
        "status_failed": "נכשל",
        "status_refund": "הֶחזֵר",
        "status_trial": "ניסיון",
        "onHoldMessage": "משהו השתבש ולא הצלחנו לחייב אותך אוטומטית עבור מנוי {productName} במכשיר ה-{platform} שלך."
    },
    "css": "",
    "validator": {
        "is_empty": "השדה צריך להיות ריק",
        "is_not_empty": "שדה חובה",
        "is_email": "בדוק שהנתונים נכונים",
        "is_phone": "הזן את המספר הנכון",
        "is_password": "הסיסמה לא יכולה להכיל {extraSymbols}",
        "is_password_has_enough_symbols": "הסיסמה חייבת להכיל 6 תווים לפחות",
        "is_password_less_max_symbols": "הסיסמה חייבת להכיל פחות מ32 תווים",
        "is_password_has_space": "הסיסמה לא יכולה להכיל סימן רווח",
        "is_not_empty_with_field_name": "השדה {fieldName} הוא חובה",
        "is_password_has_not_english_letters": "אין להכניס אותיות לא לטיניות"
    },
    "passwords-not-equal": "סיסמה לא תואמת",
    "error": {
        "e500": {
            "new": {
                "text": "אנחנו כבר יודעים על הבעיה הזו ונפתור אותה בקרוב! בבקשה נסה שוב מאוחר יותר.",
                "title": "500 - שגיאת שרת"
            }
        },
        "eUnknown": {
            "new": {
                "text": "אנחנו כבר יודעים על הבעיה הזו ונפתור אותה בקרוב! בבקשה נסה שוב מאוחר יותר.",
                "title": "500 - שגיאת שרת"
            },
            "oops": {
                "text": "אופס! אירעה שגיאה בעת טעינת נתוני משתמש. אנא נסה לטעון מחדש את הדף."
            }
        },
        "e400": {
            "new": {
                "title": "שגיאה"
            }
        }
    },
    "whitelabel": {
        "vpn99": {
            "web_be_safe": "אבטחה מקוונת במחיר הטוב ביותר",
            "web_online_security": "החל מ-${pricePerMonth} בלבד לחודש"
        },
        "trustvpn": {
            "web_be_safe": "הגן על פרטיותך המקוונת עכשיו!",
            "web_online_security": "מהירות מהירה במיוחד, שמור על הפרטיות שלך, חופש דיגיטלי"
        }
    },
    "meta": {
        "descriptionDataRetentionPolicy": "המצבים והתקופות בהם הנתונים שלך יישמרו. כל הנתונים שלך מאוחסנים בצורה מאובטחת בהתאם להנחיות GDPR.",
        "descriptionMyIp": "מצא את כתובת ה- IP שלך, כתובת המחשב שלך באינטרנט. ראה כיצד מופיעה כתובת ה- IP שלך",
        "descriptionPrivacyPolicy": "מדיניות הפרטיות שלנו כוללת מידע על הנתונים שאנו אוספים וכיצד אנו משתמשים בהם, כמו גם כיצד תוכל לשלוט במידע שלך. אנו משתמשים בעוגיות בהתאם לחוק הוולשי, אך תוכל לשנות את העדפותיך.",
        "descriptionTerms": "על ידי קבלת תנאי השימוש של {productName} אתה מסכים לתנאים ולהגבלות, כמו גם לאחריות של שני הצדדים המפורטים בהסכם. ניתן לקבל פיצוי בנסיבות מסוימות כאשר שירותי ה- VPN אינם זמינים או אינם תקינים במשך יותר מ -12 שעות רצופות.",
        "descriptionAccountBilling": "חשבון - היסטוריית תשלומים",
        "descriptionAccountPanel": "מרכז השליטה של החשבון",
        "descriptionAccountPassword": "חשבון - שנה סיסמה",
        "descriptionDownload": "הורד",
        "descriptionForgot": "שחזר סיסמה",
        "descriptionReset": "אפס סיסמה",
        "descriptionHome": "הורד והתקן את {productName} ותקבל הגנה מלאה ברחבי העולם. {productName}, מהירויות גבוהות ואנונימיות מלאה.",
        "descriptionPayFail": "התשלום נדחה",
        "descriptionPaySuccess": "התשלום עבר בהצלחה",
        "descriptionPrice": "קנה והתקן את ה-{productName} ותקבל הגנה מלאה ברחבי העולם. קבל את כל היתרונות של ה- VPN היקר. {productName}, מהירויות גבוהות ואנונימיות מלאה",
        "descriptionServers": "שרתים",
        "titleDataRetentionPolicy": "מדיניות שמירת נתונים",
        "titleMyIp": "גלה מה כתובת ה- IP שלך כמקוונת",
        "titlePrivacyPolicy": "מדיניות הפרטיות: השלבים ב {productName} ננקטו כדי להגן על המידע הפרטי שלך",
        "titleTerms": "תנאי שימוש: התנאים הספציפיים החלים בעת ניצול השירותים הניתנים על ידי {productName}.",
        "titleAccountBilling": "חשבון - היסטוריית תשלומים",
        "titleAccountPanel": "חשבון - מרכז השליטה",
        "titleAccountPassword": "חשבון - שנה סיסמה",
        "titleDownload": "הורד",
        "titleForgot": "שחזר סיסמה",
        "titleReset": "אפס סיסמה",
        "titleHome": "הורד את {productName} עוד היום ותקבל כיסוי אמין במחיר משתלם.",
        "titleLogin": "התחבר",
        "titlePayFail": "התשלום נדחה",
        "titlePaySuccess": "התשלום עבר בהצלחה",
        "titlePrice": "קבל את {productName} עוד היום ותקבל כיסוי אמין במחיר משתלם",
        "titleRegister": "הירשם",
        "titleServers": "שרתים",
        "titleApps": "אפליקציות",
        "descriptionApps": "אפליקציות",
        "descriptionLogin": "התחבר",
        "descriptionRegister": "הירשם",
        "titleReturnRefundPolicy": "מדיניות החזרות והחזרים",
        "descriptionReturnRefundPolicy": "מדיניות החזרות והחזרים",
        "titlePolicyAndProcedure": "מדיניות ונהלים של DMCA באמצעות הודעה והסרה",
        "descriptionPolicyAndProcedure": "מדיניות ונהלים של DMCA באמצעות הודעה והסרה"
    },
    "getVPN": "קבל את {productName}",
    "signIn": "התחבר",
    "billings-page": {
        "waiting-processing": "ממתין לתגובה מעיבוד התשלום."
    },
    "servers": {
        "USDal": "ארה\"ב דאלס"
    }
}
