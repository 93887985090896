import REST from './REST.js'
import User from './modules/user.js'
import Merchant from './modules/merchant.js'
import Country from './modules/country.js'
import Auth from './modules/auth.js'
import Payment from './modules/payment.js'
import Postback from './modules/postback.js'
import Promo from './modules/promo.js'
import MagicLink from './modules/magicLink.js'

export default class Api {
  constructor() {}

  create(axios) {
    const rest = new REST(axios)
    this._init(rest)
  }

  _init(rest) {
    this.user = new User(rest)
    this.country = new Country(rest)
    this.auth = new Auth(rest)
    this.merchant = new Merchant(rest)
    this.payment = new Payment(rest)
    this.postback = new Postback(rest)
    this.promo = new Promo(rest)
    this.magicLink = new MagicLink(rest)
  }
}
