import { APP_NAME } from './config'

export default (i18n) => {
  // Чтобы работал автоматический поиск строк для переводов вайтлейблов
  // необходимо явно здесь указывать точное наименование строки перевода
  // например: whitelabel.vpn99.copyright и whitelabel.trustvpn.copyright
  // пока таких переводов немного - это оправдано

  const $t = (val, params) => i18n.global.t(val, params)
  return {
    [APP_NAME.VPN99]: {
      webBeSafe: (p) => $t('whitelabel.vpn99.web_be_safe', p),
      webOnlineSecurity: (p) => $t('whitelabel.vpn99.web_online_security', p),
    },
    [APP_NAME.TRUST_VPN]: {
      webBeSafe: (p) => $t('whitelabel.trustvpn.web_be_safe', p),
      webOnlineSecurity: (p) =>
        $t('whitelabel.trustvpn.web_online_security', p),
    },
  }
}
